import React, {
  Fragment,
  useRef,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CgClose } from "react-icons/cg";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import ToogleImg from "../../assets/Icon Button.png";
import MultipleActivitySelect from "../common/MultipleActivitySelect";
import { createMailReport, updateMailReport } from "../../service/Worklog";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
const translations = {
  en: {
    emailreport: "Schedule Email Report",
    selectactivity: "Select the activity:",
    recipients: "Recipients",
    bcc: "Bcc",
    cc: "Cc",
    sendreport: "Send scheduled report",
    senddashboard: "Send Dashboard:",
    status: "Status",
    shedulesend: "Schedule send:",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    update: "Update",
    send: "Send",
    cancel: "Cancel",
  },
  ar: {
    emailreport: "جدولة تقرير البريد الإلكتروني",
    selectactivity: "اختر النشاط:",
    recipients: "المستلمون",
    bcc: "نسخة مخفية الوجهة",
    cc: "نسخة",
    sendreport: "إرسال التقرير المقرر",
    senddashboard: "إرسال لوحة المعلومات:",
    status: "حالة",
    shedulesend: "جدول الإرسال:",
    daily: "يوميًا",
    weekly: "أسبوعي",
    monthly: "شهريا",
    update: "تحديث",
    send: "يرسل",
    cancel: "يلغي",
  },
};
export default function EmailReport({ open, setOpen }) {
  const cancelButtonRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState([]);
  const { workflowId, getScheduleReportDetails, setWorkflowId, langMode } =
    useContext(AppContext);

  const [emailState, setEmailState] = useState({
    activity: "Change Meter 5242",
    reportId: "Field.Change Meter_5242",
    recipients: [],
    cc: [],
    bcc: [],
    hour: 12,
    minute: 0,
    period: "AM",
    isEnabledScheduledReport: false,
    isEnabledDashboard: false,
    showBcc: false,
    showCc: false,
    selectedFrequency: "DAILY",
    showDays: false,
    showTimer: true,
    selectedDayIndex: new Date().getDay(),
    emailId: null,
    isEnabledStatus: false,
  });

  const day = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  useEffect(() => {
    if (getScheduleReportDetails) {
      const details = getScheduleReportDetails.scheduledReport || {};
      setEmailState((prevState) => ({
        ...prevState,
        recipients: getScheduleReportDetails.toEmailIds || [],
        cc: getScheduleReportDetails.ccEmailIds || [],
        bcc: getScheduleReportDetails.bccEmailIds || [],
        isEnabledScheduledReport: details.sendExcelReport || false,
        isEnabledDashboard: details.sendDashboard || false,
        selectedFrequency: details.frequency || "DAILY",
        selectedDayIndex: day.findIndex(
          (d) => d.toUpperCase() === (details.startDay || "").toUpperCase()
        ),
        hour: parseInt(details.startTime?.split(":")[0], 10) % 12 || 12,
        minute: parseInt(details.startTime?.split(":")[1], 10) || 0,
        period:
          parseInt(details.startTime?.split(":")[0], 10) >= 12 ? "PM" : "AM",
        showDays: details.frequency !== "DAILY",
        showTimer: true,
        emailId: details.id || null,
      }));
      setWorkflowId(getScheduleReportDetails.workflowIds || []);
    }
  }, [getScheduleReportDetails, setWorkflowId, open]);

  const handleToggle = (field) => {
    setEmailState((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const togglePeriodUp = () => {
    setEmailState((prevState) => ({
      ...prevState,
      period: prevState.period === "PM" ? "AM" : "PM",
    }));
  };

  const handleInputChange = (field, value) => {
    setEmailState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleRecipientsChange = (e, field) => {
    const emailsArray = e.target.value
      .split(",")
      .map((email) => email.trim())
      .filter((email) => email.length > 0);

    setEmailState((prevState) => ({
      ...prevState,
      [field]: emailsArray,
    }));
  };

  const handleFrequencyChange = (value) => {
    setEmailState((prevState) => ({
      ...prevState,
      selectedFrequency: value,
      showDays: value !== "DAILY",
      showTimer: true,
    }));
  };

  const convertTo24HourFormat = (hour, minute, period) => {
    let convertedHour = period === "PM" && hour !== 12 ? hour + 12 : hour % 12;
    return `${convertedHour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}:00`;
  };

  const emailReportSend = useCallback(async () => {
    const {
      selectedDayIndex,
      hour,
      minute,
      period,
      isEnabledScheduledReport,
      isEnabledDashboard,
      selectedFrequency,
      emailId,
    } = emailState;
    const selectedDay = day[selectedDayIndex];
    const timeIn24HourFormat = convertTo24HourFormat(hour, minute, period);
    const payload = {
      scheduledReport: {
        sendExcelReport: isEnabledScheduledReport,
        sendDashboard: isEnabledDashboard,
        frequency: selectedFrequency,
        startDay: selectedDay.toUpperCase(),
        startTime: timeIn24HourFormat,
        status: emailState.isEnabledStatus,
        ...(emailId && { id: emailId }),
      },
      workflowIds: workflowId,
      toEmailIds: emailState.recipients,
      ccEmailIds: emailState.cc,
      bccEmailIds: emailState.bcc,
    };
    try {
      const responseData = emailId
        ? await updateMailReport(payload)
        : await createMailReport(payload);
      if (responseData.header.code === 600) {
        toast.success("Report processed successfully!");
        setOpen(false);
      } else {
        toast.error(`Error: ${responseData.body.value}`);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(`Error submitting form: ${error.message}`);
    }
  }, [emailState, workflowId]);

  useEffect(() => {}, [emailState]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 flex items-center justify-center p-4"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            className={`relative bg-white rounded-lg shadow-xl transform transition-all sm:max-w-2xl sm:w-full ${
              emailState.isMaximized ? "h-full" : "max-h-[85vh] overflow-y-auto"
            }`}
          >
            <div className="flex justify-between items-center px-4 py-2 bg-gray-800">
              <Dialog.Title
                as="h3"
                className="text-sm font-semibold text-gray-100"
              >
                {translations[langMode].emailreport}
              </Dialog.Title>
              <div className="flex items-center space-x-2">
                <CgClose
                  className="h-6 w-6 text-gray-100 cursor-pointer"
                  onClick={() => setOpen(false)}
                />
              </div>
            </div>
            <div className="bg-white px-4 ">
              <div className="flex gap-3 p-1 border-b">
                <label
                  htmlFor="activity"
                  className="text-sm text-gray-700 mt-2 whitespace-nowrap"
                >
                  {translations[langMode].selectactivity}
                </label>
                <MultipleActivitySelect
                  getWorkflowId={workflowId}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
              </div>
              {/* <div className="flex gap-3 p-1 border-b">
                <label
                  htmlFor="reportId"
                  className="text-sm text-gray-700 mt-2"
                >
                  Report ID:
                </label>
                <span
                  id="reportId"
                  className=" text-gray-600 block p-2 text-xs rounded-full border border-gray-300 bg-gray-300 shadow-sm sm:text-sm"
                >
                  {emailState.reportId}
                </span>
              </div> */}
              <div className="flex justify-between">
                <div className="flex p-1 border-b">
                  <label
                    htmlFor="recipients"
                    className="text-md text-gray-700 mt-2"
                  >
                    {translations[langMode].recipients}:
                  </label>
                  <input
                    id="recipients"
                    type="text"
                    value={emailState.recipients.join(", ")}
                    onChange={(e) => handleRecipientsChange(e, "recipients")}
                    placeholder="Enter recipients, separated by commas"
                    className="flex-1 w-[350px] border-b border-white bg-white p-2 text-sm rounded-none focus:ring-0 focus:border-gray-300 outline-none"
                  />
                </div>
                <div className="flex gap-3">
                  <button
                    type="button"
                    onClick={() => handleToggle("showBcc")}
                    className="text-md text-blue-500 hover:underline"
                  >
                    {translations[langMode].bcc}
                  </button>
                  <button
                    type="button"
                    onClick={() => handleToggle("showCc")}
                    className="text-md text-blue-500 hover:underline"
                  >
                    {translations[langMode].cc}
                  </button>
                </div>
              </div>
              {emailState.showBcc && (
                <div className="flex gap-3">
                  <label className="mt-4 text-md">{translations[langMode].bcc}</label>
                  <input
                    type="email"
                    value={emailState.bcc.join(", ")}
                    onChange={(e) => handleRecipientsChange(e, "bcc")}
                    placeholder="Enter Bcc, separated by commas"
                    className="mt-2 w-full border-b border-white bg-white p-2 text-sm rounded-none focus:ring-0 focus:border-gray-300 outline-none"
                  />
                </div>
              )}
              {emailState.showCc && (
                <div className="flex gap-3">
                  <label className="mt-4 text-md">{translations[langMode].cc}</label>
                  <input
                    type="email"
                    value={emailState.cc.join(", ")}
                    onChange={(e) => handleRecipientsChange(e, "cc")}
                    placeholder="Enter Cc, separated by commas"
                    className="mt-2 w-full border-b border-white bg-white p-2 text-sm rounded-none focus:ring-0 focus:border-gray-300 outline-none"
                  />
                </div>
              )}
              <div className="gap-3 py-1">
                <div className="flex gap-3 border-b py-1 my-2">
                  <span className="text-md text-gray-700 ">
                    {translations[langMode].sendreport}
                  </span>
                  <button
                    onClick={() => handleToggle("isEnabledScheduledReport")}
                    className={`px-2 py-1 rounded-full flex items-center justify-between w-20 transition-colors duration-300 ${
                      emailState.isEnabledScheduledReport
                        ? "bg-blue-800"
                        : "bg-gray-700"
                    }`}
                  >
                    <span
                      className={`transform transition-transform duration-300 ${
                        emailState.isEnabledScheduledReport
                          ? "translate-x-6"
                          : "translate-x-0"
                      }`}
                    >
                      <img src={ToogleImg} className="h-5 w-5" alt="Toggle" />
                    </span>
                    <span className="text-sm text-white">
                      {emailState.isEnabledScheduledReport ? "Yes" : "No"}
                    </span>
                  </button>
                </div>
                <div className="py-1 flex gap-3 border-b my-2">
                  <span className="text-md text-gray-700">
                    {translations[langMode].senddashboard}:
                  </span>
                  <button
                    onClick={() => handleToggle("isEnabledDashboard")}
                    className={`px-2 py-1 rounded-full flex items-center justify-between w-20 transition-colors duration-300 ${
                      emailState.isEnabledDashboard
                        ? "bg-blue-800"
                        : "bg-gray-700"
                    }`}
                  >
                    <span
                      className={`transform transition-transform duration-300 ${
                        emailState.isEnabledDashboard
                          ? "translate-x-6"
                          : "translate-x-0"
                      }`}
                    >
                      <img src={ToogleImg} className="h-5 w-5" alt="Toggle" />
                    </span>
                    <span className="text-sm text-white">
                      {emailState.isEnabledDashboard ? "Yes" : "No"}
                    </span>
                  </button>
                </div>
                <div className="py-1 flex gap-3 border-b my-2">
                  <span className="text-md text-gray-700">
                    {translations[langMode].status}:
                  </span>
                  <button
                    onClick={() => handleToggle("isEnabledStatus")}
                    className={`px-2 py-1 rounded-full flex items-center justify-between w-20 transition-colors duration-300 ${
                      emailState.isEnabledStatus ? "bg-blue-800" : "bg-gray-700"
                    }`}
                  >
                    <span
                      className={`transform transition-transform duration-300 ${
                        emailState.isEnabledStatus
                          ? "translate-x-6"
                          : "translate-x-0"
                      }`}
                    >
                      <img src={ToogleImg} className="h-5 w-5" alt="Toggle" />
                    </span>
                    <span className="text-sm text-white">
                      {emailState.isEnabledStatus ? "Acive" : "Deactive"}
                    </span>
                  </button>
                </div>
                <div className="flex gap-3 items-center">
                  <span className="text-md text-gray-700">
                    {translations[langMode].shedulesend}
                  </span>
                </div>
              </div>
              <div className="py-1 flex gap-5 border-b">
                <div className="flex flex-col gap-2 py-2">
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="frequency"
                      value="DAILY"
                      onChange={(e) => handleFrequencyChange(e.target.value)}
                      checked={emailState.selectedFrequency === "DAILY"}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                    />
                    <label className="text-md text-gray-700">
                      {translations[langMode].daily}
                    </label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="frequency"
                      value="WEEKLY"
                      onChange={(e) => handleFrequencyChange(e.target.value)}
                      checked={emailState.selectedFrequency === "WEEKLY"}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                    />
                    <label className="text-md text-gray-700">
                      {translations[langMode].weekly}
                    </label>
                  </div>
                  <div className="flex items-center gap-2 ">
                    <input
                      type="radio"
                      name="frequency"
                      value="MONTHLY"
                      onChange={(e) => handleFrequencyChange(e.target.value)}
                      checked={emailState.selectedFrequency === "MONTHLY"}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                    />
                    <label className="text-md text-gray-700">
                      {translations[langMode].monthly}
                    </label>
                  </div>
                </div>
                {emailState.showDays && (
                  <div className="flex flex-col items-center justify-center p-2">
                    <button
                      onClick={() =>
                        handleInputChange(
                          "selectedDayIndex",
                          (emailState.selectedDayIndex - 1 + 7) % 7
                        )
                      }
                      className="px-4 py-2 text-gray-600 hover:text-gray-800"
                    >
                      <MdKeyboardArrowUp  className=" text-gray-800 " />
                    </button>
                    <span className="mx-2 text-sm text-gray-700">
                      {day[emailState.selectedDayIndex]}
                    </span>
                    <button
                      onClick={() =>
                        handleInputChange(
                          "selectedDayIndex",
                          (emailState.selectedDayIndex + 1) % 7
                        )
                      }
                      className="px-4 py-2 text-gray-600 hover:text-gray-800"
                    >
                      <MdKeyboardArrowDown />
                    </button>
                  </div>
                )}
                {emailState.showTimer && (
                  <div className="flex flex-col items-center justify-center">
                    <div className="flex items-center space-x-2">
                      <div className="flex flex-col items-center">
                        <button
                          onClick={() =>
                            handleInputChange(
                              "hour",
                              emailState.hour === 12 ? 1 : emailState.hour + 1
                            )
                          }
                          className="text-gray-600 hover:text-gray-800"
                        >
                          <MdKeyboardArrowUp />
                        </button>
                        <span className="text-sm text-gray-700">
                          {emailState.hour.toString().padStart(2, "0")}
                        </span>
                        <button
                          onClick={() =>
                            handleInputChange(
                              "hour",
                              emailState.hour === 1 ? 12 : emailState.hour - 1
                            )
                          }
                          className="text-gray-600 hover:text-gray-800"
                        >
                          <MdKeyboardArrowDown />
                        </button>
                      </div>
                      <span className="text-lg text-gray-700">:</span>
                      <div className="flex flex-col items-center">
                        <button
                          onClick={() =>
                            handleInputChange(
                              "minute",
                              emailState.minute === 59
                                ? 0
                                : emailState.minute + 1
                            )
                          }
                          className="text-gray-600 hover:text-gray-800"
                        >
                          <MdKeyboardArrowUp />
                        </button>
                        <span className="text-sm text-gray-700">
                          {emailState.minute.toString().padStart(2, "0")}
                        </span>
                        <button
                          onClick={() =>
                            handleInputChange(
                              "minute",
                              emailState.minute === 0
                                ? 59
                                : emailState.minute - 1
                            )
                          }
                          className="text-gray-600 hover:text-gray-800"
                        >
                          <MdKeyboardArrowDown />
                        </button>
                      </div>
                      <div className="flex flex-col items-center">
                        <button
                          onClick={() => togglePeriodUp("period")}
                          className="text-gray-600 hover:text-gray-800"
                        >
                          <MdKeyboardArrowUp />
                        </button>
                        <span className="text-sm text-gray-700">
                          {emailState.period}
                        </span>
                        <button
                          onClick={() => togglePeriodUp("period")}
                          className="text-gray-600 hover:text-gray-800"
                        >
                          <MdKeyboardArrowDown />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="bg-gray-50 px-4 sm:flex py-1 sm:flex-row-reverse sm:px-6 gap-5">
              {emailState.emailId ? (
                <button
                  type="button"
                  onClick={emailReportSend}
                  className="px-4 py-2 bg-blue-800 text-white rounded-md"
                >
                  {translations[langMode].update}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={emailReportSend}
                  className="px-4 py-2 bg-blue-800 text-white rounded-md"
                >
                  {translations[langMode].send}
                </button>
              )}
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => setOpen(false)}
                ref={cancelButtonRef}
              >
                {translations[langMode].cancel}
              </button>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
