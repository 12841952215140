import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { MdDashboardCustomize, MdEditSquare } from "react-icons/md";
import FilterSelect from "../../components/common/FilterSelect";
import Header from "../../components/_app/Header";

const LeaderboardPreview = () => {
  return (
    <>
    <Header pageName={"Leader Board"} />
      <div className="px-5 py-5 flex justify-between items-center">
        <div className="bg-white p-2 border w-60 border-gray-400 flex justify-between items-center">
          <div className="flex flex-col items-center gap-2">
            <h6 className="text-sm font-semibold">Leader Board 1</h6>
            <span className="text-xs text-gray-400">Salatah water DC</span>
          </div>
          <MdEditSquare />
        </div>
        <div className="flex">
          <button
            type="button"
            class="text-white bg-yellow-400 flex items-center gap-4 hover:bg-[#FF9119]/80 focus:ring-4 focus:outline-none focus:ring-[#FF9119]/50 font-medium rounded-lg text-sm px-4 py-2 text-center inline-flex items-center dark:hover:bg-[#FF9119]/80 dark:focus:ring-[#FF9119]/40 me-2"
          >
            <MdDashboardCustomize className="w-5 h-5" /> Add Leader-board
          </button>
          <button
            data-modal-hide="small-modal"
            type="button"
            class="text-white bg-blue-800 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 ml-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Save Leader-board
          </button>
        </div>
      </div>
      <div className="px-5 py-5 ">
        <div class="grid grid-cols-3 gap-4">
          <div className="bg-white">
            <div className="p-4">
              <div className="flex flex-col gap-4">
                <div className="flex justify-between items-center text-sm font-semibold">
                  <span>Name of the Field Agent</span>
                  <span>Total Task completed</span>
                </div>
                <div className="flex justify-between items-center text-sm p-3 font-semibold text-white bg-orange-400 rounded-md">
                  <span className="flex items-center gap-2">
                    <IoIosArrowDown /> 1. Jerry Terry
                  </span>
                  <span>20</span>
                </div>
                <div className="flex justify-between items-center text-sm p-3 font-semibold text-white bg-yellow-400  rounded-md">
                  <span className="flex items-center gap-2">
                    <IoIosArrowDown /> 1. Jerry Terry
                  </span>
                  <span>20</span>
                </div>
                <div className="flex justify-between items-center text-sm p-3 font-semibold text-white bg-blue-400  rounded-md">
                  <span className="flex items-center gap-2">
                    <IoIosArrowDown /> 1. Jerry Terry
                  </span>
                  <span>20</span>
                </div>
                <div className="flex justify-between items-center text-sm p-3 font-semibold text-gray-700 bg-gray-200 rounded-md">
                  <span className="flex items-center gap-2">
                    <IoIosArrowDown /> 1. Jerry Terry
                  </span>
                  <span>20</span>
                </div>
                <div className="flex justify-between items-center text-sm p-3 font-semibold text-gray-700 bg-gray-200 rounded-md">
                  <span className="flex items-center gap-2">
                    <IoIosArrowDown /> 1. Jerry Terry
                  </span>
                  <span>20</span>
                </div>
                <div className="flex justify-between items-center text-sm p-3 font-semibold text-gray-700 bg-gray-200 rounded-md">
                  <span className="flex items-center gap-2">
                    <IoIosArrowDown /> 1. Jerry Terry
                  </span>
                  <span>20</span>
                </div>
                <div className="flex justify-between items-center text-sm p-3 font-semibold text-gray-700 bg-gray-200 rounded-md">
                  <span className="flex items-center gap-2">
                    <IoIosArrowDown /> 1. Jerry Terry
                  </span>
                  <span>20</span>
                </div>
                <div className="flex justify-between items-center text-sm p-3 font-semibold text-gray-700 bg-gray-200 rounded-md">
                  <span className="flex items-center gap-2">
                    <IoIosArrowDown /> 1. Jerry Terry
                  </span>
                  <span>20</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="grid grid-flow-row-dense grid-cols-1 grid-rows-2 ... gap-4">
              <div class="text-center bg-white p-4 border border-gray-400">
                <h6 className="mt-20 mb-20 text-blue-800 font-semibold">
                  Create your dashboard here or add new Dashboard
                </h6>
              </div>
              <div class="text-center bg-white p-4 border border-gray-400">
                <h6 className="mt-20 mb-20 text-blue-800 font-semibold">
                  Create your dashboard here or add new Dashboard
                </h6>
              </div>
            </div>
          </div>
          <div className="bg-white">
            <div className="m-5">
              <h1 className="font-bold text-xl">Add Parameter</h1>
              <div className="flex my-5 justify-between text-gray-600 font-medium">
                <span>Parameter</span>
                <select className="rounded-md">
                  <option selected disabled>
                    count
                  </option>
                </select>
              </div>
              <div>
                <select className="rounded-md w-full text-gray-600 ">
                  <option selected disabled>
                    TASK ID
                  </option>
                </select>
              </div>
              <div className="border border-gray-500 rounded-lg my-5 w-[330px] h-[250px] flex items-center justify-center">
                <button className="border border-gray-500 rounded-lg py-2 px-2">
                  Add Parameter
                </button>
              </div>
              <div className="justify-end">
                <button class=" shadow-inner focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium border border-gray-400 rounded-lg text-sm px-3 py-1.5 ml-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  Cancle
                </button>
                <button class="text-white bg-blue-800 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 ml-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaderboardPreview;
