import React, { useContext, useState, useEffect } from "react";
import FilterSelect from "../common/FilterSelect";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { CgExport } from "react-icons/cg";
import { FaCar } from "react-icons/fa";
import { LuCassetteTape } from "react-icons/lu";
import AssignAsset from "./AssignAsset";
import AssignVehical from "./AssignVehical";
import AppContext from "../../context/AppContext";
import {
  handleAssetExportClick,
  handleVehicalExportClick,
} from "../../service/Asset";
import { fetchFieldAgentMap } from "../../service/Task";
import { MdUpload, MdWallet } from "react-icons/md";
import { FaWallet } from "react-icons/fa6";
const translations = {
  en: {
    exportreport: "Export Report",
    addVehical: "Add Vehical",
    addAsset: " Add New Asset",
    from: "From",
    to: "To",
    cancle: "Cancel",
    search: "Search",
    vehicalType: "Vehicle type",
    vehicalAssign: "Vehicle Assignee",
    searchbyId: "Search By Id...",
    assetType: "Asset type",
    assetAssign: "Asset Assignee",
  },
  ar: {
    exportreport: "تقرير التصدير",
    addVehical: "أضف مركبة",
    addAsset: "إضافة أصول جديدة",
    from: "من",
    to: "ل",
    cancle: "يلغي",
    search: "يبحث",
    vehicalType: "نوع المركبة",
    vehicalAssign: "المكلف بالمركبة",
    searchbyId: "البحث بالمعرف...",
    assetType: "نوع الأصول",
    assetAssign: "المكلف بالأصول",
  },
};
const AssetToolbar = ({
  tab,
  onFilterChange,
  selectedItems,
  onSearch,
  onAssertSearch,
}) => {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const [selectedUserName, setSelectedUserName] = useState();
  const { setFieldAgentList, fieldAgentList, setSelectedUserId, langMode } =
    context;
  const vehicalType = [
    { id: "Everything", value: "Everything" },
    { id: "SUV", value: "SUV" },
    { id: "Hatchback", value: "Hatchback" },
  ];
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const [selectedAssetType, setSelectedAssetType] = useState("");
  const { theme, selectedOrgId } = useContext(AppContext);
  const [showExportPopup, setShowExportPopup] = useState(false);
  const [fromDate, setFromDate] = useState(getFormattedDate(new Date()));
  const [toDate, setToDate] = useState(getFormattedDate(getYesterday()));
  const [searchTerm, setSearchTerm] = useState("");
  const [assetIdsearch, setAssetIdsearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const assetType = [
    { id: "Everything", value: "Everything" },
    { id: "Honeywell_Mobile", value: "Honeywell_Mobile" },
    { id: "Electric_Meter", value: "Electric_Meter" },
    { id: "Water_Meter", value: "Water_Meter" },
  ];

  const handleVehicleTypeChange = (value) => {
    setSelectedVehicleType(value);
    onFilterChange("vehicleType", value);
  };
  const handleAssetTypeChange = (value) => {
    setSelectedAssetType(value);
    onFilterChange("assetType", value);
  };

  const handleExportClick = async () => {
    try {
      if (tab === 1) {
        await handleVehicalExportClick(fromDate, toDate, selectedOrgId);
      }
      if (tab === 2) {
        await handleAssetExportClick(fromDate, toDate, selectedOrgId);
      }
    } catch (error) {
      console.error("Error exporting report:", error);
    }
  };

  function getYesterday() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday;
  }
  function getFormattedDate(date) {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    return `${year}-${month}-${day}`;
  }
  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    onSearch(term);
    onFilterChange("vehicleId", term);
  };

  const handleAssetIdSearch = (event) => {
    const assetterm = event.target.value;
    setAssetIdsearch(assetterm);
    onAssertSearch(assetterm);
    onFilterChange(assetterm);
  };

  useEffect(() => {
    const fetchAgents = async () => {
      if (selectedOrgId) {
        try {
          const agents = await fetchFieldAgentMap(selectedOrgId);
          setFieldAgentList(agents.body.value);
        } catch (error) {
          console.error("An error occurred:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchAgents();
  }, [selectedOrgId]);

  const handleAssigneeChange = (selectedId) => {
    setSelectedUserId(selectedId);
    const selectedAgent = fieldAgentList.find(
      (agent) => agent.userId === selectedId
    );
    if (selectedAgent) {
      setSelectedUserName(selectedAgent.name);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex flex-row gap-2">
          {tab === 1 && (
            <>
              <div
                className={`pb-4 bg-white ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
              >
                <div className="relative mt-1">
                  <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                    <IoSearch />
                  </div>
                  <input
                    type="text"
                    placeholder={translations[langMode].search}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className={`block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-32 focus:ring-blue-300 focus:border-blue-300  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                      theme === "dark"
                        ? "dark-mode "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  />
                </div>
              </div>
              <React.Fragment>
                <FilterSelect
                  options={vehicalType}
                  defaultText={translations[langMode].vehicalType}
                  value={selectedVehicleType}
                  onChange={handleVehicleTypeChange}
                />
                <FilterSelect
                  defaultText={translations[langMode].vehicalAssign}
                  options={
                    Array.isArray(fieldAgentList) && fieldAgentList.length > 0
                      ? fieldAgentList.map((agent) => ({
                          id: agent.userId,
                          value: agent.name,
                        }))
                      : []
                  }
                  value={selectedUserName}
                  onChange={(selectedName) => {
                    const selectedAgent = fieldAgentList.find(
                      (agent) => agent.name === selectedName
                    );
                    if (selectedAgent) {
                      handleAssigneeChange(selectedAgent.userId);
                    }
                  }}
                />
              </React.Fragment>
            </>
          )}
          {tab === 2 && (
            <>
              <div
                className={`pb-4 bg-white  ${
                  theme === "dark"
                    ? "dark-mode  "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
              >
                <div className="relative mt-1">
                  <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                    <IoSearch />
                  </div>
                  <input
                    type="text"
                    placeholder={translations[langMode].searchbyId}
                    value={assetIdsearch}
                    onChange={handleAssetIdSearch}
                    className={`block pt-2 ps-10 text-sm text-gray-900 border bord rounded-lg w-48 focus:ring-blue-300 focus:border-blue-300   dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                      theme === "dark"
                        ? "dark-mode  "
                        : theme === "high-contrast"
                        ? "high-contrast "
                        : ""
                    }`}
                  />
                </div>
              </div>
              <FilterSelect
                defaultText={translations[langMode].assetType}
                options={assetType}
                value={selectedAssetType}
                onChange={handleAssetTypeChange}
              />
              <FilterSelect
                defaultText={translations[langMode].assetAssign}
                options={
                  Array.isArray(fieldAgentList) && fieldAgentList.length > 0
                    ? fieldAgentList.map((agent) => ({
                        id: agent.userId,
                        value: agent.name,
                      }))
                    : []
                }
                value={selectedUserName}
                onChange={(selectedName) => {
                  const selectedAgent = fieldAgentList.find(
                    (agent) => agent.name === selectedName
                  );
                  if (selectedAgent) {
                    handleAssigneeChange(selectedAgent.userId);
                  }
                }}
              />
            </>
          )}
        </div>
        <div>
          {tab === 1 && (
            <>
              <button
                type="button"
                className="px-3 py-2 text-xs mr-4 font-medium text-center inline-flex items-center text-white bg-gray-500 rounded-lg hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                onClick={() => setShowExportPopup(true)}
              >
                <span className="bg-white rounded-full mr-1 p-1">
                  {" "}
                  <MdUpload className="h-3 w-3 text-gray-700" />
                </span>{" "}
                {translations[langMode].exportreport}
              </button>
              <button
                type="button"
                className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-900 dark:focus:ring-blue-800"
                onClick={() => navigate("/add-vehical")}
              >
                <span className="bg-white rounded-full mr-1 p-1">
                  {" "}
                  <FaCar className="h-3 w-3 text-gray-700" />
                </span>{" "}
                {translations[langMode].addVehical}
              </button>
              <AssignVehical selectedItems={selectedItems} />
            </>
          )}
          {tab === 2 && (
            <>
              <button
                type="button"
                className="px-3 py-2 text-xs mr-4 font-medium text-center inline-flex items-center text-white bg-gray-500 rounded-lg hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                onClick={() => setShowExportPopup(true)}
              >
                <span className="bg-white rounded-full mr-1 p-1">
                  {" "}
                  <MdUpload className="h-3 w-3 text-gray-700" />
                </span>{" "}
                {translations[langMode].exportreport}
              </button>
              <button
                type="button"
                className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-900 dark:focus:ring-blue-800"
                onClick={() => navigate("/add-asset")}
              >
                <span className="bg-white rounded-full mr-1 p-1">
                  {" "}
                  <MdWallet className="h-3 w-3 text-gray-700" />
                </span>{" "}
                {translations[langMode].addAsset}
              </button>
              <AssignAsset selectedItems={selectedItems} />
            </>
          )}
        </div>
      </div>
      {showExportPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div
            className={`bg-white p-6 rounded-lg shadow-lg max-w-md w-full transform transition-all duration-300 ease-in-out ${
              theme === "dark"
                ? "dark-mode bg-gray-800 text-gray-100"
                : theme === "high-contrast"
                ? "high-contrast bg-black text-yellow-300"
                : ""
            }`}
          >
            <h2 className="text-xl font-semibold mb-6 text-center">
              {translations[langMode].exportreport}
            </h2>
            <div className="flex gap-4 mb-6">
              <div className="flex flex-col w-full">
                <label className="font-medium text-sm mb-2">
                  {translations[langMode].from}
                </label>
                <input
                  className={`rounded-md p-2 border focus:outline-none focus:ring-2 focus:ring-indigo-500 transition ${
                    theme === "dark"
                      ? "bg-gray-700 text-gray-100"
                      : theme === "high-contrast"
                      ? "bg-black text-yellow-300 border-yellow-300"
                      : "border-gray-300"
                  }`}
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="flex flex-col w-full">
                <label className="font-medium text-sm mb-2">
                  {translations[langMode].to}
                </label>
                <input
                  type="date"
                  className={`rounded-md p-2 border focus:outline-none focus:ring-2 focus:ring-indigo-500 transition ${
                    theme === "dark"
                      ? "bg-gray-700 text-gray-100"
                      : theme === "high-contrast"
                      ? "bg-black text-yellow-300 border-yellow-300"
                      : "border-gray-300"
                  }`}
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
            </div>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleExportClick}
                className="px-4 py-2 bg-yellow-400 text-white rounded-lg shadow-md hover:bg-yellow-500 transition"
                aria-label="Export report"
              >
                {translations[langMode].exportreport}
              </button>
              <button
                onClick={() => setShowExportPopup(false)}
                className={`px-4 py-2 bg-white text-gray-700 border border-gray-300 rounded-lg shadow-inner  transition ${
                  theme === "dark"
                    ? "dark-mode bg-gray-600 text-gray-100"
                    : theme === "high-contrast"
                    ? "high-contrast bg-black text-yellow-300"
                    : ""
                }`}
                aria-label="Cancel export"
              >
                {translations[langMode].cancle}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default AssetToolbar;
