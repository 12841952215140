import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useRef, useState, useEffect } from "react";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import { UpdateOrganizationData } from "../../service/User";
import AWS from "aws-sdk";
import { RxCross2 } from "react-icons/rx";
const translations = {
  en: {
    updateOrg: "Update Organization",
    orgname: "Organization Name",
    img: "  Upload Logo",
    cancel: "Cancel",
    Updating: "Updating...",
    Update: "Update",
    nolog: "No Logo",
  },
  ar: {
    updateOrg: "تحديث المنظمة",
    orgname: "اسم المنظمة",
    img: "تحميل الشعار",
    cancel: "يلغي",
    Updating: "جارٍ التحديث...",
    Update: "تحديث",
    nolog: "لا يوجد شعار",
  },
};
export default function UpdateOrganization({
  updateopen,
  setUpdateOpen,
  editOrganizationname,
  setEditOrganizationName,
  editOrganizationimg,
  setEditOrganizationImg,
  orgnizationId,
  fetchorgnizationlist,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrgName, setSelectedOrgName] = useState(editOrganizationname);
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(editOrganizationimg);
  const cancelButtonRef = useRef(null);
  const { theme, langMode } = useContext(AppContext);
  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_REGION,
  });
  useEffect(() => {
    setSelectedOrgName(editOrganizationname);
    setLogoPreview(editOrganizationimg);
  }, [editOrganizationname, editOrganizationimg]);

  const handleOrgNameChange = (event) => {
    setSelectedOrgName(event.target.value);
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    setLogo(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateOrganization = async () => {
    setIsLoading(true);
    try {
      let newLogoUrl = editOrganizationimg;
      if (logo) {
        newLogoUrl = await uploadFileToS3(logo);
      }
      const data = await UpdateOrganizationData(
        selectedOrgName,
        newLogoUrl,
        orgnizationId
      );
      if (data.header.code === 600) {
        toast.success("Organization Updated");
        setEditOrganizationName(selectedOrgName);
        setEditOrganizationImg(newLogoUrl);
        setSelectedOrgName("");
        setLogo(null);
        setLogoPreview(null);
        setUpdateOpen(false);
        fetchorgnizationlist();
      } else {
        toast.error(data.body.value);
      }
    } catch (error) {
      toast.error("Error updating organization");
    } finally {
      setIsLoading(false);
    }
  };

  async function uploadFileToS3(file) {
    const uploadParams = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: file.name,
      Body: file,
    };
    try {
      const uploadData = await s3.upload(uploadParams).promise();
      const url = uploadData.Location;
      return url;
    } catch (error) {
      throw error;
    }
  }
  useEffect(() => {
    if (!updateopen && !editOrganizationimg) {
      setLogoPreview(null);
    }
  }, [updateopen]);

  return (
    <Transition.Root show={updateopen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setUpdateOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity`}
          />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              >
                <div
                  className={`bg-white rounded-lg shadow ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                >
                  <div className="flex items-center justify-between p-2 md:p-5 rounded-t dark:border-gray-600">
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                    {translations[langMode].updateOrg}
                    </h3>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-hide="small-modal"
                      onClick={() => setUpdateOpen(false)}
                    >
                      <RxCross2 />
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="p-4 grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-4">
                    <div className="sm:col-span-2">
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700">
                        {translations[langMode].orgname}
                        </label>
                        <input
                          type="text"
                          value={selectedOrgName}
                          onChange={handleOrgNameChange}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700">
                        {translations[langMode].img}
                        </label>
                        <input
                          type="file"
                          onChange={handleLogoChange}
                          className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                        />
                      </div>
                      <div className="mt-4">
                        {logoPreview ? (
                          <img
                            src={logoPreview}
                            alt="Logo Preview"
                            className="w-32 h-32 object-cover rounded-full p-1 border-2 border-gray-300"
                          />
                        ) : (
                          <p className="w-32 h-32 object-cover  flex justify-center items-center rounded-full p-1 border-2 border-gray-300">
                            {translations[langMode].nolog}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center float-right p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                    <button
                      type="button"
                      className="py-1.5 px-3 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      onClick={() => setUpdateOpen(false)}
                    >
                      {translations[langMode].cancel}
                    </button>
                    <button
                      type="button"
                      onClick={handleUpdateOrganization}
                      className={`text-white ${
                        isLoading
                          ? "bg-gray-500 cursor-not-allowed"
                          : "bg-blue-800 hover:bg-blue-800"
                      } focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
                      disabled={isLoading}
                    >
                      {isLoading
                        ? translations[langMode].Updating
                        : translations[langMode].Update}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
