import React, {  useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "react-grid-layout/css/styles.css";
import { BiText } from "react-icons/bi";
import { CiBarcode } from "react-icons/ci";
import {
  FaCamera,
  FaCheckSquare,
  FaHeading,
  FaPhoneAlt,
  FaUser,
  FaPlus,
  FaMinus,
} from "react-icons/fa";
import { GiCheckMark } from "react-icons/gi";
import { IoIosRadioButtonOn, IoMdQrScanner, IoMdClose } from "react-icons/io";
import { IoLocationSharp, IoTime } from "react-icons/io5";
import { MdDateRange, MdEmail } from "react-icons/md";
import { PiSignature } from "react-icons/pi";
import { RiDropdownList } from "react-icons/ri";
import { TbSquareRoundedNumber7Filled } from "react-icons/tb";
import "react-resizable/css/styles.css";
import { DraggableElement } from "../../../components/workflow/DraggableElement";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import Header from "../../../components/_app/Header";
import DropAreaUpdate from "./DropAreaUpdate";

const GetDetails = () => {
  
  const [elements] = useState([
    {
      value: "",
      fieldName: "Heading",
      icon: <FaHeading />,
      fieldType: "text",
      isMandatory: false,
      isReport: false,
    },
    {
      value: "",
      fieldName: "Name",
      icon: <FaUser />,
      fieldType: "text",
      isMandatory: false,
      isReport: false,
    },
    {
      value: "",
      fieldName: "Email",
      icon: <MdEmail />,
      fieldType: "email",
      isMandatory: false,
      isReport: false,
    },
    {
      value: "",
      fieldName: "Phone",
      icon: <FaPhoneAlt />,
      fieldType: "number",
      isMandatory: false,
      isReport: false,
    },
    {
      value: "",
      fieldName: "Address",
      icon: <IoLocationSharp />,
      fieldType: "text",
      isMandatory: false,
      isReport: false,
    },
    {
      value: "",
      fieldName: "Date Picker",
      icon: <MdDateRange />,
      fieldType: "date",
      isMandatory: false,
      isReport: false,
    },
   
    {
      value: "",
      fieldName: "Take Photo",
      icon: <FaCamera />,
      fieldType: "file",
      isMandatory: false,
      isReport: false,
    },
    {
      value: "",
      fieldName: "Signature",
      icon: <PiSignature />,
      fieldType: "text",
      isMandatory: false,
      isReport: false,
    },
    {
      value: "",
      fieldName: "Radio Button",
      icon: <IoIosRadioButtonOn />,
      fieldType: "radio",
      isMandatory: false,
      isReport: false,
    },
    {
      value: "",
      fieldName: "Checkbox",
      icon: <FaCheckSquare />,
      fieldType: "checkbox",
      isMandatory: false,
      isReport: false,
    },
    {
      value: "",
      fieldName: "Dropdown",
      icon: <RiDropdownList />,
      fieldType: "select",
      isMandatory: false,
      isReport: false,
    },
    {
      value: "",
      fieldName: "Number",
      icon: <TbSquareRoundedNumber7Filled />,
      fieldType: "number",
      isMandatory: false,
      isReport: false,
    },
    {
      value: "",
      fieldName: "Time",
      icon: <IoTime />,
      fieldType: "time",
      isMandatory: false,
      isReport: false,
    },
    {
      value: "",
      fieldName: "Long Text",
      icon: <BiText />,
      fieldType: "textarea",
      isMandatory: false,
      isReport: false,
    },
    {
      value: "",
      fieldName: "Barcode",
      icon: <CiBarcode />,
      fieldType: "text",
      isMandatory: false,
      isReport: false,
    },
    {
      value: "",
      fieldName: "Scanning",
      icon: <IoMdQrScanner />,
      fieldType: "text",
      isMandatory: false,
      isReport: false,
    },
  ]);

  const [parameters] = useState([
    { id: 18, value: "", fieldName: "Account number", fieldType: "text" },
    { id: 19, value: "", fieldName: "Visit time", fieldType: "datetime-local" },
    { id: 20, value: "", fieldName: "GIS X, GIS Y", fieldType: "text" },
    { id: 21, value: "", fieldName: "Meter Number", fieldType: "text" },
    { id: 22, value: "", fieldName: "Type of Meter", fieldType: "text" },
    { id: 23, value: "", fieldName: "Outstanding Due", fieldType: "number" },
    { id: 24, value: "", fieldName: "Priority", fieldType: "text" },
    { id: 25, value: "", fieldName: "Status", fieldType: "text" },
    { id: 26, value: "", fieldName: "Address", fieldType: "text" },
    { id: 27, value: "", fieldName: "Consumer Name", fieldType: "text" },
    { id: 28, value: "", fieldName: "Consumer Mobile", fieldType: "tel" },
  ]);

  const [droppedItems, setDroppedItems] = useState([]);
  const [openTab, setOpenTab] = useState(1);
  const [mainTab, setMainTab] = useState(1);
  const [inputValues, setInputValues] = useState([]);
  const [nextId, setNextId] = useState(1);
  const [savedItems] = useState([]);
  const [preview, setPreview] = useState({});
  const [dragItems, setDragItems] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [tempInputValue, setTempInputValue] = useState({});
  const [tempEditValues, setTempEditValues] = useState({});
  const [isEditShow, setIsEditShow] = useState("");
  const [radioButtons, setRadioButtons] = useState([]);
  const [workflowName, setworkflowName] = useState("");
  const [reportField, setreportField] = useState(false);
  const [reportInput, setreportInput] = useState("");
  const [showRadioOptions, setShowRadioOptions] = useState(true);
  const [selectedRadioOption, setSelectedRadioOption] = useState();
  const handleChangee = (event) => {
    setworkflowName(event.target.value);
  };

  const handleReportInput = (event) => {
    setreportInput(event.target.value);
  };

  const workflowSubmit = async () => {
    const token = Cookies.get("accessToken");
    const dataToSend = {
      workflowName: workflowName,
      formMetadata: droppedItems,
    };
    const url = `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/workflow/create-workflow`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dataToSend),
      });

      const jsonData = await response.json();
      if (jsonData.header.code === 600) {
        toast.success(jsonData.body.value);
        setDragItems([]);
        setDroppedItems([]);
        setPreview({});
        setworkflowName('')
      } else {
        toast.error(jsonData.body.value);
      }
    } catch (err) {
    }
  };
  function updateNestedAllInputValues(items, targetUniqueKey, newInputData) {
    return items.map((item) => {
      if (item.uniqueKey === targetUniqueKey) {
        return {
          ...item,
          allInputValues: [...newInputData],
        };
      }
      if (item.radioInputValues && item.radioInputValues.length > 0) {
        const updatedRadioInputValues = updateNestedAllInputValues(
          item.radioInputValues,
          targetUniqueKey,
          newInputData
        );
        return { ...item, radioInputValues: updatedRadioInputValues };
      }
      return item; 
    });
  }

  const updateAllInputValues = (updatedValues, previewkey) => {
    let newAllInputValues = [];
    updatedValues.forEach((input) => {
      if (input.uniqueKey === previewkey && input.value !== "") {
        newAllInputValues.push({
          inputKey: `input-${input.id}`,
          inputValue: input.value,
        });
      }
    });
    const updatedDroppedItems = updateNestedAllInputValues(
      droppedItems,
      previewkey,
      newAllInputValues
    );
    setDroppedItems(updatedDroppedItems);
    setDragItems((prevItems) => {
      return prevItems.map((item) => {
        if (item.uniqueKey === previewkey) {
          return {
            ...item,
            allInputValues: newAllInputValues,
          };
        }
        return item;
      });
    });
  };

  const handleAddInput = (uniqueKey) => {
    setInputValues([
      ...inputValues,
      { id: nextId, value: "", uniqueKey: uniqueKey },
    ]);
    setRadioButtons([
      ...radioButtons,
      { id: nextId, value: "", uniqueKey: uniqueKey, isShow: true },
    ]);
    setNextId(nextId + 1);
  };
  const addReportfield = (previewKey) => {
    const updatedData = droppedItems.map((item) => {
      if (item.uniqueKey === previewKey) {
        return {
          ...item,
          reportName: reportInput,
        };
      }
      return item;
    });
    setDroppedItems(updatedData);
    setDragItems(updatedData);
    setreportInput("");
  };
  const handleRadioOptions = (id, value, previewkey) => {
    let updatedRadioValues = [...radioButtons];
    const index = updatedRadioValues.findIndex(
      (input) => input.id === id && input.uniqueKey === previewkey
    );
    if (index !== -1) {
      updatedRadioValues[index] = { ...updatedRadioValues[index], value };
    } else {
    }
    setRadioButtons(updatedRadioValues);
    updateAllInputValues(updatedRadioValues, previewkey);
    setInputValues([{ id, value, uniqueKey: previewkey }]);
    removeSpecificInput(id, previewkey);
  };
  const removeSpecificInput = (idToRemove, uniqueKeyToRemove) => {
    const updatedRadioButtons = inputValues.filter(
      (input) =>
        !(input.id === idToRemove && input.uniqueKey === uniqueKeyToRemove)
    );
    setInputValues(updatedRadioButtons);
  };
  const handleRadioDeleteOptions = (id, value, previewKey) => {
    setDroppedItems((prevItems) =>
      prevItems.map((item) => {
        if (item.uniqueKey === previewKey) {
          let canDelete =
            item.radioInputValues === undefined ||
            (Array.isArray(item.radioInputValues) &&
              item.radioInputValues.length === 0);
          if (canDelete) {
            setRadioButtons((prevItems) => {
              const updatedValues = prevItems.filter(
                (item) => !(item.id === id && item.uniqueKey === previewKey)
              );
              return updatedValues;
            });
            setDragItems((prevItems) =>
              prevItems.map((item) => {
                if (item.uniqueKey === previewKey) {
                  let updatedAllInputValues = item.allInputValues.filter(
                    (input) => input.inputKey !== `input-${id}`
                  );
                  return { ...item, allInputValues: updatedAllInputValues };
                }
                return item; 
              })
            );
            setTempInputValue((prevValues) => {
              const keyToDelete = `${previewKey}-${id}`;
              const { [keyToDelete]: _, ...remainingValues } = prevValues; 
              return remainingValues;
            });
            let updatedAllInputValues = item.allInputValues.filter(
              (input) => input.inputKey !== `input-${id}`
            );
            return { ...item, allInputValues: updatedAllInputValues };
          } else {
            toast.warning(
              "Warning: This option is already included in the workflow. Removal is restricted. You can only edit the name of this option."
            );
          }
        } else {
          toast.warning(
            "Warning: This option is already included in the workflow. Removal is restricted. You can only edit the name of this option."
          );
        }
        return item; 
      })
    );
  };
  const handleChange = (id, value, previewkey) => {
    const updatedValues = inputValues.map((input) =>
      input.id === id ? { ...input, value } : input
    );
    setInputValues(updatedValues);
    updateAllInputValues(updatedValues, previewkey);
    if (preview.uniqueKey === previewkey) {
      const updatedPreview = { ...preview, value };
      setPreview(updatedPreview);
    }
  };
  const handleInputChange = (event, preview) => {
    setIsChecked(false);
    const newDroppedItems = [...droppedItems];
    const newDraggedItems = [...dragItems];
    const newItem = newDraggedItems.find((item) => item.uniqueKey === preview);
    if (newItem) {
      newItem.fieldName = event.target.value;
    } else {
      console.error("Item not found in droppedItems");
    }
    const newDroppedItem = newDroppedItems.find(
      (item) => item.uniqueKey === preview
    );
    if (newDroppedItem) {
      newDroppedItem.fieldName = event.target.value;
    } else {
      console.error("Item not found in dragItems");
    }
    setDroppedItems(newDroppedItems);
    setDragItems(newDraggedItems);
    setPreview(newItem);
  };
  const reportMandatory = (event, preview) => {
    const newDroppedItems = [...droppedItems];
    const newDraggedItems = [...dragItems];
    const newItem = newDraggedItems.find((item) => item.uniqueKey === preview);
    if (newItem) {
      const droppedItem = newDroppedItems.find(
        (item) => item.uniqueKey === preview
      );
      if (droppedItem) {
        droppedItem.isReport = event.target.checked;
      } else {
        console.error("Item not found in droppedItems");
      }
    } else {
      console.error("Item not found in draggedItems");
    }
    const newDroppedItem = dragItems.find((item) => item.uniqueKey === preview);
    newDroppedItem.isReport = event.target.checked;
    setDroppedItems(newDroppedItems);
    setDragItems(newDraggedItems);
    setPreview(newItem);
    if (event.target.checked === true) {
      setreportField(true);
    } else {
      setreportField(false);
    }
  };
  const handleToggleChange = (event, preview) => {
    setIsChecked(false);
    const newDroppedItems = [...droppedItems];
    const newDraggedItems = [...dragItems];
    const newItem = newDraggedItems.find((item) => item.uniqueKey === preview);
    if (newItem) {
      const droppedItem = newDroppedItems.find(
        (item) => item.uniqueKey === preview
      );
      if (droppedItem) {
        droppedItem.isMandatory = event.target.checked;
      } else {
        console.error("Item not found in droppedItems");
      }
    } else {
      console.error("Item not found in draggedItems");
    }
    const newDroppedItem = dragItems.find((item) => item.uniqueKey === preview);
    newDroppedItem.isMandatory = event.target.checked;
    setDroppedItems(newDroppedItems);
    setDragItems(newDraggedItems);
    setPreview(newItem);
  };
  const handleRadioChange = (id, selectedValue, uniqueKey) => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((input) =>
        input.id === id ? { ...input, selected: selectedValue } : input
      )
    );
  };
  function insertIntoRadioInputValues(
    items,
    targetUniqueKey,
    newItem,
    radioId
  ) {
    return items.map((item) => {
      if (item.uniqueKey === targetUniqueKey) {
        const itemWithRadioId = { ...newItem, radioId: `input-${radioId}` };
        const newRadioInputValues = item.radioInputValues
          ? [...item.radioInputValues, itemWithRadioId]
          : [itemWithRadioId];
        return { ...item, radioInputValues: newRadioInputValues };
      } else if (item.radioInputValues && item.radioInputValues.length > 0) {
        const updatedRadioInputValues = insertIntoRadioInputValues(
          item.radioInputValues,
          targetUniqueKey,
          newItem,
          radioId
        );
        return { ...item, radioInputValues: updatedRadioInputValues };
      }
      return item;
    });
  }
  function removeFromRadioInputValues(items, targetUniqueKey) {
    return items.reduce((acc, item) => {
      if (item.uniqueKey === targetUniqueKey) {
        return acc;
      } else if (item.radioInputValues) {
        const updatedRadioInputValues = removeFromRadioInputValues(
          item.radioInputValues,
          targetUniqueKey
        );
        if (updatedRadioInputValues.length > 0) {
          acc.push({ ...item, radioInputValues: updatedRadioInputValues });
        } else {
          const { radioInputValues, ...rest } = item;
          acc.push(rest);
        }
      } else {
        acc.push(item);
      }
      return acc;
    }, []);
  }

  const handleRadioBtnBelongs = (radioUniqueKey, radioId, value, preview) => {
    const selectedItem = droppedItems.find(
      (dropItem) => dropItem.uniqueKey === preview.uniqueKey
    );
    const filteredDroppedItems = droppedItems.filter(
      (dropItem) => dropItem.uniqueKey !== preview.uniqueKey
    );
    if (!selectedItem) {

      const itemsWithoutSelected = removeFromRadioInputValues(
        droppedItems,
        preview.uniqueKey
      );
      const findItemRecursive = (items, uniqueKey) => {
        for (let item of items) {
          if (item.uniqueKey === uniqueKey) {
            return item;
          } else if (item.radioInputValues) {
            const found = findItemRecursive(item.radioInputValues, uniqueKey);
            if (found) return found;
          }
        }
        return null;
      };
      const newSelectedItem = findItemRecursive(
        droppedItems,
        preview.uniqueKey
      );

      const updatedDroppedItems = insertIntoRadioInputValues(
        itemsWithoutSelected,
        radioUniqueKey,
        newSelectedItem,
        radioId
      );
      setDroppedItems(updatedDroppedItems);
    } else {
      if (filteredDroppedItems.length === 0) {
      } else {
        const updatedDroppedItems = insertIntoRadioInputValues(
          filteredDroppedItems,
          radioUniqueKey,
          selectedItem,
          radioId
        );
        setDroppedItems(updatedDroppedItems);
      }
    }
  };
  const handleRemoveInput = () => {
    setInputValues(inputValues.slice(0, -1));
  };
  const shouldRenderSelectOption = radioButtons.some(
    (input) => input.uniqueKey !== preview.uniqueKey && input.value !== ""
  );
  const shouldRenderSelectOptionEdit = radioButtons.some(
    (input) => input.uniqueKey === preview.uniqueKey && input.value !== ""
  );
  const handleRemoveElement = (uniqueKey) => {
    const updatedDroppedItems = droppedItems.filter(
      (item) => item.uniqueKey !== uniqueKey
    );
    setDragItems(updatedDroppedItems);
    setDroppedItems(updatedDroppedItems);
    setPreview(updatedDroppedItems);
  };

  const selectedId = parseInt(
    selectedRadioOption ? selectedRadioOption.split("-")[1] : ""
  );

  const selectedRadioButton = radioButtons.find(
    (button) => button.id === selectedId
  );

  const displaySelectedValue = selectedRadioButton
    ? selectedRadioButton.value
    : "";
  return (
   <>
    <Header pageName={'Workflow Details'}/>
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <div class="flex items-center">
        <h2 class="font-semibold text-slate-900"> Enter Workflow Name</h2>
        <div className="w-1/3 p-4">
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={workflowName}
            onChange={handleChangee}
          />
        </div>
      </div>
      <DndProvider backend={HTML5Backend}>
        <div className="flex-grow flex justify-between gap-4">
          <div className="w-1/6 bg-white p-4">
            {mainTab === 1 && (
              <React.Fragment>
                <div className="mb-4 flex rounded-sm">
                  <button
                    onClick={() => setOpenTab(1)}
                    className={` py-2 px-2 rounded-sm text-gray-400 font-semibold transition-all duration-300 ${
                      openTab === 1
                        ? "border-b-4 border-blue-900 text-gray-900"
                        : ""
                    }`}>
                    Elements
                  </button>
                  <button
                    className={` py-2 px-2 rounded-sm text-gray-400  font-semibold  transition-all duration-300 ${
                      openTab === 2
                        ? "border-b-4 border-blue-900 text-gray-900"
                        : ""
                    }`}
                  >
                  </button>
                </div>
                <div style={{ display: openTab === 1 ? "block" : "none" }}>
                  {elements.map((element) => (
                    <DraggableElement
                      key={element.id}
                      id={element.id}
                      value={element.value}
                      fieldName={element.fieldName}
                      icon={element.icon}
                      fieldType={element.fieldType}
                      isMandatory={element.isMandatory}
                      isReport={element.isReport}
                    />
                  ))}
                </div>
                <div style={{ display: openTab === 2 ? "block" : "none" }}>
                  {parameters.map((parameter) => (
                    <DraggableElement
                      key={parameter.id}
                      id={parameter.id}
                      value={parameter.value}
                      fieldName={parameter.fieldName}
                      fieldType={parameter.fieldType}
                    />
                  ))}
                </div>
              </React.Fragment>
            )}
          </div>
          <DropAreaUpdate
            setDroppedItems={setDroppedItems}
            droppedItems={droppedItems}
            mainTab={mainTab}
            setMainTab={setMainTab}
            savedItems={savedItems}
            setPreview={setPreview}
            preview={preview}
            setDragItems={setDragItems}
            dragItems={dragItems}
            handleRemoveInput={handleRemoveInput}
            setreportInput={setreportInput}
            setreportField={setreportField}
            setShowRadioOptions={setShowRadioOptions}
            setSelectedRadioOption={setSelectedRadioOption}
            setRadioButtons={setRadioButtons}
            radioButtons={radioButtons}
          />
          <div className="w-1/3 bg-white p-6">
            <h6 className="text-sm font-bold">Preview</h6>
            <div className="block max-w-sm p-8 mt-5 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <div className="flex justify-between">
                <div></div>
                <div>
                  <IoMdClose
                    onClick={() => {
                      handleRemoveElement(preview.uniqueKey);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div key={preview.uniqueKey} className="mb-6">
                <label
                  htmlFor={`input-${preview.uniqueKey}`}
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  {preview.fieldName}
                </label>
                <input
                  type="text"
                  id={`input-${preview.uniqueKey}`}
                  defaultValue={preview.fieldName || "Default Text"}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(event) =>
                    handleInputChange(event, preview.uniqueKey)
                  } 
                />
                <div className="flex items-center justify-between">
                  <div className="relative inline-block w-10 mr-2 align-middle select-none">
                    <label>Mandatory</label>
                    <input
                      type="checkbox"
                      fieldName={`toggle-${preview.uniqueKey}`}
                      id={`toggle-${preview.uniqueKey}`}
                      className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                      onChange={(event) =>
                        handleToggleChange(event, preview.uniqueKey)
                      }
                      checked={preview.isMandatory}
                    />
                    <label
                      htmlFor={`toggle-${preview.uniqueKey}`}
                      className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                    ></label>
                  </div>
                  <div className="relative inline-block w-10 mr-2 align-middle select-none">
                    <label>Report</label>
                    <input
                      type="checkbox"
                      fieldName={`toggle-${preview.uniqueKey}`}
                      id={`toggle-${preview.uniqueKey}`}
                      className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                      onChange={(event) =>
                        reportMandatory(event, preview.uniqueKey)
                      }
                      checked={preview.isReport}
                    />
                    <label
                      htmlFor={`toggle-${preview.uniqueKey}`}
                      className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                    ></label>
                  </div>
                </div>
                {reportField && (
                  <div className="flex justify-between items-center py-3">
                    <div>
                      <input
                        type="text"
                        placeholder="Enter Report Name..."
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={reportInput}
                        onChange={handleReportInput}
                      />
                    </div>
                    <div>
                      <GiCheckMark
                        onClick={() => {
                          addReportfield(preview.uniqueKey);
                        }}
                      />
                    </div>
                  </div>
                )}
                {shouldRenderSelectOption && (
                  <div>
                    Select Option
                    {radioButtons
                      .filter((input) => input.isShow === true)
                      .map((input) =>
                        input.uniqueKey !== preview.uniqueKey &&
                        input.value != "" ? (
                          <div
                            key={input.id}
                            className="flex items-center space-x-2.5 mt-2.5"
                          >
                            <input
                              type="text"
                              value={input.value}
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            />
                            <input
                              type="radio"
                              checked={isChecked}
                              fieldName={`radio-${input.id}`}
                              value={input.value}
                              onChange={(e) =>
                                handleRadioBtnBelongs(
                                  input.uniqueKey,
                                  input.id,
                                  e.target.value,
                                  preview
                                )
                              }
                              ok
                              className="text-blue-500 dark:text-blue-400"
                            />
                          </div>
                        ) : null
                      )}
                  </div>
                )}
                {shouldRenderSelectOptionEdit && (
                  <div>
                    <div className="py-3">
                      <button
                        onClick={() => setIsEditShow((val) => !val)}
                        class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-900 dark:focus:ring-blue-800"
                      >
                        Edit Input Values
                      </button>
                    </div>
                    {isEditShow && (
                      <>
                        {radioButtons.map((input) =>
                          input.uniqueKey === preview.uniqueKey &&
                          input.value !== "" ? (
                            <div
                              key={input.id}
                              className="flex items-center space-x-2.5 mt-2.5"
                            >
                              <input
                                type="text"
                                value={
                                  tempEditValues[
                                    `${input.uniqueKey}-${input.id}`
                                  ] || input.value
                                }
                                onChange={(e) =>
                                  setTempEditValues((prevValues) => ({
                                    ...prevValues,
                                    [`${input.uniqueKey}-${input.id}`]:
                                      e.target.value,
                                  }))
                                }
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-8 hover:bg-blue-400 rounded-md text-gray-500 cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                onClick={() => {
                                  const editValue =
                                    tempEditValues[
                                      `${input.uniqueKey}-${input.id}`
                                    ] || input.value;
                                  handleRadioOptions(
                                    input.id,
                                    editValue,
                                    preview.uniqueKey
                                  );
                                  setIsEditShow(false);
                                }}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-8 hover:bg-blue-400 rounded-md text-gray-500 cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                onClick={() => {
                                  const editValue =
                                    tempEditValues[
                                      `${input.uniqueKey}-${input.id}`
                                    ] || input.value;
                                  handleRadioDeleteOptions(
                                    input.id,
                                    editValue,
                                    preview.uniqueKey
                                  );

                                  setIsEditShow(false);
                                }}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </div>
                          ) : null
                        )}
                      </>
                    )}
                  </div>
                )}
                {displaySelectedValue && (
                  <p>
                    Selected Option:{" "}
                    <span class="text-md font-semibold">
                      {displaySelectedValue}
                    </span>
                  </p>
                )}
                <div>
                  <div>
                    {preview.fieldType === "select" ||
                    preview.fieldType === "radio" ||
                    preview.fieldType === "checkbox" ? (
                      <>
                        <div className="flex justify-between item-center py-2">
                          <label>Add/Remove Options</label>
                          <FaPlus
                            onClick={() => {
                              handleAddInput(preview.uniqueKey);
                            }}
                          />
                          <FaMinus onClick={handleRemoveInput} />
                        </div>
                        {inputValues.map((input) =>
                          input.uniqueKey === preview.uniqueKey ? (
                            <div
                              key={input.id}
                              className="flex items-center space-x-2.5 mt-2.5"
                            >
                              <input
                                type="text"
                                value={
                                  tempInputValue[
                                    `${input.uniqueKey}-${input.id}`
                                  ] || input.value
                                } 
                                onChange={(e) =>
                                  setTempInputValue((prevValues) => ({
                                    ...prevValues,
                                    [`${input.uniqueKey}-${input.id}`]:
                                      e.target.value,
                                  }))
                                }
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              />
                              <GiCheckMark
                                onClick={() => {
                                  const editValue =
                                    tempInputValue[
                                      `${input.uniqueKey}-${input.id}`
                                    ] || input.value;
                                  handleRadioOptions(
                                    input.id,
                                    editValue,
                                    preview.uniqueKey
                                  );
                                }}
                              />
                            </div>
                          ) : null
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DndProvider>
      <div className="w-100  p-6">
        <button
          type="button"
          class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-900 dark:focus:ring-blue-800"
          onClick={workflowSubmit}
        >
          Submit
        </button>
      </div>
    </div>
   </>
  );
};

export default GetDetails;
