import React, { useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext } from "react";
import { RxCross2 } from "react-icons/rx";
import AppContext from "../../../context/AppContext";
export default function ExitModal({
  setExitModal,
  exitModal,
  navigateToWorkflow,
}) {
  const { theme } = useContext(AppContext);
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={exitModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setExitModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity `}
          />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              >
                <div
                  className={`bg-white rounded-lg shadow pb-2  ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                >
                  <div className="flex items-center justify-between p-2 md:p-5  rounded-t dark:border-gray-600">
                    <button
                      type="button"
                      className="text-gray-600 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-hide="small-modal"
                      onClick={() => setExitModal(false)}
                    >
                      <RxCross2 />
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="px-5">
                    Do you really want to leave? This action will delete your
                    created workflow structure .
                  </div>
                  <div className="flex justify-end px-5 gap-5">
                    <button
                      className="px-3 py-2 border border-gray-300 rounded-md"
                      onClick={() => setExitModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="px-3 py-2 border bg-blue-800 rounded-md text-white"
                      onClick={navigateToWorkflow}
                    >
                      Leave
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
