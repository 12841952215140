import React, { useContext, useState } from "react";
import Header from "../../components/_app/Header";
import AssetTable from "../../components/asset/AssetTable";
import AppContext from "../../context/AppContext";
const translations = {
  en: {
    vehical:"Vehical",
    Inventory:"Inventory",
    asset:"Assets"

  },
  ar: {
    vehical:"عربة",
    Inventory:"جرد",
     asset:"أصول"

  },
};
const AssetList = ({initialData}) => {
  const [openTab, setOpenTab] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const {  theme , langMode } = useContext(AppContext);
  return (
    <>
      <Header
        pageName={translations[langMode].asset}
        subPage={openTab === 1 ? translations[langMode].vehical : translations[langMode].Inventory}
      />
      <div className={`px-5 bg-white p-4 ${theme === 'dark' ? 'dark-mode  ' : (theme === 'high-contrast' ? 'high-contrast  ' : '')}`}>
        <div className=" flex rounded-sm">
          <button
            onClick={() => setOpenTab(1)}
            className={` px-2 rounded-sm font-semibold transition-all duration-300 ${
              openTab === 1 ? "border-b-4 border-gray-600 text-gray-900" : ""
            } ${theme === 'dark' ? 'dark-mode  ' : (theme === 'high-contrast' ? 'high-contrast  ' : '')}`}
          >
            {translations[langMode].vehical}
          </button>
          <button
            onClick={() => setOpenTab(2)}
            className={` px-2 rounded-sm  font-semibold  transition-all duration-300 ${
              openTab === 2 ? "border-b-4 border-gray-600 text-gray-900" : ""
            } ${theme === 'dark' ? 'dark-mode  ' : (theme === 'high-contrast' ? 'high-contrast  ' : '')}`}
          >
              {translations[langMode].Inventory}
          </button>
        </div>
        <div style={{ display: openTab === 1 ? "block" : "none" }}>
          <AssetTable 
           searchQuery={searchQuery}
           setSearchQuery={setSearchQuery}
            tab={1}
            tableHeaders={[
              "Vehicle ID",
              "Vehicle Type",
              "Vehicle (km)",
              "Vehicle Assigned",
             
            ]}
          />
        </div>
        <div style={{ display: openTab === 2 ? "block" : "none" }}>
          <AssetTable
            tab={2}
            tableHeaders={[
              "Asset ID",
              "Asset Name",
              "Asset Barcode",
              "Asset Type",
              "Agent Assigned",
              "Asset Status",
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default AssetList;
