import React, { useContext, useState } from "react";
import Header from "../../components/_app/Header";
import TaskListToolbar from "../../components/task/TaskListToolbar";
import TaskListView from "../../components/task/TaskListView";
import AppContext from "../../context/AppContext";
const translation = {
  en: {
    listvbiew:"Task List View"
  },
  ar: {
    listvbiew:"عرض قائمة المهام"
  },
};
const TaskManageMain = () => {
  const [openTab, setOpenTab] = useState(2);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedtask, setselectedtask] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [workflowName,setWorkflowName] = useState([])
  const {  theme , langMode } = useContext(AppContext);
  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterType]: value }));
    setCurrentPage(0);
  };
  return (
    <>
      <Header
        pageName={translation[langMode].listvbiew}
      />
      <div className={`px-5 py-2  bg-white ${theme === 'dark' ? 'dark-mode  ' : (theme === 'high-contrast' ? 'high-contrast  ' : '')}`}>
        <TaskListToolbar
          openTab={openTab}
          setOpenTab={setOpenTab}
          isChecked={isChecked}
          selectedtask={selectedtask}
          setIsChecked={setIsChecked}
          onFilterChange={handleFilterChange} 
          setWorkflowName={setWorkflowName} />
        <div className="" style={{ display: openTab === 2 ? "block" : "none" }}>
          <TaskListView
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            selectedtask={selectedtask}
            setselectedtask={setselectedtask}
          />
        </div>
      </div>
    </>
  );
};

export default TaskManageMain;
