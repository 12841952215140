import React, { useContext } from "react";
import { MainNav } from "./MainNav";
import AppContext from "../../context/AppContext";

export const Layout = ({ children }) => {
  const { theme } = useContext(AppContext);
  return (
    <>
      <div className={`px-5 py-5  bg-gray-100 ${theme === 'dark' ? 'dark-mode' : (theme === 'high-contrast' ? 'high-contrast' : '')} `}>
        <MainNav />
        <main className="">
          <div className="">{children}</div>
        </main>
      </div>
    </>
  );
};
