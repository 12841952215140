import React from 'react';
import { Field } from 'formik';

const CommonRadio = ({ que, name , disabled }) => {
  return (
    <div>
      <span className="text-sm font-semibold">{que}</span>
      <div className="flex mt-3">
        <div className="flex items-center mr-4">
          <Field
            id={`${name}-yes`}
            type="radio"
            value="true"
            name={name}
            disabled={disabled}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor={`${name}-yes`}
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Yes
          </label>
        </div>
        <div className="flex items-center">
          <Field
            id={`${name}-no`}
            type="radio"
            value="false"
            name={name}
            disabled={disabled}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor={`${name}-no`}
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default CommonRadio;
