import React, { useContext, useEffect, useState } from "react";
import { fetchOrganizations } from "../../../service/Report";
import AppContext from "../../../context/AppContext";
import Joyride from "react-joyride";

const OrganizationFilterSelect = ({
  setSelectedOrgId,
  setSelectedOrgName,
  selectedOrgName,
  setWorkflowName,
}) => {
  const [organizations, setOrganizations] = useState([]);
  const { theme, setWorkflowId, tourSteps, runTour } = useContext(AppContext);
  useEffect(() => {
    const loadOrganizations = async () => {
      try {
        const orgs = await fetchOrganizations();
        setOrganizations(orgs);
        const defaultOrg = orgs.find((org) => org.organizationId === 1);
        if (defaultOrg) {
          setSelectedOrgId(defaultOrg.organizationId);
          setSelectedOrgName(defaultOrg.organizationName);
        }
      } catch (error) {
        console.error("Error loading organizations:", error);
      }
    };
    loadOrganizations();
  }, [setSelectedOrgId, setSelectedOrgName]);

  const handleOrganizationChange = (selectedName) => {
    setSelectedOrgName("");
    const selectedOrg = organizations.find(
      (org) => org.organizationName === selectedName
    );
    if (selectedOrg) {
      setSelectedOrgId(selectedOrg.organizationId);
      setSelectedOrgName(selectedOrg.organizationName);
      setWorkflowId("");
      setWorkflowName("");
    }
  };
  return (
    <>
      <Joyride
        steps={tourSteps}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
      />
      <select
        value={selectedOrgName}
        onChange={(e) => handleOrganizationChange(e.target.value)}
        className={`org block px-2.5 w-44  text-sm bg-white text-gray-900 bg-transparent rounded-md appearance-none  
        ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        <option value="" disabled>
          Select Organization
        </option>
        {organizations.map((org) => (
          <option
            key={org.organizationId}
            value={org.organizationName}
            className="hover:bg-indigo-700"
          >
            {org.organizationName}
          </option>
        ))}
      </select>
    </>
  );
};

export default OrganizationFilterSelect;
