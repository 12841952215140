import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { IoDownloadOutline, IoSearch } from "react-icons/io5";
import { LuMoreVertical } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";
import FilterSelect from "../common/FilterSelect";
import FieldListModal from "./FieldStaffListModal";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import MapView from "../../assets/map-sharp.png";
import createImg from "../../assets/Vector (6).png";
import importImg from "../../assets/arrow-undo-circle.png";
import assignImg1 from "../../assets/Group 2085662708.png";
import assignImg2 from "../../assets/Group 2085662707.png";
import DeletePermission from "../suspence/DeletePermission";
import { assignmentRulesList, assignByArea } from "../../service/Configuration";
import { fetchFilterExcel } from "../../service/Task";
import {
  deleteTasks,
  putTasksOnHold,
  fetchFieldAgentMap,
  deleteAllRecords,
  smartAssign,
} from "../../service/Task";
import { SubActivity } from "../common/task/SubActivity";
import { RxCross2 } from "react-icons/rx";
import { FaBell, FaCirclePause, FaUser } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import Modal from "../DownloadModal/Modal";
import Joyride from "react-joyride";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const translations = {
  en: {
    createTask: "Create new task",
    importCSV: "Import CSV",
    download: "Download",
    deleteAllRecord: " Delete All Record",
    assign: "Assignee",
    onhold: "On-hold",
    alert: "Sent Alert",
    delete: " Delete",
    search: "Search",
    priority: "Priority",
    status: "Status",
    searchbycode: "Search by Area Code..",
    selectAssign: "Select Assignee",
  },
  ar: {
    createTask: "إنشاء مهمة جديدة",
    importCSV: "استيراد ملف CSV",
    download: "تحميل",
    deleteAllRecord: "حذف كافة السجلات",
    assign: "المكلف",
    onhold: "في الانتظار",
    alert: "تنبيه مرسل",
    delete: " يمس",
    search: "يبحث",
    priority: "أولوية",
    status: "حالة",
    searchbycode: "البحث عن طريق رمز المنطقة..",
    selectAssign: "حدد المكلف",
  },
};
const TaskListToolbar = ({
  openTab,
  setOpenTab,
  isChecked,
  selectedtask,
  setIsChecked,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const {
    resetDropdown,
    setPriority,
    setActive,
    setIsDraggable,
    isDraggable,
    setSelectAll,
    selectAll,
    setAreaCode,
    areaCode,
    accountNo,
    setAccountNo,
    setFieldAgentList,
    fieldAgentList,
    setSelectedUserId,
    workflowId,
    priority,
    active,
    taskData,
    setTaskData,
    selectedOrgId,
    setSelectedOrgId,
    setSelectedOrgName,
    langMode,
    tasktollbar,
    runTour,
  } = context;
  const userType = Cookies.get("userType");
  const [selectedUserName, setSelectedUserName] = useState(0);
  const [selectedStatusName, setselectedStatusName] = useState("Status");
  const [selectedPriorityName, setselectedPriorityName] = useState("Priority");
  const [showAssignByRules, setShowAssignByRules] = useState(false);
  const { theme } = useContext(AppContext);
  const priorityoptions = [
    { id: "Everything", value: "Default" },
    { id: "Low_Priority", value: "Low_Priority" },
    { id: "Medium_Priority", value: "Medium_Priority" },
    { id: "High_Priority", value: "High_Priority" },
  ];
  const activeoptions = [
    { id: "Everything", value: "Default" },
    { id: "Active", value: "Active" },
    { id: "Assigned", value: "Assigned" },
    { id: "In_Progress", value: "In_Progress" },
    { id: "Completed", value: "Completed" },
    { id: "On_Hold", value: "On_Hold" },
  ];

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");
  const [deleteItemType, setDeleteItemType] = useState("");
  const [assignmentList, setAssignmentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const [downloadModal, setDownloadModal] = useState(false);

  const handleShowModal = () => {
    setDownloadModal(true);
  };
  const showDeleteModal = (type, id) => {
    setDeleteItemType(type);
    setDeleteItemId(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const resetFilters = () => {
    setPriority("Default");
    setActive("Default");
    setSelectedUserId("");
    setSelectedUserName("Default");
    setselectedStatusName("Status");
    setselectedPriorityName("Priority");
    setSelectedOrgName("");
    setSelectedOrgId("");
  };

  const handlePrioritySelection = (selectedValue) => {
    const selectedOption = priorityoptions.find(
      (option) => option.value === selectedValue
    );
    if (selectedOption) {
      setselectedPriorityName(selectedOption.value);
      setPriority(
        selectedOption.id === "Priority" ? "Default" : selectedOption.id
      );
    }
  };

  const handleStatusSelection = (selectedValue) => {
    const selectedOption = activeoptions.find(
      (option) => option.value === selectedValue
    );
    if (selectedOption) {
      setselectedStatusName(selectedOption.value);
      setActive(selectedOption.id === "Status" ? "Default" : selectedOption.id);
    }
  };

  const toggleDraggable = () => {
    setIsDraggable(!isDraggable);
  };

  const taskDeleteList = async () => {
    try {
      const data = await deleteTasks(selectedtask);
      setIsChecked(false);
      setOpen(false);
      if (selectAll) {
        handleCloseDeleteModal();
        const updatedTaskData = taskData.filter(
          (task) => !selectedtask.includes(task.taskId)
        );
        setTaskData(updatedTaskData);
      } else {
        toast.success("Task deleted successfully");
        const updatedTaskData = taskData.filter(
          (task) => !selectedtask.includes(task.taskId)
        );
        setTaskData(updatedTaskData);
        handleCloseDeleteModal();
      }
    } catch (error) {}
  };

  const handleAreaCodeChange = (event) => {
    setAreaCode(event.target.value);
  };
  const handleAccountNoChange = (event) => {
    setAccountNo(event.target.value);
  };

  useEffect(() => {
    const fetchFieldAgents = async () => {
      try {
        const agents = await fetchFieldAgentMap(selectedOrgId);
        setFieldAgentList(agents.body.value);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
    fetchFieldAgents();
  }, [selectedOrgId]);

  useEffect(() => {}, [fieldAgentList]);

  const handleAssigneeChange = (selectedId) => {
    setSelectedUserId(selectedId);
    const selectedAgent = fieldAgentList.find(
      (agent) => agent.userId === selectedId
    );
    if (selectedAgent) {
      setSelectedUserName(selectedAgent.name);
    } else if (selectedId === "") {
      setSelectedUserName("Default");
    }
  };

  const handleDeleteAllRecord = async (workflowId) => {
    try {
      const responseData = await deleteAllRecords(workflowId);
      if (responseData.header.code === 600) {
        const updatedTaskData = taskData.includes((task) => task.workflowId);
        setTaskData(updatedTaskData);
        toast.success("All records are deleted");
        handleCloseDeleteModal();
      } else {
        throw new Error(responseData.header.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("Error: " + error.message);
    }
  };

  const [showSmartAssignModal, setShowSmartAssignModal] = useState(false);
  const toggleSmartAssignModal = async () => {
    try {
      const responseData = await smartAssign(workflowId);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleTabChange = (tab) => {
    setOpenTab(tab);
    localStorage.setItem("activeTab", tab);
  };

  const fetchAssignmentRules = async (reset = false) => {
    await assignmentRulesList(
      reset,
      workflowId,
      page,
      size,
      setIsLoading,
      setAssignmentList,
      setHasMore,
      setError
    );
  };

  useEffect(() => {
    if (page === 0) {
      fetchAssignmentRules(true);
    } else {
      fetchAssignmentRules();
    }
  }, [page, workflowId]);

  const loadMore = () => {
    if (!isLoading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop <
        document.documentElement.offsetHeight - 200
      )
        return;
      loadMore();
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading, hasMore]);

  const handleAssignByArea = async () => {
    const selectedRules = assignmentList.filter((rule) => rule.isSelected);
    const ruleList = selectedRules.map((rule) => ({
      userId: rule.userId,
      areaCode: rule.areaCode,
      workflowId: rule.workflowId,
    }));
    try {
      const response = await assignByArea(+workflowId, ruleList);
      if (response.header.code === 600) {
        toast.success("Tasks successfully assigned by area.");
        setShowSmartAssignModal(false);
      } else {
        throw new Error(response.header.message);
      }
    } catch (error) {
      console.error("Error assigning tasks by area:", error);
      toast.error("Failed to assign tasks by area.");
    }
  };

  const openSmartAssignPopup = () => {
    if (workflowId) {
      setShowSmartAssignModal(true);
    } else {
      toast.warn("Please select activity");
    }
  };

  const downloadFiltration = async (selectedOption) => {
    try {
      const userTypeCookie = Cookies.get("userType");
      const params = {
        workflowIds: workflowId || "", // Updated to workflowIds
        priority: priority || "",
        active: active || "",
        areaCode: areaCode || "",
        accountNo: accountNo || "",
        organizationId: "",
      };
      
      if (selectedOption === "all" && userTypeCookie === "super_admin") {
        params.organizationId = selectedOrgId || "";
      } else if (
        selectedOption === "particular" ||
        userTypeCookie !== "super_admin"
      ) {
        params.organizationId = "";
      }
  
      const data = await fetchFilterExcel({
        workflowIds: params.workflowIds, // Updated to workflowIds
        priority: params.priority,
        active: params.active,
        areaCode: params.areaCode,
        accountNo: params.accountNo,
        organizationId: params.organizationId,
      });
  
      const code = data.header?.code;
      if (code === 600) {
        const s3Link = data.body?.value;
        const link = document.createElement("a");
        link.href = s3Link;
        link.setAttribute("download", "report.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success("Excel is downloading");
      } else {
        toast.error("No records found");
      }
    } catch (error) {
      console.error("Error during report download:", error);
      toast.error("An error occurred while downloading the report.");
    }
  };
  

  const handleMapView = () => {
    if (workflowId) {
      resetDropdown();
      navigate("/maptask");
    }
    navigate("/maptask");
  };

  const handleGoCsv = () => {
    Cookies.set("viewMode", "list");
    navigate("/task/import-csv");
  };
  return (
    <>
      <Joyride
        steps={tasktollbar}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
      />
      <div className="flex flex-col ">
        <div className="flex justify-between items-center gap-4">
          <div className="flex mt-1">
            <button
              onClick={handleMapView}
              className={`py-2 px-2  text-lg rounded-l-md font-semibold border border-gray-300 transition-all duration-300 ${
                theme === "dark"
                  ? "dark-mode  "
                  : theme === "high-contrast"
                  ? "high-contrast  "
                  : ""
              }`}
            >
              <img src={MapView} alt="mapview" />
            </button>
            <button
              onClick={() => handleTabChange(2)}
              className={`py-2 px-2 rounded-r-md bg-blue-800 font-semibold border border-gray-100 transition-all duration-300 ${
                theme === "dark"
                  ? "dark-mode  "
                  : theme === "high-contrast"
                  ? "high-contrast  "
                  : ""
              }`}
            >
              <FiMenu className="text-white" />
            </button>
          </div>
          <div className="flex">
            <button
              type="button"
              className={`download text-white h-9 mt-1 bg-yellow-400 hover:bg-yellow-600 border border-gray-200 focus:ring-4 focus:outline-none
        focus:ring-gray-100 font-medium rounded-lg text-sm px-2 py-2.5 text-center inline-flex gap-2 items-center
        dark:focus:ring-gray-600 dark:border-gray-700 dark:text-white dark:hover:bg-yellow-600 dark:bg-yellow-400 me-2 ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
              // onClick={downloadFiltration}
              onClick={handleShowModal}
            >
              <IoDownloadOutline className="text-white" />
              {translations[langMode].download}
            </button>
            <button
              onClick={() => showDeleteModal("all", workflowId)}
              className={`deleteAll text-white h-9 mt-1 bg-red-600 hover:bg-red-500 border border-gray-200 focus:ring-4 focus:outline-none
                focus:ring-gray-100 font-medium rounded-lg text-sm px-2 py-2.5 text-center inline-flex gap-2 items-center
                dark:focus:ring-gray-600 dark:border-gray-700 dark:text-white dark:bg-red-600 dark:hover:bg-red-500 me-2 ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
            >
              {translations[langMode].deleteAllRecord}
            </button>
            <button
              type="button"
              className={`createTask text-white h-9 mt-1 bg-gray-700 hover:bg-gray-600 border border-gray-200 focus:ring-4 focus:outline-none
              focus:ring-gray-100 font-medium rounded-lg text-sm px-2 py-2.5 text-center inline-flex gap-2 items-center
              dark:focus:ring-gray-600 dark:border-gray-700 dark:text-white dark:hover:bg-gray-600 dark:bg-gray-700 me-2 ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
              onClick={() => navigate("/task/create-task")}
            >
              <img src={createImg} className="px-1" alt="create tmg" />
              {translations[langMode].createTask}
            </button>
            <button
              type="button"
              className={`gocsv text-white h-9 mt-1 bg-blue-800 hover:bg-indigo-600 border border-gray-200 focus:ring-4 focus:outline-none
              focus:ring-gray-100 font-medium rounded-lg text-sm px-2 py-2.5 text-center inline-flex gap-2 items-center
              dark:focus:ring-gray-600 dark:border-gray-700 dark:bg-blue-800 dark:text-white dark:hover:bg-indigo-600 me-2 ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
              onClick={handleGoCsv}
            >
              {" "}
              <img src={importImg} className="px-1 h-5" alt="import cvs" />
              {translations[langMode].importCSV}
            </button>
            <Menu as="div" className="relative mt-1">
              <div>
                <Menu.Button
                  className={`relative flex rounded-md p-2 bg-white text-lg border border-gray-400 ${
                    isChecked ? "" : "pointer-events-none opacity-50"
                  }  ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                  disabled={!isChecked}
                >
                  <span />
                  <LuMoreVertical />
                </Menu.Button>
              </div>
              {isChecked && (
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    className={` ${
                      theme === "dark"
                        ? "dark-mode border "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    } absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-lg bg-white py-1 shadow-md ring-1
                 ring-black ring-opacity-5 focus:outline-none`}
                  >
                    <Menu.Item
                      className={` ${
                        theme === "dark"
                          ? "dark-mode hover:text-indigo-700 "
                          : theme === "high-contrast"
                          ? "high-contrast  "
                          : ""
                      }`}
                    >
                      {({ active }) => (
                        <Link
                          to=""
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "flex px-4 py-2 text-sm text-gray-700 gap-4 items-center"
                          )}
                          onClick={() => setOpen(true)}
                        >
                          <FaUser className="w-5" />
                          {translations[langMode].assign}
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item
                      className={` ${
                        theme === "dark"
                          ? "dark-mode hover:text-indigo-700 "
                          : theme === "high-contrast"
                          ? "high-contrast  "
                          : ""
                      }`}
                    >
                      {({ active }) => (
                        <Link
                          to=""
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "flex px-4 py-2 text-sm text-gray-700  gap-4 items-center"
                          )}
                          onClick={putTasksOnHold}
                        >
                          <FaCirclePause className="w-5" />
                          {translations[langMode].onhold}
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item
                      className={` ${
                        theme === "dark"
                          ? "dark-mode hover:text-indigo-700 "
                          : theme === "high-contrast"
                          ? "high-contrast  "
                          : ""
                      }`}
                    >
                      {({ active }) => (
                        <Link
                          to=""
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "px-4 py-2 text-sm text-gray-700 flex gap-4 items-center"
                          )}
                        >
                          <FaBell className="w-5" />
                          {translations[langMode].alert}
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item
                      className={` ${
                        theme === "dark"
                          ? "dark-mode hover:text-indigo-700 "
                          : theme === "high-contrast"
                          ? "high-contrast  "
                          : ""
                      }`}
                    >
                      {({ active }) => (
                        <Link
                          to=""
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "px-4 py-2 text-sm text-gray-700 flex gap-4 items-center"
                          )}
                          onClick={() => showDeleteModal("single", null)}
                        >
                          <MdDelete className="w-5" />
                          {translations[langMode].delete}
                        </Link>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              )}
            </Menu>
          </div>
        </div>
        <div className="flex flex-row py-5  gap-2">
          <div
            className={` bg-white  ${
              theme === "dark"
                ? "dark-mode  "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
          >
            <div className="relative mt-1">
              <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                <IoSearch />
              </div>
              <input
                type="text"
                id="table-search"
                className={`block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-24
                   focus:ring-blue-300 focus:border-blue-300  dark:border-gray-600
                    dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                      theme === "dark"
                        ? "dark-mode  "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                placeholder={translations[langMode].search}
                value={accountNo}
                onChange={handleAccountNoChange}
              />
            </div>
          </div>
          <FilterSelect
            defaultText={translations[langMode].priority}
            options={priorityoptions}
            onChange={handlePrioritySelection}
            value={selectedPriorityName}
          />
          <FilterSelect
            defaultText={translations[langMode].status}
            options={activeoptions}
            onChange={handleStatusSelection}
            value={selectedStatusName}
          />
          <div
            className={` bg-white ${
              theme === "dark"
                ? "dark-mode  "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
          >
            <div className={`relative mt-1  `}>
              <div
                className={`absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none `}
              >
                <IoSearch />
              </div>
              <input
                type="text"
                id="table-search"
                className={`${
                  theme === "dark"
                    ? "dark-mode  "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                } block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-48 focus:ring-blue-300 focus:border-blue-300  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500  `}
                placeholder={translations[langMode].searchbycode}
                value={areaCode}
                onChange={handleAreaCodeChange}
              />
            </div>
          </div>
          <FilterSelect
            defaultText={translations[langMode].selectAssign}
            options={
              Array.isArray(fieldAgentList) && fieldAgentList.length > 0
                ? [
                    { id: "", value: "Default" },
                    ...fieldAgentList.map((agent) => ({
                      id: agent.userId,
                      value: agent.name,
                    })),
                  ]
                : [{ id: "", value: "Default" }]
            }
            value={selectedUserName}
            onChange={(selectedName) => {
              if (selectedName === "Default") {
                handleAssigneeChange("");
              } else {
                const selectedAgent = fieldAgentList.find(
                  (agent) => agent.name === selectedName
                );
                if (selectedAgent) {
                  handleAssigneeChange(selectedAgent.userId);
                }
              }
            }}
          />

          <SubActivity />
        </div>
      </div>

      <FieldListModal
        taskData={taskData}
        setTaskData={setTaskData}
        open={open}
        setOpen={setOpen}
        selectedtask={selectedtask}
        setIsChecked={setIsChecked}
        selectedOrgId={selectedOrgId}
        resetFilters={resetFilters}
      />
      {deleteModalOpen && deleteItemType === "all" && (
        <DeletePermission
          deleteFunction={() => handleDeleteAllRecord(deleteItemId)}
          onClose={handleCloseDeleteModal}
          onDeleteText="Are you sure you want to delete all records?"
        />
      )}
      {deleteModalOpen && deleteItemType === "single" && (
        <DeletePermission
          deleteFunction={taskDeleteList}
          onClose={handleCloseDeleteModal}
          onDeleteText="Are you sure you want to delete records?"
        />
      )}
      <Modal
        setDownloadModal={setDownloadModal}
        downloadModal={downloadModal}
        downloadFiltration={downloadFiltration}
      />
    </>
  );
};
export default TaskListToolbar;
