import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import Header from "../../components/_app/Header";
import CommonInputField from "../../components/common/CommonInputField";
import CommonRadio from "../../components/common/CommonRadio";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import {
  fetchOrganizations,
  createUser,
  getUserInfoAPI,
  updateUser,
} from "../../service/User";
import { useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaArrowLeft } from "react-icons/fa";
const translations ={
  en:{
    userdetail:"User Details",
    previllage:"Previleges",
    cancle:"Cancel",
    update:"Update User",
    add:"Add User",
    username:"User Name",
    userType:"Select User Type",
    email:"Email",
    selectOrg:"Select Organization",
    areCode:"Area Code",
    addeditActivity:"Add/edit Activity templates",
    addnewlabel:"Add new Labels to the activity template",
    addEdituser:"Add/edit User",
    canasset:"Can access asset Management",
    uservisibility:" User Visibility",
    guidline:"Modifying Guidelines",
    createDashboar:"Create Dashboard",
    addeditleader:"Add/edit Leader-board"
  },
  ar:{
    userdetail:"تفاصيل المستخدم",
    previllage:"الامتيازات",
    cancle:"يلغي",
    update:"تحديث المستخدم",
    add:"إضافة مستخدم",
    username:"اسم المستخدم",
    userType:"حدد نوع المستخدم",
    email:"بريد إلكتروني",
    selectOrg:"حدد المنظمة",
    areCode:"كود المنطقة",
    addeditActivity:"إضافة/تحرير قوالب النشاط",
    addnewlabel:"أضف تسميات جديدة إلى قالب النشاط",
    addEdituser:"إضافة/تحرير مستخدم",
    canasset:"يمكن الوصول إلى إدارة الأصول",
    uservisibility:"رؤية المستخدم",
    guidline:"تعديل المبادئ التوجيهية",
    createDashboar:"إنشاء لوحة المعلومات",
    addeditleader:"إضافة/تحرير لوحة المتصدرين"
  }
}
const AddUser = () => {
  const { userId } = useParams();
  const [organizations, setOrganizations] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [initialUserInfo, setInitialUserInfo] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const { theme , langMode } = useContext(AppContext);
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    name: "",
    userType: "",
    email: "",
    phone: "",
    areaCode: "",
    organization: "",
    canAddEditActivityTemplates: undefined,
    canAddEditLeaderboard: undefined,
    canCreateDashboard: undefined,
    canModifyGuidelines: undefined,
    canAddEditUser: undefined,
    canAddLabelsToActivityTemplates: undefined,
    canAccessAssetManagement: undefined,
    userVisibility: [],
  });
  const [selectedUserType, setSelectedUserType] = useState("");
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("User Name is required"),
    userType: Yup.string().required("User Type is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });
  const GetUserInfo = () => {
    getUserInfoAPI(userId)
      .then((response) => {
        const user = response.data.body.value;
        setUserInfo(user);
        setInitialUserInfo(user);
        setInitialValues({
          name: user.name || "",
          userType: user.userType || "",
          email: user.email || "",
          phone: user.phone || "",
          areaCode: user.areaCode || "",
          organization: user.organization.organizationId || "",
          canAddEditActivityTemplates: String(user.canAddEditActivityTemplates),
          canAddEditLeaderboard: String(user.canAddEditLeaderboard),
          canCreateDashboard: String(user.canCreateDashboard),
          canModifyGuidelines: String(user.canModifyGuidelines),
          canAddEditUser: String(user.canAddEditUser),
          canAddLabelsToActivityTemplates: String(
            user.canAddLabelsToActivityTemplates
          ),
          canAccessAssetManagement: String(user.canAccessAssetManagement),
          userVisibility: user.userVisibility || [],
        });
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  useEffect(() => {
    if (userId) {
      GetUserInfo();
    }
  }, [userId]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const token = Cookies.get("accessToken");
    if (!token) {
      toast.error("Token is not present");
      return;
    }
    try {
      let response;
      if (userId) {
        response = await updateUser(
          {
            userId: Number(userId),
            ...values,
            userVisibility: initialValues.userVisibility,
            organization: { organizationId: values.organization },
          },
          token
        );
      } else {
        response = await createUser(
          {
            ...values,
            userVisibility: initialValues.userVisibility,
            organization: { organizationId: values.organization },
          },
          token
        );
      }
      if (response.header.code === 600) {
        if (userId) {
          toast.success("User updated successfully");
        } else {
          toast.success("User created successfully");
          resetForm();
          setInitialValues((prev) => ({ ...prev, userVisibility: [] }));
        }
      } else if (response.header.code === 650) {
        toast.error("Server Error");
      } else if (response.header.code === 612) {
        toast.error(response.body.value);
      } else {
        toast.error(response.body.value);
      }
    } catch (error) {
      console.error("Error handling user:", error);
      toast.error("Failed to handle user");
    } finally {
      setSubmitting(false);
    }
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;
    setInitialValues((prevValues) => {
      if (isChecked) {
        return {
          ...prevValues,
          userVisibility: [...prevValues.userVisibility, value],
        };
      } else {
        return {
          ...prevValues,
          userVisibility: prevValues.userVisibility.filter(
            (item) => item !== value
          ),
        };
      }
    });
  };

  const fetchOrganizationsData = async () => {
    const token = Cookies.get("accessToken");
    try {
      const data = await fetchOrganizations(token);
      setOrganizations(data);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchOrganizationsData();
  }, []);

  useEffect(() => {
    const userType = Cookies.get("userType");
    setIsSuperAdmin(userType === "super_admin");
    setIsAdmin(userType === "admin");
  });

  const handleGoBack = () => {
    navigate("/all-users");
  };

  return (
    <>
      <Header  subPage={userId ? translations[langMode].update : translations[langMode].add} />
      <div
        onClick={handleGoBack}
        className={`absolute top-30 p-2 rounded-full border cursor-pointer w-7 m-3 ml-5
    ${theme === "dark" ? "bg-gray-800 border-gray-600 hover:bg-gray-700" : ""} 
    ${
      theme === "high-contrast"
        ? "bg-yellow-200 border-black hover:bg-yellow-300"
        : ""
    } 
    ${
      theme !== "dark" && theme !== "high-contrast"
        ? "border-gray-400 hover:bg-gray-200"
        : ""}`} >
        <FaArrowLeft
          className={`h-3 w-3 ${
            theme === "dark"
              ? "text-white"
              : theme === "high-contrast"
              ? "text-black"
              : "text-gray-700"
          }`}
        />
      </div>
      <div
        className={`px-5 py-12 bg-white ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`} >
        <h3 className="text-xs text-gray-500 font-bold"> {translations[langMode].userdetail}</h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <React.Fragment>
              <Form>
                <div className="w-3/5">
                  <div className=" mt-3 grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-4">
                    <div className="sm:col-span-2">
                      <Field
                        type="text"
                        name="name"
                        label={translations[langMode].username}
                        component={CommonInputField}
                        value={values.name}
                        onChange={(e) => setFieldValue("name", e.target.value)}
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <Field
                        className={`px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2  ${
                          theme === "dark"
                            ? "dark-mode border rounded-full"
                            : theme === "high-contrast"
                            ? "high-contrast border rounded-full"
                            : ""
                        }`}
                        name="userType"
                        label="User Type"
                        component="select"
                        id="floating_outlined_1"
                        onChange={(e) => {
                          const value = e.target.value;
                          setFieldValue("userType", value);
                          setSelectedUserType(value);
                        }}
                      >
                        <option value="">{translations[langMode].userType}</option>
                        <option value="admin">Admin</option>
                        <option value="office_user">Office user</option>
                        <option value="field_agent">Field agent</option>
                      </Field>
                    </div>
                    <div className="sm:col-span-2">
                      <Field
                        type="text"
                        name="email"
                        label={translations[langMode].email}
                        component={CommonInputField}
                        value={values.email}
                        onChange={(e) => setFieldValue("email", e.target.value)}
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <PhoneInput
                        country={"om"}
                        style={{
                          border: "1px solid gray",
                          borderRadius:"5px"
                        }}
                        value={values.phone}
                        onChange={(phone) => setFieldValue("phone", phone)}
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                          style: {
                            height: "49px", 
                            width: "100%", 
                            fontSize: "16px",
                            border: "1px solid gray",
                            
                          },
                        }}
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                    {isSuperAdmin && (
                      <div className="sm:col-span-2">
                        <Field
                          className={`px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2  ${
                            theme === "dark"
                              ? "dark-mode border rounded-full"
                              : theme === "high-contrast"
                              ? "high-contrast border rounded-full"
                              : ""
                          }`}
                          name="organization"
                          label="Organization"
                          component="select"
                          id="floating_outlined_1"
                          value={values.organization}
                          onChange={(e) => {
                            setFieldValue("organization", e.target.value);
                          }}>
                          <option value="">{translations[langMode].selectOrg}</option>
                          {organizations.map((org) => (
                            <option
                              key={org.organizationId}
                              value={org.organizationId}
                            >
                              {org.organizationName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="organizationId"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    )}
                    <div className="sm:col-span-2">
                      <Field
                        type="text"
                        name="areaCode"
                        label={translations[langMode].areCode}
                        component={CommonInputField}
                        value={values.areaCode}
                        onChange={(e) =>
                          setFieldValue("areaCode", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {selectedUserType !== "field_agent" && (
                    <>
                      {isSuperAdmin && (
                        <div className="py-2">
                          <h1 className="font-bold text-gray-600 text-xs"> {translations[langMode].previllage}</h1>
                          <div className="grid grid-cols-3 gap-2 text-gray-600 py-2">
                            <CommonRadio
                              name="canAddEditActivityTemplates"
                              que={translations[langMode].addeditActivity}
                            />
                            <CommonRadio
                              name="canAddLabelsToActivityTemplates"
                              que={translations[langMode].addnewlabel}
                            />
                            <CommonRadio
                              name="canAddEditUser"
                              que={translations[langMode].addEdituser}                            />
                            <CommonRadio
                              name="canAccessAssetManagement"
                              que={translations[langMode].canasset}
                            />
                            <div>
                              <span className="text-sm font-semibold">
                              {translations[langMode].userVisibility}
                              </span>
                              <div className="flex items-center mb-4 mt-2">
                                <input
                                  id="office-user-checkbox"
                                  type="checkbox"
                                  name="userVisibility"
                                  value="office_user"
                                  onChange={handleCheckboxChange}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                  htmlFor="office-user-checkbox"
                                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                  Office User
                                </label>
                              </div>
                              <div className="flex items-center mb-4">
                                <input
                                  id="field-user-checkbox"
                                  type="checkbox"
                                  name="userVisibility"
                                  value="field_agent"
                                  onChange={handleCheckboxChange}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                  htmlFor="field-user-checkbox"
                                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300" >
                                  Field User
                                </label>
                              </div>
                            </div>
                            <CommonRadio
                              name="canModifyGuidelines"
                              que={translations[langMode].guidline}
                            />
                            <CommonRadio
                              name="canCreateDashboard"
                              que={translations[langMode].createDashboar}
                            />
                            <CommonRadio
                              name="canAddEditLeaderboard"
                              que={translations[langMode].addeditleader}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {selectedUserType !== "field_agent" && (
                    <>
                      {isAdmin && (
                        <div className="px-5 py-3">
                          <h1 className="text-xs"> {translations[langMode].previllage}</h1>
                          <div className="grid grid-cols-3 gap-8 text-gray-600 py-2">
                            <CommonRadio
                              name="canAddEditActivityTemplates"
                              que={translations[langMode].addeditActivity}
                            />
                            <CommonRadio
                              name="canAddLabelsToActivityTemplates"
                              que={translations[langMode].addnewlabel}
                            />
                            <CommonRadio
                              name="canAddEditUser"
                              que={translations[langMode].addEdituser}
                            />
                            <CommonRadio
                              name="canAccessAssetManagement"
                              que={translations[langMode].canasset}
                            />
                            <div>
                              <span className="text-md font-semibold">
                                {translations[langMode].uservisibility}
                              </span>
                              <div className="flex items-center mb-4 mt-5">
                                <input
                                  id="office-user-checkbox"
                                  type="checkbox"
                                  name="userVisibility"
                                  value="office_user"
                                  onChange={handleCheckboxChange}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                  htmlFor="office-user-checkbox"
                                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                  Office User
                                </label>
                              </div>
                              <div className="flex items-center mb-4">
                                <input
                                  id="field-user-checkbox"
                                  type="checkbox"
                                  name="userVisibility"
                                  value="field_agent"
                                  onChange={handleCheckboxChange}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                  htmlFor="field-user-checkbox"
                                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                  Field Agent
                                </label>
                              </div>
                            </div>
                            <CommonRadio
                              name="canModifyGuidelines"
                              que={translations[langMode].guidline}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="">
                  <div className="sm:col-span-4">
                    <hr className="w-full border-gray-300" />
                  </div>
                  <div className="sm:col-span-4 pt-2 flex justify-end items-center">
                    <button
                      type="button"
                      onClick={handleGoBack}
                      className="px-3 py-2 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    >
                      {translations[langMode].cancle}
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="px-3 py-2 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                      {userId ? translations[langMode].update : translations[langMode].add}
                    </button>
                  </div>
                </div>
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddUser;
