import React, { useState, useEffect, useContext } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import AssetToolbar from "./AssetToolbar";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";
import { fetchAssetDataTab } from "../../service/Asset";
const AssetTable = ({ tab, tableHeaders, initialTableData }) => {
  const [totalValue, setTotalValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [filters, setFilters] = useState({
    vehicleType: "Everything",
    assetType: "Everything",
  });
  const [tableData, setTableData] = useState(initialTableData || []);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [AssertIdSearch, setAssetIdsearch] = useState("");
  const context = useContext(AppContext);
  const { selectedUserId } = context;
  const totalPages = Math.ceil(totalValue / pageSize) - 1;
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const { theme, selectedOrgId } = useContext(AppContext);
  const totalItems = totalValue;
  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterType]: value }));
    setCurrentPage(0);
  };
  const handleSearch = (term) => {
    setSearchTerm(term);
    fetchAssetData(0, pageSize, filters, term); // Make sure this is being called
  };
  const handleAssertSearch = (assetterm) => {
    setAssetIdsearch(assetterm);
    fetchAssetData(0, pageSize, filters, searchTerm, assetterm); // Make sure this is being called
  };
  useEffect(() => {
    fetchAssetData(pageSize,currentPage,filters,searchTerm,AssertIdSearch,selectedUserId,selectedOrgId);
  }, [currentPage,pageSize,filters, searchTerm, AssertIdSearch, selectedUserId, selectedOrgId]);
  const fetchAssetData = async (pageSize,currentPage,filters,searchTerm,AssertIdSearch,selectedUserId,selectedOrgId) => {
    setIsLoading(true);
    setError("");
    if (!selectedOrgId) {
      setError("Organization ID is required.");
      setIsLoading(false);
      return;
    }
    try {
      const data = await fetchAssetDataTab(tab, pageSize, currentPage,filters,searchTerm,AssertIdSearch,selectedUserId,selectedOrgId);
      if (tab === 1) {
        setTableData(data.vehicleList || []);
        setTotalValue(data.totalCount || 0);
      } else if (tab === 2) {
        if (data && data.assertList) {
          setTableData(data.assertList);
          setTotalValue(data.totalCount || 0);
        } else {
          throw new Error("Invalid response format for assertList");
        }}
    } catch (err) {
      setError(err.message || "An error occurred while fetching data.");
    } finally {
      setIsLoading(false);
    }};

  const handlePageSizeChange = (e) => {
    const size = parseInt(e.target.value, 10);
    setPageSize(size);
    setCurrentPage(0);
  };
  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }};
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }};

  const navigate = useNavigate();
  const handleVehicleClick = async (asset) => {
    const vehicleId = asset.vehicleId;
    const updateVehicleUrl = `/update-vehicle/${vehicleId}`;
    navigate(updateVehicleUrl); 
  };

  const handleAssertClick = async (asset) => {
    const assertId = asset.assertId;
    const updateAssrtUrl = `/update-assert/${assertId}`;
    navigate(updateAssrtUrl); 
  };

  const handleVehicalCheckboxChange = (index) => {
    const newData = tableData.map((item, i) => ({
      ...item,
      isChecked: i === index ? !item.isChecked : false,
    }));
    if (newData.some((asset) => asset.isChecked)) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
    if (newData[index].isChecked) {
      setSelectedItems([
        ...selectedItems,
        { id: newData[index].id, vehicleId: newData[index].vehicleId },
      ]);
    } else {
      setSelectedItems(
        selectedItems.filter((item) => item.id !== newData[index].id)
      );}
    setTableData(newData);
  };

  const handleAssetCheckboxChange = (index) => {
    const newData = tableData.map((item, i) => ({
      ...item,
      isChecked: i === index ? !item.isChecked : false,
    }));
    if (newData.some((asset) => asset.isChecked)) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
    if (newData[index].isChecked) {
      setSelectedItems([
        ...selectedItems,
        {
          id: newData[index].id,
          assertId: newData[index].assertId,
          assertName: newData[index].assertName,
        },
      ]);
    } else {
      setSelectedItems(
        selectedItems.filter((item) => item.id !== newData[index].id)
      );}
    setTableData(newData);
  };

  return (
    <>
      <div
        className={` py-1 bg-white  ${
          theme === "dark"? "dark-mode b" : theme === "high-contrast" ? "high-contrast " : ""}`} >
        <div className="relative overflow-x-auto  sm:rounded-lg">
          <AssetToolbar
            tab={tab}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            onFilterChange={handleFilterChange}
            onSearch={handleSearch}
            onAssertSearch={handleAssertSearch}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
          />
          <table
            className={`w-full h-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ${
              theme === "dark" ? "dark-mode  " : theme === "high-contrast" ? "high-contrast": " "}`} >
            <thead className="text-sm text-gray-500  ">
              <tr>
                <th className="px-3 py-1">#</th>
                {tableHeaders.map((header, index) => (
                  <th key={index} scope="col" className="px-3 py-1 text-left text-xs font-medium uppercase tracking-wider">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="h-full">
              {tableData && tableData.length > 0 ? (
                tableData.map((asset, index) => (
                  <tr
                    key={index}
                    className={`${
                      theme === "dark" ? "dark-mode  " : theme === "high-contrast" ? "high-contrast  " : ""} bg-white text-sm border-b  dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800 `}>
                    {tab === 1 ? (
                      <>
                        <td className="px-3 py-2">
                          <input
                            type="checkbox"
                            checked={asset.isChecked || false}
                            onChange={() =>
                              handleVehicalCheckboxChange(index,asset.vehicleId)}/>
                        </td>
                        <td
                          className="px-3 py-2 cursor-pointer"
                          onClick={() => handleVehicleClick(asset)} >
                          {asset.vehicleId}
                        </td>
                        <td>
                           <span
                            className={`${
                              asset.vehicleType === "SUV"
                                ? " px-3 py-1 text-center font-semibold text-purple-500 rounded-full bg-purple-400 bg-opacity-30"
                                : ""
                            }${
                              asset.vehicleType === "Hatchback"
                                ? " text-center px-3 py-1 font-semibold text-yellow-500 rounded-full bg-yellow-400 bg-opacity-30"
                                : ""
                            }`}>{asset.vehicleType}
                          </span>
                        </td>
                        <td className="px-3 py-2">{asset.kilometersUsed}</td>
                        <td className="px-3 py-2">{asset.assigneeId}</td>
                      </>
                    ) : (
                      <>
                        <td>
                          <input
                            type="checkbox"
                            checked={asset.isChecked || false}
                            onChange={() => handleAssetCheckboxChange(index)}
                          />
                        </td>
                        <td
                          className="px-3 py-2 cursor-pointer"
                          onClick={() => handleAssertClick(asset)}>
                          {asset.assertId}
                        </td>
                        <td className="px-3 py-2">{asset.assertName}</td>
                        <td className="px-3 py-2">{asset.assertBarcode}</td>
                        <td>
                          <span
                            className={` text-center${
                              asset.assertType === "Honeywell_Mobile"
                                ? " px-3 py-1 text-center font-semibold text-purple-500 rounded-full bg-purple-400 bg-opacity-30"
                                : ""
                            }${
                              asset.assertType === "Water_Meter"
                                ? " text-center px-3 py-1 font-semibold text-yellow-500 rounded-full bg-yellow-400 bg-opacity-30"
                                : ""
                            }
                        ${
                          asset.assertType === "Electric_Meter"
                            ? " px-3 py-1  text-center font-semibold text-blue-500 rounded-full bg-blue-400 bg-opacity-30"
                            : "" }`}>
                            {asset.assertType}
                          </span>
                        </td>
                        <td className={`px-3 py-2`}>{asset.assigneeId}</td>
                        <td className={`px-3 py-2`}>
                          {asset.inUse ? "In Use" : "Not in Use"}
                        </td>
                      </>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableHeaders.length + 1}
                    className="text-center py-4"
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="px-5 py-5 flex justify-between items-center">
          <div className="text-sm text-gray-400 flex items-center gap-4">
            <label htmlFor="pageSize">Rows per page:</label>
            <select
              id=""
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                theme === "dark" ? "dark-mode": theme === "high-contrast" ? "high-contrast": ""}`}
              value={pageSize}
              onChange={handlePageSizeChange}>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
          </div> 
        <div>
          <p className="text-sm text-gray-400 flex items-center gap-4">
            {`${startIndex + 1}-${Math.min( endIndex, totalItems )} of ${totalItems} items`}
            <IoIosArrowBack
              onClick={goToPreviousPage}
              disabled={currentPage === 0}
            />
            <IoIosArrowForward
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            />
          </p>
        </div>
      </div>
    </>
  );
};

export default AssetTable;
