import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { IoEye } from "react-icons/io5";
import ChartModal from "./ChartModal";
import { fetchTaskPriorityCount } from "../../../../service/UserDashboard";
import { FaExclamationCircle } from "react-icons/fa";
import AppContext from "../../../../context/AppContext";
const translations = {
  en: {
    priority:" Task Priority",
    select:"Please select an activity"
  },
  ar: {
    priority:"أولوية المهمة ",
    select:"الرجاء تحديد نشاط"  },
};
const PriorityChart = ({ selectedWorkflowId }) => {
  const [userCount, setUserCount] = useState({});
  const [showModal, setShowModal] = useState(false);
  const {langMode ,theme} = useContext(AppContext)
  const [chartView, setChartView] = useState("area"); // Default to pie chart view
  const Piecolors = ["#FF9800", "#41B06E", "#E72929"];
  const colors = ["#399918", "#EF5A6F", "#674188", "#FFD433", "#FF0000"];
  const formatLabel = (label) => {
    switch (label) {
      case "highPriority":
        return "High Priority";
      case "lowPriority":
        return "Low Priority";
      case "mediumPriority":
        return "Medium Priority";
      default:
        return label;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchTaskPriorityCount(selectedWorkflowId);
        setUserCount(data);
      } catch (error) {
        console.error("Error fetching task priority count:", error);
      }
    };
    fetchData();
  }, [selectedWorkflowId]);

  const ChartView = ({ series, options }) => (
    <div id="chart" className="w-full">
      <ReactApexChart
        options={{
          ...options,
          toolbar: {
            show: false, // Hide the toolbar to remove download options
          },
          dataLabels: {
            enabled: true, // Hide data labels
          },
          legend: {
            show: true, // Hide legend
            position: 'bottom',
          },
          tooltip: {
            enabled: true, // Hide tooltips
          },
        }}
        series={series}
        type="donut"
        width="380"
      />
    </div>
  );

  const GraphView = ({ series, options }) => {
    const graphSeries = [
      {
        name: "Task Priority Count",
        data: series,
      },
    ];

    const graphOptions = {
      ...options,
      chart: {
        ...options.chart,
        type: "bar", // Changing the chart type to bar for graph view
        toolbar: {
          show: false, // Hide the toolbar to remove download options
        },
      },
      xaxis: {
        categories: Object.keys(userCount).map(formatLabel),
      },
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: true,
        },
      },
      dataLabels: {
        enabled: true, // Show data labels
      },
      legend: {
        show: true, // Hide legend
      },
      tooltip: {
        enabled: true, // Show tooltips
      },
      colors: colors,
    };

    return (
      <div id="graph" className="w-full">
        <ReactApexChart
          options={graphOptions}
          series={graphSeries}
          type="bar"
          width="440"
        />
      </div>
    );
  };

  const AreaChartView = ({ series, options }) => {
    const areaOptions = {
      ...options,
      chart: {
        ...options.chart,
        type: "area", // Changing the chart type to area for area chart view
        toolbar: {
          show: false, // Hide the toolbar to remove download options
        },
      },
      xaxis: {
        categories: Object.keys(userCount).map(formatLabel),
      },
      colors: colors,

      stroke: {
        curve: "smooth",
      },
      dataLabels: {
        enabled: true, // Show data labels
      },
      legend: {
        show: true, // Hide legend
      },
      tooltip: {
        enabled: true, // Show tooltips
      },
    };

    return (
      <div id="area-chart" className="w-full">
        <ReactApexChart
          options={areaOptions}
          series={[{ name: "Task Priority Count", data: series }]}
          type="area"
          width="440"
        />
      </div>
    );
  };

  const RadarChartView = ({ series, options }) => {
    const radarOptions = {
      ...options,
      chart: {
        ...options.chart,
        type: "radar", // Set chart type to radar
        toolbar: {
          show: false, // Hide the toolbar to remove download options
        },
      },
      xaxis: {
        categories: Object.keys(userCount).map(formatLabel),
      },
      stroke: {
        show: true,
        width: 2,
        colors: colors,
      },
      fill: {
        opacity: 0.3,
      },
      markers: {
        size: 4,
        hover: {
          size: 6,
        },
      },
      dataLabels: {
        enabled: true, // Show data labels
      },
      legend: {
        show: true, // Hide legend
      },
      tooltip: {
        enabled: true, // Show tooltips
      },
    };

    return (
      <div id="radar-chart" className="w-full">
        <ReactApexChart
          options={radarOptions}
          series={[{ name: "Task Priority Count", data: series }]}
          type="radar"
          width="440"
        />
      </div>
    );
  };

  const categories = Object.keys(userCount).map(formatLabel);
  const data = categories.map(
    (category) =>
      userCount[
        Object.keys(userCount).find((key) => formatLabel(key) === category)
      ] || 0
  );

  const options = {
    labels: categories,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: "Task Priority Count",
              showAlways: true,
            },
          },
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
    },
    dataLabels: {
      enabled: true, // Show data labels
    },

    tooltip: {
      enabled: true, // Show tooltips
    },
    colors: Piecolors,
  };

  return (
    <div
    className={` ${
      theme === "dark"
        ? " text-gray-200 "
        : " text-gray-900"
    }`}
  >
      <div className="flex justify-between py-1">
        <h2 className="font-semibold text-sm text-left py-1 flex">
          <FaExclamationCircle className="mt-1 text-green-400 mr-1" />{translations[langMode].priority}
        </h2>
        {categories.length !== 0 && (
          <button
            type="button"
            className="px-3 text-xs justify-end font-medium text-center inline-flex items-center"
            onClick={() => setShowModal(true)}
          >
            <IoEye className="w-5 h-5 mr-2" />
          </button>
        )}
      </div>
      {categories.length === 0 ? (
       <div className="flex items-center justify-center text-center text-red-600  h-[280px] w-[500px] ">
          <span className="text-center text-lg"> {translations[langMode].select}.</span>
        </div>
      ) : (
        <>
          {chartView === "chart" ? (
            <ChartView series={data} options={options} />
          ) : chartView === "area" ? (
            <AreaChartView series={data} options={options} />
          ) : chartView === "radar" ? (
            <RadarChartView series={data} options={options} />
          ) : (
            <GraphView series={data} options={options} />
          )}
        </>
      )}
      <div className="mixed-chart  mt-2">
        <ChartModal
          showModal={showModal}
          setShowModal={setShowModal}
          setChartView={setChartView}
        />
      </div>
    </div>
  );
};
export default PriorityChart;
