import React, { useContext, useState } from "react";
import PreviewForm from "./PreviewForm";
import { RxCross2 } from "react-icons/rx";
import AppContext from "../../../context/AppContext";

const Modal = ({ onClose, previewData, page }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const {theme}= useContext(AppContext)
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const previousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm">
      <div
        className={`bg-white rounded-lg p-8 relative overflow-y-auto border border-gray-400 h-[500px] ${
          theme === "dark"
            ? "dark-mode "
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
        style={{ width: "500px" }}
      >
        <span
          className="absolute top-0 right-0 m-4 cursor-pointer text-gray-500"
          onClick={onClose}
        >
          <RxCross2 className="h-5 w-5" />
        </span>
        <div className="">
          {previewData && (
            <PreviewForm
              fields={previewData}
              totalPages={page}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          )}
        </div>
        <div className="mt-32 justify-end flex">
          <button
            onClick={previousPage}
            className="rounded-md p-2 border bg-blue-800 text-white border-blue-800 hover:bg-blue-700 mx-1"
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className="rounded-md p-2 border bg-blue-800 text-white border-blue-800 hover:bg-blue-700 mx-1"
            onClick={nextPage}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
