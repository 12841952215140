import React, { useContext, useState } from "react";
import TaskStatusCountChart from "./static-charts/TaskStatusCountChart";
import UserCountChart from "./static-charts/UserCountChart";
import PriorityChart from "./static-charts/PriorityChart";
import AppContext from "../../../context/AppContext";
import { FaUsers, FaChartPie, FaTasks } from "react-icons/fa";
import { RiAdminLine, RiMapPinUserFill } from "react-icons/ri";

const translations = {
  en: {
    admin: "Admin",
    office_user: "Office Users",
    field_agent: "Field Agents",
  },
  ar: {
    admin: "مسؤل",
    office_user: "مستخدمي المكتب",
    field_agent: "الوكلاء الميدانيو",
  },
};

const UserDashboard = ({ selectedWorkflowId }) => {
  const {
    admincount = 0,
    fieldagentcount = 0,
    officeuser = 0,
    theme,
    selectedOrgId,
    langMode,
    runTour ,
    tourSteps
  } = useContext(AppContext);

  const cardClass = `rounded-lg shadow-lg p-4 transform transition-transform duration-300 ease-in-out 
    ${
      theme === "dark" ? "bg-gray-800 text-gray-300" : "bg-white text-gray-700"
    } 
    hover:scale-105 hover:shadow-xl`;

  const titleClass = `text-lg font-semibold mb-1 ${
    theme === "dark" ? "text-blue-400" : "text-blue-600"
  }`;
  const countClass = `text-2xl font-bold ${
    theme === "dark" ? "text-blue-200" : "text-blue-500"
  }`;

  return (
    <div className="px-6 py-1">
      {/* Cards Section */}
      <div className="grid grid-cols-1 md:grid-cols-6 gap-6 mb-8">
        {/* Admin Card */}
        <div className={cardClass}>
          <div className="flex items-center justify-between">
            <h3 className={titleClass}>{translations[langMode].admin}</h3>
            <RiAdminLine className="text-blue-500" size={28} />
          </div>
          <p className={countClass}>{admincount}</p>
        </div>

        {/* Office Users Card */}
        <div className={cardClass}>
          <div className="flex items-center justify-between">
            <h3 className={titleClass}>{translations[langMode].office_user}</h3>
            <FaUsers className="text-purple-500" size={28} />
          </div>
          <p className={countClass}>{officeuser}</p>
        </div>

        {/* Field Agents Card */}
        <div className={cardClass}>
          <div className="flex items-center justify-between">
            <h3 className={titleClass}>{translations[langMode].field_agent}</h3>
            <RiMapPinUserFill className="text-green-500" size={28} />
          </div>
          <p className={countClass}>{fieldagentcount}</p>
        </div>
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 py-3">
        <div className="w-full">
          <div
            className={`bg-white rounded-lg shadow-md p-3
        ${
          theme === "dark"
            ? "dark-mode border"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
          >
            <UserCountChart
              selectedWorkflowId={selectedWorkflowId}
              selectedOrgId={selectedOrgId}
            />
          </div>
        </div>
        <div className="w-full">
          <div
            className={`bg-white rounded-lg shadow-md p-3
        ${
          theme === "dark"
            ? "dark-mode border"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
          >
            <TaskStatusCountChart
              selectedWorkflowId={selectedWorkflowId}
              selectedOrgId={selectedOrgId}
            />
          </div>
        </div>
        <div className="w-full">
          <div
            className={`bg-white rounded-lg shadow-md p-3
        ${
          theme === "dark"
            ? "dark-mode border"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
          >
            <PriorityChart
              selectedWorkflowId={selectedWorkflowId}
              selectedOrgId={selectedOrgId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
