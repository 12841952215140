import React, { useContext } from "react";
import AppContext from "../../context/AppContext";

const CommonInputField = ({
  field, 
  form, 
  id,
  label,
  value,
  onChange
}) => {
  const hasError = form?.touched?.[field?.name] && form?.errors?.[field?.name];
  const { theme } = useContext(AppContext);
  
  return (
    <div className="relative">
      <input
        {...field}
        type="text"
        id={id}
        value={value}
        onChange={onChange}
        className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 required: ${
          hasError ? 'border-red-500' : 'border-gray-400'
        } ${theme === 'dark' ? 'dark-mode' : 'light-mode'} appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
      />
      <label
        htmlFor={id}
        className={`absolute text-md text-gray-500 bg-white duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0]  px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4  ${theme === 'dark' ? 'dark-mode' : 'light-mode'}`}
      >
        {label}
      </label>
      {hasError && (
        <div className="text-red-500 text-sm mt-1">{form?.errors?.[field?.name]}</div>
      )}
    </div>
  );
};

export default CommonInputField;
