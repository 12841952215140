import React, { useContext, useEffect, useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { CgExport, CgFileDocument } from "react-icons/cg";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import DateRangePicker from "../common/DateRangePicker";
import AppContext from "../../context/AppContext";
import { fetchOrganizations } from "../../service/Report";
import { getScheduleReport } from "../../service/Worklog";
import Joyride from "react-joyride";
const translation={
  en:{
    email:"  Schedule email report",
    export:"Export Report",
    pdf:"Export PDF",
    priority:"Priority"
  },
  ar:{
    email:"جدولة تقرير البريد الإلكتروني",
    export:"تقرير التصدير",
    pdf:"تصدير قوات الدفاع الشعبي",
    priority:"أولوية"
  }
}
const WorkLogToolbar = ({
  setOpen,
  setSelectedPriority,
  setDates,
  setSearchAccountNumber,
}) => {
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const { setgetScheduleReportDetails } = useContext(AppContext);
  const { theme , langMode , runTour,workLogtoolbar } = useContext(AppContext);

  useEffect(() => {
    const userType = Cookies.get("userType");
    setIsSuperAdmin(userType === "super_admin");
    const fetchAndSetOrganizations = async () => {
      try {
        const orgs = await fetchOrganizations();
        setOrganizations(orgs);
      } catch (error) {
        console.error("Error fetching organizations:", error);
      }
    };
    fetchAndSetOrganizations();
  }, []);

  const priorityoptions = [
    { id: "Low_Priority", value: "Low Priority" },
    { id: "Medium_Priority", value: "Medium priority" },
    { id: "High_Priority", value: "High Priority" },
  ];
  const handlePriorityChange = (e) => {
    setSelectedPriority(e.target.value); 
  };

  const handleDateSelect = (fromDate, toDate) => {
    setDates({
      fromDate: fromDate ? new Date(fromDate).toISOString() : null,
      toDate: toDate ? new Date(toDate).toISOString() : null,
    });
  };

  const GoExport = () => {
    navigate("/export");
  };
  const GoExportPDF = () => {
    Cookies.set("viewMode","worklog");
    navigate("/pdf-generator");
  };
  const closeDatePopup = () => {
    if (showDatePicker === true) {
      setShowDatePicker(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setSearchAccountNumber(e.target.value); 
  };

  const getScheduleMailReport = async () => {
    try {
      const data = await getScheduleReport();
      setgetScheduleReportDetails(data);
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setOpen(true);
  };
  return (
    <>
    <Joyride
        steps={workLogtoolbar}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
        
      />
      <div className="flex justify-between items-center">
        <div className="flex flex-row gap-2">
          <div
            className={`pb-4 bg-white  ${
              theme === "dark"
                ? "dark-mode"
                : theme === "high-contrast"
                ? "high-contrast"
                : ""
            }`}
          >
            <div className="relative mt-1">
              <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                <IoSearch />
              </div>
              <input
                type="text"
                id="table-search"
                className={`block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-24
                 focus:ring-blue-300 focus:border-blue-300  dark:border-gray-600
                  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <React.Fragment>
            <select
              onChange={handlePriorityChange}
              onClick={closeDatePopup} 
              className={`w-full h-9 mt-1 text-base border-gray-300 focus:outline-none focus:ring-purple-500
               focus:border-indigo-500 sm:text-sm rounded-md ${
                 theme === "dark"
                   ? "dark-mode"
                   : theme === "high-contrast"
                   ? "high-contrast"
                   : ""
               }`}
            >
              <option selected disabled>
              {translation[langMode].priority}
              </option>
              {priorityoptions.map((priorityoptions) => (
                <option key={priorityoptions.id} value={priorityoptions.id}>
                  {priorityoptions.value}
                </option>
              ))}
            </select>
            <DateRangePicker
              onDateSelect={handleDateSelect}
              showDatePicker={showDatePicker}
              setShowDatePicker={setShowDatePicker}
            />
          </React.Fragment>
        </div>
        <div className="">
          <button
            type="button"
            className="px-3 py-2 text-xs mr-4 font-medium text-center inline-flex items-center text-white bg-gray-800 rounded-lg hover:bg-gray-700 focus:ring-1 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            onClick={getScheduleMailReport}
          >
            <span className="bg-white rounded-full p-1 mr-1">
              <AiOutlineMail className="w-4 h-4  text-gray-800 " />
            </span>
            {translation[langMode].email}
          </button>
          <button
            type="button"
            onClick={GoExport}
            className="exportreport px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-1 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-900 dark:focus:ring-blue-800">
            <span className="bg-white rounded-full p-1 mr-1">
              <CgExport className="w-4 h-4  text-gray-800 " />
            </span>
            {translation[langMode].export}
          </button>
          <button
            type="button"
            onClick={GoExportPDF}
            className="exportpdf px-3 py-2 mx-3 text-xs font-medium text-center inline-flex items-center text-white bg-yellow-400 rounded-lg hover:bg-yellow-500 focus:ring-1 focus:outline-none focus:ring-yellow-600 dark:bg-yellow-600 dark:hover:bg-yellow-900 dark:focus:ring-yellow-800" >
            <span className="bg-white rounded-full p-1 mr-1">
              <CgFileDocument className="w-4 h-4  text-yellow-500 " />
            </span>{translation[langMode].pdf}
          </button>
        </div>
      </div>
    </>
  );
};

export default WorkLogToolbar;
