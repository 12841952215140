import React, { useState } from "react";
import {
  FaCheck,
  FaClock,
  FaEye,
  FaLocationDot,
  FaPause,
  FaSpinner,
  FaUser,
} from "react-icons/fa6";
import editImg from "../../../assets/editImg.png";
import deleteImg from "../../../assets/deteteImg.png";
import { MdClose, MdDeleteForever } from "react-icons/md";
import { updateTaskDetails } from "../../../service/Task";
import { toast } from "react-toastify";
import { FaUserEdit } from "react-icons/fa";
import { RiDeleteBack2Fill, RiDeleteBin5Fill } from "react-icons/ri";
const MarkerInfoModal = ({ taskDetail, onClose, onDelete, setAssignTask }) => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [formData, setFormData] = useState({
    priority: taskDetail.priority || "N/D",
    areaCode: taskDetail.areaCode || "N/D",
    area: taskDetail.taskDetailsParsed?.["Area"] || "N/D",
    mobile: taskDetail.taskDetailsParsed?.["Mobile"] || "N/D",
    geoCode: taskDetail.taskDetailsParsed?.["GEO_CODE"] || "N/D",
    meterNo: taskDetail.taskDetailsParsed?.["METERNO"] || "N/D",
    outstanding: taskDetail.taskDetailsParsed?.["Outstanding Init"] || "N/D",
    category: taskDetail.taskDetailsParsed?.["CATEGORY"] || "N/D",
    typeCode: taskDetail.taskDetailsParsed?.["TypeCode"] || "N/D",
  });
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" }); // 'Jan', 'Feb', etc.
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };
  const formattedCreatedDate = taskDetail.createdDate
    ? formatDate(taskDetail.createdDate)
    : "N/A";
  const formattedFinishedDate = taskDetail.finishedDate
    ? formatDate(taskDetail.finishedDate)
    : "N/A";

  const getStatusIcon = (status) => {
    switch (status) {
      case "Active":
        return <FaCheck className="text-purple-200" />;
      case "Assigned":
        return <FaClock className="text-blue-500" />;
      case "On_Hold":
        return <FaPause className="text-yellow-500" />;
      case "In_Progress":
        return <FaSpinner className="text-orange-500 animate-spin" />;
      case "Completed":
        return <FaCheck className="text-green-500" />;
      default:
        return <FaEye className="text-gray-500" />;
    }
  };

  const handleEditTask = () => {
    setShowEditPopup((prevedit) => !prevedit);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleUpdateTask = async () => {
    const updatedTaskDetails = {
      ...taskDetail.taskDetailsParsed,
      "Area Code": formData.areaCode,
      Area: formData.area,
      Mobile: formData.mobile,
      GEO_CODE: formData.geoCode,
      METERNO: formData.meterNo,
      "Outstanding Init": formData.outstanding,
      CATEGORY: formData.category,
      TypeCode: formData.typeCode,
    };

    const updatedTask = {
      taskId: taskDetail.taskId,
      workflowId: taskDetail.workflowId,
      assigneeId: taskDetail.assigneeId,
      priority: formData.priority || "Low_Priority",
      taskDetails: JSON.stringify(updatedTaskDetails),
      taskStatus: taskDetail.taskStatus,
      assignedBy: taskDetail.assignedBy,
      createdDate: taskDetail.createdDate,
      latitude: parseFloat(updatedTaskDetails["Latitude"]) || null,
      longitude: parseFloat(updatedTaskDetails["Longitude"]) || null,
      areaCode: updatedTaskDetails["Area Code"] || null,
      tariff: taskDetail.tariff,
      accountNo: updatedTaskDetails["Account No."] || null,
      createdBy: taskDetail.createdBy,
      finishedDate: taskDetail.finishedDate,
      office: updatedTaskDetails["Office"] || null,
      area: updatedTaskDetails["Area"] || null,
      source: taskDetail.source,
      icon: taskDetail.icon,
      completed: taskDetail.completed,
      assigned: taskDetail.assigned,
      deleted: taskDetail.deleted,
    };
    try {
      await updateTaskDetails(updatedTask);
      onClose();
      toast.success("Task Updated Successfully");
    } catch (error) {
      console.error("Error updating task details:", error);
    }
  };
  const taskDetailClose = () => {
    onClose();
    setAssignTask(false);
  };

  return (
    <div className="flex justify-between gap-44">
      <div
        className="absolute bg-black bg-opacity-80 p-3 rounded-lg shadow-lg overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-300"
        style={{
          top: "3%",
          left: "10%",
          zIndex: 9999,
          width: "20%",
          height: "470px",
        }}
      >
        <div className="flex justify-between items-center mb-3">
          <h6 className="font-bold text-lg text-white">
            {taskDetail.taskDetailsParsed &&
              taskDetail.taskDetailsParsed["Account No."]}
          </h6>
          <div className="flex gap-2">
            <button
              className="text-white hover:text-gray-300"
              onClick={handleEditTask}
            >
              <FaUserEdit size={20} />
            </button>
            <button
              className="text-white hover:text-gray-300"
              onClick={onDelete}
            >
              <RiDeleteBin5Fill size={20}  />
            </button>
            <button
              className="text-white hover:text-gray-300"
              onClick={taskDetailClose}
            >
              <MdClose size={20} />
            </button>
          </div>
        </div>
        <div className="text-white text-xs">
          <div className="flex justify-between items-center mb-2">
            <h3 className="flex items-center gap-2 font-semibold text-sm">
              <FaLocationDot className="text-rose-400" />
              {taskDetail.taskDetailsParsed &&
                taskDetail.taskDetailsParsed["Activity"]}
            </h3>
            <h3 className="text-gray-400 font-semibold flex gap-2 text-sm">
              <FaUser />
              {taskDetail.assigneeId}
            </h3>
          </div>
          <div className="flex justify-between items-center mb-2">
            <p className="flex items-center gap-2 font-semibold text-sm">
              <FaEye className="text-yellow-400" />
              {taskDetail.taskDetailsParsed &&
                taskDetail.taskDetailsParsed["Status"]}
            </p>
            <p className="flex items-center gap-2 font-semibold text-sm">
              {getStatusIcon(taskDetail.taskStatus)}
              {taskDetail.taskStatus}
            </p>
          </div>
          <hr className="border-gray-600 my-2" />
          <div className="p-2">
            <h6 className="text-sm font-semibold mb-2">Task Params</h6>
            <div className="bg-gray-900 bg-opacity-50 rounded-lg flex flex-col px-4 py-3 text-md gap-1">
              <span>
                Mobile Number:{" "}
                {taskDetail.taskDetailsParsed &&
                  taskDetail.taskDetailsParsed["Mobile"]}
              </span>
              <span>
                GEO CODE:{" "}
                {taskDetail.taskDetailsParsed &&
                  taskDetail.taskDetailsParsed["GEO_CODE"]}
              </span>
              <span>
                METERNO:{" "}
                {taskDetail.taskDetailsParsed &&
                  taskDetail.taskDetailsParsed["METERNO"]}
              </span>
              <span>Priority: {taskDetail.priority}</span>
              <span>Created Date: {formattedCreatedDate}</span>
              <span>Finished Date: {formattedFinishedDate}</span>
              <span>Area code: {taskDetail.areaCode}</span>
              <span>Office: {taskDetail.office}</span>
              <span>Area: {taskDetail.area}</span>
              <span>Tariff: {taskDetail.tariff}</span>
              <span>Latitude: {taskDetail.latitude}</span>
              <span>Longitude: {taskDetail.longitude}</span>
              <span>
                Category:{" "}
                {taskDetail.taskDetailsParsed &&
                  taskDetail.taskDetailsParsed["CATEGORY"]}
              </span>
              <span>
                Outstanding Init:{" "}
                {taskDetail.taskDetailsParsed &&
                  taskDetail.taskDetailsParsed["Outstanding Init"]}
              </span>
              <span>
                TypeCode:{" "}
                {taskDetail.taskDetailsParsed &&
                  taskDetail.taskDetailsParsed["TypeCode"]}
              </span>
              <span>
                Activity:{" "}
                {taskDetail.taskDetailsParsed &&
                  taskDetail.taskDetailsParsed["Activity"]}
              </span>
            </div>
          </div>
        </div>
      </div>

      {showEditPopup && (
        <div
          className="absolute bg-white p-2 shadow-lg rounded-md overflow-y-auto"
          style={{
            top: "10px",
            left: "470px",
            zIndex: 1000,
            width: "300px",
            height: "470px",
          }}
        >
          <h3 className="text-sm font-bold px-2 ">Task Details</h3>
          <form>
            <div className="bg-white py-1 px-2 rounded-md border shadow-inner text-sm">
              <h6 className="font-bold text-gray-700">
                {taskDetail.taskDetailsParsed &&
                  taskDetail.taskDetailsParsed["Account No."]}
              </h6>
              <h3 className="flex items-center gap-2 font-semibold">
                <FaLocationDot className="text-rose-400" />
                {taskDetail.taskDetailsParsed &&
                  taskDetail.taskDetailsParsed["Activity"]}
              </h3>
              <p className="flex items-center gap-2 font-semibold text-gray-700 text-sm">
                {getStatusIcon(taskDetail.taskStatus)}
                {taskDetail.taskStatus}
              </p>
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Mobile Number:
              </label>
              <input
                type="text"
                className="w-full px-3 py-1 border rounded-md bg-white"
                value={taskDetail.taskDetailsParsed?.Mobile || "N/A"}
                onChange={(e) => handleInputChange("Mobile", e.target.value)}
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                GEO CODE:
              </label>
              <input
                type="text"
                className="w-full px-3 py-1 border rounded-md bg-white"
                value={taskDetail.taskDetailsParsed?.GEO_CODE || "N/A"}
                onChange={(e) => handleInputChange("GEO_CODE", e.target.value)}
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                METERNO:
              </label>
              <input
                type="text"
                className="w-full px-3 py-1 border rounded-md bg-white"
                value={taskDetail.taskDetailsParsed?.METERNO || "N/A"}
                onChange={(e) => handleInputChange("METERNO", e.target.value)}
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Priority:
              </label>
              <input
                type="text"
                className="w-full px-3 py-1 border rounded-md bg-white"
                value={taskDetail.priority || "N/A"}
                onChange={(e) => handleInputChange("priority", e.target.value)}
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Created Date:
              </label>
              <input
                type="text"
                className="w-full px-3 py-1 border rounded-md bg-white"
                value={formattedCreatedDate || "N/A"}
                readOnly
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Finished Date:
              </label>
              <input
                type="text"
                className="w-full px-3 py-1 border rounded-md bg-white"
                value={formattedFinishedDate || "N/A"}
                readOnly
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Area code:
              </label>
              <input
                type="text"
                className="w-full px-3 py-1 border rounded-md bg-white"
                value={taskDetail.areaCode || "N/A"}
                onChange={(e) => handleInputChange("areaCode", e.target.value)}
              />
            </div>

            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Office:
              </label>
              <input
                type="text"
                className="w-full px-3 py-1 border rounded-md bg-white"
                value={taskDetail.office || "N/A"}
                onChange={(e) => handleInputChange("office", e.target.value)}
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">Area:</label>
              <input
                type="text"
                className="w-full px-3 py-1 border rounded-md bg-white"
                value={taskDetail.area || "N/A"}
                onChange={(e) => handleInputChange("area", e.target.value)}
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Tarrif:
              </label>
              <input
                type="text"
                className="w-full px-3 py-1 border rounded-md bg-white"
                value={taskDetail.tariff || "N/A"}
                onChange={(e) => handleInputChange("tariff", e.target.value)}
              />
            </div>

            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Latitude:
              </label>
              <input
                type="text"
                className="w-full px-3 py-1 border rounded-md bg-white"
                value={taskDetail.latitude || "N/A"}
                onChange={(e) => handleInputChange("latitude", e.target.value)}
              />
            </div>

            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Longitude:
              </label>
              <input
                type="text"
                className="w-full px-3 py-1 border rounded-md bg-white"
                value={taskDetail.longitude || "N/A"}
                onChange={(e) => handleInputChange("longitude", e.target.value)}
              />
            </div>
            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Category:
              </label>
              <input
                type="text"
                className="w-full px-3 py-1 border rounded-md bg-white"
                value={taskDetail.taskDetailsParsed?.CATEGORY || "N/A"}
                onChange={(e) => handleInputChange("CATEGORY", e.target.value)}
              />
            </div>

            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                Outstanding Init:
              </label>
              <input
                type="text"
                className="w-full px-3 py-1 border rounded-md bg-white"
                value={
                  taskDetail.taskDetailsParsed?.["Outstanding Init"] || "N/A"
                }
                onChange={(e) =>
                  handleInputChange("Outstanding Init", e.target.value)
                }
              />
            </div>

            <div className="mb-1 py-1">
              <label className="block text-xs font-semibold mb-1">
                TypeCode:
              </label>
              <input
                type="text"
                className="w-full px-3 py-1 border rounded-md bg-white"
                value={taskDetail.taskDetailsParsed?.TypeCode || "N/A"}
                onChange={(e) => handleInputChange("TypeCode", e.target.value)}
              />
            </div>

            {/* {[
              "priority",
              "areaCode",
              "area",
              "mobile",
              "geoCode",
              "meterNo",
              "outstanding",
              "category",
              "typeCode",
            ].map((field) => (
              <div className="mb-1 py-1" key={field}>
                <label className="block text-xs font-semibold mb-1">
                  {field.charAt(0).toUpperCase() +
                    field.slice(1).replace(/([A-Z])/g, " $1")}
                </label>
                <input
                  type="text"
                  className="w-full px-3 py-1 border rounded-md bg-white"
                  name={field}
                  value={formData[field]}
                  onChange={handleInputChange}
                />
              </div>
            ))} */}
            <div className="flex justify-end gap-2">
              <button
                type="button"
                className="px-3 py-1 bg-white text-gray-700 rounded-md border-2"
                onClick={() => setShowEditPopup(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="px-3 py-1 bg-blue-800 text-white rounded-md"
                onClick={handleUpdateTask}
              >
                Update
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default MarkerInfoModal;
