import React, { useState, useEffect, useContext } from "react";
import FilterSelect from "../common/FilterSelect";
import AppContext from "../../context/AppContext";
const translations = {
  en: {
    reportType: "Report Type",
    userType: "User Type",
    status: "Status",
    fromdate:"From date",
    todate:"To date",
  },
  ar: {
    reportType: "نوع التقرير",
    userType: "نوع المستخدم",
    status: "حالة",
    fromdate:"من تاريخ",
    todate:"حتى الآن",
  },
};
const ReportToolbar = ({ onFiltersChange }) => {
  const [ReportType, setReportType] = useState("");
  const [UserType, setUserType] = useState("");
  const [Status, setStatus] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const { theme , langMode } = useContext(AppContext);

  const reportType = [
    { id: "", value: "Default" },
    { id: "VEHICLES", value: "Vehicles" },
    { id: "INVENTORY", value: "Inventory" },
  ];

  const userType = [
    { id: "", value: "Default" },
    { id: "admin", value: "Admin" },
    { id: "office_user", value: "Office_user" },
  ];

  const status = [
    { id: "", value: "Default" },
    { id: "0", value: "Pending" },
    { id: "1", value: "Ready To Download" },
  ];

  useEffect(() => {
    onFiltersChange({
      ReportType,
      UserType,
      Status,
      fromDate: fromDate ? formatDate(fromDate) : "",
      toDate: toDate ? formatDate(toDate) : "",
    });
  }, [ReportType, UserType, Status, fromDate, toDate, onFiltersChange]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };
  const handleReportType = (value) => {
    if (value === "Default") {
      setReportType("");
    } else {
      setReportType(value);
    }
  };

  const handleUserTypeChange = (value) => {
    if (value === "Default") {
      setUserType("");
    } else {
      setUserType(value);
    }
  };
  const handleStatusChange = (value) => {
    if (value === "Default") {
      setStatus("");
    } else {
      const selectedStatus = status.find((status) => status.value === value);
      setStatus(selectedStatus ? selectedStatus.id : "");
    }
  };

  return (
    <div className="flex justify-between items-center">
      <div className="flex flex-row gap-2">
        <div
          className={`pb-4 ${
            theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }`}
        ></div>
        <FilterSelect
          options={reportType}
          defaultText={translations[langMode].reportType}
          value={ReportType}
          onChange={handleReportType}
        />
        <FilterSelect
          options={userType}
          defaultText={translations[langMode].userType}
          value={UserType}
          onChange={handleUserTypeChange}
        />
        <FilterSelect
          options={status}
          defaultText={translations[langMode].status}
          value={status.find((s) => s.id === Status)?.value || ""}
          onChange={handleStatusChange}
        />
        <div className=" px-1 mt-1">
          <div className="flex items-center">
            <input
              type="text"
              className={`form-input border pt-1 border-gray-400 rounded-md w-36 h-9 ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast  "
                  : ""
              }`}
              value={fromDate ? formatDate(fromDate) : ""}
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => {
                if (!e.target.value) {
                  e.target.type = "text";
                }
              }}
              onChange={(e) => setFromDate(e.target.value)}
              placeholder={translations[langMode].fromdate}
            />
          </div>
        </div>
        <div className="mt-1 px-1">
          <div className="flex items-center">
            <input
              type="text"
              className={`form-input border pt-1 border-gray-400 rounded-md w-36 h-9 ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast  "
                  : ""
              }`}
              value={toDate ? formatDate(toDate) : ""}
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => {
                if (!e.target.value) {
                  e.target.type = "text";
                }
              }}
              onChange={(e) => setToDate(e.target.value)}
              placeholder={translations[langMode].todate}
            />
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default ReportToolbar;
