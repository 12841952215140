import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { IoEye } from "react-icons/io5";
import ChartModal from "../user/static-charts/ChartModal";

const BarChart = ({ tempData, chartName }) => {
  const [showModal, setShowModal] = useState(false);
  const [chartView, setChartView] = useState("bar");

  const filteredData = tempData?.graphValueList?.filter(item => item.value != null) || [];
  const categories = filteredData.map(item => item.value);
  const data = filteredData.map(item => item.frequency);
  const colors = ["#EF5A6F", "#3A1078", "#399918", "#FFD433", "#EBD3F8"];
  const options = {
    chart: {
      stacked: true,
    },
    xaxis: {
      categories: categories.length > 0 ? categories : ["Default Label"],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
      },
    },
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true, 
    },
    tooltip: {
      enabled: true,
    },
    toolbar: {
      show: false, // Hide the toolbar to remove download options
  },
    colors: colors,
  };

  const series = [
    {
      name: "Frequency",
      data: data,
    },
  ];

  const ChartView = () => (
    <div id="chart">
      <ReactApexChart
        options={{
          ...options,
          chart: {
            ...options.chart,
            type: "donut",
          },
          toolbar: {
            show: false, // Hide the toolbar to remove download options
        },
          labels: categories, 
          plotOptions: {
            pie: {
              expandOnClick: true,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
            position: 'bottom',
          },
          tooltip: {
            enabled: true, 
          },
        }}
        series={data}
        type="donut"
        width="390"
      />
    </div>
  );

  const GraphView = () => (
    <div id="graph">
      <ReactApexChart
        options={{ ...options, chart: { ...options.chart, type: "bar",
          toolbar: {
            show: false, // Hide the toolbar to remove download options
        },
         },dataLabels: {
          enabled: true, // Hide data labels
        },
        legend: {
          show: true, // Hide legend
        },
        tooltip: {
          enabled: true, // Hide tooltips
        }, }}
        series={series}
        type="bar"
         width="450"
      />
    </div>
  );

  const AreaChartView = () => (
    <div id="area-chart">
      <ReactApexChart
        options={{
          ...options,
          chart: {
            ...options.chart,
            type: "area",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          dataLabels: {
            enabled: true, // Hide data labels
          },
          legend: {
            show: true, // Hide legend
          },
          tooltip: {
            enabled: true, // Hide tooltips
          },
        }}
        series={series}
        type="area"
      width="450"
      />
    </div>
  );
  const RadarChartView = () => {
    const radarOptions = {
      chart: {
        type: "radar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: categories || ['Category 1', 'Category 2', 'Category 3'],
      },
      markers: {
        size: 4,
      },
      dataLabels: {
        enabled: true, // Hide data labels
      },
      legend: {
        show: true, // Hide legend
      },
      tooltip: {
        enabled: true, // Hide tooltips
      },
      colors: colors
    };

    return (
      <div id="radar">
        <ReactApexChart
          options={radarOptions}
          series={series}
          type="radar"
            width="450"
        />
      </div>
    );
  };
  return (
    <div className="stacked-column-chart">
      <div className="flex justify-between my-5">
        <h3 className="text-md font-semibold">{chartName}</h3>
        <button
          type="button"
          className="px-3 text-xs justify-end font-medium text-center inline-flex items-center"
          onClick={() => setShowModal(true)}
        >
          <IoEye className="w-5 h-5 mr-2" />
        </button>
      </div>
      {chartView === "chart" ? (
        <ChartView />
      ) : chartView === "area" ? (
        <AreaChartView />
      ) : chartView === "radar" ? (
        <RadarChartView />
      ) : (
        <GraphView />
      )}
      <ChartModal
        showModal={showModal}
        setShowModal={setShowModal}
        setChartView={setChartView}
      />
    </div>
  );
};

export default BarChart;
