import React, { useState, useEffect, useContext, useRef } from "react";
import Header from "../../components/_app/Header";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import {
  updateProfileInfo,
  getUserInfo,
  updateProfilePicture,
} from "../../service/User";
import dummyImg from "../../assets/dummy.webp";
import { deleteS3Object, uploadFileToS3 } from "../../service/S3Bucket";
const ProfileSection = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { profilePic, setProfilePic } = useContext(AppContext);
  const [userInfo, setUserInfo] = useState(null);
  const [initialUserInfo, setInitialUserInfo] = useState(null); 
  const { theme } = useContext(AppContext); 
  const fileInputRef = useRef(null);

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  useEffect(() => {
    const profilePic = Cookies.get("profilePic");
    if (profilePic && profilePic !== "null") {
      setProfilePic(profilePic);
    } else {
      setProfilePic(dummyImg);
    }
  }, []);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  async function deleteExistingImage() {
    const oldPic = Cookies.get("profilePic");
    if (!oldPic) {
      return;
    }
    try {
      const oldKey = new URL(oldPic).pathname.slice(1);
      await deleteS3Object(oldKey); 
    } catch (error) {
    }
  }

  const handleImageUpload = (event) => {
    if (event.target.files[0]) {
      const profilePic = Cookies.get("profilePic");
      if (profilePic) {
        deleteExistingImage(profilePic);
      }
      uploadAdapter(event.target.files[0]);
    }
  };

  async function uploadAdapter(loader) {
    try {
      const url = await uploadFileToS3(loader);
      setProfilePic(url);
    } catch (error) {
    }
  }

  const navigate = useNavigate();
  const ChangePassword = () => {
    if (userInfo?.email) {
      navigate("/reset-password", {
        state: { emaill: userInfo.email, fromProfile: true },
      });
    } else {
      toast.error("Email not available. Please ensure your profile is loaded.");
    }
  };
  useEffect(() => {
    GetUserInfo();
  }, []);

  const GetUserInfo = async () => {
    try {
      const data = await getUserInfo();
      setUserInfo(data);
      setInitialUserInfo(data);
    } catch (error) {
    }
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      [name]: value,
    }));
  };

  const handleCancle = () => {
    setUserInfo(initialUserInfo);
  };
  const handleSaveChanges = async () => {
    try {
      await updateProfileInfo(
        userInfo.userId,
        userInfo.name,
        userInfo.email,
        userInfo.phone
      );
      updateProfilePicture(profilePic);
      sessionStorage.setItem("userDetails", JSON.stringify(userInfo));
      Cookies.set("userType", userInfo.userType, {
        secure: true,
        sameSite: "strict",
      });
      Cookies.set("userId", userInfo.userId);
      Cookies.set("userName", userInfo.name);
      Cookies.set("userVisibility", userInfo.userVisibility);
      Cookies.set("userEmail", userInfo.email, { httpOnly: true });
      GetUserInfo();
      toast.success("Profile Updated");
    } catch (error) {
    }
  };

  return (
    <>
      <Header pageName={"Profile"} />
      <div
        className={`px-5 py-5 bg-white ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        } `}
      >
        <div className="w-1/2">
          <div className="mb-5">
            <img
              key={profilePic}
              className={`w-20 h-20 p-1 rounded-full border-2 border-gray-400 my-2 ml-2 ${
                theme === "dark"
                  ? "bg-white"
                  : theme === "high-contrast"
                  ? "bg-white"
                  : ""
              }`}
              src={profilePic}
              alt="profileimg"
            />
            <span
              className="text-sm text-blue-700 font-semibold underline cursor-pointer"
              onClick={triggerFileInput}
            >
              <p className="">Change Profile</p>
            </span>
            <input
              type="file"
              name="media"
              onChange={handleImageUpload}
              accept="image/jpeg, image/png, image/gif, image/jpg"
              className="uploadFile hidden"
              ref={fileInputRef}
            />
          </div>
          <h3 className="text-sm text-gray-500 font-bold">Basic Info</h3>
          <div className=" mt-5 grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-4">
            <div className="sm:col-span-2">
              <div className="relative">
                <input
                  type="text"
                  value={userInfo && userInfo.name}
                  onChange={handleFieldChange}
                  name="name"
                  className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 
                    appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer ${
                      theme === "dark"
                        ? "dark-mode "
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }`}
                />
                <label
                  className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                >
                  Name
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="relative">
                <input
                  type="text"
                  value={userInfo && userInfo.userType}
                  name="userType"
                  className={`block read-only cursor-none px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 
                    appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer ${
                      theme === "dark"
                        ? "dark-mode"
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }`}
                />
                <label
                  className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                >
                  userType
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="relative">
                <input
                  type="text"
                  value={userInfo && userInfo.email}
                  onChange={handleFieldChange}
                  name="email"
                  className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 
                    appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer ${
                      theme === "dark"
                        ? "dark-mode"
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }`}
                />
                <label
                  className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                >
                  Email
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="relative">
                <input
                  type="text"
                  value={userInfo && userInfo.phone}
                  onChange={handleFieldChange}
                  name="phone"
                  className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 
                    appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer ${
                      theme === "dark"
                        ? "dark-mode"
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }`}
                />
                <label
                  className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                >
                  Phone
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="relative">
                <input
                  type="text"
                  value={userInfo && userInfo.userId}
                  name="userId"
                  className={`block read-only px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 
                    appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 cursor-none peer ${
                      theme === "dark"
                        ? "dark-mode"
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }`}
                />
                <label
                  className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                >
                  userId
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="relative">
                <input
                  type="password"
                  value="122345678"
                  className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 font-semibold bg-transparent rounded-md border-2 border-gray-400 appearance-none dark:text-white dark:border-gray-500 dark:focus:border-gray-500 focus:outline-none focus:ring-0 focus:border-gray-500 peer ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                />
                <label
                  htmlFor="password"
                  className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                >
                  Password
                </label>
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute text-md text-gray-400 top-3.5 right-3.5"
                ></button>
              </div>
              <span
                onClick={ChangePassword}
                className="float-right text-sm text-blue-700 font-semibold underline cursor-pointer"
              >
                Change Password
              </span>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <div className="sm:col-span-4">
            <hr className="w-full border-gray-300" />
          </div>
          <div className="sm:col-span-4 pt-5 flex justify-end items-center">
            <button
              type="button"
              onClick={handleCancle}
              className="px-3 py-2 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSaveChanges}
              className="px-3 py-2 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfileSection;
