import React, { useContext, useState } from "react";
import CommonInputField from "../../common/CommonInputField";
import { FaLocationDot } from "react-icons/fa6";
import AppContext from "../../../context/AppContext";
import { FaSquare } from "react-icons/fa";
const translations = {
  en: {
    langSetting: "Language Settings",
    colormode: "Color Mode",
    light: "Light",
    dark: "Dark",
    highcontrast: "High Contrast",
    lang: "Language",
    customize: "Customizing Pin",
    discon: "Disconnection",
    reconn: "Reconnection",
    meterreplace: "Meter Replacement",
    noticeDel: "Notice Delivery",
    priority: "Priority",
    highPri: "High Priority",
    lowPri: "Low Priority",
    medPri: "Medium Priority",
  },
  ar: {
    langSetting: "إعدادات اللغة",
    colormode: "وضع اللون",
    light: "ضوء",
    dark: "مظلم",
    highcontrast: "التباين العالي",
    lang: "لغة",
    customize: "تخصيص دبوس",
    discon: "قطع الاتصال",
    reconn: "إعادة الاتصال",
    meterreplace: "استبدال العداد",
    noticeDel: "تسليم الإشعار",
    priority: "أولوية",
    highPri: "أولوية عالية",
    lowPri: "أولوية منخفضة",
    medPri: "أولوية متوسطة",
  },
};
export const GeneralSetting = () => {
  const {
    toggleTheme,
    theme,
    langMode,
    setLangMode,
    toggleLangMode,
    setRunTour,
    runTour,
  } = useContext(AppContext);
  const [color, setColor] = useState("#561ecb");
  const toggleTour = () => {
    setRunTour((prevRun) => !prevRun);
  };

  return (
    <>
        <div className="flex gap-5">
          <h1 className="text-gray-700 text-md font-semibold mt-1"> Guide Tour :- </h1>
          <button className="rounded-md px-2 py-1 text-white bg-blue-800" onClick={toggleTour}>
            {runTour ? "Stop Tour" : "Start Tour"}
          </button>
        </div>
      <div className="mt-5">
        <h3 className="text-sm text-gray-500 font-bold">
          {translations[langMode].langSetting}
        </h3>

        <div
          className={`mt-5 grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-4 ${
            theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }`}
        >
          <div className="sm:col-span-2">
            <CommonInputField id="floating_outlined_1" label="Languages" />
          </div>
          <div className="sm:col-span-2">
            <CommonInputField
              id="floating_outlined_2"
              label="Keyboard Layout"
            />
          </div>
        </div>
      </div>
      <div className="mt-10">
        <h3 className="text-sm text-gray-500 font-bold">
          {translations[langMode].colormode}
        </h3>
        <div
          className="mt-5 inline-flex rounded-md gap-4 shadow-sm"
          role="group"
        >
          <button
            type="button"
            onClick={() => toggleTheme("light")}
            className={`px-4 py-2 text-sm font-medium text-gray-900 bg-white border
               border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 
               focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600
                dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 
                dark:focus:text-white `}
          >
            {translations[langMode].light}
          </button>
          <button
            type="button"
            onClick={() => toggleTheme("dark")}
            className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
          >
            {translations[langMode].dark}
          </button>
          <button
            type="button"
            onClick={() => toggleTheme("high-contrast")}
            className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
          >
            {translations[langMode].highcontrast}
          </button>
        </div>
      </div>
      <div className="mt-10">
        <h3>{translations[langMode].lang}</h3>
        <div
          className="mt-5 inline-flex rounded-md gap-4 shadow-sm"
          role="group"
        >
          <button
            type="button"
            onClick={() => toggleLangMode("en")}
            className={`px-4 py-2 text-sm font-medium ${
              langMode === "English"
                ? "bg-blue-700 text-white"
                : "text-gray-900 bg-white"
            } border border-gray-400 rounded-s-lg  focus:z-10 focus:ring-2 focus:ring-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-600`}
          >
            English
          </button>
          <button
            type="button"
            onClick={() => toggleLangMode("ar")}
            className={`px-4 py-2 text-sm font-medium ${
              langMode === "Arabic"
                ? "bg-blue-700 text-white"
                : "text-gray-900 bg-white"
            } border border-gray-400 rounded-e-lg  focus:z-10 focus:ring-2 focus:ring-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-600`}
          >
            Arabic
          </button>
        </div>
      </div>
      <div className="mt-10">
        <h3 className="text-sm text-gray-500 font-bold">
          {translations[langMode].customize}
        </h3>
        <div
          className="mt-5 inline-flex rounded-md gap-4 shadow-sm"
          role="group"
        >
          <div className="flex flex-col">
            <div className="flex items-center gap-2 p-4 shadow-sm">
              <FaLocationDot className="text-rose-500" />
              <span className="text-sm text-gray-500">
                {translations[langMode].discon}
              </span>
            </div>
            <div className="flex items-center gap-2 p-4 shadow-sm">
              <FaLocationDot className="text-green-500" />
              <span className="text-sm text-gray-500">
                {translations[langMode].reconn}
              </span>
            </div>
            <div className="flex items-center gap-2 p-4 shadow-sm">
              <FaLocationDot className="text-pink-500" />
              <span className="text-sm text-gray-500">
                {translations[langMode].meterreplace}
              </span>
            </div>
            <div className="flex items-center gap-2 p-4 shadow-sm">
              <FaLocationDot className="text-yellow-400" />
              <span className="text-sm text-gray-500">
                {translations[langMode].noticeDel}
              </span>
            </div>
          </div>
          <input
            type="color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            className="w-20 h-20 border-none outline-none"
          />
          <div className="flex flex-col">
            <div className="flex items-center gap-2 p-4 shadow-sm">
              <FaLocationDot className="text-rose-500" />
              <span className="text-sm text-gray-500">
                {translations[langMode].discon}
              </span>
            </div>
            <div className="flex items-center gap-2 p-4 shadow-sm">
              <FaLocationDot className="text-green-500" />
              <span className="text-sm text-gray-500">
                {translations[langMode].reconn}
              </span>
            </div>
            <div className="flex items-center gap-2 p-4 shadow-sm">
              <FaLocationDot className="text-pink-500" />
              <span className="text-sm text-gray-500">
                {translations[langMode].meterreplace}
              </span>
            </div>
            <div className="flex items-center gap-2 p-4 shadow-sm">
              <FaLocationDot className="text-yellow-400" />
              <span className="text-sm text-gray-500">
                {translations[langMode].noticeDel}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 flex flex-col gap-4">
        <h3 className="text-sm text-gray-500 font-bold">
          {translations[langMode].priority}
        </h3>
        <input
          type="text"
          id="first_name"
          className="bg-gray-50 border-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-60 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Add new Priority"
          required
        />
        <div className="w-1/2">
          <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-3.5 py-1.5 rounded-full dark:bg-blue-900 dark:text-blue-300">
            {translations[langMode].highPri}
          </span>
          <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-3.5 py-1.5 rounded-full dark:bg-gray-700 dark:text-gray-300">
            {translations[langMode].lowPri}
          </span>
          <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-3.5 py-1.5 rounded-full dark:bg-red-900 dark:text-red-300">
            {translations[langMode].medPri}
          </span>
        </div>
      </div>
    </>
  );
};
