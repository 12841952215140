import React from "react";
import { BsThreeDots } from "react-icons/bs";

const WorkflowVersions = ({ workflowHistories }) => {
  
  return (
    <>
      {workflowHistories.length > 0 && (
        <div className="block max-w-sm p-4 mt-5 bg-white border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <div className="flex justify-between content-center item-center p-2">
            <div className="  mt-2 w-full  rounded-lg ">
              <span className="text-base font-semibold ">Versions</span>
              {workflowHistories.map((history) => (
                <div
                  key={history.id}
                  className="p-4 mb-2 bg-gray-100 rounded-lg flex justify-between items-center hover:bg-gray-200"
                >
                  <div>
                    <div className="font-semibold">
                      {history.updatedDateTime}
                    </div>
                    <div className="text-gray-600">
                      Updated by: {history.updatedBy}
                    </div>
                  </div>
                  <label className="bg-gray-50 border cursor-pointer border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <BsThreeDots />
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WorkflowVersions;
