import React, { useContext, useState } from "react";
import AppContext from "../../../context/AppContext";
import { RxCross2 } from "react-icons/rx";

const ListChartModal = ({
  onClose,
  handleSelectedChart,
  handleCreateGraph,
}) => {
  const [selectedChart, setSelectedChart] = useState(null);
  const { theme } = useContext(AppContext);

  const chartInfo = {
    PIECHART: {
      name: "Pie Chart",
      imageUrl: "/chart/piechart.png",
      verify: "piechart",
    },
    BARCHART: {
      name: "Bar Chart",
      imageUrl: "/chart/bar.png",
      verify: "barchart",
    },
    AREACHART: {
      name: "Area Chart",
      imageUrl: "/chart/areachart.png",
      verify: "area",
    },
    RADARCHART: {
      name: "Radar Chart",
      imageUrl: "/chart/radar.png",
      verify: "radar",
    },
  };

  const handleChartClick = (verify) => {
    setSelectedChart(verify);
    handleSelectedChart(verify);
  };

  return (
    <div  className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 bg-black bg-opacity-50 outline-none focus:outline-none">
      <div
        className={`bg-white p-5 rounded-md relative w-full max-w-2xl mx-auto${
          theme === "high-contrast" ? "high-contrast" : ""
        }  ${
                     theme === "dark"
                       ? "dark-mode border"
                       : theme === "high-contrast"
                       ? "high-contrast"
                       : ""
                   }`}
        style={{ maxHeight: "90vh" }}
      >
        <span
          className="absolute top-4 right-4 text-gray-600 dark:text-gray-300 cursor-pointer text-2xl"
          onClick={onClose}
        >
          <RxCross2 />
        </span>
        <h5
          className={`text-xl font-semibold text-center mb-4 ${
            theme === "dark" ? "text-gray-300" : "text-gray-800"
          } ${
                     theme === "dark"
                       ? "dark-mode "
                       : theme === "high-contrast"
                       ? "high-contrast"
                       : ""
                   }`}
        >
          Select Chart
        </h5>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-6">
          {Object.entries(chartInfo).map(
            ([key, { name, imageUrl, verify }]) => (
              <div
                key={key}
                onClick={() => handleChartClick(verify)}
                className={`cursor-pointer flex flex-col items-center justify-center bg-gray-100 dark:bg-gray-700 rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow duration-300 ${
                  selectedChart === verify
                    ? "border border-blue-700 bg-blue-500  shadow-lg shadow-blue-500"
                    : "bg-gray-200 border border-gray-300 hover:bg-gray-200 dark:bg-gray-600 dark:hover:bg-gray-700"
                }`}
              >
                <img
                  src={imageUrl}
                  alt={name}
                  className="h-24 w-24 mb-2 rounded-md object-cover"
                />
                <div
                  className={`text-center font-semibold whitespace-nowrap ${
                    selectedChart === verify
                      ? "text-blue-700"
                      : "text-gray-800 dark:text-gray-300"
                  }`}
                >
                  {name}
                </div>
              </div>
            )
          )}
        </div>
        <div className="flex justify-center mt-6">
          <button
            type="button"
            className="px-6 py-2 bg-blue-800 text-white rounded-md font-semibold hover:bg-blue-700 transition-colors"
            onClick={handleCreateGraph}
          >
            Create Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListChartModal;
