import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_BASE_BACKEND_API_URL;

export const createAssignmentRule = async (
  workflowId,
  criteria,
  ruleName,
  isActive,
  selectedUserId
) => {
  const url = `${BASE_URL}api/v1/assignment-rule/create`;
  const data = {
    workflowId: workflowId,
    rule: criteria,
    ruleName,
    activeStatus: isActive,
    userId: selectedUserId,
  };
  const token = Cookies.get("accessToken");

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error(`Error submitting form: ${error.message}`);
  }
};

export const fetchAreaCodes = async (token, workflowId) => {
  try {
    const response = await axios.get(`${BASE_URL}api/v1/task/get-area-list`, {
      params: {
        workflowId: workflowId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status !== 200) {
      throw new Error("Network response was not ok");
    }

    return response.data.body.value;
  } catch (error) {
    throw error;
  }
};

export const assignmentRules = async (
  reset = false,
  workflowId,
  page,
  size,
  setIsLoading,
  setAssignmentList,
  setHasMore,
  setError
) => {
  if (!workflowId) {
    console.warn("workflowId is required to fetch task priority count");
    return {};
  }
  const token = Cookies.get("accessToken");
  setIsLoading(true);
  try {
    const response = await fetch(
      `${BASE_URL}api/v1/assignment-rule/getAll?workflowId=${workflowId}&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const jsonData = await response.json();
    const newData = jsonData.body.value.list || [];
    
    // Determine if there's more data to load
    const moreData = newData.length === size;

    // Get existing items in the list
    setAssignmentList((prevList) => {
      const prevListIds = new Set(prevList.map(item => item.id)); // Adjust 'id' based on your unique key
      const filteredNewData = newData.filter(item => !prevListIds.has(item.id));
      const updatedList = reset ? filteredNewData : [...prevList, ...filteredNewData];
      
      return updatedList;
    });

    setHasMore(moreData); // Set flag for more data
  } catch (error) {
    setError(error);
  } finally {
    setIsLoading(false);
  }
};

export const updateFunction = async (
  assignment,
  workflowId,
  fetchAssignmentRules,
  toast
) => {
  const { id, ruleName, userId,rule,activeStatus } = assignment;
  const url = `${BASE_URL}api/v1/assignment-rule/update`;
  const data = {
    id: Number(id),
    workflowId: Number(workflowId), // Assuming workflowId is passed as a parameter
    rule: rule,
    ruleName : ruleName ,
    userId: Number(userId),
    activeStatus : activeStatus
  };
  const token = Cookies.get("accessToken");
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    if (responseData.header.code === 600) {
      toast.success("Rule updated successfully!");
      fetchAssignmentRules(); // Assuming fetchAssignmentRules is passed as a parameter
    } else {
      toast.error(`Error: ${responseData.body.value}`);
    }
  } catch (error) {
    console.error("Error updating assignment rule:", error);
  }
};

export const assignmentRulesList = async (
  reset = false,
  workflowId,
  page,
  size,
  setIsLoading,
  setAssignmentList,
  setHasMore,
  setError
) => {
  const token = Cookies.get("accessToken");
  setIsLoading(true);
  try {
    const response = await fetch(
      `${BASE_URL}api/v1/assignment-rule/get-list?workflowId=${workflowId}&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const jsonData = await response.json();
    if (
      jsonData.body.value === "No Data Found" ||
      jsonData.body.value.length === 0
    ) {
      setHasMore(false);
    } else {
      if (reset) {
        setAssignmentList(jsonData.body.value.list);
      } else {
        setAssignmentList((prevList) => [
          ...new Set([...prevList, ...jsonData.body.value]),
        ]);
      }
      setHasMore(jsonData.body.value.length === size);
    }
  } catch (error) {
    setError(error);
  } finally {
    setIsLoading(false);
  }
};

export const smartAssignRulesList = async (
  reset = false,
  workflowId,
  page ,
  size ,
  setIsLoading,
  setAssignmentList,
  setHasMore,
  setError
) => {
  if (!workflowId) {
    console.warn("workflowId is required to fetch Data");
    return;
  }
  const token = Cookies.get("accessToken");
  setIsLoading(true);
  try {
    const response = await fetch(
      `${BASE_URL}api/v1/assignment-rule/get-rule-names?workflowId=${workflowId}&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const jsonData = await response.json();
    const rules = jsonData?.body?.value?.ruleNameList || [];

    if (jsonData?.body?.value === "No Data Found" || rules.length === 0) {
      setHasMore(false);
    } else {
      setAssignmentList((prevList) =>
        reset ? rules : [...prevList, ...rules]
      );
      setHasMore(rules.length === size);
    }
  } catch (error) {
    setError(error.message || "An error occurred");
  } finally {
    setIsLoading(false);
  }
};

export const assignByArea = async (selectedIds) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await fetch(
      `${BASE_URL}api/v1/assignment-rule/assign-by-rules`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify( selectedIds ),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error assigning by area:", error);
    throw error;
  }
};

export const fetchCompletionRules = async (selectedWorkflowId, page, size) => {
  const token = Cookies.get("accessToken");
  if (!selectedWorkflowId) {
    console.warn("workflowId is required to fetch Data");
    return {};
  }

  try {
    const response = await fetch(
      `${BASE_URL}api/v1/completion-rule/get-rules?workflowId=${selectedWorkflowId}&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch completion rules");
    }

    const data = await response.json();
    return data.body.value; // Assuming 'value' contains the array of rules
  } catch (error) {
    console.error("Error fetching completion rules:", error);
    throw error;
  }
};

export const createCompletionRule = async (
  selectedWorkflowId,
  criteria,
  status,
  iconStatus,
  isActive
) => {
  const token = Cookies.get("accessToken");

  try {
    const response = await fetch(
      `${BASE_URL}api/v1/completion-rule/create-rule`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          workflowId: selectedWorkflowId,
          rule: criteria,
          status,
          icon: iconStatus,
          activeStatus: isActive,
        }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to create completion rule");
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error creating completion rule:", error);
    throw error;
  }
};

export const deleteCompletionRule = async (ruleid) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.delete(
      `${BASE_URL}api/v1/completion-rule/delete-rule?completionRuleId=${ruleid}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAssignmentRule = async (ruleid) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.delete(
      `${BASE_URL}api/v1/assignment-rule/delete?id=${ruleid}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

//update compliton rule

export const updateRule = async (
  ruleid,
  status,
  icon,
  criteria,
  selectedWorkflowId,
  activeStatus
) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.post(
      `${BASE_URL}api/v1/completion-rule/edit-rule`,
      {
        id: ruleid,
        status,
        icon,
        rule: criteria,
        workflowId: selectedWorkflowId,
        activeStatus,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Assuming API returns data upon success
  } catch (error) {
    console.error("Error updating rule:", error);
    throw error; // Handle error as needed
  }
};

//create custome rule
export const createCustomeRule = async (criteria, selectedWorkflowId) => {
  const url = `${BASE_URL}api/v1/custom-variable/create-or-update`;
  const data = {
    workflowId: selectedWorkflowId,
    criteria,
  };
  const token = Cookies.get("accessToken");

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error(`Error submitting form: ${error.message}`);
  }
};

//fwetch custome rule
export const fetchCustomRules = async (selectedWorkflowId) => {
  const token = Cookies.get('accessToken');

  try {
    const response = await fetch(
      `${BASE_URL}api/v1/custom-variable/get?workflowId=${selectedWorkflowId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch custom rules');
    }

    const data = await response.json();
    return data.body.value; // Assuming 'value' contains the array of rules
  } catch (error) {
    console.error('Error fetching custom rules:', error);
    throw error;
  }
};
//dele custome rule
export const deleteCustomRule = async (ruleid) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.delete(
      `${BASE_URL}api/v1/completion-rule/delete-rule?completionRuleId=${ruleid}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};


//update custom varible

export const updateCustomRule = async (
  ruleid,
  criteria,
  selectedWorkflowId,
) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.post(
      `${BASE_URL}api/v1/custom-variable/create-or-update`,
      {
        id: ruleid,
        criteria,
        workflowId: selectedWorkflowId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Assuming API returns data upon success
  } catch (error) {
    console.error("Error updating rule:", error);
    throw error; // Handle error as needed
  }
};