import React, { useContext, useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { MdOutlineCopyAll } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import "react-resizable/css/styles.css";
import { TbVersionsFilled } from "react-icons/tb";
import AppContext from "../../context/AppContext";
import Joyride from "react-joyride";

const ItemTypes = {
  ELEMENT: "element",
};

const DroppableArea = ({
  setDroppedItems,
  droppedItems,
  mainTab,
  setMainTab,
  allInputValues,
  setPreview,
  preview,
  setDragItems,
  dragItems,
  handleRemoveInput,
  setreportInput,
  setreportField,
  setShowRadioOptions,
  setSelectedRadioOption,
  setRadioButtons,
  radioButtons,
  page,
  handleVersionClick,
  setCollapse,
  setShowInput,
  isNewFieldDragged,
  setIsNewFieldDragged,
  setSelectedRadioId,
  setpdfInput,
}) => {
  const [itemSelected, setItemsSelected] = useState();
  const { theme ,runTour, workflowmaker} = useContext(AppContext);
  const pageRef = useRef(page);
  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.ELEMENT,
      drop: (item, monitor) => {
        const newUniqueKey = `${new Date().getTime()}`;
        setDroppedItems((prevFields) => [
          ...prevFields,
          { ...item, uniqueKey: newUniqueKey, page: page },
        ]);
        setDragItems((prevFields) => [
          ...prevFields,
          { ...item, uniqueKey: newUniqueKey, page: page },
        ]);
        setPreview({ ...item, uniqueKey: newUniqueKey, page: page });
        handleRemoveInput();
        setIsNewFieldDragged(false);
        setSelectedRadioId(null);
        setCollapse(false);
      },
    }),
    [page]
  );

  const calculateHeight = (item) => {
    if (
      (item.fieldType === "radio" || item.fieldType === "checkbox") &&
      item.allInputValues
    ) {
      return item.allInputValues.length + 1;
    }
    return 3;
  };

  const containerWidth = window.innerWidth * 0.45;
  const cols = Math.floor(containerWidth / 50);

  // const layout = dragItems.map((item, index) => ({
  //   i: item.uniqueKey,
  //   x: 0,
  //   y: index * calculateHeight(item),
  //   w: cols,
  //   h: calculateHeight(item),
  //   autoSize: true,
  //   isDraggable: true,
  //   isResizable: false,
  //   static: false,
  // }));
  const layout = dragItems.map((item, index) => ({
    i: item.uniqueKey,
    x: 0,
    y: 0, // Use 0 for absolute positioning
    w: cols,
    h: calculateHeight(item),
    autoSize: true,
    isDraggable: true,
    isResizable: false,
    static: false,
  }));

  function radioIdSelected(items, targetUniqueKey) {
    let selectedRadioId = null;
    items.forEach((item) => {
      if (item.uniqueKey === targetUniqueKey) {
        selectedRadioId = item.radioId;
      } else if (item.radioInputValues && item.radioInputValues.length > 0) {
        const updatedRadioInputValues = radioIdSelected(
          item.radioInputValues,
          targetUniqueKey
        );
        if (updatedRadioInputValues) {
          selectedRadioId = updatedRadioInputValues;
        }
      }
    });
    return selectedRadioId;
  }
  const checkIsChildContains = (radioInputValues, uniqueKey) => {
    if (radioInputValues && Array.isArray(radioInputValues)) {
      for (const item of radioInputValues) {
        if (item.uniqueKey === uniqueKey) {
          return true;
        }
        if (item.radioInputValues && Array.isArray(item.radioInputValues)) {
          if (checkIsChildContains(item.radioInputValues, uniqueKey)) {
            return true;
          }
        }
      }
    }
    return false;
  };

  function isOrphan(itemUniqueKey) {
    const item = droppedItems.find(
      ({ uniqueKey }) => uniqueKey === itemUniqueKey
    );
    if (item) {
      if (
        item.radioInputValues === undefined ||
        (Array.isArray(item.radioInputValues) &&
          item.radioInputValues.length === 0)
      ) {
        return true;
      }
    } else {
      return false;
    }
  }

  function findAllParentKeys(items, childKey, path = []) {
    for (const item of items) {
      if (item.uniqueKey === childKey) {
        return path;
      } else if (item.radioInputValues) {
        const result = findAllParentKeys(
          item.radioInputValues,
          childKey,
          path.concat(item.uniqueKey)
        );
        if (result) return result;
      }
    }
    return null;
  }

  function updateRadioButtonsVisibility(preview) {
    const parentKeys = findAllParentKeys(droppedItems, preview);
    const updatedRadioButtons = radioButtons.map((button) => {
      const isParent = parentKeys && parentKeys.includes(button.uniqueKey);
      if (
        isParent ||
        button.uniqueKey === preview ||
        isOrphan(button.uniqueKey)
      ) {
        return { ...button, isShow: true };
      } else {
        return { ...button, isShow: false };
      }
    });
    setRadioButtons(updatedRadioButtons);
    setShowRadioOptions(true);
  }

  const handleItemClick = (previewKey) => {
    const previewfield = dragItems.find(
      (item) => item.uniqueKey === previewKey,
      setIsNewFieldDragged(true),
      setCollapse(false)
    );
    setPreview(previewfield);
    setreportInput(previewfield.reportName);
    if (previewfield.isReport === true) {
      setreportField(true);
      setShowInput(true);
    } else {
      setreportField(false);
      setShowInput(false);
    }
    setItemsSelected(previewKey);
    const selectedRadioId = radioIdSelected(droppedItems, previewKey);
    setSelectedRadioOption(selectedRadioId);

    let droppedValues = [...droppedItems];
    const index = droppedValues.findIndex(
      (input) => input.uniqueKey === previewKey
    );
    if (index !== -1) {
      const updatedRadioButtons = radioButtons.map((button) => {
        const isShow = !checkIsChildContains(
          droppedValues[index].radioInputValues,
          button.uniqueKey
        );
        return { ...button, isShow };
      });
      const updatedRadioButtons2 = droppedItems.map((button) => {
        const isShow = !checkIsChildContains(
          droppedValues[index].radioInputValues,
          button.uniqueKey
        );
        return { ...button, isShow };
      });
      setRadioButtons(updatedRadioButtons);
      setShowRadioOptions(true);
    } else {
      if (!preview.allInputValues) {
        const updatedRadioButtons = radioButtons.map((button) => {
          const isShow = true;
          return { ...button, isShow };
        });
        setRadioButtons(updatedRadioButtons);
      } else {
        updateRadioButtonsVisibility(previewKey);
      }
    }
  };
  const radioValues = dragItems.find(
    (item) => item.fieldType === "radio"
  )?.allInputValues;

  return (
    <>
     <Joyride
        steps={workflowmaker}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
        
      />
      <div
        ref={drop}
        className={`droparea rounded-md border border-gray-300 shadow-lg bg-white  ${
          theme === "dark"
            ? "dark-mode "
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
        style={{ width: "50%", height: "auto" }}
      >
        <div
          className="flex w-full justify-end items-end"
          onClick={handleVersionClick}
        >
          <div className="p-3 relative group">
            <label
              className={`bg-gray-50 border cursor-pointer border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                theme === "dark"
                  ? "dark-mode "
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
            >
              <BsThreeDots />
            </label>
            <div
              className={`absolute top-full  w-auto p-3 font-semibold bg-white text-gray-500 text-sm rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center space-x-2  ${
                theme === "dark"
                  ? "dark-mode "
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
            >
              <TbVersionsFilled className="text-lg" />
              <span>Versions</span>
            </div>
          </div>
        </div>
        <GridLayout
          className="layout w-full"
          layout={layout}
          cols={cols}
          rowHeight={20}
          width={containerWidth}
          isResizable={false}
          autoSize
          compactType="vertical" // Try this instead of null
          preventCollision={true}
          onDragStop={(layout, oldItem, newItem) => {
            newItem.y = oldItem.y;
          }}
        >
          {dragItems.map((item, idx) => {
            if (item.page !== page) {
              return null;
            }
            return (
              <div
                key={item.uniqueKey}
                data-grid={layout.find((l) => l.i === item.uniqueKey)}
                className={`border-2  w-full max-w-xs md:max-w-lg lg:max-w-md xl:max-w-2xl rounded-md   ${
                  itemSelected === item.uniqueKey
                    ? "border-green-400 bg-green-100"
                    : item.excelReportName
                    ? "border-blue-400 bg-blue-100"
                    : "border-red-400 bg-red-100"
                }  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }transition-all duration-300 ease-in-out hover:shadow-2xl hover:scale-110`}
                onClick={() => handleItemClick(item.uniqueKey)}
              >
                <div className="mx-3">
                  {item.fieldType === "text" && (
                    <div className="">
                      <label
                        className="block text-sm font-medium text-gray-700 dark:text-white w-full"
                        htmlFor={item.id}
                      >
                        {item.fieldName}{" "}
                      </label>
                      <input
                        id={item.id}
                        className={` ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast"
                            : ""
                        }bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
                         block w-full p-2.5 
                          `}
                        type="text"
                      />
                    </div>
                  )}
                  {item.fieldType === "date" && (
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                        htmlFor={item.id} // Assuming each item has a unique id
                      >
                        {item.fieldName}{" "}
                      </label>
                      <input
                        id={item.id} // Matching the input's id with the label's htmlFor
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="date" /* ... */
                      />
                    </div>
                  )}
                  {item.fieldType === "time" && (
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                        htmlFor={item.id} // Assuming each item has a unique id
                      >
                        {item.fieldName}{" "}
                      </label>
                      <input
                        id={item.id} // Matching the input's id with the label's htmlFor
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="time" /* ... */
                      />
                    </div>
                  )}
                  {item.fieldType === "textarea" && (
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                        htmlFor={item.id} // Assuming each item has a unique id
                      >
                        {item.fieldName}{" "}
                      </label>
                      <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="textarea" /* ... */
                      />
                    </div>
                  )}
                  {item.fieldType === "scan" && (
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                        htmlFor={item.id}
                      >
                        {item.fieldName}
                      </label>
                      <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="textarea" /* ... */
                      />
                    </div>
                  )}
                  {item.fieldType === "number" && (
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                        htmlFor={item.id} // Assuming each item has a unique id
                      >
                        {item.fieldName}{" "}
                      </label>
                      <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number" /* ... */
                      />
                    </div>
                  )}
                  {item.fieldType === "email" && (
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                        htmlFor={item.id} // Assuming each item has a unique id
                      >
                        {item.fieldName}{" "}
                      </label>
                      <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="email"
                      />
                    </div>
                  )}
                  {item.fieldType === "file" && (
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                        htmlFor={item.id} // Assuming each item has a unique id
                      >
                        {item.fieldName}{" "}
                      </label>
                      <input type="file" disabled />
                    </div>
                  )}
                  {item.fieldType === "checkbox" && (
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                        htmlFor={item.uniqueKey} // Assuming each item has a unique uniqueKey
                      >
                        {item.fieldName}{" "}
                      </label>
                      {item.allInputValues && item.allInputValues.length > 0 ? (
                        item.allInputValues.map((inputItem) => (
                          <div key={inputItem.inputKey}>
                            <input
                              type="checkbox"
                              id={inputItem.inputKey} // Assuming each inputItem has a unique inputKey
                              value={inputItem.inputValue} // Assuming each inputItem has an inputValue
                            />
                            <label
                              htmlFor={inputItem.inputKey}
                              className="ml-2"
                            >
                              {inputItem.inputValue}
                            </label>
                          </div>
                        ))
                      ) : (
                        <p>No checkbox options available</p>
                      )}
                    </div>
                  )}
                  {item.fieldType === "radio" && (
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                        htmlFor={item.id} // Assuming each item has a unique id
                      >
                        {item.fieldName}{" "}
                      </label>
                      {item.allInputValues && item.allInputValues.length > 0 ? (
                        item.allInputValues.map(
                          (input, index) =>
                            input.inputValue !== "" && (
                              <div
                                key={`${item.uniqueKey}-${index}`}
                                className="flex items-center space-x-2 "
                              >
                                <input
                                  type="radio"
                                  id={`${item.uniqueKey}-input-${index}`}
                                  fieldName={`${item.uniqueKey}-options`}
                                  value={input.inputValue}
                                  className=""
                                />
                                <label
                                  htmlFor={`${item.uniqueKey}-input-${index}`}
                                >
                                  {input.inputValue}
                                </label>
                              </div>
                            )
                        )
                      ) : (
                        <p></p>
                      )}
                    </div>
                  )}
                  {item.fieldType === "select" && (
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                        htmlFor={item.uniqueKey}
                      >
                        {item.fieldName}
                      </label>
                      <select
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        id={item.uniqueKey}
                      >
                        {item.allInputValues ? (
                          item.allInputValues.map((inputItem) => (
                            <option
                              key={inputItem.inputKey}
                              value={inputItem.inputValue}
                            >
                              {inputItem.inputValue}
                            </option>
                          ))
                        ) : (
                          <option value="">No options available</option>
                        )}
                      </select>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </GridLayout>
        {dragItems == 0 && (
          <div
            className={`ml-2 mr-8 border-2 border-blue-800 p-8 rounded-md  ${
              theme === "dark"
                ? "dark-mode "
                : theme === "high-contrast"
                ? "high-contrast"
                : ""
            }`}
          >
            <div className="flex items-center justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className={` ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }flex items-center justify-center gap-2 p-6 w-full h-34 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 `}
              >
                <MdOutlineCopyAll className="text-2xl" />
                <h6 className="text-md text-gray-500">
                  Drag your elements to second page from left panel
                </h6>
              </label>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DroppableArea;
