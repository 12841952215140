import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../../context/AppContext";

const PreviewForm = ({ fields ,currentPage, setCurrentPage }) => {
  const [formData, setFormData] = useState({});
  // const [currentPage, setCurrentPage] = useState(1);
  const [selectedRadioIds, setSelectedRadioIds] = useState({});
  const [visibleFields, setVisibleFields] = useState([]);
  const {theme} = useContext(AppContext)
  const handleRadioChange = (parentKey, inputKey, inputValue) => {
    setSelectedRadioIds((prev) => ({ ...prev, [parentKey]: inputKey }));
    setFormData((prev) => ({ ...prev, [parentKey]: inputValue }));
  };

  const handleInputChange = (key, value, fieldType) => {
    if (fieldType === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [key]: Array.isArray(prev[key])
          ? prev[key].includes(value)
            ? prev[key].filter((val) => val !== value)
            : [...prev[key], value]
          : [value],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };
  
  const updateVisibleFields = () => {
    let fieldsForPage = fields.filter((field) => field.page === currentPage);
    fieldsForPage = fieldsForPage.map((field) => {
      if (field.radioId) {
        const parentRadioKey = Object.keys(selectedRadioIds).find(
          (key) => selectedRadioIds[key] === field.radioId
        );
        if (parentRadioKey) {
          return field;
        }
        return null;
      }
      return field;
    }).filter(Boolean);

    setVisibleFields(fieldsForPage);
  };
  useEffect(() => {
    updateVisibleFields();
  }, [currentPage, selectedRadioIds]);

  const renderField = (field, parentKey) => (
    <div key={field.uniqueKey}>
      <label>{field.fieldName}</label>
      {field.fieldType === "radio" && (
        <>
         {Array.isArray(field.allInputValues) && field.allInputValues.map((input) => (
            <div key={input.inputKey} className="my-5">
              <input
                type="radio"
                
                id={input.inputKey}
                name={parentKey}
                value={input.inputValue}
                checked={formData[parentKey] === input.inputValue}
                onChange={() =>
                  handleRadioChange(parentKey, input.inputKey, input.inputValue)
                }
                className={` mr-2 h-5 w-5 text-lg${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              />
              <label htmlFor={input.inputKey}>{input.inputValue}</label>
            </div>
          ))}
        </>
      )}
      {["text", "textarea", "email", "number", "date", "time", "file", "scan"].includes(
        field.fieldType
      ) && (
        <input
          type={field.fieldType}
          className={`${
            theme === "dark"
              ? "dark-mode "
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }border p-2 rounded-md w-full my-5`}
          placeholder={field.fieldName}
          value={formData[parentKey] || ""}
          onChange={(e) =>
            handleInputChange(parentKey, e.target.value, field.fieldType)
          }
        />
      )}

      {field.fieldType === "checkbox" && (
        <>
            {Array.isArray(field.allInputValues) && field.allInputValues.map((input) => (
            <div key={input.inputKey} className="flex items-center mb-2">
              <input
                type="checkbox"
                className={`${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }mr-2 h-5 w-5 text-lg`}
                id={input.inputKey}
                name={parentKey}
                value={input.inputValue}
                checked={formData[parentKey]?.includes(input.inputValue)}
                onChange={(e) =>
                  handleInputChange(parentKey, e.target.value, field.fieldType)
                }
              />
              <label htmlFor={input.inputKey}>{input.inputValue}</label>
            </div>
          ))}
        </>
      )}
      {field.fieldType === "select" && (
        <select
          className={`border p-2 rounded-md w-full ${
            theme === "dark"
              ? "dark-mode "
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }`}
          value={formData[parentKey] || ""}
          onChange={(e) =>
            handleInputChange(parentKey, e.target.value, field.fieldType)
          }
        >
          {Array.isArray(field.allInputValues) && field.allInputValues.map((input) => (
            <option key={input.inputKey} value={input.inputValue}>
              {input.inputValue}
            </option>
          ))}
        </select>
      )}
    </div>
  );

  const renderFields = () => {
    return visibleFields.map((field) => renderField(field, field.uniqueKey));
  };

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const previousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <div className="p-5">
      {renderFields()}
      
    </div>
  );
};
export default PreviewForm;
