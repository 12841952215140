import React, { useState, useContext, useEffect } from "react";
import Cookies from "js-cookie";
import Header from "../_app/Header";
import CommonInputField from "../common/CommonInputField";
import { createProtectedAccount } from "../../service/Security";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
const translations = {
  en: {
    editacc: "Edit Protected Account",
    createacc: "Create Protected Account",
    permanent: "Permanent Protection",
    accProtection: "Account Protection for All Activity",
    cancel: "Cancel",
    update: "Update",
    save: "Save",
    accno:"Account No",
    remark:"Remarks",
    enterremark:"Enter your remarks here",
    fromdate:"From date",
    todate:"To date",
  },
  ar: {
    editacc: "تحرير الحساب المحمي",
    createacc: "إنشاء حساب محمي",
    permanent: "الحماية الدائمة",
    accProtection: "حماية الحساب لجميع الأنشطة",
    cancel: "يلغي",
    update: "تحديث",
    save: "يحفظ",
    accno:"رقم الحساب",
    remark:"ملاحظات",
    enterremark:"أدخل ملاحظاتك هنا",
    fromdate:"من تاريخ",
    todate:"حتى الآن",
  }
}
export const CreateAccount = () => {
  const [accountNo, setAccountNo] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isPermanentProtection, setIsPermanentProtection] = useState(false);
  const [isAccountProtectionForAll, setIsAccountProtectionForAll] =
    useState(false);
  const [workflowIds, setWorkflowIds] = useState([]);
  const [remarks, setRemarks] = useState("");
  const { particularAccount, isEditRequest, selectedOrgId, theme, langMode } =
    useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (isEditRequest && particularAccount) {
      setAccountNo(particularAccount.accountNo || "");
      setIsPermanentProtection(particularAccount.permanent || false);
      setFromDate(particularAccount.fromDate || "");
      setToDate(particularAccount.toDate || "");
      setIsAccountProtectionForAll(
        particularAccount.isAccountProtectionForAll || false
      );
      setWorkflowIds(particularAccount.workflowIds || []);
    }
  }, [isEditRequest, particularAccount]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  const addProtectedaccount = async () => {
    const token = Cookies.get("accessToken");
    const proctedAcc = {
      id: particularAccount.id ? particularAccount.id : null,
      accountNo: accountNo,
      permanent: isPermanentProtection,
      fromDate: isPermanentProtection ? null : formatDate(fromDate),
      toDate: isPermanentProtection ? null : formatDate(toDate),
      editRequest: isEditRequest ? isEditRequest : false,
      organizationId: selectedOrgId,
      remarks: remarks, 
    };

    try {
      const data = await createProtectedAccount(proctedAcc, token);
      if (data.header.code === 600) {
        if (!isEditRequest) {
          toast.success("Protected Account Created");
          setAccountNo("");
          setFromDate("");
          setToDate("");
          setIsPermanentProtection(false);
          setIsAccountProtectionForAll(false);
          setWorkflowIds([]);
          setRemarks(""); // Reset remarks
          navigate("/security");
        } else {
          toast.success("Protected Account Updated !");
          navigate("/security");
        }
      } else {
        toast.error(data.body.value);
      }
    } catch (error) {
      toast.error("Error creating protected account");
    }
  };

  const handleGoBack = () => {
    navigate("/security");
  };
  return (
    <div>
      <Header
        pageName={
          isEditRequest ? translations[langMode].editacc : translations[langMode].createacc
        }
      />
      <div
        onClick={handleGoBack}
        className="p-2 absolute top-30 rounded-full border border-gray-400 hover:bg-gray-200 cursor-pointer w-9 m-3"
      >
        <FaArrowLeft />
      </div>
      <div
        className={`bg-white pt-14 ${theme === "dark"
          ? "dark-mode"
          : theme === "high-contrast"
            ? "high-contrast  "
            : ""
          }`}
      >
        <div className="w-3/5">
          <div className="mt-4 mx-5 grid grid-cols-1 gap-x-8 gap-y-5 ">
            <div className="sm:col-span-2 w-[500px]">
              <CommonInputField
                label={translations[langMode].accno}
                value={
                  accountNo
                    ? accountNo
                    : particularAccount.accountNo
                      ? particularAccount.accountNo
                      : ""
                }
                onChange={(e) => setAccountNo(e.target.value)}
              />
            </div>
            <div className="sm:col-span-2 w-[500px]">
              <label className="block text-sm font-medium text-gray-700">
                {translations[langMode].remark}
              </label>
              <textarea
                className={`form-textarea border-1 pt-1 border-gray-700 rounded-md w-full h-24 mt-1 ${theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                  }`}
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                placeholder={translations[langMode].enterremark}
              />
            </div>
            <div className="sm:col-span-2 flex gap-4">
              <label className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  checked={
                    isPermanentProtection
                      ? isPermanentProtection
                      : particularAccount.permanent
                        ? particularAccount.permanent
                        : false
                  }
                  onChange={(e) => setIsPermanentProtection(e.target.checked)}
                />
                <span className="whitespace-nowrap">{translations[langMode].permanent}</span>
              </label>
              <div>
                {!isPermanentProtection && (
                  <div className="flex">
                    <div className=" px-1 mt-1">
                      <div className="flex items-center">
                        <input
                          type="text"
                          className={`form-input border pt-1 border-gray-700 rounded-md w-36 h-9 ${theme === "dark"
                            ? "dark-mode border rounded-md "
                            : theme === "high-contrast"
                              ? "high-contrast  "
                              : ""
                            }`}
                          value={fromDate}
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => {
                            if (!e.target.value) {
                              e.target.type = "text";
                            }
                          }}
                          onChange={(e) => setFromDate(e.target.value)}
                          placeholder={translations[langMode].fromdate}
                        />
                      </div>
                    </div>
                    <div className="mt-1 px-1">
                      <div className="flex items-center">
                        <input
                          type="text"
                          className={`form-input border pt-1 border-gray-700 rounded-md w-36 h-9 ${theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                              ? "high-contrast  "
                              : ""
                            }`}
                          value={toDate}
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => {
                            if (!e.target.value) {
                              e.target.type = "text";
                            }
                          }}
                          onChange={(e) => setToDate(e.target.value)}
                          placeholder={translations[langMode].todate}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="sm:col-span-2">
              <label className="flex items-center space-x-3 py-2">
                <input
                  type="checkbox"
                  checked={
                    isAccountProtectionForAll
                      ? isAccountProtectionForAll
                      : particularAccount.isAccountProtectionForAll
                        ? particularAccount.isAccountProtectionForAll
                        : false
                  }
                  onChange={(e) =>
                    setIsAccountProtectionForAll(e.target.checked)
                  }
                />
                <span>{translations[langMode].accProtection}</span>
              </label>
            </div>
            <div className="sm:col-span-2"></div>
          </div>
        </div>
        <div className="">
          <div className="sm:col-span-4">
            <hr className="w-full border-gray-300" />
          </div>
          <div className="sm:col-span-4 pt-5 flex justify-end items-center">
            <button
              type="button"
              className="px-3 py-2 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              {translations[langMode].cancel}
            </button>
            <button
              type="submit"
              className="px-3 py-2 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={addProtectedaccount}
            >
              {isEditRequest ? translations[langMode].update : translations[langMode].save}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
