import axios from "axios";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import BarChart from "../charts/BarChart";
import PieChart from "../charts/PieChart";
import ActivityQuestions from "./ActivityQuestions";
import ListChartModal from "./ListChartModal";
import { RiDeleteBin5Line } from "react-icons/ri";
import { toast } from "react-toastify";
import DeletePermission from "../../suspence/DeletePermission";
import AppContext from "../../../context/AppContext";
import AreaChart from "../charts/AreaChart";
import RadarChart from "../charts/RadarChart";

const AddChart = ({
  selectedOrgId,
  dashboardNames,
  setDashboardNames,
  selectedDashboardName,
  dashboardNameInput,
  setDashboardNameInput,
  roleInput,
}) => {
  const { theme } = useContext(AppContext);
  const [isListChartModalOpen, setIsListChartModalOpen] = useState(false);
  const [openActivityQueModal, setOpenActivityQueModal] = useState(false);
  const [tempMapData, setTempMapData] = useState([]);
  const [selectedFieldQuestionId, setSelectedFieldQuestionId] = useState("");
  const [roleForDelete, setRoleForDelete] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemType, setDeleteItemType] = useState("");
  const [deleteItemId, setDeleteItemId] = useState("");
  const [selectedChart, setSelectedChart] = useState();
  const [statusChecks, setStatusChecks] = useState({
    Active: false,
    Assigned: false,
    In_Progress: false,
    Completed: false,
    On_Hold: false,
  });
  const [chartType, setChartType] = useState("pie");
  const showDeleteModal = (type, id) => {
    setDeleteItemType(type);
    setDeleteItemId(id);
    setDeleteModalOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const handleCloseListModal = () => {
    setIsListChartModalOpen(false);
  };
  const handleCloseQuestionsModal = () => {
    setOpenActivityQueModal(false);
  };
  const showListModal = () => setIsListChartModalOpen(true);
  const handleOpenListModal = () => {
    if (selectedFieldQuestionId) {
      setIsListChartModalOpen(true);
      setOpenActivityQueModal(false);
    } else {
      toast.warning("Select Field name");
    }};

  const handleSelectedChart = (chart) => {
    setSelectedChart(chart);
  };
  const handleQuestionChange = (event) => {
    setSelectedFieldQuestionId(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setStatusChecks((prevStatusChecks) => ({
      ...prevStatusChecks,
      [name]: checked,
    }));
  };
  let [tempData, setTempData] = useState({});

  const handleSubmit = () => {
    const selectedTaskParams = Object.entries(statusChecks)
      .filter(([key, value]) => value) // Filter for entries where the checkbox is true
      .map(([key, value]) => key); // Extract just the keys
    let obj = {
      active: 20,
      assigned: 40,
      onHold: 60,
    };
    setTempData(obj);
  };

  useEffect(() => {
    if (dashboardNameInput) {
      fetchData();
    }}, [dashboardNameInput, roleInput]);

  const fetchData = async () => {
    try {
      const token = Cookies.get("accessToken");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/activity-dashboard/get-dashboard`,
        {
          params: {
            activityDashboardId: dashboardNameInput,
            role: roleInput,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      const graphList = response.data.body.value.graphList;
      const names = graphList.map((data) => {
        return data.graphValueList.map((value) => value.question);
      }).flat();
      setTempMapData(graphList);
      setRoleForDelete(response.data.body.value.role);
    } catch (error) {
      console.error("Error fetching graph data:", error);
    } };

  const handleCreateGraph = (event) => {
    event.preventDefault();
    const token = Cookies.get("accessToken");
    const formData = {
      activityDashboardId: +dashboardNameInput,
      graphType: selectedChart,
      formFieldId: +selectedFieldQuestionId,
      role: roleInput,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/activity-dashboard/create-graph-metric`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.header.code === 600) {
            toast.success(responseData.body.value);
            handleCloseListModal();
            fetchData();
          } else {
            toast.error("Something went wrong");
          }
        } else {
          console.error("Unexpected response status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error creating dashboard:", error);
      });
  };

  const handleDeleteDashboard = () => {
    const token = Cookies.get("accessToken");
    axios
      .delete(
        `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/activity-dashboard/delete-dashboard`,
        {
          params: {
            activityDashboardId: dashboardNameInput,
            role: roleInput,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const responseData = response.data;
        if (responseData.header.code === 600) {
          toast.success(responseData.body.value);
          handleCloseDeleteModal();
          setDashboardNameInput("");
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error("Error deleting dashboard:", error);
      });
  };

  const handleDeleteGraphMetric = (graphMetricId, role) => {
    const token = Cookies.get("accessToken");
    axios
      .delete(
        `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/activity-dashboard/delete-graph-metric`,
        {
          params: {
            graphMetricId: graphMetricId,
            role: roleForDelete,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const responseData = response.data;

        if (responseData.header.code === 600) {
          toast.success(responseData.body.value);
          handleCloseDeleteModal();
          fetchData();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error("Error deleting graph metric:", error);
      });
  };
  const handleChartTypeChange = (type) => {
    setChartType(type);
  };
  return (
    <>
      {dashboardNameInput && (
        <div
          className={`bg-gray-50 min-h-screen border border-gray-300 shadow-2xl rounded-lg  ${theme === "dark"
            ? "dark-mode border border-blue-500 "
            : theme === "high-contrast"
              ? "high-contrast  "
              : ""
            }`} >
          <div className="px-5 py-5">
            <div className="flex justify-end">
              <button
                onClick={() => showDeleteModal("dashboard", dashboardNameInput)}
                className="p-2 px-3 shadow-lg border bg-red-600 border-red-600 text-xs flex items-center gap-2  rounded-lg cursor-pointer font-semibold text-white hover:bg-red-500"
              >
                <RiDeleteBin5Line /> Delete Dashboard
              </button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 py-3">
              {tempMapData.map((data, i) => (
                <div key={i} className={`bg-white px-5 py-5 border border-gray-300 rounded-md shadow-2xl ${
                  theme === "dark"
                    ? "dark-mode border rounded-md"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}>
                  <div className="flex justify-end">
                    <RiDeleteBin5Line
                      className="text-red-600 cursor-pointer "
                      onClick={() => showDeleteModal("graph", data.graphMetricId)}
                    />
                  </div>
                  <div>
                    {data.graphType === "barchart" && <BarChart tempData={data}  chartName={data.graphValueList[0]?.question || "No Name"}  />}
                    {data.graphType === "piechart" && <PieChart tempData={data} chartName={data.graphValueList[0]?.question || "No Name"} />}
                    {data.graphType === "area" && <AreaChart  tempData={data} chartName={data.graphValueList[0]?.question || "No Name"}/>}
                    {data.graphType === "radar" && <RadarChart tempData={data} chartName={data.graphValueList[0]?.question || "No Name"} />}
                  </div>
                </div>
              ))}
              <button
                onClick={() => setOpenActivityQueModal(true)}
                className="p-4 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer font-semibold bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                Add Chart
              </button>
            </div>
          </div>
        </div>
      )}
      {isListChartModalOpen && (
        <ListChartModal
          onClose={handleCloseListModal}
          handleSelectedChart={handleSelectedChart}
          handleCreateGraph={handleCreateGraph}
        />
      )}
      {openActivityQueModal && (
        <ActivityQuestions
          onClose={handleCloseQuestionsModal}
          handleOpenListModal={handleOpenListModal}
          dashboardNameInput={dashboardNameInput}
          selectedFieldQuestionId={selectedFieldQuestionId}
          setSelectedFieldQuestionId={setSelectedFieldQuestionId}
          handleQuestionChange={handleQuestionChange}
        />
      )}
      {deleteModalOpen && deleteItemType === "dashboard" && (
        <DeletePermission
          deleteFunction={handleDeleteDashboard}
          onClose={handleCloseDeleteModal}
          onDeleteText="Are you sure you want to delete this dashboard?"
        />
      )}
      {deleteModalOpen && deleteItemType === "graph" && (
        <DeletePermission
          deleteFunction={() =>
            handleDeleteGraphMetric(deleteItemId, roleInput) }
          onClose={handleCloseDeleteModal}
          onDeleteText="Are you sure you want to delete this graph metric?"
        />
      )}
    </>
  );
};
export default AddChart;
