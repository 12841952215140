import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/_app/Header";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import { fetchExportData } from "../../service/Task";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import MultipleActivitySelect from "../../components/common/MultipleActivitySelect";
import Cookies from "js-cookie";
const translations = {
  en: {
    export: "Export",
  },
  ar: {
    export: "يصدّر",
  },
};
const ExportData = () => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const [startDate, setStartDate] = useState(formatDate(yesterday));
  const [endDate, setEndDate] = useState(formatDate(new Date()));
  const [selectedOption, setSelectedOption] = useState([]);
  const context = useContext(AppContext);
  const { theme, langMode } = context;

  const fetchData = () => {
    console.log(selectedOption, "selectedoption");

    if (selectedOption) {
      fetchExportData(selectedOption, startDate, endDate)
        .then((response) => {
          if (response.data.header.code === 607) {
            toast.error("No Records are Available");
          } else {
            const s3Link = response.data.body.value.reportUrl;
            const link = document.createElement("a");
            link.href = s3Link;
            link.setAttribute("download", "report.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    } else {
      toast.error("Please select the activity");
    }
  };
  const navigate = useNavigate();
  const location = useLocation();
  const handleGoBack = () => {
    navigate("/worklog");
  };

  useEffect(() => {
    Cookies.set("exportData", "true");
    return () => {
      Cookies.remove("exportData", "true");
    };
  }, [location]);
  return (
    <>
      <Header pageName={translations[langMode].export} />
      <div className="bg-white">
        <div className="flex ml-2 flex-nowrap ">
          <div
            onClick={handleGoBack}
            className="p-2 rounded-full border border-gray-400 hover:bg-gray-200 cursor-pointer w-9 h-9 m-3"
          >
            <FaArrowLeft />
          </div>
          <div className="px-5 py-2">
            <MultipleActivitySelect
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </div>
        </div>

        <div
          className={`px-24 py-5 bg-white flex gap-64  ${
            theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }`}
        >
          <div className="gap-5 flex">
            <div className="flex gap-4">
              <input
                type="text"
                className="form-input border pt-1 border-gray-400 rounded-md w-36 h-9"
                value={startDate ? formatDate(startDate) : ""}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => {
                  if (!e.target.value) {
                    e.target.type = "text";
                  }
                }}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="From date"
              />
              <input
                type="text"
                className="form-input border pt-1 border-gray-400 rounded-md w-36 h-9"
                value={endDate ? formatDate(endDate) : ""}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => {
                  if (!e.target.value) {
                    e.target.type = "text";
                  }
                }}
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="To date"
              />
            </div>
            <div className=" w-full">
              <button
                onClick={fetchData}
                type="button"
                className="px-3 py-3 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {translations[langMode].export}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ExportData;
