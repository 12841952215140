import React from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NotificationImg from "../../assets/notification.png";
import SecondImg from "../../assets/Icon (1).png";
import LogutImg from "../../assets/Icon.png";
import AppContext from "../../context/AppContext";
import lightmodeImg from "../../assets/Bluefield.png";
import darkmodeImg from "../../assets/Bluefield (2).png";
import dummyImg from "../../assets/dummy.webp";
import OrganizationFilterSelect from "../common/task/OrganizationFilterSelect";
import ActivitySelect from "../common/task/ActivitySelect";
import { getUnreadMsgCount } from "../../service/Notification";
import Logout from "../common/Logout";
import { IoIosNotifications, IoMdLogOut } from "react-icons/io";
import { IoLogOut, IoSettings } from "react-icons/io5";
import Joyride from "react-joyride";

export const MainNav = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const user = Cookies.get("userName");
  const [userType, setUserType] = useState(null);
  const [totalUnreadMsgCount, setTotalUnreadMsgCount] = useState(0);
  const [exportData, setExportData] = useState(null);
  const context = useContext(AppContext);
  const {
    theme,
    profilePic,
    setProfilePic,
    workflowName,
    setWorkflowName,
    selectedOrgId,
    setSelectedOrgId,
    setSelectedOrgName,
    selectedOrgName,
    runTour,
    menu
  } = context;
  const handleLogout = () => {
    setShowModal(true);
  };

  useEffect(() => {
    const profilePic = Cookies.get("profilePic");
    if (profilePic && profilePic !== "null") {
      setProfilePic(profilePic);
    } else {
      setProfilePic(dummyImg);
    }
  }, []);

  useEffect(() => {
    const userTypeCookie = Cookies.get("userType");
    setUserType(userTypeCookie);
  }, []);

  useEffect(() => {
    const fetchUnreadMsgCount = async () => {
      try {
        const count = await getUnreadMsgCount();
        setTotalUnreadMsgCount(count.body.value.unreadMsgCount);
      } catch (error) {
        console.error("Failed to fetch unread message count:", error);
      }
    };
    fetchUnreadMsgCount();
  }, []);

  const handleViewNotificationsAndNavigate = () => {
    navigate("/notification");
  };
  const handleSetting = () => {
    navigate("/settings");
  };

  function formatUserType(userType) {
    if (!userType) {
      return "Unknown";
    }
    switch (userType) {
      case "super_admin":
        return "Super Admin";
      case "admin":
        return "Admin";
      case "office_user":
        return "Office User";
      default:
        return (
          userType.charAt(0).toUpperCase() +
          userType.slice(1).replace(/_/g, " ")
        );
    }
  }

  useEffect(() => {
    const data = Cookies.get("exportData");
    setExportData(data);
  }, []);

  const location = useLocation();
  const showActivitySelect = location.pathname !== "/export";

  return (
    <>
       <Joyride
        steps={menu}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
      />
      <Disclosure
        as="nav"
        className={`bg-white shadow-md    ${
          theme === "dark"
            ? "dark-mode border-b"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        {({ open }) => (
          <>
            <nav>
              <div className="mx-auto max-w-10xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-18 p-2 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <Link to="/dashboard">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-600">
                          <img
                            src={theme === "dark" ? darkmodeImg : lightmodeImg}
                            className={`h-10 `}
                            alt="Logo"
                          />
                        </h1>
                      </Link>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="flex justify-between items-cente gap-6">
                      <div
                        className={`ml-4 flex items-center md:ml-6 gap-2  ${
                          theme === "dark"
                            ? "dark-mode"
                            : theme === "high-contrast"
                            ? "high-contrast"
                            : ""
                        }`}
                      >
                        {userType === "super_admin" && (
                          <OrganizationFilterSelect
                            selectedOrgName={selectedOrgName}
                            setSelectedOrgId={setSelectedOrgId}
                            setSelectedOrgName={setSelectedOrgName}
                            setWorkflowName={setWorkflowName}
                          />
                        )}
                        {showActivitySelect && (
                          <ActivitySelect
                            setWorkflowName={setWorkflowName}
                            workflowName={workflowName}
                            selectedOrgId={selectedOrgId}
                          />
                        )}
                      </div>
                      <div className="ml-4 flex items-center md:ml-6 gap-2">
                        <div className="flex items-center gap-4">
                          <div className="relative">
                            <img
                              className={`${
                                theme === "dark" || theme === "high-contrast"
                                  ? "bg-white border border-blue-600"
                                  : ""
                              } w-10 h-10 p-1 rounded-full cursor-pointer ring-2 ring-gray-300`}
                              src={profilePic}
                              alt="Avatar"
                              onClick={() => navigate("/user-profile")}
                            />
                            <span className="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-sm font-bold">{user}</span>
                            <span className="text-sm text-gray-400 font-semibold">
                              {formatUserType(userType)}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="ml-4 flex items-center md:ml-6 gap-4">
                        {/* <button
                          type="button"
                          className="relative rounded-full p-1 text-gray-200 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={handleViewNotificationsAndNavigate}
                        >
                          <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full px-2 py-1">
                            {totalUnreadMsgCount}
                          </span>
                          <img
                            src={NotificationImg}
                            className="h-8 w-8 filter brightness-75"
                            alt="notification"
                          />
                        </button> */}
                        <div
                          className="relative group "
                          onClick={handleViewNotificationsAndNavigate}
                        >
                          <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full px-2 py-1">
                            {totalUnreadMsgCount}
                          </span>
                          <IoIosNotifications className=" notification h-8 w-8 filter brightness-75 hover:text-blue-500" />

                          <div
                            role="tooltip"
                            className="absolute left-full ml-2 z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 group-hover:visible group-hover:opacity-100"
                          >
                            Notification
                            <div
                              className="tooltip-arrow"
                              data-popper-arrow
                            ></div>
                          </div>
                        </div>

                        {/* <button
                          onClick={handleSetting}
                          type="button"
                          className="relative rounded-full p-1 text-gray-200 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        >
                          <span className="absolute -inset-1.5" />
                          <img
                            src={SecondImg}
                            className="h-8 w-8 filter brightness-50"
                            alt="setting icon"
                          />
                        </button> */}
                          <div
                          className="relative group "
                          onClick={handleSetting}
                        >
                         
                          <IoSettings className=" seting h-7 w-7 filter brightness-75 hover:text-blue-500" />

                          <div
                            role="tooltip"
                            className="absolute left-full ml-2 z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 group-hover:visible group-hover:opacity-100"
                          >
                            Settings
                            <div
                              className="tooltip-arrow"
                              data-popper-arrow
                            ></div>
                          </div>
                        </div>
                        {/* <button
                          type="button"
                          className="relative p-1 text-gray-200 hover:text-gray-700"
                          onClick={handleLogout}
                        >
                          <img
                            src={LogutImg}
                            className="h-8 w-8 filter brightness-50"
                            alt="logout"
                          />
                        </button> */}
                         <div
                          className="relative group"
                          onClick={handleLogout}
                        >
                         
                          <IoMdLogOut className="h-7 w-7 filter brightness-75 hover:text-blue-500" />

                          <div
                            role="tooltip"
                            className="absolute  ml-2 z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 group-hover:visible group-hover:opacity-100"
                          >
                            Logout
                            <div
                              className="tooltip-arrow"
                              data-popper-arrow
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3"></div>
                <div className="border-t border-gray-700 pb-3 pt-4">
                  <div className="flex items-center px-5">
                    <button
                      type="button"
                      className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1 px-2"></div>
                </div>
              </Disclosure.Panel>
            </nav>
          </>
        )}
      </Disclosure>
      <Logout setShowModal={setShowModal} showModal={showModal} />
    </>
  );
};
