import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useState } from "react";
import { FaUsers } from "react-icons/fa6";
import { IoSettings } from "react-icons/io5";
import {
  RiDashboardFill,
  RiMenuFill,
  RiGitBranchLine,
  RiTimeLine,
  RiTrophyLine,
  RiFileTextLine,
  RiGroupFill,
  RiSettingsLine,
} from "react-icons/ri";
import { MdOutlineSecurity } from "react-icons/md";
import { TbAsset, TbListCheck } from "react-icons/tb";
import { BsEye } from "react-icons/bs";
import { Link } from "react-router-dom";
import AppContext from "../../context/AppContext";
import Cookies from "js-cookie";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DashboardMenus = () => {
  const { theme, resetDropdown ,toggle ,runTour} = useContext(AppContext);
  const [showNames, setShowNames] = useState(false); 

  const toggleShowNames = () => {
    setShowNames((prev) => !prev); 
  };

  const menus = [
    { icon: <RiDashboardFill />, link: "/dashboard", tooltip: "Dashboard" },
    { icon: <FaUsers />, link: "/all-users", tooltip: "Users" },
    { icon: <IoSettings />, link: "/settings", tooltip: "Settings" },
    { icon: <TbAsset />, link: "/asset", tooltip: "Asset" },
    {
      icon: <RiSettingsLine />,
      link: "/configuration",
      tooltip: "Configuration",
    },
    {
      icon: <TbListCheck />,
      link: "/maptask",
      tooltip: "Task Manage",
      onClick: resetDropdown,
    },
    { icon: <RiGitBranchLine />, link: "/workflow", tooltip: "Workflow" },
    { icon: <RiTimeLine />, link: "/worklog", tooltip: "Worklog" },
    { icon: <RiTrophyLine />, link: "/leaderboard", tooltip: "Leaderboard" },
    { icon: <MdOutlineSecurity />, link: "/security", tooltip: "Security" },
  ];

  const userType = Cookies.get("userType");
  if (userType === "super_admin") {
    menus.push(
      { icon: <RiFileTextLine />, link: "/reports", tooltip: "Reports" },
      { icon: <RiGroupFill />, link: "/organization", tooltip: "Organization" }
    );
  }

  // Add the last icon to toggle tooltips/names
  // menus.push({
  //   icon: <BsEye />, // Icon for toggling tooltips and names
  //   onClick: toggleShowNames,
  // });

  return (
    <>
      <Menu as="div" className="relative ml-5 ">
        <div>
          <Menu.Button
            className={`relative flex rounded-full p-2 bg-white text-lg border border-gray-400 ${
              theme === "dark" ? "dark-mode " : "light-mode"
            }`}
          >
            <span />
            <RiMenuFill  className=" text-gray-700 dark:text-white"/>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`absolute right-0 z-20 mt-2 ${
              showNames ? " w-24 left-0 right-24" : "w-10"
            } origin-top-center rounded-full dark:border-2 dark:border-blue-500 bg-gray-50 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
              theme === "dark" ? "dark-mode " : "light-mode"
            }`}
            style={{zIndex:"9999"}}
          >
            {menus.map((menu, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <div className="relative group">
                    <Link
                      to={menu.link}
                      onClick={menu.onClick}
                      className={classNames(
                        active ? " text-blue-700" :" ",
                        "flex py-2 px-3 text-md text-gray-700 justify-center hover:scale-125 hover:text-blue-800 dark:text-white dark:hover:text-blue-800"
                      ) }
                    >
                      {menu.icon}
                      {showNames && (
                        <div className="absolute font-semibold   text-center w-full text-xs text-gray-600 my-3 hover:scale-0">
                          {menu.tooltip}
                        </div>
                      )}
                    </Link>
                    {!showNames && (
                      <div
                        id={`tooltip-${index}`}
                        role="tooltip"
                        className="absolute whitespace-nowrap justify-center left-full ml-2 z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 group-hover:visible group-hover:opacity-100"
                      >
                        {menu.tooltip}
                        <div className="tooltip-arrow" data-popper-arrow></div>
                      </div>
                    )}
                  </div>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default DashboardMenus;
