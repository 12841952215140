import Cookies from "js-cookie";
import React, { useContext, useEffect, useState, useRef } from "react";
import AppContext from "../../../context/AppContext";
import { getWorkflows } from "../../../service/workflow";
import classNames from "classnames";
import Joyride from "react-joyride";

const translations = {
  en: {
    select: " Select Activity",
  },
  ar: {
    select: " حدد النشاط",
  },
};
const ActivitySelect = ({ setWorkflowName, selectedOrgId, workflowName }) => {
  const [data, setData] = useState([]);
  const { setWorkflowId } = useContext(AppContext);
  const { selectedOption, setSelectedOption } = useContext(AppContext);
  const { theme, langMode, runTour, menu } = useContext(AppContext);
  const [toastShown, setToastShown] = useState(false);
  const [selectedActivityName, setSelectedActivityName] = useState("");
  const selectRef = useRef(null);

  useEffect(() => {
    const userType = Cookies.get("userType");
    const fetchData = async () => {
      try {
        const response = await getWorkflows(selectedOrgId, userType);
        const workflows = response.body.value;
        if (Array.isArray(workflows)) {
          setData(workflows);
          const workflowMap = workflows.reduce((map, workflow) => {
            map[workflow.workflowId] = workflow.workflowName;
            return map;
          }, {});
          setWorkflowName(workflowMap);
        } else {
          console.error("Expected an array but got:", workflows);
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching workflows:", error);
      }
    };
    setSelectedOption("");
    setWorkflowId("");
    setData([]);
    fetchData();
  }, [selectedOrgId, toastShown]);

  const handleOptionChange = (event) => {
    const workflowId = event.target.value;
    setSelectedOption(workflowId);
    setWorkflowId(workflowId);
    const selectedWorkflow = data.find(
      (workflow) => workflow.workflowId === workflowId
    );
    setSelectedActivityName(
      selectedWorkflow ? selectedWorkflow.workflowName : ""
    );
    if (selectRef.current) {
      const selectedText =
        event.target.options[event.target.selectedIndex].text;
      const tempSpan = document.createElement("span");
      tempSpan.style.visibility = "hidden";
      tempSpan.style.position = "absolute";
      tempSpan.style.whiteSpace = "nowrap";
      tempSpan.textContent = selectedText;
      document.body.appendChild(tempSpan);
      selectRef.current.style.width = `${tempSpan.offsetWidth + 40}px`; // Adjust the width with some padding
      document.body.removeChild(tempSpan);
    }
  };

  return (
    <>
      <Joyride
        steps={menu}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
      />
      <select
        id="activities"
        ref={selectRef}
        className={classNames(
          "border h-10 text-gray-900 text-sm rounded-lg p-2.5 activity",
          "focus:ring-blue-500 focus:border-blue-500",
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : "border-gray-500"
        )}
        style={{ width: selectedOption ? "auto" : "150px" }} // Optionally move to CSS if needed
        onChange={handleOptionChange}
        value={selectedOption || ""}
        aria-label="Activity Selection"
      >
        <option value="" disabled={!selectedOption}>
          {translations[langMode].select}
        </option>
        {data.map((workflow) => (
          <option key={workflow.workflowId} value={workflow.workflowId}>
            {workflow.workflowName}
          </option>
        ))}
      </select>
    </>
  );
};

export default ActivitySelect;
