import React, { useContext, useEffect } from "react";
import { useDrag } from "react-dnd";
import AppContext from "../../context/AppContext";

const ItemTypes = {
  ELEMENT: "element",
};

export const DraggableElement = ({
  value,
  fieldName,  
  onInputChange,
  icon,
  fieldType,     
  isMandatory,
  isReport,
  cloneName,
  validationValues,
  isNewFieldDragged,
  setIsNewFieldDragged,
  setSelectedRadioId
}) => {
  const {theme} = useContext(AppContext)
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.ELEMENT,
    item: {fieldName, value,fieldType,isMandatory,isReport,cloneName,validationValues},
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (item, monitor) => {
      setIsNewFieldDragged(false);
      setSelectedRadioId(null)
    },   
  }));
  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <div className={`px-10  text-start flex items-center gap-4 transition duration-300 ease-in-out transform hover:scale-110 bg-white shadow-md border-2 border-gray-300 rounded-md font-semibold p-2 mb-2  ${
                      theme === "dark"
                        ? "dark-mode "
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }`}>
        <span className="text-blue-900">{icon}</span>{" "}
        <h6 className="text-sm">
          {fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
        </h6>
      </div>
    </div>
  );
};
