import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/_app/Header";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";
import { fetchVehicleData, updateVehicleData } from "../../service/Asset";
import { FaArrowLeft } from "react-icons/fa";
const translations ={
  en:{
    updateVehical:"Update Vehical",
    Update: "Update",
    cancle: "Cancel",
  },
  ar:{
    updateVehical:"تحديث المركبة",
    Update: "تحديث",
    cancle: "يلغي",
  }
}
const UpdateVehical = () => {
  const navigate = useNavigate();
  const { vehicleId } = useParams();
  const [vehicleData, setVehicleData] = useState(null);
  const [id, setId] = useState("");
  const [VehicleId, setVehicleId] = useState("");
  const [vehicleType, setVehicleType] = useState("SUV");
  const [kilometerUsed, setKilometerUsed] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [inUse, setInUse] = useState(false); 
  const { theme , langMode } = useContext(AppContext);
  const handleSelectChange = (event) => {
    setVehicleType(event.target.value);
  };
  const handleKilometerChange = (event) => {
    setKilometerUsed(event.target.value);
  };

  useEffect(() => {
    const fetchVehicle = async () => {
      try {
        const data = await fetchVehicleData(vehicleId);
        if (data.header.code === 600) {
          setVehicleData(data.body.value);
          setId(data.body.value.id);
          setVehicleId(data.body.value.vehicleId);
          setVehicleType(data.body.value.vehicleType);
          setKilometerUsed(data.body.value.kilometerUsed);
          setOrganizationId(data.body.value.organizationId);
          setInUse(data.body.value.inUse);
        } else {
        }
      } catch (error) {
      }
    };
    fetchVehicle();
  }, [vehicleId]);

  const handleUpdate = async () => {
    const formData = {
      id: id,
      vehicleId: vehicleId,
      vehicleType: vehicleType,
      kilometerUsed: parseInt(kilometerUsed),
      organizationId: organizationId,
      inUse: inUse,
    };
    try {
      const data = await updateVehicleData(formData);
      if (data.header.code === 600) {
        toast.success("Vehicle Updated Successfully");
        navigate("/asset");
      }
    } catch (error) {
    }
  };
  const handleCancel = () => {
    setVehicleType(vehicleData.vehicleType);
    setKilometerUsed(vehicleData.kilometerUsed);
  };

  const handleGoBack =()=>{
    navigate("/asset")
  }
  return (
    <>
      <Header pageName={translations[langMode].updateVehical} />
      <div
        onClick={handleGoBack}
        className={`p-2 absolute top-30 rounded-full border cursor-pointer w-9 m-3 ml-5
    ${theme === "dark" ? "bg-gray-800 border-gray-600 hover:bg-gray-700" : ""} 
    ${
      theme === "high-contrast"
        ? "bg-yellow-200 border-black hover:bg-yellow-300"
        : ""
    } 
    ${
      theme !== "dark" && theme !== "high-contrast"
        ? "border-gray-400 hover:bg-gray-200"
        : ""
    }`} >
        <FaArrowLeft
          className={`${
            theme === "dark"
              ? "text-white"
              : theme === "high-contrast"
              ? "text-black"
              : "text-gray-700"
          }`} />
      </div>
      <div
        className={`px-5 pt-14 bg-white ${
          theme === "dark"
            ? "dark-mode  "
            : theme === "high-contrast"
            ? "high-contrast  "
            : ""
        }`}>
        <div className="w-1/2">
          {/* <h3 className="text-sm text-gray-500 font-bold">Update Vehical</h3> */}
          <div className=" grid grid-cols-1 gap-x-4 gap-y-5 sm:grid-cols-4">
            <div className="sm:col-span-2">
              <div className="relative">
                <input
                  type="text"
                  value={id}
                  disabled
                  className={` ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  } block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                />
                <label
                  className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                >
                  Id
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="relative">
                <select
                  id="vehical_dropdown"
                  value={vehicleType}
                  onChange={handleSelectChange}
                  className={`${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  } block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900
                   bg-transparent rounded-md border-2 border-gray-400 appearance-none 
                   focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                >
                  <option value="SUV">SUV</option>
                  <option value="Hatchback">Hatchback</option>
                </select>
                <label
                  htmlFor="vehical_dropdown"
                  className={`absolute text-md text-gray-500 duration-300 
                  transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2
                   peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                   peer-focus:scale-75 peer-focus:-translate-y-4 ${
                     theme === "dark"
                       ? "dark-mode  "
                       : theme === "high-contrast"
                       ? "high-contrast  "
                       : ""
                   }`}
                >
                  Vehicle Type
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="relative">
                <input
                  type="text"
                  value={VehicleId}
                  disabled
                  className={`${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  } block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                />
                <label
                  className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                >
                  Vehical Id
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="relative">
                <input
                  type="text"
                  id="organizationId"
                  disabled
                  value={organizationId}
                  className={`${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  } block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                />
                <label
                  className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                >
                  Organization Id
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="relative">
                <input
                  type="text"
                  id="kilometerUsed"
                  value={kilometerUsed}
                  onChange={handleKilometerChange}
                  className={`${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  } block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                />
                <label
                  className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                >
                  Kilometers
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="relative">
                <input
                  type="text"
                  id="inUse"
                  disabled
                  value={inUse ? "Yes" : "No"}
                  className={`${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  } block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                />
                <label
                  className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                >
                  In Use
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="sm:col-span-4">
            <hr className="w-full border-gray-300" />
          </div>
          <div className="sm:col-span-4 pt-5 flex justify-end items-center">
            <button
              type="button"
              onClick={handleCancel}
              className="px-3 py-2 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              {translations[langMode].cancle}
            </button>
            <button
              type="button"
              onClick={handleUpdate}
              className="px-3 py-2 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              {translations[langMode].Update}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateVehical;
