import React, { useContext } from "react";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
const translations = {
  en: {
    previous: "Prev",
    next:"Next"
  },
  ar: {
     previous: "سابق",
    next:"التالي"
  },
};
const DynamicPage = ({ page, setPage, dragItems }) => {
  const { theme , langMode } = useContext(AppContext);
  const handleIncrement = () => {
    let containsItem = dragItems.findIndex((item) => item.page === page);
    if (containsItem >= 0) {
      setPage((prevPage) => prevPage + 1);
    } else {
      toast.warning(
        "Add at least one element to the current page before moving to the next page"
      );
    }
  };
  const handleDecrement = () => {
    setPage((prevPage) => {
      return prevPage > 1 ? prevPage - 1 : prevPage;
    });
  };
  return (
    <>
      <div
        className={`block max-w-sm p-4 mt-5 bg-slat-50 border border-gray-300 rounded-md shadow-lg  ${
          theme === "dark"
            ? "dark-mode "
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        <div className="flex items-center  space-x-2.5 mt-2.5">
          <div className="flex items-start font-semibold">Pages :</div>
          <div className="flex justify-between content-center item-center p-2">
            <button
              onClick={handleDecrement}
              className="mt-1 w-14 bg-blue-800 hover:bg-blue-700 text-white font-semibold py-2  rounded-l-xl focus:outline-none"
            >
            {translations[langMode].previous}
            </button>
            <input
              type="text"
              value={page}
              readOnly
              className="ml-3 w-12 flex text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
            />
            <button
              onClick={handleIncrement}
              className="mt-1  w-14 ml-3 bg-blue-800 hover:bg-blue-700 text-white font-semibold py-2  rounded-r-xl focus:outline-none"
            >
             {translations[langMode].next}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicPage;
