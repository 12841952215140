import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/_app/Header";
import AppContext from "../../context/AppContext";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import EditImage from "../../assets/Vector (3).png";
import { toast } from "react-toastify";
import { GetTaskHistory } from "../../service/Task";
import { fetchTaskDetail, updateTaskDetails } from "../../service/Task";
import { FaArrowLeft, FaUserEdit } from "react-icons/fa";
const TaskDetailPage = () => {
  const { taskId } = useParams();
  const [loading, setLoading] = useState(true);
  const [fieldData, setFieldData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [taskHistory, setTaskHistory] = useState([]);
  const [page, setPage] = useState(0);
  const [task, setTask] = useState(null);
  const [size, setSize] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const [expandedTask, setExpandedTask] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const userType = Cookies.get("userType");
  const [alltaskData, setallTaskData] = useState([]);
  const context = useContext(AppContext);
  const { accountNo, theme } = context;
  useEffect(() => {
    fetchTaskDetails();
  }, [taskId]);

  const fetchTaskDetails = async () => {
    try {
      const data = await fetchTaskDetail(taskId);
      const task = data.body.value.task;
      const additionalFields = {
        TaskID: task.taskId,
        "Account No.": task.accountNo,
        Activity: task.activity, 
      };
      const otherFields = {
        Priority: task.priority,
        Status: task.taskStatus,
        "Created Date": task.createdDate,
        Latitude: task.latitude,
        Longitude: task.longitude,
        "Area Code": task.areaCode,
        Tariff: task.tariff,
        Office: task.office,
        Area: task.area,
      };
      const taskDetails = JSON.parse(task.taskDetails);
      const fieldValues = [
        ...Object.entries(additionalFields).map(([fieldName, value]) => ({
          fieldName,
          value,
        })),
        ...Object.entries(otherFields).map(([fieldName, value]) => ({
          fieldName,
          value,
        })),
        ...Object.entries(taskDetails).map(([fieldName, value]) => ({
          fieldName,
          value,
        })),
      ];
      setTask(task);
      setFieldData(fieldValues);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching task details:", error);
      setLoading(false);
    }
  };

  const handleDiscard = () => {
    fetchTaskDetails();
    setEditMode(false);
  };

  const handleConfirm = async () => {
    try {
      const updatedTaskDetails = fieldData.reduce((acc, field) => {
        acc[field.fieldName] = field.value;
        return acc;
      }, {});
      updatedTaskDetails["Priority"] = task.priority || "Low_Priority";
      const updatedTask = {
        taskId: task.taskId,
        workflowId: task.workflowId,
        assigneeId: task.assigneeId,
        priority: task.priority || "Low_Priority",
        taskDetails: JSON.stringify(updatedTaskDetails),
        taskStatus: task.taskStatus,
        assignedBy: task.assignedBy,
        createdDate: task.createdDate,
        latitude: parseFloat(updatedTaskDetails["Latitude"]) || null,
        longitude: parseFloat(updatedTaskDetails["Longitude"]) || null,
        areaCode: updatedTaskDetails["Area Code"] || null,
        tariff: task.tariff,
        accountNo: updatedTaskDetails["Account No."] || null,
        createdBy: task.createdBy,
        finishedDate: task.finishedDate,
        office: updatedTaskDetails["Office"] || null,
        area: updatedTaskDetails["Area"] || null,
        source: task.source,
        icon: task.icon,
        completed: task.completed,
        assigned: task.assigned,
        deleted: task.deleted,
      };
      const data = await updateTaskDetails(updatedTask);
      if (data.header.code === 600) {
        toast.success("Task details updated successfully!");
        setEditMode(false);
      } else {
        toast.error(data.body.value || "Failed to update task details");
      }
    } catch (error) {
      console.error("Error updating task details:", error);
      alert(error.message);
    }
  };

  const handleEditClick = () => {
    setEditMode(!editMode);
  };

  const navigate = useNavigate();
  const fetchTaskHistory = async (accountNo, page, size) => {
    setIsLoading(true);
    try {
      const responseData = await GetTaskHistory(accountNo, page, size);
      if (!Array.isArray(responseData)) {
        throw new Error("Response data is not an array");
      }
      if (responseData.length === 0) {
        setHasMore(false); // No more data to load
      } else {
        setTaskHistory(responseData);
      }
      toast.error(responseData);
    } catch (error) {
      console.error("Error fetching task history:", error);
      setError(error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (accountNo) {
      fetchTaskHistory(accountNo, page, size);
    }
  }, [accountNo, page, size]);

  const handleToggle = (index) => {
    setExpandedTask(expandedTask === index ? null : index);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString(undefined, options);
  };
  const isValidURL = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };
  const loadMore = () => {
    if (!isLoading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop <
        document.documentElement.offsetHeight - 200
      )
        return;
      loadMore();
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading, hasMore]);

  useEffect(() => {
  }, [taskHistory]);
  const handleGoBack = () => {
    navigate("/task-manage");
  };
  return (
    <div>
      <Header pageName={"Task Detail"} />
      <div
        onClick={handleGoBack}
        className={`p-2 rounded-full border cursor-pointer w-9 m-3 ml-5
        ${theme === "dark" ? "bg-gray-800 border-gray-600 hover:bg-gray-700" : ""} 
        ${theme === "high-contrast" ? "bg-yellow-200 border-black hover:bg-yellow-300": ""} 
        ${ theme !== "dark" && theme !== "high-contrast" ? "border-gray-400 hover:bg-gray-200": "" } `} >
        <FaArrowLeft
          className={`${
            theme === "dark"
              ? "text-white"
              : theme === "high-contrast"
              ? "text-black"
              : "text-gray-700"
          }`}
        />
      </div>
      <div className="bg-white px-5 py-5 rounded-md ">
        <div className="flex gap-4">
          <div className="w-[700px] bg-gray-100 rounded-md shadow-lg border border-blue-300">
            <div className="px-4 py-2 flex justify-between ">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Task Details
              </h3>
              {userType !== "field_agent" && (
                <span className=" rounded-full">
                  <FaUserEdit
                    onClick={handleEditClick} 
                    className={`h-6 w-6 cursor-pointer ${
                      editMode ? "text-green-500" : "text-black"
                    }`}
                  />
                </span>
              )}
            </div>
            <div className="p-6 border-t border-gray-200 bg-white rounded-lg shadow-sm">
              <dl className="divide-y divide-gray-200">
                {loading ? (
                  <div className="text-center text-gray-500">Loading...</div>
                ) : (
                  fieldData.map((field, index) => (
                    <div
                      key={index}
                      className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-4"
                    >
                      <dt className="text-sm font-semibold text-gray-700">
                        {field.fieldName}
                      </dt>
                      <dd
                        className={`mt-1 text-sm sm:col-span-2 sm:mt-0 ${
                          editMode ? "break-all" : "whitespace-pre-wrap"
                        }`}
                      >
                        {editMode ? (
                          <input
                            type="text"
                            className="w-full border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 rounded-md p-2"
                            value={field.value}
                            onChange={(e) => {
                              const updatedFieldData = [...fieldData];
                              updatedFieldData[index].value = e.target.value;
                              setFieldData(updatedFieldData);

                              const updatedTaskDetails = {
                                ...JSON.parse(task.taskDetails),
                              };
                              updatedTaskDetails[field.fieldName] =
                                e.target.value;
                              setallTaskData({
                                ...task,
                                taskDetails: JSON.stringify(updatedTaskDetails),
                              });
                            }}
                            disabled={field.fieldName === "TaskID"}
                          />
                        ) : (
                          <span
                            className={`inline-block rounded-md px-3 py-1 text-wrap ${
                              field.fieldName === "Priority"
                                ? "bg-red-100 text-red-800"
                                : field.fieldName === "TaskID"
                                ? "bg-green-100 text-green-800"
                                : field.fieldName === "Status"
                                ? "bg-blue-100 text-blue-800"
                                : "text-gray-700"
                            }`}
                          >
                            {field.value}
                          </span>
                        )}
                      </dd>
                    </div>
                  ))
                )}
              </dl>
              {editMode && (
                <div className="flex justify-end gap-3 mt-6">
                  <button
                    className="bg-gray-100 text-sm border border-gray-300 text-gray-700 font-medium py-2 px-4 rounded-md hover:bg-gray-200"
                    onClick={handleDiscard} >
                    Discard
                  </button>
                  <button
                    className="bg-blue-800 text-sm text-white font-medium py-2 px-4 rounded-md hover:bg-blue-700"
                    onClick={handleConfirm} >
                    Confirm
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="w-full p-4 custom-scrollbar h-72">
            <div className="px-4 sm:px-0 flex ">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Task History
              </h3>
            </div>
            <div
              id="accordion my-5 custom-scrollbar"
              style={{ height: "1100px", overflowY: "scroll" }}>
              {taskHistory.length === 0 ? (
                <div>No task history available.</div>
              ) : (
                taskHistory.map((task, index) => (
                  <div key={index} className="mb-4 my-3">
                    <div
                      className="cursor-pointer bg-gray-100 p-4 rounded-lg  flex justify-between"
                      onClick={() => handleToggle(index)}
                    >
                      <h5 className="text-gray-900 text-sm font-semibold">
                        user {task.userId} {task.heading}
                      </h5>
                      <h5 className="text-gray-900 text-sm font-semibold">
                        {formatDate(task.createdAt)}
                      </h5>
                    </div>
                    {expandedTask === index && (
                      <div className="mt-2 p-4  rounded-md border border-gray-500">
                        <div className="flex gap-3">
                          {isValidURL(task.body) ? (
                            <img
                              src={task.body}
                              alt="Task Image"
                              className="max-w-full h-44 rounded-md border-gray-600"
                            />
                          ) : (
                            <p>{task.body}</p>
                          )}
                        </div>
                        <div>{task.comment}</div>
                      </div>
                    )}
                  </div>
                ))
              )}
              {isLoading && <div>Loading more task history...</div>}
              {!hasMore && (
                <div className="text-center text-gray-500">
                  No more task history to load
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TaskDetailPage;
