import React, { useEffect, useState, useContext } from "react";
import Header from "../../components/_app/Header";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import {
  approveRejectNotification,
  generateReport,
} from "../../service/Notification";
const translations = {
  en: {
    notification: "Notification",
    all: "All Notification",
    request: "Request",
  },
  ar: {
    notification: "إشعار",
    all: "كل الإخطار",
    request: "طلب",
  },
};
const NotificationSection = () => {
  const [openTab, setOpenTab] = useState(1);
  const context = useContext(AppContext);
  const {
    langMode,
    notifications,
    fetchNotifications,
    isLoading,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    updateNotificationStatus,
    updateNotificationReportStatus,
    theme,
    noMoreNotifications,
    setNoMoreNotifications,
  } = context;
  useEffect(() => {
    setCurrentPage(0);
    setPageSize(5);
    setNoMoreNotifications(false);
    const fetchInitialNotifications = async () => {
      const hasMoreNotifications = await fetchNotifications(currentPage);
      if (!hasMoreNotifications) {
        setNoMoreNotifications(true);
      }
    };
    fetchInitialNotifications();
  }, [currentPage]);

  const handleApproveReject = async (
    referenceId,
    notificationId,
    isApproved
  ) => {
    try {
      const data = await approveRejectNotification(
        referenceId,
        notificationId,
        isApproved
      );
      if (data.header.code === 600) {
        toast.success(data.body.value);
        updateNotificationStatus(notificationId, isApproved);
      }
    } catch (error) {
      console.error("Error while approving/rejecting:", error);
    }
  };

  const handleGenerateReport = async (referenceId, notificationId) => {
    try {
      const data = await generateReport(referenceId, notificationId);
      if (data.header.code === 600) {
        toast.success(data.body.value);
        updateNotificationReportStatus(notificationId);
      } else if (data.header.code === 607) {
        toast.error(data.body.value);
      }
    } catch (error) {
      console.error("Error while generating report:", error);
    }
  };

  const getRandomColor = () => {
    const colors = [
      "#EF4444", // Red-500
      "#F97316", // Orange-500
      "#F59E0B", // Amber-500
      "#EAB308", // Yellow-500
      "#84CC16", // Lime-500
      "#22C55E", // Green-500
      "#10B981", // Emerald-500
      "#14B8A6", // Teal-500
      "#06B6D4", // Cyan-500
      "#0EA5E9", // Sky-500
      "#3B82F6", // Blue-500
      "#6366F1", // Indigo-500
      "#8B5CF6", // Violet-500
      "#A855F7", // Purple-500
      "#D946EF", // Fuchsia-500
      "#EC4899", // Pink-500
      "#F43F5E", // Rose-500
    ];

    // Select a random color from the array
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    return randomColor;
  };

  const formatCreatedAtDate = (createdAt) => {
    const date = new Date(createdAt);
    const formattedDate = `${date.getDate()} ${date.toLocaleString("default", {
      month: "long",
    })} ${date.getFullYear()}, ${date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
    return formattedDate;
  };

  const handleScroll = async (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.1 && !isLoading) {
      setCurrentPage((prevPage) => prevPage + 1);
      const hasMoreNotifications = await fetchNotifications(currentPage + 1);
      if (!hasMoreNotifications) {
        setNoMoreNotifications(true);
      }
    }
  };
  return (
    <>
      <Header pageName={translations[langMode].notification} />
      <div
        className={`px-5 py-5 bg-white overflow-y-auto ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
        onScroll={handleScroll}
        style={{ maxHeight: "80vh" }}
      >
        <div>
          <div className="mb-4 flex ">
            <button
              onClick={() => setOpenTab(1)}
              className={`py-2 px-2 rounded-l-md font-semibold border border-gray-300 transition-all duration-300 ${
                openTab === 1
                  ? "bg-blue-500 text-white hover:bg-blue-600 backdrop-blur-sm" // Active tab: white text, blue background, blurred effect
                  : "bg-gray-200 text-gray-600 hover:bg-gray-300"
              }`}
            >
              {translations[langMode].all}
            </button>
            <button
              onClick={() => setOpenTab(2)}
              className={`py-2 px-2 rounded-r-md font-semibold border border-gray-300 transition-all duration-300 ${
                openTab === 2
                  ? "bg-blue-500 text-white hover:bg-blue-600 backdrop-blur-sm" // Active tab: white text, blue background, blurred effect
                  : "bg-gray-200 text-gray-600 hover:bg-gray-300"
              }`}
            >
              {translations[langMode].request}
            </button>
          </div>
          <div style={{ display: openTab === 1 ? "block" : "none" }}>
            {notifications.map((notification, index) => (
              <div
                key={index}
                className={`p-4 mb-5 rounded-lg shadow-lg border ${
                  theme === "dark"
                    ? "border-gray-700 bg-gray-800"
                    : "border-blue-300 bg-white"
                } ${notification.isRead === 0 ? "bg-blue-50" : "bg-gray-100"}`}
              >
                <div className="flex justify-between items-start">
                  <div className="w-2/3">
                    <span
                      className="inline-block text-white text-xs font-semibold mb-2 px-3 py-1 rounded-full"
                      style={{ backgroundColor: getRandomColor() }}
                    >
                      {notification.notificationId}
                    </span>
                    <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                      {notification.user}{" "}
                      <span className="text-sm font-normal text-gray-600 dark:text-gray-400">
                        {notification.heading}
                      </span>
                    </h3>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      {notification.body}
                    </p>
                  </div>
                  <div className="w-1/3 text-right">
                    <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                      {formatCreatedAtDate(notification.createdAt)}
                    </p>

                    {notification.notificationType === "REPORT_REQUEST" &&
                      notification.isAcknowledged === 0 && (
                        <button
                          type="button"
                          onClick={() =>
                            handleGenerateReport(
                              notification.referenceId,
                              notification.notificationId
                            )
                          }
                          className="px-3 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-300 focus:outline-none dark:bg-blue-700 dark:hover:bg-blue-800 dark:focus:ring-blue-800"
                        >
                          Generate Report
                        </button>
                      )}

                    {notification.notificationType === "EXTENSION" &&
                      notification.isApproved === 0 && (
                        <div className="mt-4 space-x-2">
                          <button
                            type="button"
                            onClick={() =>
                              handleApproveReject(
                                notification.referenceId,
                                notification.notificationId,
                                false
                              )
                            }
                            className="px-3 py-2 text-sm font-medium text-gray-700 bg-gray-200 hover:bg-gray-300 border border-gray-300 rounded-lg dark:text-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-500"
                          >
                            Reject
                          </button>
                          <button
                            type="button"
                            onClick={() =>
                              handleApproveReject(
                                notification.referenceId,
                                notification.notificationId,
                                true
                              )
                            }
                            className="px-3 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-300 focus:outline-none dark:bg-blue-700 dark:hover:bg-blue-800 dark:focus:ring-blue-800"
                          >
                            Approve
                          </button>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div style={{ display: openTab === 2 ? "block" : "none" }}>
            {notifications
              .filter((notification) => notification.isApproved === 0)
              .map((notification, index) => (
                <div
                  key={index}
                  className={`bg-white border shadow-lg border-blue-300  rounded-lg p-4 mb-5 ${
                    theme === "dark"
                      ? "dark-mode border border-gray-700"
                      : "light-mode"
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <span
                        className="text-white text-sm font-semibold me-2 px-2.5 py-1.5 rounded-full"
                        style={{ backgroundColor: getRandomColor() }}
                      >
                        {notification.notificationId}
                      </span>
                      <h3 className="py-4 text-sm text-gray-500 font-semibold">
                        {notification.user}
                        <span className="text-normal">
                          {notification.heading}
                        </span>
                      </h3>
                      <p className="px-2 py-1 text-sm text-gray-500 font-semibold">
                        {notification.body}
                      </p>
                    </div>
                    <div>
                      <h3 className="text-sm text-gray-600">
                        {formatCreatedAtDate(notification.createdAt)}
                      </h3>
                      {notification.notificationType === "REPORT_REQUEST" &&
                        notification.isAcknowledged === 0 && (
                          <div>
                            <button
                              type="button"
                              onClick={() =>
                                handleGenerateReport(
                                  notification.referenceId,
                                  notification.notificationId
                                )
                              }
                              className="px-3 py-2 mt-4 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                            >
                              Generate
                            </button>
                          </div>
                        )}{" "}
                      {notification.isApproved === 0 &&
                        notification.notificationType === "EXTENSION" && (
                          <div>
                            <button
                              type="button"
                              onClick={() =>
                                handleApproveReject(
                                  notification.referenceId,
                                  notification.notificationId,
                                  false
                                )
                              }
                              className="px-3 py-2 mt-4 text-gray-800 bg-white hover:bg-gray-100 border border-gray-300 font-medium rounded-lg text-sm me-2 mb-2"
                            >
                              Reject
                            </button>
                            <button
                              type="button"
                              onClick={() =>
                                handleApproveReject(
                                  notification.referenceId,
                                  notification.notificationId,
                                  true
                                )
                              }
                              className="px-3 py-2 mt-4 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                            >
                              Approve
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {isLoading && (
            <div className="text-center py-4">
              <p>Loading more notifications...</p>
            </div>
          )}
          {!isLoading && noMoreNotifications && (
            <div className="text-center py-4">
              <p>No more notifications to load</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NotificationSection;
