import React, { useState, useContext, useEffect } from "react";
import { IoSearch } from "react-icons/io5";
import FilterSelect from "../common/FilterSelect";
import { useNavigate } from "react-router-dom";
import { RiAccountCircleFill } from "react-icons/ri";
import AppContext from "../../context/AppContext";
import Cookies from "js-cookie";
const translations = {
  en: {
    addUser:"Add User",
    searchusername:"Search by username...",
    searchareacode:"Search by areacode...",
    userType:"User Type"

  },
  ar: {
    addUser:"إضافة مستخدم",
    searchusername:"البحث حسب اسم المستخدم...",
    searchareacode:"البحث عن طريق رمز المنطقة...",
    userType:"نوع المستخدم"
  },
};
const UserToolbar = ({
  onFilterChange,
  onSearch,
  onAreaSearch,
}) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/add-user");
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUserType, setSelectedUserType] = useState("");
  const [searchArecodeTerm, setSearchArecodeTerm] = useState("");
  const { theme , langMode } = useContext(AppContext);
  const userType = [
    { id: "", value: "Default" }, // Adding an "Everything" option
    { id: "super_admin", value: "super_admin" },
    { id: "admin", value: "admin" },
    { id: "office_user", value: "office_user" },
    { id: "field_agent", value: "field_agent" },
  ];
  const [filteredUserType, setFilteredUserType] = useState(userType);

  useEffect(() => {
    const userVisibility = Cookies.get("userVisibility");
    const userTypeCookie = Cookies.get("userType");
    let allowedUserTypes = [...userType];

    if (userTypeCookie === "super_admin") {
      allowedUserTypes = allowedUserTypes.filter(
        (type) => type.id !== "super_admin"
      );
    } else if (userTypeCookie === "admin") {
      allowedUserTypes = allowedUserTypes.filter(
        (type) => type.id !== "super_admin" && type.id !== "admin"
      );
    } else if (userTypeCookie === "office_user") {
      if (userVisibility === "field_agent") {
        allowedUserTypes = allowedUserTypes.filter(
          (type) => type.id === "field_agent"
        );
      } else if (userVisibility === "office_user") {
        allowedUserTypes = allowedUserTypes.filter(
          (type) => type.id === "office_user"
        );
      } else if (
        userVisibility === "office_user,field_agent" ||
        userVisibility === "field_agent,office_user"
      ) {
        allowedUserTypes = allowedUserTypes.filter(
          (type) => type.id === "office_user" || type.id === "field_agent"
        );
      }
    } else if (userTypeCookie === "field_agent") {
      if (userVisibility === "field_agent") {
        allowedUserTypes = allowedUserTypes.filter(
          (type) => type.id === "field_agent"
        );
      }
    } else {
      allowedUserTypes = userType;
    }
    setFilteredUserType(allowedUserTypes);
  }, [userType]);

  const handleUserTypeChange = (value) => {
    setSelectedUserType(value);
    onFilterChange("userType", value === "Default" ? "" : value); // Pass null if "Everything" is selected
  };
  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    onSearch(term);
    onFilterChange("userName", term);
  };

  const handleAreacodeSearchChange = (event) => {
    const areacodeterm = event.target.value;
    setSearchArecodeTerm(areacodeterm);
    onAreaSearch(areacodeterm);
    onFilterChange("areacode", areacodeterm);
  };

  return (
    <div className={`flex justify-between items-center   `}>
      <div className="flex flex-row gap-2">
        <div
          className={` bg-white  flex gap-3 ${
            theme === "dark"
              ? "dark-mode"
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }`}
        >
          <div className={`relative mt-1  `}>
            <div
              className={`absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none `}
            >
              <IoSearch />
            </div>
            <input
              type="text"
              id="table-search"
              className={`${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              } block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-48 focus:ring-blue-300 focus:border-blue-300  dark:focus:ring-blue-500 dark:focus:border-blue-500  `}
              placeholder={translations[langMode].searchusername}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div className={`relative mt-1  `}>
            <div
              className={`absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none `}
            >
              <IoSearch />
            </div>
            <input
              type="text"
              id="table-search"
              className={`${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              } block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-48 focus:ring-blue-300 focus:border-blue-300  dark:focus:ring-blue-500 dark:focus:border-blue-500  `}
              placeholder={translations[langMode].searchareacode}
              value={searchArecodeTerm}
              onChange={handleAreacodeSearchChange}
            />
          </div>
        </div>
        <FilterSelect
          options={filteredUserType}
          defaultText={translations[langMode].userType}
          value={selectedUserType}
          onChange={handleUserTypeChange}
        />
      </div>
      <div className="gap-5 flex">
        <button
          type="button"
          className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={handleNavigate}
        >
          <RiAccountCircleFill className="w-5 h-5 mr-2" />{translations[langMode].addUser}
        </button>
      </div>
    </div>
  );
};

export default UserToolbar;
