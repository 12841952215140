import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useRef, useState } from "react";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import { createOrganization } from "../../service/User";
import Cookies from "js-cookie";
import { uploadFileToS3 } from "../../service/S3Bucket"; // Import the S3 upload function
import { RxCross2 } from "react-icons/rx";
const translations = {
  en: {
    createOrg: " Create Organization",
    orgname: "Organization Name",
    img: "  Upload Logo",
    cancel: "Cancel",
    creating: "Creating...",
    create: "Create",
  },
  ar: {
    createOrg: "إنشاء منظمة",
    orgname: "اسم المنظمة",
    img: "تحميل الشعار",
    cancel: "يلغي",
    creating: "جارٍ الإنشاء...",
    create: "يخلق",
  },
};
export default function CreateOrganization({
  open,
  setOpen,
  fetchorgnizationlist,
}) {
  const [isLoading, setIsLoading] = useState(false); // State to manage loading
  const [selectedOrgName, setSelectedOrgName] = useState("");
  const [logo, setLogo] = useState(null);
  const cancelButtonRef = useRef(null);
  const { theme , langMode } = useContext(AppContext);
  const handleOrgNameChange = (event) => {
    setSelectedOrgName(event.target.value);
  };
  const handleLogoChange = (event) => {
    setLogo(event.target.files[0]);
  };
  const handleAddOrganization = async () => {
    setIsLoading(true); // Start loading
    const token = Cookies.get("accessToken");
    try {
      let logoUrl = "";
      if (logo) {
        logoUrl = await uploadFileToS3(logo);
      }
      const data = await createOrganization(selectedOrgName, logoUrl, token);
      if (data.header.code === 600) {
        toast.success("Organization Added");
        setSelectedOrgName("");
        setLogo(null); // Clear logo state
        setOpen(false);
        fetchorgnizationlist();
      } else {
        toast.error(data.body.value);
      }
    } catch (error) {
      toast.error("Error creating organization");
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity`}
          />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              >
                <div
                  className={`bg-white rounded-lg shadow ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                >
                  <div className="flex items-center justify-between p-2 md:p-5 rounded-t dark:border-gray-600">
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                    {translations[langMode].createOrg}
                    </h3>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-hide="small-modal"
                      onClick={() => setOpen(false)}
                    >
                      <RxCross2 />
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="p-4 grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-4">
                    <div className="sm:col-span-2">
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700">
                        {translations[langMode].orgname}
                        </label>
                        <input
                          type="text"
                          value={selectedOrgName}
                          onChange={handleOrgNameChange}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="relative">
                        <label className="block text-sm font-medium text-gray-700">
                        {translations[langMode].img}
                        </label>
                        <input
                          type="file"
                          onChange={handleLogoChange}
                          className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center float-right p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                    <button
                      type="button"
                      className="py-1.5 px-3 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      onClick={() => setOpen(false)}
                    >
                      {translations[langMode].cancel}
                    </button>
                    <button
                      type="button"
                      onClick={handleAddOrganization}
                      className={`text-white ${
                        isLoading
                          ? "bg-gray-500 cursor-not-allowed"
                          : "bg-blue-800 hover:bg-blue-800"
                      } focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 ml-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
                      disabled={isLoading}
                    >
                      {isLoading ? translations[langMode].creating : translations[langMode].create}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
