import React from "react";
import Header from "../_app/Header";
import CommonInputField from "../common/CommonInputField";

export const ProtectionForm = () => {
  return (
    <div>
      <Header
        pageName={"SSL Special Protected Account"}
      />
      <div className="bg-white p-5">
        <div className="w-3/5">
          <div className="mt-10 grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-4">
            <div className="sm:col-span-2 mt-4">
              <CommonInputField
                label={"Enter account number to be protected"}
              />
            </div>
            <div className="sm:col-span-2 flex gap-4">
              <div className="flex flex-col mt-0 mb-10">
                <label className="text-xs">Enter Extented Date</label>
                <input type="date" className="w-[300px] h-12" />
              </div>
            </div>
            <div className="sm:col-span-2">
            <CommonInputField
                label={"Target Outstanding amount by extended date"}
              />
            </div>
            <div className="sm:col-span-2">
            <CommonInputField
                label={"Comments"}
              />
            </div>
          </div>
        </div>
        <div className="mt-40">
          <div className="sm:col-span-4">
            <hr className="w-full border-gray-300" />
          </div>
          <div className="sm:col-span-4 pt-5 flex justify-end items-center">
            <button
              type="submit"
              className="px-3 py-2 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
