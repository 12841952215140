import React from 'react';
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import FilterSelect from "../common/FilterSelect";
import { fetchOrganizations } from "../../service/Report";
import { fetchDynamicWorkflows } from "../../service/UserDashboard";
import { toast } from "react-toastify";
export default function AddLeaderBoard({ open, setOpen }) {
  const [selectedOrgName, setSelectedOrgName] = useState("");
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState("");
  const [selectedWorkflowName, setSelectedWorkflowName] = useState("");

  const cancelButtonRef = useRef(null);
  const navigate = useNavigate();
  const handleOrganizationChange = (selectedName) => {
    const selectedOrg = organizations.find(
      (org) => org.organizationName === selectedName
    );
    if (selectedOrg) {
      setSelectedOrgId(selectedOrg.organizationId);
      setSelectedOrgName(selectedOrg.organizationName);
    }
  };

  useEffect(() => {
    const loadOrganizations = async () => {
      try {
        const orgs = await fetchOrganizations();
        setOrganizations(orgs);
      } catch (error) {
        console.error("Error loading organizations:", error);
      }
    };

    loadOrganizations();
  }, []);

  const handleWorkflowChange = (selectedName) => {
    const selectedWorkflow = workflows.find(
      (workflow) => workflow.workflowName === selectedName
    );
    if (selectedWorkflow) {
      setSelectedWorkflowName(selectedWorkflow.workflowName);
      setSelectedWorkflowId(selectedWorkflow.workflowId);
    }
  };

  useEffect(() => {
    if (selectedOrgId) {
      const loadWorkflows = async () => {
        try {
          const workflows = await fetchDynamicWorkflows(selectedOrgId);
          setWorkflows(Array.isArray(workflows) ? workflows : []);
        } catch (error) {
          console.error("Error loading workflows:", error);
          setWorkflows([]); // Set to an empty array on error
        }
      };

      loadWorkflows();
    }
  }, [selectedOrgId]);

  const selectActicity = () => {
    if (!selectedWorkflowId) {
      toast.error("Select orgnization first");
    }
    return;
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div class="bg-white rounded-lg shadow dark:bg-gray-700">
                  <div class="flex items-center justify-between p-2 md:p-5  rounded-t dark:border-gray-600">
                    <h3 class="text-sm font-semibold text-gray-900 dark:text-white">
                      Add Leader-board Dashboard
                    </h3>
                    <button
                      type="button"
                      class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-hide="small-modal"
                      onClick={() => setOpen(false)}
                    >
                      <svg
                        class="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                      <span class="sr-only">Close modal</span>
                    </button>
                  </div>

                  <div class="p-2 md:p-5 space-y-4 ">
                    <div class="mb-6">
                      <label
                        for="default-input"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Name of the Leader Board
                      </label>
                      <input
                        type="text"
                        id="default-input"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                    <div className="flex justify-start gap-5">
                      <div>
                        <label
                          for="countries"
                          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Select Orgnization
                        </label>
                        <FilterSelect
                          defaultText="Organizations"
                          options={organizations.map((org) => ({
                            id: org.organizationId,
                            value: org.organizationName,
                          }))}
                          value={selectedOrgName}
                          onChange={(selectedName) => {
                            const selectedOrg = organizations.find(
                              (org) => org.organizationName === selectedName
                            );
                            if (selectedOrg)
                              handleOrganizationChange(
                                selectedOrg.organizationName
                              );
                          }}
                        />
                      </div>
                      <div>
                        <label
                          for="countries"
                          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Select Activity
                        </label>
                        <FilterSelect
                         onClick={selectActicity}
                          defaultText="Workflows"
                          options={workflows.map((workflow) => ({
                            id: workflow.workflowId,
                            value: workflow.workflowName,
                          }))}
                          value={selectedWorkflowName}
                          onChange={(selectedName) =>
                            handleWorkflowChange(selectedName)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div class="flex items-center float-right p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                    <button
                      data-modal-hide="small-modal"
                      type="button"
                      onClick={() => setOpen(false)}
                      class="py-1.5 px-3 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    >
                      Cancel
                    </button>
                    <button
                      data-modal-hide="small-modal"
                      type="button"
                      class="text-white bg-blue-800 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 ml-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      onClick={() => navigate("/leaderboard-preview")}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
