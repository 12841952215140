import React, { useState, useRef, useEffect, useContext } from "react";
import Header from "../../components/_app/Header";
import dragdrop from "../../assets/download.png";
import AWS from "aws-sdk";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { fetchDynamicWorkflows } from "../../service/UserDashboard";
import {
  addGuideline,
  fetchGuidelines,
  deleteGuideline,
} from "../../service/Guidlines";
import AppContext from "../../context/AppContext";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IoMdMore } from "react-icons/io";
import { IoCloudUpload } from "react-icons/io5";
const translations = {
  en: {
    guidline: "Guidlines",
    noworkflow: "No workflows available",
    pleaseselect: " Please select a workflow first.",
  },
  ar: {
    guidline: "المبادئ التوجيهية",
    noworkflow: "لا توجد مسارات عمل متاحة",
    pleaseselect: " الرجاء تحديد سير العمل أولاً.",
  },
};
const Guidline = () => {
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState("");
  const [activeTab, setActiveTab] = useState("");
  const [uploadFile, setuploadFile] = useState(null);
  const [fileLinks, setFileLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDropdownId, setShowDropdownId] = useState(null);
  const fileInputRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const userType = Cookies.get("userType");
  const { selectedOrgId, theme, langMode } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);
  const navigate = useNavigate();

  const handleDropdownToggle = (fileId, event) => {
    setShowModal((prev) => {
      const shouldToggle = showDropdownId !== fileId || !prev;
      setShowDropdownId(shouldToggle ? fileId : null);
      return shouldToggle;
    });
    const button = event.currentTarget;
    const rect = button.getBoundingClientRect();
    setDropdownPosition({
      top: rect.top + window.scrollY + rect.height,
      left: rect.left + window.scrollX,
    });
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTabClick = (tabName, selectedWorkflowId) => {
    setActiveTab(tabName);
    setSelectedWorkflowId(selectedWorkflowId);
    fetchFiles(selectedWorkflowId);
  };
  const handleDivClick = () => {
    fileInputRef.current.click();
  };
  const handlefileUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const fileType = event.target.files[0].type;
      if (fileType === "application/pdf" || fileType.startsWith("video/")) {
        setLoading(true);
        uploadFileToS3(event.target.files[0], fileType);
      } else {
        toast.error("Please upload PDF or video files only.");
      }
    }
  };
  const uploadGuideline = async (url, fileType, selectedWorkflowId) => {
    const token = Cookies.get("accessToken");
    if (!token) {
      toast.error("Access token is missing");
      setLoading(false);
      return;
    }
    try {
      await addGuideline(token, selectedWorkflowId, fileType, url);
      toast.success("Guideline uploaded successfully");
    } catch (error) {
      toast.error(`Error uploading guideline: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  async function uploadFileToS3(loader) {
    if (loading) {
      return;
    }
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_REGION,
    });
    let contentType = "application/octet-stream";
    let fileType = "other";
    if (loader.type === "application/pdf") {
      contentType = "application/pdf";
      fileType = "pdf";
    } else if (loader.type.startsWith("video/")) {
      contentType = loader.type;
      fileType = "video";
    } else {
      toast.error("Unsupported file type.");
      setLoading(false);
      return;
    }

    const uploadParams = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: `${Date.now()}_${loader.name}`,
      Body: loader,
      ContentType: contentType,
    };
    try {
      const uploadData = await s3.upload(uploadParams).promise();
      const fileUrl = `https://${process.env.REACT_APP_BUCKET_NAME}.s3.${process.env.REACT_APP_REGION}.amazonaws.com/${uploadParams.Key}`;
      setuploadFile(fileUrl);
      setFileLinks((prevFileLinks) => {
        const newFileLinks = [...(prevFileLinks || []), { fileUrl, fileType }];
        return newFileLinks;
      });
      try {
        await uploadGuideline(fileUrl, fileType, selectedWorkflowId);
      } catch (error) {}
    } catch (error) {
      toast.error("Failed to upload file.");
    } finally {
      setLoading(false);
    }
  }
  const noImg =
    "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";
  async function fetchFiles(selectedWorkflowId) {
    setLoading(true);
    const token = Cookies.get("accessToken");
    try {
      const guidelines = await fetchGuidelines(token, selectedWorkflowId);
      setFileLinks(guidelines);
    } catch (err) {
      setError("Error fetching files");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchFiles(selectedWorkflowId);
  }, [selectedWorkflowId]);

  const handleDeleteFile = async (fileId) => {
    const token = Cookies.get("accessToken");
    try {
      await deleteGuideline(token, fileId);
      setShowDropdownId(null);
      setFileLinks((prevFileLinks) =>
        prevFileLinks.filter((file) => file.id !== fileId)
      );
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const PDFViewer = ({ fileUrl }) => {
    return (
      <div>
        <iframe
          src={`https://docs.google.com/viewer?url=${encodeURIComponent(
            fileUrl
          )}&embedded=true`}
          style={{ width: "100%", height: "500px" }}
          frameBorder="0"
        ></iframe>
      </div>
    );
  };

  useEffect(() => {
    if (selectedOrgId) {
      const loadWorkflows = async () => {
        try {
          const workflows = await fetchDynamicWorkflows(selectedOrgId);
          setWorkflows(Array.isArray(workflows) ? workflows : []);
        } catch (error) {
          console.error("Error loading workflows:", error);
          setWorkflows([]);
        }
      };
      loadWorkflows();
      setSelectedWorkflowId("");
    }
  }, [selectedOrgId]);
  const navigateToWorkflow = () => {
    navigate("/workflow");
  };
  return (
    <>
      <Header pageName={translations[langMode].guidline} />
      <div
        onClick={navigateToWorkflow}
        className={`p-2 ml-10 absolute top-[87px] left-40 rounded-full border cursor-pointer w-9 m-3
    ${theme === "dark" ? "bg-gray-800 border-gray-600 hover:bg-gray-700" : ""} 
    ${
      theme === "high-contrast"
        ? "bg-yellow-200 border-black hover:bg-yellow-300"
        : ""
    } 
    ${
      theme !== "dark" && theme !== "high-contrast"
        ? "border-gray-400 hover:bg-gray-200"
        : ""
    }
  `}
      >
        <FaArrowLeft
          className={`${
            theme === "dark"
              ? "text-white"
              : theme === "high-contrast"
              ? "text-black"
              : "text-gray-700"
          }`}
        />
      </div>
      <div className="min-h-screen md:flex">
        <ul
          className={`flex-column space-y overflow-y-auto h-min-screen mr-5 shadow-2xl rounded-md border border-blue-400 p-4 text-sm font-medium text-gray-500 ${
            theme === "dark"
              ? "dark-mode "
              : theme === "high-contrast"
              ? "high-contrast"
              : ""
          }`}
          style={{ width: "300px", height: "800px" }}
        >
          {workflows.length === 0 ? (
            <li>{translations[langMode].noworkflow}</li>
          ) : (
            workflows.map((workflow) => (
              <li
                key={workflow.workflowId}
                onClick={() => handleTabClick(activeTab, workflow.workflowId)}
                className={`inline-flex justify-center px-4 py-3 my-1 border cursor-pointer font-semibold rounded-md w-full transition duration-300 ease-in-out transform hover:scale-105 ${
                  selectedWorkflowId === workflow.workflowId
                    ? "bg-blue-800 text-white"
                    : theme === "dark"
                    ? " border-blue-600 text-white"
                    : "bg-white border-blue-800 text-gray-700"
                } ${
                  theme === "dark"
                    ? "  border-blue-600"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              >
                {workflow.workflowName}
              </li>
            ))
          )}
        </ul>
        <div
          className={`p-6 bg-white text-medium text-gray-500 shadow-2xl rounded-md border border-blue-400 w-full ${
            theme === "dark"
              ? "dark-mode "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          }`}
        >
          {userType === "super_admin" && (
            <>
              {!selectedOrgId && <div className={`text-gray-700 mb-4 `}></div>}
            </>
          )}
          {selectedOrgId && !selectedWorkflowId && (
            <div className={`text-gray-700 mb-4 text-center`}>
              {translations[langMode].pleaseselect}
            </div>
          )}
          {selectedOrgId && selectedWorkflowId && (
            <div className="flex gap-5">
              <div className="link-content hide-scrollbar flex">
                {loading ? (
                  <p className="text-gray-500">Uploading...</p>
                ) : error ? (
                  <p>{error}</p>
                ) : fileLinks ? (
                  Array.isArray(fileLinks) && fileLinks.length > 0 ? (
                    <div className="flex flex-wrap justify-around items-start gap-4">
                      {fileLinks.map((file, index) => (
                        <>
                          {file.fileType === "pdf" ||
                          file.fileType === "video" ? (
                            <div
                              key={index}
                              className={`file-preview bg-white shadow-lg rounded-lg overflow-hidden ${
                                theme === "dark"
                                  ? "dark-mode border rounded-md "
                                  : theme === "high-contrast"
                                  ? "high-contrast  "
                                  : ""
                              }`}
                            >
                              <div className="p-4">
                                {file.fileType === "pdf" ? (
                                  <>
                                    <div className="w-40 h-24 mb-2">
                                      <iframe
                                        src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                                          file.fileUrl || noImg
                                        )}&embedded=true`}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                        frameBorder="0"
                                        title={`file-${index}`}
                                      ></iframe>
                                    </div>
                                    <a
                                      href={`https://docs.google.com/viewer?url=${encodeURIComponent(
                                        file.fileUrl
                                      )}&embedded=true`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-blue-500 hover:text-blue-600"
                                    >
                                      View PDF
                                    </a>
                                  </>
                                ) : file.fileType === "video" ? (
                                  <>
                                    <video controls className="w-40 h-24 mb-2">
                                      <source
                                        src={file.fileUrl}
                                        type={file.fileType}
                                      />
                                    </video>
                                    <a
                                      href={file.fileUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-blue-500 hover:text-blue-600"
                                    >
                                      View Video
                                    </a>
                                  </>
                                ) : null}
                              </div>
                              <div>
                                <div
                                  className="dots relative flex flex-col items-end pr-5 pb-2 gap-1 cursor-pointer"
                                  onClick={(event) =>
                                    handleDropdownToggle(file.id, event)
                                  }
                                >
                                  <IoMdMore />
                                </div>
                                {showModal && showDropdownId === file.id && (
                                  <div
                                    ref={modalRef}
                                    className="bg-gray-200 px-9 py-2 z-10 absolute"
                                    style={{
                                      top: `${dropdownPosition.top}px`,
                                      left: `${dropdownPosition.left}px`,
                                    }}
                                  >
                                    <ul>
                                      <li
                                        className="text-black cursor-pointer"
                                        onClick={() => {
                                          handleDeleteFile(file.id);
                                          setShowModal(false);
                                        }}
                                      >
                                        Delete
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null}
                        </>
                      ))}
                    </div>
                  ) : (
                    <p>No files available</p>
                  )
                ) : (
                  <p>No files available</p>
                )}
              </div>
              <div onClick={handleDivClick} className="cursor-pointer">
                <div className="border border-dashed border-blue-500 rounded-md w-[200px] h-[180px] flex flex-col items-center justify-center hover:border-blue-700 transition-all duration-300">
                  <IoCloudUpload  className="h-10 w-10 mb-2"/>
                  <span className="text-sm text-gray-600">
                    Drag and drop to upload
                  </span>
                  <input
                    ref={fileInputRef}
                    type="file"
                    className="hidden"
                    accept=".pdf, .mp4"
                    onChange={handlefileUpload}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Guidline;
