import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import AppContext from "../../context/AppContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function FilterSelect({
  options,
  defaultText,
  onChange,
  value,
  pageType
}) {
  const { theme } = useContext(AppContext);
  const context = useContext(AppContext);
  const {  priority } = context;
  useEffect(() => {
  }, [priority]);

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative mt-1">
            <Listbox.Button className={`relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-300 sm:text-sm sm:leading-6 ${theme === 'dark' ? 'dark-mode' : (theme === 'high-contrast' ? 'high-contrast' : '')}`}>
              <span className="flex items-center">
                {value ? (
                  <span className="ml-3 block truncate">{value}</span>
                ) : (
                  <span className="ml-3 block text-gray-500">
                    {defaultText}
                  </span>
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <IoIosArrowDown
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className={`absolute z-10 mt-1 max-h-56 w-auto  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${theme === 'dark' ? 'dark-mode' : (theme === 'high-contrast' ? 'high-contrast' : '')}`}>
                {options.length > 0 ? (
                  options.map((option) => (
                    <Listbox.Option
                      key={option.id}
                      value={option.value}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-indigo-600 text-white" : "text-gray-600",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                    >
                      {({ selected, active }) => (
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                           {pageType === "report" ? option.label : option.value}
                          </span>
                        </div>
                      )}
                    </Listbox.Option>
                  ))
                ) : (
                  <Listbox.Option
                    value=""
                    disabled
                    className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-500"
                  >
                    <div className="flex items-center">
                      <span className="ml-3 block truncate">No options</span>
                    </div>
                  </Listbox.Option>
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
