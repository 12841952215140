import React from "react";
import { RxCross2 } from "react-icons/rx";

const DeletePermission = ({ onClose, deleteFunction, onDeleteText }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 ">
      <div className="bg-white rounded-lg p-4 relative overflow-y-auto w-1/4 max-h-full">
        <span
          className="absolute top-0 right-0 m-4 cursor-pointer text-gray-500"
          onClick={onClose}
        >
          <RxCross2 />
        </span>
        <h5 className="font-normal text-md  text-wrap  text-center mt-10">
          {onDeleteText}
        </h5>
        <div className="flex justify-center mt-2">
          <button
            type="button"
            onClick={onClose}
            className="mt-6 px-4 py-2 bg-white border border-gray-500 text-black font-semibold rounded-md hover:bg-gray-200"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="mt-6 ml-4 px-4 py-2 bg-red-600 text-white rounded-md font-semibold hover:bg-red-700"
            onClick={deleteFunction}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeletePermission;
