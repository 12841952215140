import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/_app/Header";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import { fetchTaskForm, saveTask } from "../../service/Task";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const translations = {
  en: {
    selectMsg: " Please select an activity to proceed.",
    createnewtask: "Create new task",
    cancle: "Cancle",
    Create: "Create",
  },
  ar: {
    selectMsg: "الرجاء تحديد نشاط للمتابعة.",
    createnewtask: "إنشاء مهمة جديدة",
    cancle: "كانكل",
    Create: "يخلق",
  },
};
const CreateTask = () => {
  const [accountNo, setAccountNo] = useState("");
  const [taskData, setTaskData] = useState({});
  const [inputFields, setInputFields] = useState([]);
  const context = useContext(AppContext);
  const { workflowId, theme, langMode } = context;

  const handleActivityChange = async () => {
    try {
      const responseData = await fetchTaskForm(workflowId);
      const responseValue = responseData.body.value;
      if (Array.isArray(responseValue)) {
        setInputFields(responseValue);
        const accountField = responseValue.find(
          (field) => field.fieldName === "Account No."
        );
        if (accountField) {
          setAccountNo(taskData[accountField.fieldName] || "");
        }
      } else {
        console.error("API response data is not an array:", responseValue);
      }
      if (responseData.header.code === 607) {
        toast.error("No data available");
        setInputFields([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleActivityChange();
  }, [workflowId]);

  const handleInputChange = (fieldName, value) => {
    setTaskData((prevTaskData) => ({
      ...prevTaskData,
      [fieldName]: value,
    }));
    if (fieldName === "Account No.") {
      setAccountNo(value);
    }
  };

  const handleSaveTask = async () => {
    try {
      const response = await saveTask(workflowId, taskData, accountNo);
      if (response.header.code === 600) {
        toast.success("Task Created Successfully");
        setTaskData({});
        setInputFields([]);
        setAccountNo("");
        navigate("/maptask");
      } else {
        toast.error(response.body.value);
      }
    } catch (error) {}
  };

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/maptask");
  };

  return (
    <>
      <Header pageName={translations[langMode].createnewtask} />
      <div
        onClick={handleGoBack}
        className={`p-2 absolute top-30 rounded-full border cursor-pointer w-9 m-3 ml-5
    ${theme === "dark" ? "bg-gray-800 border-gray-600 hover:bg-gray-700" : ""} 
    ${
      theme === "high-contrast"
        ? "bg-yellow-200 border-black hover:bg-yellow-300"
        : ""
    } 
    ${
      theme !== "dark" && theme !== "high-contrast"
        ? "border-gray-400 hover:bg-gray-200"
        : ""
    }
  `}
      >
        <FaArrowLeft
          className={`${
            theme === "dark"
              ? "text-white"
              : theme === "high-contrast"
              ? "text-black"
              : "text-gray-700"
          }`}
        />
      </div>
      <div
        className={`px-5 pt-14 bg-white  ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        {/* Conditional rendering for when activity is not selected */}
        {!workflowId && inputFields.length === 0 ? (
          <p className="text-red-500 font-semibold mt-10 text-center">
            {translations[langMode].selectMsg}
          </p>
        ) : (
          <div className="w-2/2">
            <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-8">
              {inputFields.map((field, index) => (
                <div className="sm:col-span-2" key={index}>
                  <div className="relative">
                    <input
                      id={`input_${index}`}
                      value={taskData[field.fieldName] || ""}
                      onChange={(e) =>
                        handleInputChange(field.fieldName, e.target.value)
                      }
                      className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 border-gray-500 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                    />
                    <label
                      className={`absolute text-md text-gray-700 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4  ${
                        theme === "dark"
                          ? "dark-mode"
                          : theme === "high-contrast"
                          ? "high-contrast"
                          : ""
                      } `}
                    >
                      {field.fieldName}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="">
          <div className="sm:col-span-4">
            <hr className="w-full border-gray-300" />
          </div>
          <div className="sm:col-span-4 pt-5 flex justify-end items-center">
            <button
              type="button"
              onClick={handleGoBack}
              className="px-3 py-2 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              {translations[langMode].cancle}
            </button>
            <button
              type="button"
              onClick={handleSaveTask}
              className="px-3 py-2 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              {translations[langMode].Create}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTask;
