import React, { useContext, useState } from "react";
import Header from "../../components/_app/Header";
import ConfigurationSetting from "../../components/profile-comp/notification/ConfigurationSetting";
import { GeneralSetting } from "../../components/profile-comp/notification/GeneralSetting";
import AppContext from "../../context/AppContext";
const translations ={
  en:{
     general:"General",
     config:"Configuration",
     save:"Save Changes",
     cancle:"Cancel"

  },
  ar:{
    general:"عام",
    config:"إعدادات",
    save:"حفظ التغييرات",
    cancle:"يلغي"
  }
}
const Settings = () => {
  const [openTab, setOpenTab] = useState(1);
  const { theme , langMode } = useContext(AppContext); // Use toggleTheme from context
  return (
    <>
      <Header pageName={"Settings"} />
      <div className={`px-5 py-5 bg-white p-4  ${theme === 'dark' ? 'dark-mode' : (theme === 'high-contrast' ? 'high-contrast' : '')}`}>
        <div className="max-w-3xl">
          <div className="mb-4 flex rounded-sm">
            <button
              onClick={() => setOpenTab(1)}
              className={`px-4 py-2 text-sm font-medium flex items-center gap-2 rounded-l-lg focus:ring-2 focus:ring-blue-700 transition-all ${
                openTab === 1 ? "bg-blue-500 text-white hover:bg-blue-600 backdrop-blur-sm" : "bg-gray-200 text-gray-600 hover:bg-gray-300"
              }`} >
              {translations[langMode].general}
            </button>
            <button
              onClick={() => setOpenTab(2)}
              className={`px-4 py-2 text-sm font-medium flex items-center gap-2 rounded-r-lg focus:ring-2 focus:ring-blue-700 transition-all ${
                openTab === 2 ? "bg-blue-500 text-white hover:bg-blue-600 backdrop-blur-sm" : "bg-gray-200 text-gray-600 hover:bg-gray-300"
              }`}>
              {translations[langMode].config}
            </button>
          </div>
          <div style={{ display: openTab === 1 ? "block" : "none" }}>
            <GeneralSetting />
          </div>
          <div style={{ display: openTab === 2 ? "block" : "none" }}>
            <ConfigurationSetting />
          </div>
        </div>
        <div className="mt-20">
          <div className="sm:col-span-4">
            <hr className="w-full border-gray-300" />
          </div>
          <div className="sm:col-span-4 pt-5 flex justify-end items-center">
            <button
              type="button"
              className="px-3 py-2 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
            {translations[langMode].cancle}
            </button>
            <button
              type="button"
              className="px-3 py-2 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
             {translations[langMode].save}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Settings;
