import React, { useContext, useState, useEffect } from "react";
import { FaUserCircle } from "react-icons/fa";
import Header from "../../components/_app/Header";
import { useNavigate } from "react-router-dom";
import ActivityDashboard from "../../components/dashboard/actvity/ActivityDashboard";
import UserDashboard from "../../components/dashboard/user/UserDashboard";
import { IoBagHandleSharp } from "react-icons/io5";
import { CgExport } from "react-icons/cg";
import AppContext from "../../context/AppContext";
import Cookies from "js-cookie";
import FilterSelect from "../../components/common/FilterSelect";
import { fetchDynamicWorkflows } from "../../service/UserDashboard.js";
import Joyride from "react-joyride";


const DASHBOARDS = {
  USER: "user",
  ACTIVITY: "activity",
};

const translations = {
  en: {
    workflow: "Workflows",
    dashboard: "Dashboard",
    userdashboard: "User Dashboard",
    activitydashboard: "Activity Dashboard",
    exportPdf: "Export PDF",
  },
  ar: {
    workflow: "سير العمل",
    dashboard: "لوحة القيادة",
    userdashboard: "لوحة تحكم المستخدم",
    activitydashboard: "لوحة معلومات النشاط",
    exportPdf: "تصدير قوات الدفاع الشعبي",
  },
};

const Dashboard = () => {
  const navigate = useNavigate();
  const GoExport = () => {
    Cookies.set("viewMode", "dashboard");
    navigate("/pdf-generator");
  };

  const { theme, langMode, selectedOrgId, runTour ,dashboard} = useContext(AppContext);
  const [activeDashboard, setActiveDashboard] = useState(DASHBOARDS.USER);
  const [loading, setLoading] = useState(false);
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState("");
  const [selectedWorkflowName, setSelectedWorkflowName] = useState("");

  const userType = Cookies.get("userType");

  const handleWorkflowChange = (selectedName) => {
    const selectedWorkflow = workflows.find(
      (workflow) => workflow.workflowName === selectedName
    );
    if (selectedWorkflow) {
      setSelectedWorkflowName(selectedWorkflow.workflowName);
      setSelectedWorkflowId(selectedWorkflow.workflowId);
    }
  };

  const switchDashboard = (dashboard) => {
    setLoading(true);
    setTimeout(() => {
      setActiveDashboard(dashboard);
      setLoading(false);
    }, 300);
  };

  useEffect(() => {
    const loadWorkflows = async () => {
      try {
        const workflows = await fetchDynamicWorkflows(selectedOrgId);
        setWorkflows(Array.isArray(workflows) ? workflows : []);
      } catch (error) {
        console.error("Error loading workflows:", error);
        setWorkflows([]);
      }
    };
    loadWorkflows();
  }, [selectedOrgId]);

  return (
    <>
      {" "}
      <Joyride
        steps={dashboard}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
      />
      <div className="flex flex-col lg:flex-row gap-6  lg:justify-start">
        <Header subPage={translations[langMode].dashboard} />

        {userType === "super_admin" && (
          <div className="flex flex-wrap gap-3 my-5 chooseworkflow">
            <FilterSelect
              defaultText={translations[langMode].workflow}
              options={workflows.map((workflow) => ({
                id: workflow.workflowId,
                value: workflow.workflowName,
              }))}
              value={selectedWorkflowName}
              onChange={handleWorkflowChange}
            />
          </div>
        )}
      </div>
      <div className="flex justify-between items-center pb-4">
        <div className="inline-flex rounded-md shadow-sm ml-5" role="group">
          <button
            type="button"
            onClick={() => switchDashboard(DASHBOARDS.USER)}
            className={` px-4 py-2 text-sm font-medium flex items-center gap-2 rounded-l-lg focus:ring-2 focus:ring-blue-700 transition-all
    ${
      activeDashboard === DASHBOARDS.USER
        ? "bg-blue-500 text-white hover:bg-blue-600 backdrop-blur-sm" // Active tab: white text, blue background, blurred effect
        : "bg-gray-200 text-gray-600 hover:bg-gray-300" // Inactive tab: gray text, default background
    }
    ${
      theme === "dark" && activeDashboard === DASHBOARDS.USER
        ? "bg-blue-500 dark:text-white"
        : ""
    }`}
            aria-label="User Dashboard"
          >
            <FaUserCircle className="h-5 w-5" />
            {translations[langMode].userdashboard}
          </button>

          <button
            type="button"
            onClick={() => switchDashboard(DASHBOARDS.ACTIVITY)}
            className={`activitydashboard px-4 py-2 text-sm font-medium flex items-center gap-2 rounded-r-lg focus:ring-2 focus:ring-blue-700 transition-all
    ${
      activeDashboard === DASHBOARDS.ACTIVITY
        ? "bg-blue-500 text-white hover:bg-blue-600 backdrop-blur-sm" // Active tab: white text, blue background, blurred effect
        : "bg-gray-200 text-gray-600 hover:bg-gray-300" // Inactive tab: gray text, default background
    }
    ${
      theme === "dark" && activeDashboard === DASHBOARDS.ACTIVITY
        ? "bg-blue-500 dark:text-white"
        : ""
    }`}
            aria-label="Activity Dashboard"
          >
            <IoBagHandleSharp className="h-5 w-5" />
            {translations[langMode].activitydashboard}
          </button>
        </div>

        <button
          type="button"
          onClick={GoExport}
          className="importpdf mr-3 flex items-center gap-3 text-xs text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-500 font-medium rounded-lg px-6 py-1 transition-all"
        >
          <span className="bg-white rounded-full p-1">
            <CgExport className="w-4 h-4 text-yellow-500" />
          </span>
          {translations[langMode].exportPdf}
        </button>
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500"></div>
        </div>
      ) : activeDashboard === DASHBOARDS.USER ? (
        <UserDashboard
          selectedWorkflowId={selectedWorkflowId}
          selectedOrgId={selectedOrgId}
        />
      ) : (
        <ActivityDashboard />
      )}
    </>
  );
};

export default Dashboard;
