import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import AddChart from "./AddChart";
import AddDashboardModal from "./AddDashboardModal";
import ProtectedRoute from "../../../ProtecedRoutes/ProtectedRoute";
import {
  fetchDashboardNames,
  fetchWorkflows,
} from "../../../service/UserDashboard";
import AppContext from "../../../context/AppContext";
import { RiDashboard2Fill } from "react-icons/ri";
import Joyride from "react-joyride";
const translations = {
  en: {
    dashboardName:"Dashboard Name",
    addnewDashboard:"Add New Dashboard",
    chartMessage:" Select the Dashboard to view charts"
  },
  ar: {
    dashboardName:"اسم لوحة المعلومات",
    addnewDashboard:"إضافة لوحة تحكم جديدة",
    chartMessage:"حدد لوحة المعلومات لعرض المخططات"

  },
};
const ActivityDashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState("");
  const [selectedWorkflowId, setSelectedWorkflowId] = useState("");
  const [dashboardName, setDashboardName] = useState("");
  const [selectedrole, setSelectedRole] = useState("");
  const userType = Cookies.get("userType");
  const { selectedOrgId, langMode ,runTour ,activityDash } = useContext(AppContext);
  const [dashboardNames, setDashboardNames] = useState([]);
  const [dashboardNameInput, setDashboardNameInput] = useState("");
  const [roleInput, setRoleInput] = useState("");
  const [data, setData] = useState([]);

  const idForParam = userType === "super_admin" ? selectedOrgId : null;
  const handleDashboardNameChange = (event) => {
    const selectedDashboardId = event.target.value;
    const selectedRole =
      event.target.options[event.target.selectedIndex].getAttribute(
        "data-role"
      );
    setDashboardNameInput(selectedDashboardId);
    setRoleInput(selectedRole);
  };

  const handleFetchDashboardNames = () => {
    if (userType === "super_admin") {
      if (selectedOrgId) {
        fetchDashboardNames(idForParam, Cookies.get("accessToken"))
          .then((dashboardNames) => {
            setDashboardNames(dashboardNames);
          })
          .catch(() => {
            setDashboardNames([]);
          });
      }
    } else {
      fetchDashboardNames(idForParam, Cookies.get("accessToken"))
        .then((dashboardNames) => {
          setDashboardNames(dashboardNames);
        })
        .catch(() => {
          setDashboardNames([]);
        });
    }
  };

  const fetchActivityNames = async () => {
    try {
      const workflows = await fetchWorkflows(
        idForParam,
        Cookies.get("accessToken")
      );
      setData(workflows);
    } catch (error) {
      console.error("Error fetching workflows:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
   
    if (userType === "super_admin") {
      if (selectedOrgId) {
        setIsModalOpen(true);
        fetchActivityNames();
      } else {
        toast.warning("Please select organization");
      }
    } else {
      setIsModalOpen(true);
      fetchActivityNames();
    }
  };

  return (
    <>
    <Joyride
        steps={activityDash}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
        
      />
      <div className="">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-5 ml-5">
            <div className="dashboardname">
              <label
                htmlFor="organizationId"
                className="block text-sm mb-2 font-medium text-gray-900 dark:text-white"
              >
               {translations[langMode].dashboardName}
              </label>
              <select
                id="dashboardName"
                name="dashboardName"
                value={dashboardNameInput}
                onChange={handleDashboardNameChange}
                onClick={handleFetchDashboardNames}
                className="w-60 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="">Select</option>
                {dashboardNames.map((dashboard) => (
                  <option
                    key={dashboard.activityDashboardId}
                    value={dashboard.activityDashboardId}
                    data-role={dashboard.role}
                  >
                    {dashboard.dashboardName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <ProtectedRoute privilegesRequired={["canCreateDashboard"]}>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={showModal}
                className="createdashboard text-white py-2 px-2 mr-3 bg-blue-800 mt-5 gap-1 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-[#FF9119]/50 font-medium rounded-lg text-xs  text-center inline-flex items-center "
              >
                <RiDashboard2Fill className="h-4 w-4" /> {translations[langMode].addnewDashboard}
              </button>
            </div>
          </ProtectedRoute>
        </div>
        <div className="mt-5 p-5  text-center">
          {!dashboardNameInput && (
            <h2 className="text-md font-semibold text-gray-900">
               {translations[langMode].chartMessage}
            </h2>
          )}
        </div>

        {isModalOpen && (
          <AddDashboardModal
            onClose={handleCloseModal}
            organizations={organizations}
            setOrganizations={setOrganizations}
            selectedOrganizationId={selectedOrganizationId}
            setSelectedOrganizationId={setSelectedOrganizationId}
            selectedWorkflowId={selectedWorkflowId}
            setSelectedWorkflowId={setSelectedWorkflowId}
            dashboardName={dashboardName}
            setDashboardName={setDashboardName}
            selectedrole={selectedrole}
            setSelectedRole={setSelectedRole}
            selectedOrgId={selectedOrgId}
            data={data}
          />
        )}
        <AddChart
          selectedOrgId={selectedOrgId}
          dashboardNames={dashboardNames}
          setDashboardNames={setDashboardNames}
          dashboardNameInput={dashboardNameInput}
          setDashboardNameInput={setDashboardNameInput}
          roleInput={roleInput}
        />
      </div>
    </>
  );
};

export default ActivityDashboard;
