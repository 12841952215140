import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "../components/_app/Layout";
import AddAsset from "../pages/asset/AddAsset";
import AssetList from "../pages/asset/AssetList";
import LoginPage from "../pages/auth/LoginPage";
import OtpVerification from "../pages/auth/OtpVerification";
import ResetPasswordPage from "../pages/auth/ResetPasswordPage";
import ConfigurationMain from "../pages/configuration/ConfigurationMain";
import LeaderboardMain from "../pages/leaderboard/LeaderboardMain";
import LeaderboardPreview from "../pages/leaderboard/LeaderboardPreview";
import TaskManageMain from "../pages/map1/TaskManageMain";
import NotificationSection from "../pages/profile/NotificationSection";
import ProfileSection from "../pages/profile/ProfileSection";
import Settings from "../pages/profile/Settings";
import ReportList from "../pages/reports/ReportList";
import CreateTask from "../pages/task/CreateTask";
import ImportCsv from "../pages/task/ImportCsv";
import TaskDetailPage from "../pages/task/TaskDetailPage";
import AddUser from "../pages/users/AddUser";
import UserList from "../pages/users/UserList";
import WorkflowMain from "../pages/workflow/WorkflowMain";
import ExportData from "../pages/worklog/ExportData";
import WorkLogMain from "../pages/worklog/WorkLogMain";
import DynamicWorkFlow from "./../pages/workflow/DynamicWorkFlow";
import Guidline from "../pages/Guidlines/Guidline";
import ProtectedRoute from "../ProtecedRoutes/ProtectedRoute";
import AddVehical from "../pages/asset/AddVehical";
import UpdateVehical from "../pages/asset/UpdateVehical";
import UpdateAssrt from "../pages/asset/UpdateAssrt";
import GetDetails from "../pages/workflow/Updateworkflow/GetDetails";
import PdfGenerator from "../pages/PDF_Generator/PdfGenerator";
import Dashboard from "../pages/Dashboard/Dashboard";
import { Security } from "../components/Security/Security";
import { CreateAccount } from "../components/Security/CreateAccount";
import { ProtectionForm } from "../components/Security/ProtectionForm";
import { InstallmentPlan } from "../components/InstallmentPlan/InstallmentPlan";
// import MapTask from "../components/task/MapTask";
import TaskListView from "../components/task/TaskListView";
import OrgnizationList from "../components/Orgnization/OrgnizationList";
import MapViewTask from "../components/task/MapViewTask";

const Router = () => {
  const [user] = useState(null);

  const loggedIn = window.localStorage.getItem("isLoggedIn");
  return (
    <Routes>
      <Route
        path="/"
        element={
          loggedIn ? (
            <Layout>
              <Dashboard />
            </Layout>
          ) : (
            <LoginPage />
          )
        }
      />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/otp-verify" element={<OtpVerification />} />
      <Route
        path="/dashboard"
        element={
          <Layout>
            <Dashboard />
          </Layout>
        }
      />
      <Route
        path="/map"
        element={
          <Layout>
            <MapViewTask />
          </Layout>
        }
      />
      <Route
        path="/pdf-generator"
        element={
          <Layout>
            <PdfGenerator />
          </Layout>
        }
      />
      <Route
        path="/task-detail/:taskId"
        element={
          <Layout>
            <TaskDetailPage />
          </Layout>
        }
      />

      <Route
        path="/*"
        element={
          <Routes>
            <Route
              path="all-users"
              element={
                <Layout>
                  <UserList />
                </Layout>
              }
            />
            <Route
              path="/add-user"
              element={
                <ProtectedRoute
                  privilegesRequired={["canAddEditUser"]}
                  user={user}
                >
                  <Layout>
                    <AddUser />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/add-user/:userId"
              element={
                <ProtectedRoute privilegesRequired={["canAddEditUser"]}>
                  <Layout>
                    <AddUser />
                  </Layout>
                </ProtectedRoute>
              }
            />
          </Routes>
        }
      />
      <Route
        path="/guidelines"
        element={
          <ProtectedRoute
            privilegesRequired={["canModifyGuidelines"]}
            user={user}
          >
            <Layout>
              <Guidline />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="user-profile"
        element={
          <Layout>
            <ProfileSection />
          </Layout>
        }
      />
      <Route
        path="settings"
        element={
          <Layout>
            <Settings />
          </Layout>
        }
      />
      <Route
        path="notification"
        element={
          <Layout>
            <NotificationSection />
          </Layout>
        }
      />

      <Route
        path="/task/*"
        element={
          <Routes>
            <Route
              path="import-csv"
              element={
                <Layout>
                  <ImportCsv />
                </Layout>
              }
            />
            <Route
              path="create-task"
              element={
                <Layout>
                  <CreateTask />
                </Layout>
              }
            />
          </Routes>
        }
      />
      <Route
        path="/asset"
        element={
          <ProtectedRoute privilegesRequired={["canAccessAssetManagement"]}>
            <Layout>
              <AssetList />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-asset"
        element={
          <Layout>
            <AddAsset />
          </Layout>
        }
      />
      <Route
        path="/update-assert/:assertId"
        element={
          <Layout>
            <UpdateAssrt />
          </Layout>
        }
      />
      <Route
        path="/add-vehical"
        element={
          <Layout>
            <AddVehical />
          </Layout>
        }
      />
      <Route
        path="/update-vehicle/:vehicleId"
        element={
          <Layout>
            <UpdateVehical />
          </Layout>
        }
      />
      <Route
        path="/configuration"
        element={
          <Layout>
            <ConfigurationMain />
          </Layout>
        }
      />

      <Route
        path="/workflow"
        element={
          <Layout>
            <WorkflowMain />
          </Layout>
        }
      />
      <Route
        path="/workflow-form"
        element={
          <ProtectedRoute privilegesRequired={["canAddEditActivityTemplates"]}>
            <Layout>
              <DynamicWorkFlow />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/worklog"
        element={
          <Layout>
            <WorkLogMain />
          </Layout>
        }
      />
      <Route
        path="/task-manage"
        element={
          <Layout>
            <TaskManageMain />
          </Layout>
        }
      />

      {/* <Route
        path="/leaderboard"
        element={
          <Layout>
            <LeaderboardMain />
          </Layout>
        }
      /> */}
      <Route
        path="/leaderboard"
        element={
          <ProtectedRoute privilegesRequired={["canAddEditLeaderboard"]}>
            <Layout>
              <LeaderboardMain />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/leaderboard-preview"
        element={
          <Layout>
            <LeaderboardPreview />
          </Layout>
        }
      />
      <Route
        path="/reports"
        element={
          <Layout>
            <ReportList />
          </Layout>
        }
      />
      <Route
        path="/task-detail"
        element={
          <Layout>
            <TaskDetailPage />
          </Layout>
        }
      />
      <Route
        path="/export"
        element={
          <Layout>
            <ExportData />
          </Layout>
        }
      />
      <Route
        path="/get-workflow-details/:workflowId"
        element={
          <Layout>
            <GetDetails />
          </Layout>
        }
      />
      <Route
        path="/security"
        element={
          <Layout>
            <Security />
          </Layout>
        }
      />
      <Route
        path="/createAccount"
        element={
          <Layout>
            <CreateAccount />
          </Layout>
        }
      />
      <Route
        path="/createAccount/:userId"
        element={
          <Layout>
            <CreateAccount />
          </Layout>
        }
      />
      <Route
        path="/special-form"
        element={
          <Layout>
            <ProtectionForm />
          </Layout>
        }
      />
      <Route
        path="/installment-plan"
        element={
          <Layout>
            <InstallmentPlan />
          </Layout>
        }
      />
      <Route
        path="/maptask"
        element={
          <Layout>
            <MapViewTask />
          </Layout>
        }
      />

      <Route
        path="/listview"
        element={
          <Layout>
            <TaskListView />
          </Layout>
        }
      />

      <Route
        path="/organization"
        element={
          <Layout>
            <OrgnizationList />
          </Layout>
        }
      />
    </Routes>
  );
};

export default Router;
