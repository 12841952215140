import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/_app/Header";
import EmailReport from "../../components/worklog/EmailReport";
import { WorkLogCard } from "../../components/worklog/WorkLogCard";
import WorkLogToolbar from "../../components/worklog/WorkLogToolbar";
import AppContext from "../../context/AppContext";
import { fetchWorkLogs } from "../../service/Worklog";
const translation = {
  en: {
    worklog:"Worklog",
    todo: "To do",
    progress: "In Progress",
    completed: "Completed",
    loading: "Loading more worklog...",
    nomore: "No more worklog to load",
  },
  ar: {
    todo: "للقيام به",
    worklog:"سجل العمل",
    progress: "في تَقَدم",
    completed: "مكتمل",
    loading: "جاري تحميل المزيد من سجل العمل...",
    nomore: "لا مزيد من سجل العمل للتحميل",
  },
};
const WorkLogMain = () => {
  const [open, setOpen] = useState(false);
  const [todos, setTodos] = useState([]);
  const [inProgress, setInProgress] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [selectedPriority, setSelectedPriority] = useState("Low_Priority");
  const [todoTotalCount, setTodoTotalCount] = useState(0);
  const [inProgressTotalCount, setInProgressTotalCount] = useState(0);
  const [completedTotalCount, setCompletedTotalCount] = useState(0);
  const { workflowId, selectedOrgId, setSelectedOrgId ,langMode } =
    useContext(AppContext);
  const { theme } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const formatDate = (date, time) => {
    return date.toISOString().split("T")[0] + time;
  };
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const defaultFromDate = formatDate(yesterday, "T00:00:00");
  const defaultToDate = formatDate(today, "T23:59:59");
  const [dates, setDates] = useState({
    fromDate: defaultFromDate,
    toDate: defaultToDate,
  });
  const [searchAccountNumber, setSearchAccountNumber] = useState("");

  const fetchData = async (page, size, reset = false) => {
    setIsLoading(true);
    try {
      const data = await fetchWorkLogs({
        page,
        size,
        workflowId,
        selectedOrgId,
        selectedPriority,
        dates,
        accountNo: searchAccountNumber,
      });
      const {
        toDo,
        inProgress,
        completed,
        toDoTotalCount,
        inProgressTotalCount,
        completedTotalCount,
      } = data;
      if (
        Array.isArray(toDo) &&
        Array.isArray(inProgress) &&
        Array.isArray(completed)
      ) {
        if (reset) {
          setTodos(toDo);
          setInProgress(inProgress);
          setCompleted(completed);
        } else {
          setTodos((prevTodos) =>
            Array.isArray(prevTodos)
              ? [...new Set([...prevTodos, ...toDo])]
              : [...toDo]
          );
          setInProgress((prevInProgress) =>
            Array.isArray(prevInProgress)
              ? [...new Set([...prevInProgress, ...inProgress])]
              : [...inProgress]
          );
          setCompleted((prevCompleted) =>
            Array.isArray(prevCompleted)
              ? [...new Set([...prevCompleted, ...completed])]
              : [...completed]
          );
        }
      } else {
        console.error(
          "Invalid data format: toDo, inProgress, or completed is not an array"
        );
      }
      setTodoTotalCount(toDoTotalCount);
      setInProgressTotalCount(inProgressTotalCount);
      setCompletedTotalCount(completedTotalCount);
    } catch (error) {
      console.error("Error fetching work logs:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPage(0);
    setSize(10);
    setTodos([]);
    setInProgress([]);
    setCompleted([]);
    fetchData(0, 10, true);
  }, [selectedOrgId, selectedPriority, workflowId, dates, searchAccountNumber]);

  useEffect(() => {
    if (page === 0) return;
    fetchData(page, size);
  }, [page]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 2
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Header pageName={translation[langMode].worklog} />
      <div
        className={`${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        } px-5 py-1 bg-white`}
      >
        <WorkLogToolbar
          setOpen={setOpen}
          selectedOrgId={selectedOrgId}
          setSelectedOrgId={setSelectedOrgId}
          setSelectedPriority={setSelectedPriority}
          setDates={setDates}
          setSearchAccountNumber={setSearchAccountNumber}
        />
        <div className="grid grid-cols-3 gap-12 shadow-md py-5">
          <div className="border-dashed border-2 border-blue-700 px-5 py-5">
            <h6 className="text-sm text-blue-700 font-semibold">
            {translation[langMode].todo} ({todoTotalCount})
            </h6>
            <WorkLogCard workLogs={todos} />
          </div>
          <div className="border-dashed border-2 border-red-600 px-5 py-5">
            <h6 className="text-sm text-red-600 font-semibold">
            {translation[langMode].progress}({inProgressTotalCount})
            </h6>
            <WorkLogCard workLogs={inProgress} />
          </div>
          <div className="border-dashed border-2 border-green-500 px-5 py-5">
            <h6 className="text-sm text-green-500 font-semibold">
              {translation[langMode].completed} ({completedTotalCount})
            </h6>
            <WorkLogCard workLogs={completed} />
          </div>
        </div>
        {isLoading && (
          <div className="text-center py-4">
            <p>{translation[langMode].loading}</p>
          </div>
        )}

        {!isLoading && (
          <div className="text-center py-4">
            <p>{translation[langMode].nomore}</p>
          </div>
        )}
      </div>
      <EmailReport open={open} setOpen={setOpen} />
    </>
  );
};

export default WorkLogMain;
