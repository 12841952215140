import React, { useContext } from "react";
import { IoMdAddCircle, IoMdClipboard } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import ProtectedRoute from "../../ProtecedRoutes/ProtectedRoute";
import Joyride from "react-joyride";
const translations = {
  en: {
    viewGuideline: "View Guideline",
    createWorkflow: "Create new Workflow",
    selectOrgWarning: "Select organization first",
  },
  ar: {
    viewGuideline: "عرض الإرشادات",
    createWorkflow: "إنشاء سير عمل جديد",
    selectOrgWarning: "حدد المنظمة أولاً",
  }
};

const WorkflowMainToolbar = () => {
  const { theme, selectedOrgId ,langMode ,workflow ,runTour } = useContext(AppContext);
  const userType = Cookies.get("userType");
  const navigate = useNavigate();
  const handleWorkflowscreen = () => {
    if (!selectedOrgId && userType === "super_admin") {
      toast.warn("select orgnization first");
      return;
    }
    navigate("/workflow-form");
  };
  const handleNavigate = () => {
    navigate("/guidelines");
  };
  return (
    <>
    <Joyride
        steps={workflow}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
        styles={{
          options: {
            zIndex: 10000, // Ensure Joyride is on top of other elements
          },
        }}
      />
      <div className="flex justify-between items-center">
        <div className="flex items-center ps-4  dark:border-gray-700"></div>
        <div className="flex items-center gap-2">
          {/* <ProtectedRoute privilegesRequired={["canModifyGuidelines"]}> */}
            <button
              type="button"
              className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={handleNavigate}
            >
              <IoMdClipboard className="w-5 h-5 mr-2" />  {translations[langMode].viewGuideline}
            </button>
          {/* </ProtectedRoute> */}
          {/* <ProtectedRoute privilegesRequired={["canAddEditActivityTemplates"]}> */}
            <button
              type="button"
              className="createworkflow px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={handleWorkflowscreen}
            >
              <IoMdAddCircle className="w-5 h-5 mr-2" /> {translations[langMode].createWorkflow}
            </button>
          {/* </ProtectedRoute> */}
        </div>
      </div>
    </>
  );
};

export default WorkflowMainToolbar;
