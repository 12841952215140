import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from "../_app/Header";
import CommonInputField from "../common/CommonInputField";

const validationSchema = Yup.object({
  advanceAmount: Yup.number()
    .required("Advance amount is required")
    .positive("Must be a positive number"),
  advanceDate: Yup.date().required(
    "Date to pay the advance amount is required"
  ),
  firstInstallmentDate: Yup.date().required(
    "Date for the first installment is required"
  ),
  numberOfMonths: Yup.number()
    .required("Number of months is required")
    .positive("Must be a positive number")
    .integer("Must be an integer"),
});

export const InstallmentPlan = () => {
  const formik = useFormik({
    initialValues: {
      advanceAmount: "",
      advanceDate: "",
      firstInstallmentDate: "",
      numberOfMonths: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const { advanceAmount, firstInstallmentDate, numberOfMonths } = values;
      const totalAmount = 10000; // Example total amount, replace with actual value
      const remainingAmount = totalAmount - advanceAmount;
      const monthlyInstallment = remainingAmount / numberOfMonths;
      const installments = Array.from(
        { length: numberOfMonths },
        (_, index) => {
          const date = new Date(firstInstallmentDate);
          date.setMonth(date.getMonth() + index);
          return {
            date: date.toDateString(),
            amount: monthlyInstallment.toFixed(2),
          };
        }
      );
    },
  });

  return (
    <div>
      <Header pageName={"Installment Plan"} />
      <div className="bg-white p-5">
        <div className="w-full max-w-md mx-auto">
          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col gap-6">
              <div>
                <CommonInputField
                  label="Advance Amount"
                  name="advanceAmount"
                  type="number"
                  field={formik.getFieldProps('advanceAmount')}
                  form={formik}
                />
              </div>
              <div>
                <label className="block text-xs mb-1">
                  Date to Pay Advance Amount
                </label>
                <input
                  type="date"
                  className="w-full h-12 border border-gray-300 rounded px-3"
                  name="advanceDate"
                  value={formik.values.advanceDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.advanceDate && formik.errors.advanceDate ? (
                  <div className="text-red-600 text-xs mt-1">
                    {formik.errors.advanceDate}
                  </div>
                ) : null}
              </div>
              <div>
                <label className="block text-xs mb-1">
                  Date for the First Installment
                </label>
                <input
                  type="date"
                  className="w-full h-12 border border-gray-300 rounded px-3"
                  name="firstInstallmentDate"
                  value={formik.values.firstInstallmentDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.firstInstallmentDate &&
                formik.errors.firstInstallmentDate ? (
                  <div className="text-red-600 text-xs mt-1">
                    {formik.errors.firstInstallmentDate}
                  </div>
                ) : null}
              </div>
              <div>
                <CommonInputField
                  label="Number of Months"
                  name="numberOfMonths"
                  type="number"
                  field={formik.getFieldProps('numberOfMonths')}
                  form={formik}
                />
              </div>
              <div className="pt-5 flex justify-end">
                <button
                  type="submit"
                  className="px-4 py-2 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
