import React, { useEffect, useState } from "react";
import AppContext from "./AppContext";
import axios from "axios";
import Cookies from "js-cookie";
import Joyride, { STATUS } from "react-joyride";
const AppState = (props) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [multiselectedOption, setMultiSelectedOption] = useState([]);
  const [workflowId, setWorkflowId] = useState();
  const [priority, setPriority] = useState("Priority");
  const [active, setActive] = useState("Status");
  const [isDraggable, setIsDraggable] = useState(true);
  const [taskData, setTaskData] = useState([]);
  const [taskDataHistory, settaskDataHistory] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [areaCode, setAreaCode] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [fieldAgentList, setFieldAgentList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [admincount, setAdminCount] = useState();
  const [fieldagentcount, setFieldAgentCount] = useState();
  const [officeuser, setOfficeUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalUnreadMsgCount, setTotalUnreadMsgCount] = useState(0);
  const [view, setView] = useState("graph");
  const [particularAccount, setParticularAccount] = useState([]);
  const [isEditRequest, setIsEditRequest] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const [workflowName, setWorkflowName] = useState("");
  const [selectedOrgName, setSelectedOrgName] = useState("Outbox");
  const [noMoreNotifications, setNoMoreNotifications] = useState(false);
  const [fieldAgents, setFieldAgents] = useState([]);
  const [subactivity, setSubActivity] = useState([]);
  const [selectedSubactivity, setSelectedSubactivity] = useState("");
  const [getScheduleReportDetails, setgetScheduleReportDetails] = useState();
  const [runTour, setRunTour] = useState(false);
  useEffect(() => {
    const userLoggedIn = Cookies.get("FirstLogin"); // Replace this with your actual login check

    // if (userLoggedIn === "true") {
    //   setRunTour(true);
    // }
  }, []);
  const resetDropdown = () => {
    setWorkflowId();
    setSelectedOption("");
  };
  const [user, setUser] = useState(() => {
    const savedUserDetails = sessionStorage.getItem("userDetails");
    return savedUserDetails ? JSON.parse(savedUserDetails) : null;
  });

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [langMode, setLangMode] = useState(
    localStorage.getItem("langMode") || "en"
  );

  useEffect(() => {
    localStorage.setItem("theme", theme); // Save theme to local storage
    document.body.className = theme; // Apply the class to body
  }, [theme]); // This effect runs when 'theme' changes

  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
  };

  const toggleLangMode = (newLangMode) => {
    setLangMode(newLangMode);
    localStorage.setItem("langMode", newLangMode); // Save language mode to local storage
  };

  useEffect(() => {
    localStorage.setItem("langMode", langMode); // Save theme to local storage
    document.body.className = langMode; // Apply the class to body
  }, [langMode]); // This effect runs when 'theme' changes

  const fetchNotifications = async () => {
    setIsLoading(true);
    const token = Cookies.get("accessToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/notification/get-all?page=${currentPage}&size=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (
        response.data.body &&
        Array.isArray(response.data.body.value.notificationList)
      ) {
        const newNotifications = response.data.body.value.notificationList;

        const unreadNotificationIds = newNotifications
          .filter((notification) => notification.isRead === 0)
          .map((notification) => notification.notificationId);

        // Update read status for new notifications
        if (unreadNotificationIds.length > 0) {
          await updateReadStatus(unreadNotificationIds);
        }

        setNotifications((prevNotifications) => {
          const combinedNotifications = [
            ...prevNotifications,
            ...newNotifications,
          ];
          const uniqueNotifications = combinedNotifications.filter(
            (notification, index, self) =>
              index ===
              self.findIndex(
                (t) => t.notificationId === notification.notificationId
              )
          );
          return uniqueNotifications;
        });
      } else {
        console.error(
          "API response does not contain expected data structure:",
          response.data
        );
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateNotificationStatus = (notificationId, isApproved) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.notificationId === notificationId
          ? { ...notification, isApproved }
          : notification
      )
    );
  };

  const updateNotificationReportStatus = (notificationId) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.notificationId === notificationId
          ? { ...notification, isAcknowledged: 1 }
          : notification
      )
    );
  };
  const updateReadStatus = async (notificationIds) => {
    const token = Cookies.get("accessToken");
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/notification/read-status`,
        { notificationIdList: notificationIds },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error updating notification read status:", error);
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
    }
  };
  const menu = [
    {
      target: ".seting",
      content: "Click here to go settings!",
    },
    {
      target: ".notification",
      content: "Click here to see notifications!",
    },
    {
      target: ".menu",
      content: "click here for the menus!",
    },
    {
      target: ".org",
      content: "Select organization form here!",
    },
    {
      target: ".activity",
      content: "Select Activity from here!",
    },
  ];
  const dashboard = [
    {
      target: ".chooseworkflow",
      content: "Choose the workflow to see the charts!",
    },
    {
      target: ".activitydashboard",
      content: "Click here to create custom dashboard!",
    },
    {
      target: ".importpdf",
      content: "From here u can download the PDF!",
    },
  ];
  const activityDash = [
    {
      target: ".dashboardname",
      content: "Click here to select Dashboard name!",
    },
    {
      target: ".createdashboard",
      content: "Click here to create dashboards!",
    },
  ];
  const workflow = [
    {
      target: ".createworkflow",
      content: "Click here for creating dynamic workflow!",
    },
    {
      target: ".cloneworkflow",
      content: "Click here for clone the dynamic workflow!",
    },
    {
      target: ".editworkflow",
      content: "Click here for edit the existing dynamic workflow!",
    },
    {
      target: ".deleteworkflow",
      content: "Click here for delete the dynamic workflow!",
    },
  ];
  const workflowmaker = [
    {
      target: ".fields",
      content: "Drag the field from here!",
    },
    {
      target: ".droparea",
      content: "Drop the selected fields in droppable area!",
    },
    {
      target: ".mandtory",
      content: "check this to make field mandatory!",
    },
    {
      target: ".excel",
      content: "check this to add excel name to this field!",
    },
    {
      target: ".pdf",
      content: "check this to add PDF name to this field!",
    },
  ];

  const tasktollbar = [
    {
      target: ".download",
      content: "Click here to download the uploaded task sheet!",
    },
    {
      target: ".deleteAll",
      content: "Click here to delete the uploaded tasklist!",
    },
    {
      target: ".createTask",
      content: "Click here to create task manually!",
    },
    {
      target: ".gocsv",
      content: "Click here to upload the task list!",
    },
  ];
  const maptoolbar = [
    {
      target: "multiselect",
      content: "Click here to drow and select the tasks!",
    },
    {
      target: "smartAssign",
      content: "Click here to assign the assignment rules!",
    },
    {
      target: "fieldagent",
      content: "Click here to see field agent list!",
    },
    {
      target: ".createTasks",
      content: "Click here to create task manually!",
    },
    {
      target: ".gocsvs",
      content: "Click here to upload the task list!",
    },
  ];
  const workLogtoolbar = [
    {
      target: ".exportreport",
      content: "Click here to download excel report!",
    },
    {
      target: ".exportpdf",
      content: "Click here to download PDF !",
    },
  ];

  const toggle = [
    { 
    target: ".menus",
    content: "Click here to view menus"
  }
  ];
  return (
    <AppContext.Provider
      value={{
        toggle,
        activityDash,
        workLogtoolbar,
        maptoolbar,
        tasktollbar,
        handleJoyrideCallback,
        workflowmaker,
        workflow,
        dashboard,
        menu,
        getScheduleReportDetails,
        setgetScheduleReportDetails,
        selectedSubactivity,
        setSelectedSubactivity,
        setFieldAgents,
        fieldAgents,
        selectAll,
        setSelectAll,
        selectedOption,
        setSelectedOption,
        workflowId,
        setWorkflowId,
        setPriority,
        priority,
        active,
        setActive,
        areaCode,
        setAreaCode,
        setAccountNo,
        accountNo,
        setIsDraggable,
        isDraggable,
        setTaskData,
        taskData,
        settaskDataHistory,
        setFieldAgentList,
        fieldAgentList,
        setSelectedUserId,
        selectedUserId,
        taskDataHistory,
        profilePic,
        setProfilePic,
        setNotifications,
        notifications,
        multiselectedOption,
        setMultiSelectedOption,
        user,
        setUser,
        theme,
        toggleTheme,
        fetchNotifications,
        isLoading,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
        updateNotificationStatus,
        updateNotificationReportStatus,
        totalUnreadMsgCount,
        setTotalUnreadMsgCount,
        setView,
        view,
        admincount,
        setAdminCount,
        fieldagentcount,
        setFieldAgentCount,
        officeuser,
        setOfficeUser,
        setParticularAccount,
        particularAccount,
        setIsEditRequest,
        isEditRequest,
        setSelectedOrgId,
        selectedOrgId,
        workflowName,
        setWorkflowName,
        selectedOrgName,
        setSelectedOrgName,
        noMoreNotifications,
        setNoMoreNotifications,
        subactivity,
        setSubActivity,
        resetDropdown,
        langMode,
        setLangMode,
        toggleLangMode,
        runTour,
        setRunTour,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
