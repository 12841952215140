import React, { useState } from "react";
import { useContext } from "react";
import AppContext from "../../../../context/AppContext";

export default function ChartModal({ showModal, setShowModal, setChartView }) {
  const context = useContext(AppContext);
  const [selectedChart, setSelectedChart] = useState(null);
  const { setView , theme , langMode} = context;

  const translations = {
    en: {
      chartModal:"Choose Chart",

    },
    ar: {
      chartModal:"اختر الرسم البياني"
    },
  };
  
  const chartInfo = {
    PIECHART: {
      name: "Pie Chart",
      imageUrl: "/chart/piechart.png",
      verify: "chart",
    },
    BARCHART: {
      name: "Bar Chart",
      imageUrl: "/chart/bar.png",
      verify: "graph",
    },
    AREACHART: {
      name: "Area Chart",
      imageUrl: "/chart/areachart.png",
      verify: "area",
    },
    RADARCHART: {
      name: "Radar Chart",
      imageUrl: "/chart/radar.png",
      verify: "radar",
    },
  };

  const handleChangeView = (verify) => {
    setView(verify);
    setChartView(verify);
    setShowModal(false);
    setSelectedChart(verify);
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 bg-black bg-opacity-50 outline-none focus:outline-none">
            <div className="relative w-full max-w-2xl mx-auto">
              <div className={`rounded-lg shadow-lg bg-white  ${
                     theme === "dark"
                       ? "dark-mode border "
                       : theme === "high-contrast"
                       ? "high-contrast"
                       : ""
                   }`}>
                <div className="flex items-center justify-between p-4 border-b">
                  <h3 className={` ${
                     theme === "dark"
                       ? "dark-mode "
                       : theme === "high-contrast"
                       ? "high-contrast"
                       : ""
                   }text-xl font-semibold text-gray-700`}>{translations[langMode].chartModal}</h3>
                  <button
                    className="text-gray-500 hover:text-red-600 transition-colors"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-2xl">×</span>
                  </button>
                </div>
                <div className="p-6">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    {Object.entries(chartInfo).map(
                      ([key, { name, imageUrl, verify }]) => (
                        <div
                          key={key}
                          className={`cursor-pointer flex flex-col items-center bg-white border-2 rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow duration-300 ${
                            selectedChart === verify
                              ? "border-blue-500 shadow-lg"
                              : "border-gray-200 hover:border-blue-300"
                          }  ${
                     theme === "dark"
                       ? "dark-mode "
                       : theme === "high-contrast"
                       ? "high-contrast"
                       : ""
                   }`}
                          onClick={() => handleChangeView(verify)}
                        >
                          <img
                            src={imageUrl}
                            alt={name}
                            className="h-24 w-24 mb-2 rounded"
                          />
                          <span
                            className={`text-lg font-medium ${
                              selectedChart === verify
                                ? "text-blue-500"
                                : "text-gray-700"
                            }`}
                          >
                            {name}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="flex justify-end p-4 border-t">
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded-md shadow hover:bg-red-600 transition-colors"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
