import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { LuMoreVertical } from "react-icons/lu";
import { toast } from "react-toastify";
import {  assignVehicle } from "../../service/Asset";
import { fetchFieldAgentMap } from "../../service/Task";
import AppContext from "../../context/AppContext";
import { RxCross2 } from "react-icons/rx";
const AssignVehical = ({
  open,
  setOpen,
  selectedtask,
  setSelectedItems,
  taskData,
  isChecked,
  setIsChecked,
  tableData,
  selectedItems,
  setTableData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const { selectedOrgId,setFieldAgents,fieldAgents } = useContext(AppContext);
  const [error, setError] = useState(null);
  const closeModal = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    if (selectedItems.length === 0) {
      toast.error("Please select at least one asset before assigning.");
    } else {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    const fetchAgents = async () => {
      setLoading(true);
      if(selectedOrgId){
        try {
          const agents = await fetchFieldAgentMap(selectedOrgId);
          setFieldAgents(agents.body.value);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchAgents();
  }, [selectedOrgId]);

  const handleAssignVehical = async () => {
    if (!selectedAgent) {
      toast.error("Please select a field agent.");
      return;}
    setIsSubmitting(true);
    try {
      if (selectedItems.length > 0) {
        const { id, vehicleId } = selectedItems[0];
        const data = await assignVehicle(id, vehicleId, selectedAgent);
        if (data.header.code === 600) {
          toast.success(data.body.value);
          setSelectedAgent(null);
          setIsOpen(false);
          setIsChecked(false);
          setSelectedItems([]);
          const updatedTableData = tableData.map((asset) =>
            asset.id === id ? { ...asset, isChecked: false } : asset
          );
          setTableData(updatedTableData);
        } else if (data.header.code === 601) {
          toast.error(data.body.value);
          setSelectedAgent(null);
          setIsOpen(false);
          setSelectedItems([]);
        } else {
          toast(data.body.value);
        }}
    } catch (error) {
      console.error("Failed to assign asset:", error);
    } finally {
      setIsSubmitting(false);
    }};
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <>
      <button onClick={openModal} className="p-2 rounded-full px-4">
        <LuMoreVertical />
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0" >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-center gap-10 p-2 md:p-5 rounded-t border-b dark:border-gray-600">
                      <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                        Field Staff List
                      </h3>
                      <button
                        type="button"
                        disabled={isSubmitting}
                        onClick={handleAssignVehical}
                        className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        {isSubmitting ? "Assigning..." : "Assign"}
                      </button>
                      <button
                        type="button"
                        className="ml-auto inline-flex justify-center items-center px-3 py-2 text-xs font-medium text-gray-500  focus:ring-1 focus:outline-none focus:ring-blue-300 "
                        onClick={closeModal}
                      >
                        <RxCross2/>
                      </button>
                    </div>
                    <div className="p-2 md:p-5 space-y-4">
                      {Array.isArray(fieldAgents) &&
                        fieldAgents.map((agent) => (
                          <div
                            key={agent.userId}
                            className="bg-white p-3 border border-gray-300 mt-2" >
                            <div className="flex items-center">
                              <input
                                id={`agent-${agent.userId}`}
                                type="radio"
                                value={agent.userId}
                                name="fieldAgent"
                                checked={selectedAgent === agent.userId}
                                onChange={() => setSelectedAgent(agent.userId)}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor={`agent-${agent.userId}`}
                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300" >
                                {agent.name}
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AssignVehical;
