import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { IoEye } from "react-icons/io5";
import ChartModal from "./ChartModal";
import { fetchTaskStatusCount } from "../../../../service/UserDashboard";
import { FaTasks } from "react-icons/fa";
import AppContext from "../../../../context/AppContext";
const translations = {
  en: {
    taskstatus:"Task Status",
    select:"Please select an activity"
  },
  ar: {
    taskstatus:"حالة المهمة",
    select:"الرجاء تحديد نشاط"  },
};
const TaskStatusChart = ({ selectedWorkflowId }) => {
  const {langMode ,theme} = useContext(AppContext)
  const [taskStatus, setTaskStatus] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [chartView, setChartView] = useState("bar"); // Default to donut chart view
  const Piecolors = ["#E72929", "#03AED2", "#FF9800", "#C738BD", "#41B06E"];
  const colors = ["#399918", "#EF5A6F", "#674188", "#FFD433", "#FF0000"];
  const formatLabel = (label) => {
    switch (label) {
      case "onHold":
        return "On Hold";
      case "inProgress":
        return "In Progress";
      case "active":
        return "Active";
      case "assigned":
        return "Assigned";
      case "completed":
        return "Completed";
      default:
        return label;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchTaskStatusCount(selectedWorkflowId);
      setTaskStatus(data);
    };
    fetchData();
  }, [selectedWorkflowId]);

  const ChartView = ({ series, options }) => (
    <div id="chart" className="w-full">
      <ReactApexChart
        options={{
          ...options,
          dataLabels: {
            enabled: true, // Hide data labels
          },
          toolbar: {
            show: false, // Hide the toolbar to remove download options
          },
          legend: {
            show: true, // Hide legend
            position: 'bottom',
          },
          tooltip: {
            enabled: true, // Hide tooltips
          },
        }}
        series={series}
        type="donut"
        width="390"
      
      />
    </div>
  );

  const GraphView = ({ series, options }) => {
    const graphSeries = [
      {
        name: "Task Status Count",
        data: series,
      },
    ];

    const graphOptions = {
      ...options,
      chart: {
        ...options.chart,
        type: "bar", // Changing the chart type to bar for graph view
        toolbar: {
          show: false, // Hide the toolbar to remove download options
        },
      },
      xaxis: {
        categories: Object.keys(taskStatus).map(formatLabel),
      },
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: true, // Ensures each bar has a different color
        },
      },
      colors: colors,
      dataLabels: {
        enabled: true, // Show data labels
      },
      legend: {
        show: true, // Hide legend
      },
      tooltip: {
        enabled: true, // Show tooltips
      },
    };

    return (
      <div id="graph" className="w-full">
        <ReactApexChart
          options={graphOptions}
          series={graphSeries}
          type="bar"
          width="450"
        />
      </div>
    );
  };

  const RadarChartView = ({ series, options }) => {
    const radarOptions = {
      ...options,
      chart: {
        ...options.chart,
        type: "radar", // Changing the chart type to radar
        toolbar: {
          show: false, // Hide the toolbar to remove download options
        },
      },
      stroke: {
        show: true,
        width: 2,
      },
      fill: {
        opacity: 0.2, // Fill area with some opacity
      },
      markers: {
        size: 4,
      },
      dataLabels: {
        enabled: true, // Show data labels
      },
      legend: {
        show: true, // Hide legend
      },
      tooltip: {
        enabled: true, // Show tooltips
      },
      xaxis: {
        categories: Object.keys(taskStatus).map(formatLabel), // Ensure taskStatus is correctly populated
      },
      colors: colors, // Customize your radar chart colors here
    };

    return (
      <div id="radar-chart" className="w-full">
        <ReactApexChart
          options={radarOptions}
          series={[{ name: "Task Status Count", data: series }]}
          type="radar"
          width="450"
        />
      </div>
    );
  };

  const AreaChartView = ({ series, options }) => {
    const areaOptions = {
      ...options,
      chart: {
        ...options.chart,
        type: "area", // Changing the chart type to area for area chart view
        toolbar: {
          show: false, // Hide the toolbar to remove download options
        },
      },
      xaxis: {
        categories: Object.keys(taskStatus).map(formatLabel), // Ensure userCount is correctly populated
      },
      colors: colors, // Customize your area chart colors here
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      dataLabels: {
        enabled: true, // Show data labels
      },
      legend: {
        show: true, // Hide legend
      },
      tooltip: {
        enabled: true, // Show tooltips
      },
    };

    return (
      <div id="area-chart" className="w-full">
        <ReactApexChart
          options={areaOptions}
          series={[{ name: "Task Priority Count", data: series }]}
          type="area"
          width="450"
        />
      </div>
    );
  };

  const categories = Object.keys(taskStatus).map(formatLabel);
  const data = categories.map(
    (category) =>
      taskStatus[
        Object.keys(taskStatus).find((key) => formatLabel(key) === category)
      ] || 0
  );

  const options = {
    labels: categories,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: "Task Status Count",
              showAlways: true,
            },
          },
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
    },
    colors: Piecolors, // Customize your donut chart colors here
  };

  const series = data;

  return (
    <div
    className={` ${
      theme === "dark"
        ? " text-gray-200 "
        : " text-gray-900"
    }`}
  >
      <div className="flex justify-between py-1">
        <h2 className="semibold text-sm text-left py-1 flex">
          <FaTasks className="mt-1 text-purple-500 mr-1" /> {translations[langMode].taskstatus}
        </h2>
        {categories.length !== 0 && (
          <button
            type="button"
            className="px-3 text-xs justify-end font-medium text-center inline-flex items-center"
            onClick={() => setShowModal(true)}
          >
            <IoEye className="w-5 h-5 mr-2" />
          </button>
        )}
      </div>

      {categories.length === 0 ? (
        <div className="flex items-center  justify-center text-center text-red-600 h-[290px] w-[500px] ">
          <span className="text-center text-lg"> {translations[langMode].select}</span>
        </div>
      ) : (
        <>
          {chartView === "chart" ? (
            <ChartView series={series} options={options} />
          ) : chartView === "area" ? (
            <AreaChartView series={series} options={options} />
          ) : chartView === "radar" ? (
            <RadarChartView series={series} options={options} />
          ) : (
            <GraphView series={series} options={options} />
          )}
        </>
      )}
      <div className="mixed-chart">
        <ChartModal
          showModal={showModal}
          setShowModal={setShowModal}
          setChartView={setChartView}
        />
      </div>
    </div>
  );
};

export default TaskStatusChart;
