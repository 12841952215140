import React, { useContext, useEffect, useState, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FaHardHat } from "react-icons/fa";
import AppContext from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchTasks,
  getTaskDetail,
  updateTaskPriority,
} from "../../service/Task";
import { MdOutlineSecurity } from "react-icons/md";

const TaskListView = ({
  isChecked,
  setIsChecked,
  selectedtask,
  setselectedtask,
  selectedfieldAgent,
  selectedFieldAgentId,
  resetFilters,
}) => {
  const navigate = useNavigate();
  const [totalValue, setTotalValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const context = useContext(AppContext);
  const {
    workflowId,
    priority,
    active,
    setTaskData,
    taskData,
    settaskDataHistory,
    setSelectAll,
    selectAll,
    areaCode,
    accountNo,
    selectedUserId,
    setAccountNo,
    selectedSubactivity,
  } = context;
  const previousPriority = useRef(priority);
  const previousPageSize = useRef(pageSize);
  const activeTab = parseInt(localStorage.getItem("activeTab"), 10);

  const fetchData = async (
    workflowId,
    currentPage,
    pageSize,
    priority,
    active,
    areaCode,
    accountNo,
    selectedUserId,
    selectedSubactivity
  ) => {
    if (!workflowId) {
      setTaskData([]);
      setTotalValue(0);
      return; // Exit early if workflowId is not selected
    }
    try {
      const data = await fetchTasks({
        workflowId,
        currentPage,
        pageSize,
        priority,
        active,
        areaCode,
        accountNo,
        selectedUserId,
        selectedSubactivity,
      });
      setTaskData(data.taskList);
      setTotalValue(data.totalSize);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  useEffect(() => {
    if (
      priority !== previousPriority.current ||
      pageSize !== previousPageSize.current
    ) {
      setCurrentPage(0);
      fetchData(
        workflowId,
        0,
        pageSize,
        priority,
        active,
        areaCode,
        accountNo,
        selectedUserId,
        selectedSubactivity
      );
    } else {
      fetchData(
        workflowId,
        currentPage,
        pageSize,
        priority,
        active,
        areaCode,
        accountNo,
        selectedUserId,
        selectedSubactivity
      );
    }
    previousPriority.current = priority;
    previousPageSize.current = pageSize;
  }, [
    workflowId,
    currentPage,
    pageSize,
    priority,
    active,
    areaCode,
    accountNo,
    selectedUserId,
    selectedSubactivity,
  ]);

  const totalPages = Math.ceil(totalValue / pageSize) - 1;
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const totalItems = totalValue;

  const handlePageSizeChange = (e) => {
    const size = parseInt(e.target.value, 10);
    setPageSize(size);
    setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleCheckboxChange = (index) => {
    const newData = [...taskData];
    newData[index].isChecked = !newData[index].isChecked;

    if (newData.some((task) => task.isChecked)) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }

    if (newData[index].isChecked) {
      setselectedtask([...selectedtask, newData[index].taskId]);
    } else {
      setselectedtask((prevValues) =>
        prevValues.filter((value) => value !== newData[index].taskId)
      );
    }
    setTaskData(newData);
  };

  const handleSelectAllChange = () => {
    const newData = taskData.map((task) => ({
      ...task,
      isChecked: !selectAll,
    }));
    if (!selectAll) {
      const allTaskIds = newData.map((task) => task.taskId);
      setselectedtask(allTaskIds);
      setIsChecked(true);
    } else {
      setselectedtask([]);
      setIsChecked(false);
    }
    setTaskData(newData);
    setSelectAll(!selectAll);
  };

  const taskHistory = async (taskId) => {
    try {
      const taskDetail = await getTaskDetail(taskId);
      if (taskDetail.taskDetails) {
        taskDetail.taskDetailsParsed = JSON.parse(taskDetail.taskDetails);
      }
      settaskDataHistory(taskDetail);
    } catch (error) {
      console.error("Error fetching task detail:", error);
    }
  };

  const updateTaskPriorityHandler = async (taskId, newPriority) => {
    try {
      const responseData = await updateTaskPriority(taskId, newPriority);
      if (responseData.header.code === 600) {
        const updatedTaskData = taskData.map((task) => {
          if (task.taskId === taskId) {
            return {
              ...task,
              priority: newPriority,
            };
          }
          return task;
        });
        setTaskData(updatedTaskData);
        setselectedtask(
          updatedTaskData
            .filter((task) => task.isChecked)
            .map((task) => task.taskId)
        );
        toast.success("Task Priority Updated");
      } else {
        console.error("Failed to update priority:", responseData.statusText);
      }
    } catch (error) {
      console.error("Error updating task priority:", error);
    }
  };

  const handleTaskClick = (taskId, accountNo) => {
    navigate(`/task-detail/${taskId}`);
    setAccountNo(accountNo);
  };

  const baseHeaders = [
    "taskId",
    "Account No.",
    "Activity",
    "priority",
    "taskStatus",
    "Area Code",
    "Office ",
  ]; // Moved 'taskId' to the first position
  const dynamicHeaders =
    taskData.length > 0
      ? Object.keys(JSON.parse(taskData[0].taskDetails)).filter(
          (key) => !baseHeaders.includes(key)
        )
      : [];
  const tableHeaders = [...baseHeaders, ...dynamicHeaders];
  const { theme } = useContext(AppContext);

  if (!Array.isArray(taskData)) {
    return <div>No tasks available.</div>;
  }
  return (
    <>
      <div
        className={`px-5  bg-white ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        <div className="overflow-x-auto sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-sm text-gray-500 border-b border-gray-300 dark:text-gray-400">
              <tr>
                <th>#</th>
                <th scope="col" className="px-1 py-3">
                  <input
                    type="checkbox"
                    className={`w-4 h-4 text-blue-800 bg-gray-100 border-gray-300 rounded focus:ring-blue-800 dark:focus:ring-blue-800 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:border-gray-600 ${
                      theme === "dark"
                        ? "dark-mode"
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }`}
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  />
                </th>
                {tableHeaders.map((header, index) => (
                  <th key={index} scope="col" className="px-6 py-2 text-left text-xs font-medium uppercase tracking-wider">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {totalValue === 0 ? (
                <tr>
                  <td
                    colSpan={tableHeaders.length + 2}
                    className="text-gray-700 font-normal"
                  >
                    No data available
                  </td>
                </tr>
              ) : (
                taskData.map((task, index) => {
                  const taskDetailsObj = JSON.parse(task.taskDetails);
                  return (
                    <tr
                      key={index}
                      className={`bg-white text-sm hover:bg-gray-50 dark:hover:bg-gray-800 ${
                        theme === "dark"
                          ? "dark-mode"
                          : theme === "high-contrast"
                          ? "high-contrast"
                          : ""
                      }`}
                    >
                      <td className="w-2 p-2">{index + 1}</td>
                      <td className="w-2 p-2">
                        <div className="flex items-center">
                          {!task.protected && (
                            <>
                              <input
                                id={`checkbox-table-search-${index}`}
                                type="checkbox"
                                className={`w-4 h-4 text-blue-800 bg-gray-100 border-gray-300 rounded focus:ring-blue-800
                               dark:focus:ring-blue-800 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2
                                 ${
                                   theme === "dark"
                                     ? "dark-mode  "
                                     : theme === "high-contrast"
                                     ? "high-contrast  "
                                     : ""
                                 }`}
                                checked={task.isChecked || false}
                                onChange={() => handleCheckboxChange(index)}
                              />
                              <label
                                htmlFor={`checkbox-table-search-${index}`}
                                className="sr-only"
                              >
                                checkbox
                              </label>
                            </>
                          )}
                        </div>
                      </td>
                      {tableHeaders.map((header, index) => (
                        <td key={index} className="px-3 py-2">
                          {header === "Account No." ? (
                            <div className="flex">
                              {task.protected && (
                                <MdOutlineSecurity className="mr-2 text-blue-800" />
                              )}
                              {task.accountNo}
                            </div>
                          ) : header === "taskId" ? (
                            <div className="flex">
                              <span
                                className="flex gap-2 cursor-pointer bg-green-400 bg-opacity-30 rounded-full text-green-600 px-4 py-1"
                                onClick={() =>
                                  handleTaskClick(task.taskId, task.accountNo)
                                }
                              >
                                <div className="relative group ">
                                  <span className="pt-2">
                                    {task.taskStatus === "Assigned" && (
                                      <FaHardHat className="text-yellow-400 m-1" />
                                    )}
                                    {task.taskStatus === "Completed" && (
                                      <FaHardHat className="text-green-500 m-1" />
                                    )}
                                    {task.taskStatus === "On_Hold" && (
                                      <FaHardHat className="text-red-500 m-1" />
                                    )}
                                    {task.taskStatus === "In_Progress" && (
                                      <FaHardHat className="text-orange-500 m-1" />
                                    )}
                                  </span>
                                  <div
                                    role="tooltip"
                                    className="absolute w-[200px] left-full ml-2 z-10 invisible px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 group-hover:visible group-hover:opacity-100"
                                  >
                                    <div>Field Agent ID: {task.assigneeId}</div>
                                    <div>
                                      Field Agent Name: {task.assigneeName}
                                    </div>
                                    <div
                                      className="tooltip-arrow"
                                      data-popper-arrow
                                    ></div>
                                  </div>
                                </div>
                                {task.taskId}
                              </span>
                            </div>
                          ) : header === "taskStatus" ? (
                            <span
                              className={`${
                                task.taskStatus === "Active"
                                  ? "px-4 py-1 font-semibold text-blue-500 rounded-full bg-blue-400 bg-opacity-30"
                                  : ""
                              }${
                                task.taskStatus === "Assigned"
                                  ? "px-4 py-1  font-semibold text-yellow-500 rounded-full bg-yellow-400 bg-opacity-30"
                                  : ""
                              }
                              ${
                                task.taskStatus === "On_Hold"
                                  ? "px-4 py-1 font-semibold text-red-500 rounded-full bg-red-400 bg-opacity-30"
                                  : ""
                              }
                              ${
                                task.taskStatus === "Completed"
                                  ? "px-4 py-1 font-semibold text-green-500 rounded-full bg-green-400 bg-opacity-30"
                                  : ""
                              } ${
                                task.taskStatus === "In_Progress"
                                  ? "px-4 py-1 font-semibold text-orange-500 rounded-full bg-orange-400 bg-opacity-30"
                                  : ""
                              } text-sm`}
                            >
                              {task.taskStatus}
                            </span>
                          ) : header === "priority" ? (
                            <select
                              value={task.priority}
                              onChange={(e) => {
                                const newPriority = e.target.value;
                                updateTaskPriorityHandler(
                                  task.taskId,
                                  newPriority
                                );
                              }}
                              className={`py-1 px-2 border w-24 rounded-md ${
                                theme === "dark"
                                  ? "dark-mode"
                                  : theme === "high-contrast"
                                  ? "high-contrast"
                                  : ""
                              }`}
                            >
                              <option value="Low_Priority">Low</option>
                              <option value="Medium_Priority">Medium</option>
                              <option value="High_Priority">High</option>
                            </select>
                          ) : (
                            taskDetailsObj[header]
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="px-5 py-5 flex justify-between items-center">
        <div>
          <div className="text-sm text-gray-400 flex items-center gap-4">
            <label htmlFor="pageSize">Rows per page:</label>
            <select
              id="countries_disabled"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
        <div>
          <p className="text-sm text-gray-400 flex px-5 justify-end gap-4">
            {`${startIndex}-${endIndex} of ${totalItems} items`}
            <IoIosArrowBack
              className="cursor-pointer"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            />{" "}
            <IoIosArrowForward
              className="cursor-pointer"
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            />
          </p>
        </div>
      </div>
    </>
  );
};

export default TaskListView;
