import React, { useContext } from "react";
import DashboardMenus from "./DashboardMenus.js";
import AppContext from "../../context/AppContext.js";
const Header = ({ pageName, subPage , onClick }) => {
  const {  theme } = useContext(AppContext); 
  return (
    <header className="" onClick={onClick}>
      <div className="py-5">
        <div className="flex items-center gap-4">
          <DashboardMenus />
          <div className="flex flex-col">
            <span className={`text-sm text-gray-700 font-bold ${theme === 'dark' ? 'dark-mode ' : 'light-mode'} `}>{pageName}</span>
            <span className={`text-sm text-gray-400 font-bold ${theme === 'dark' ? 'dark-mode ' : 'light-mode'}`}>{subPage}</span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
