import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/_app/Header";
import AssignmentRules from "../../components/configurations/AssignmentRules";
import { CompletionRules } from "../../components/configurations/CompletionRules";
import CustomVariable from "../../components/configurations/CustomVariable";
import AppContext from "../../context/AppContext";
const translations = {
  en: {
    complitionrule:"Completion Rules",
    assignment:"Assignment Rules",
    customerule:"Custom Variables"
  },
  ar: {
    complitionrule:"قواعد الإكمال",
    assignment:"قواعد التعيين",
    customerule:"المتغيرات المخصصة"
  },
};
const ConfigurationMain = () => {
  const [activeTab, setActiveTab] = useState("completion");
  const { theme, langMode } = useContext(AppContext);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <Header pageName={"Configuration"} />
      <div className="min-h-screen md:flex">
        <ul
          className={`flex-column rounded-md w-[300px] space-y-4 h-min-screen p-4 text-sm font-medium border md:me-4 mb-4 md:mb-0 ${
            theme === "dark"
              ? "border text-white border-gray-700"
              : theme === "high-contrast"
              ? "bg-yellow-200 text-black border-black"
              : "bg-white text-gray-700 border-gray-300"
          }`}
          style={{ width: "300px" }}
        >
          <li>
            <Link
              to=""
              className={`inline-flex justify-center px-2 py-2 font-bold border-2 rounded-lg w-full whitespace-nowrap ${
                activeTab === "completion"
                  ? theme === "dark"
                    ? "bg-blue-600 text-white border-blue-700"
                    : "bg-blue-800 text-white border-blue-900"
                  : ""
              }`}
              onClick={() => handleTabClick("completion")}
            >
              {translations[langMode].complitionrule}
            </Link>
          </li>
          <li>
            <Link
              to=""
              className={`inline-flex justify-center px-2 py-2 font-bold border-2 rounded-lg w-full whitespace-nowrap ${
                activeTab === "assignment"
                  ? theme === "dark"
                    ? "bg-blue-600 text-white border-blue-700"
                    : "bg-blue-800 text-white border-blue-900"
                  : ""
              }`}
              onClick={() => handleTabClick("assignment")}
            >
               {translations[langMode].assignment}
            </Link>
          </li>
          <li>
            <Link
              to=""
              className={`inline-flex justify-center px-2 py-2 font-bold border-2 rounded-lg w-full whitespace-nowrap ${
                activeTab === "custom"
                  ? theme === "dark"
                    ? "bg-blue-600 text-white border-blue-700"
                    : "bg-blue-800 text-white border-blue-900"
                  : ""
              }`}
              onClick={() => handleTabClick("custom")}
            >
               {translations[langMode].customerule}
            </Link>
          </li>
        </ul>
        <div
          className={`p-6 rounded-lg w-full ${
            theme === "dark"
              ? " border text-white border-gray-700"
              : theme === "high-contrast"
              ? "bg-yellow-200 text-black border-black"
              : "bg-white text-gray-700 border-gray-300"
          }`}
        >
          {activeTab === "completion" && <CompletionRules />}
          {activeTab === "assignment" && <AssignmentRules />}
          {activeTab === "custom" && <CustomVariable />}
        </div>
      </div>
    </>
  );
};

export default ConfigurationMain;
