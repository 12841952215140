import React, { useContext, useEffect, useState } from "react";
import { CiStar } from "react-icons/ci";
import Header from "../../components/_app/Header";
import WorkflowMainToolbar from "../../components/workflow/WorkflowMainToolbar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/modals/Modal";
import AppContext from "../../context/AppContext";
import {
  fetchData,
  deleteWorkflow,
  fetchWorkflowData,
  fetchFormData,
} from "../../service/workflow";
import Cookies from "js-cookie";
import DeletePermission from "../../components/suspence/DeletePermission";
import { RiDeleteBin2Fill, RiEditBoxFill } from "react-icons/ri";
import { TbCopyPlusFilled } from "react-icons/tb";
import Joyride from "react-joyride";
const WorkflowMain = () => {
  const [dynamicWorkflow, setdynamicWorkflow] = useState([]);
  const [getFormData, setgetFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const [clone, setClone] = useState(false);
  const [refreshPage] = useState(false); // New state variable
  const navigate = useNavigate();
  const { theme, selectedOrgId ,workflow ,runTour } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const userType = Cookies.get("userType");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [workflowToDelete, setWorkflowToDelete] = useState(null);

  const loadData = async () => {
    try {
      const data = await fetchData(selectedOrgId, userType);
      setdynamicWorkflow(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [refreshPage, selectedOrgId]);

  const handleDeleteClick = (workflowId) => {
    setWorkflowToDelete(workflowId);
    setShowDeleteModal(true);
  };

  const handleDeleteWorkflow = async (workflowId) => {
    try {
      const successMessage = await deleteWorkflow(workflowId);
      toast.success(successMessage);
      const updatedDynamicWorkflow = dynamicWorkflow.filter(
        (item) => item.workflowId !== workflowId
      );
      setdynamicWorkflow(updatedDynamicWorkflow);
      setShowDeleteModal(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEditClick = async (workflowId) => {
    try {
      const {
        workflowFields,
        existWorkflowName,
        existPdfTitleName,
        workflowTimer,
        existDragItems,
        existRadioButtons,
      } = await fetchWorkflowData(workflowId);
      navigate("/workflow-form", {
        state: {
          workflowId,
          workflowFields,
          existWorkflowName,
          existPdfTitleName,
          workflowTimer,
          existDragItems,
          existRadioButtons,
          isEdit: true,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleCloneClick = async (workflowId) => {
    try {
      const {
        workflowFields,
        existPdfTitleName,
        existWorkflowName,
        existDragItems,
        existRadioButtons,
      } = await fetchWorkflowData(workflowId);
      navigate("/workflow-form", {
        state: {
          workflowFields,
          existWorkflowName,
          existPdfTitleName,
          existDragItems,
          existRadioButtons,
          isClone: true, // Add this flag to indicate cloning
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
     <Joyride
        steps={workflow}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
      />
      <Header pageName={"Workflow"} />
      <div
        className={`${
          theme === "dark"
            ? "dark-mode "
            : theme === "high-contrast"
            ? "high-contrast "
            : ""
        } min-h-screen px-5 py-5 bg-white`}
      >
        <WorkflowMainToolbar />
        <div className="px-5 p-2 ">
          {Array.isArray(dynamicWorkflow) && dynamicWorkflow.length > 0 ? (
            <ul role="list" className="">
              {dynamicWorkflow.map((item, index) => (
                <li
                  key={index}
                  className={`${
                    theme === "dark"
                      ? "dark-mode "
                      : theme === "high-contrast"
                      ? "high-contrast "
                      : ""
                  } flex justify-between items-center gap-x-6 py-3 px-3 m-2 rounded-md border-2 border-indigo-500 bg-gray-50 shadow-xl hover:shadow-2xl  transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  <div className="flex min-w-0 items-center gap-x-4">
                    <CiStar className="text-lg" />
                    <div className="min-w-0 flex-auto">
                      <p
                        className={`text-sm font-semibold leading-6 text-gray-900 ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast "
                            : ""
                        }`}
                      >
                        <span>{item.workflowName}</span>
                      </p>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                        Updated on
                        {item.updatedAt
                          ? new Date(item.updatedAt).toLocaleDateString(
                              "en-US",
                              {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              }
                            )
                          : new Date(item.createdAt).toLocaleDateString(
                              "en-US",
                              {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              }
                            )}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 items-center">
                    <div className="items-center">
                      <p
                        className="text-sm font-semibold  text-gray-700 cursor-pointer cloneworkflow"
                        onClick={() => handleCloneClick(item.workflowId)}
                      >
                        <TbCopyPlusFilled className=" text-xl ml-2 text-gray-700" />
                        Clone
                      </p>
                    </div>
                    <div className="">
                      <p
                        className="text-sm font-semibold  text-gray-700 cursor-pointer editworkflow"
                        onClick={() =>
                          handleEditClick(
                            item.workflowId,
                            item.workflowName,
                            item.timer
                          )
                        }
                      >
                        <RiEditBoxFill className=" text-xl text-gray-700" />
                        Edit
                      </p>
                    </div>
                    <div
                      className=""
                      onClick={() => handleDeleteClick(item.workflowId)}
                    >
                      <p className=" cursor-pointer text-sm font-semibold  text-gray-700 deleteworkflow">
                        <RiDeleteBin2Fill className=" text-xl ml-2 text-gray-700" />
                        Delete
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500 text-center">No workflows available.</p>
          )}
        </div>
      </div>
      <Modal
        open={open}
        setOpen={setOpen}
        clone={clone}
        setClone={setClone}
        getFormData={getFormData}
        loadData={loadData}
      />
      {showDeleteModal && (
        <DeletePermission
          onClose={() => setShowDeleteModal(false)}
          deleteFunction={() => handleDeleteWorkflow(workflowToDelete)}
          onDeleteText="Are you sure you want to delete this workflow?"
        />
      )}
    </>
  );
};

export default WorkflowMain;
