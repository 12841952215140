import React, { useState, useEffect } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineMessage } from "react-icons/md";
import { RiAttachmentFill } from "react-icons/ri";
import axios from "axios";
import Cookies from "js-cookie";
import AppContext from "../../context/AppContext";
import { useContext } from "react";
export const WorkLogCard = ({ workLogs }) => {
  const {  theme } = useContext(AppContext);
  return (
    <>
      {workLogs ?.map((workLog, index) => (
        <div
          key={index}
          className={`p-6 bg-white border border-gray-400 rounded-lg shadow-md hover:bg-gray-100
           my-2 dark:border-gray-700 dark:hover:bg-gray-900 ${theme === 'dark' ? 'dark-mode ' : (theme === 'high-contrast' ? 'high-contrast ' : '')}`}
        >
          <div className="flex justify-between items-center">
            <h6 className="text-sm font-semibold">{workLog.activity}</h6>
            <span
              className={`${
                workLog.priority === "High_Priority"
                  ? "bg-red-500 bg-opacity-50 text-red-600"
                  : workLog.priority === "Medium_Priority"
                  ? "bg-yellow-500 bg-opacity-50 text-yellow-600"
                  : "bg-gray-800 bg-opacity-80 text-gray-600"
              } text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded-full`}
            >
              {workLog.priority || "Low_Priority"}
            </span>
          </div>
          <div className="flex items-center gap-2 mt-2">
            <span className="bg-green-400 text-white text-xs font-semibold me-2 px-2.5 py-2 rounded-full dark:bg-red-900 dark:text-red-300">
              {workLog.taskId}
            </span>
            <span
              className={`py-2 px-2.5 ${
                workLog.status === "Active"
                  ? "bg-blue-500 "
                  : workLog.status === "Assigned"
                  ? "bg-yellow-500"
                  : workLog.status === "In_Progress"
                  ? "bg-red-500"
                  : workLog.status === "Completed"
                  ? "bg-green-500"
                  : workLog.status === "On_Hold"
                  ? "bg-gray-500"
                  : "bg-blue-500"
              } text-white text-xs font-semibold me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300`}
            >
              {workLog.status || "Active"}
            </span>

            <div className="flex items-center gap-1 text-purple-700">
              <RiAttachmentFill size={20} />
              <span>{workLog.attachments}</span>
            </div>
            <div className="flex items-center gap-1 text-yellow-400">
              <MdOutlineMessage size={20} />
              <span>{workLog.messages}</span>
            </div>
          </div>
          <div className="flex-col gap-4 py-2">
            <h6 className="text-sm font-normal">
              Activity:{" "}
              <span className="font-semibold">{workLog.activity}</span>
            </h6>
            <h6 className="text-sm font-normal">
              Account No:{" "}
              <span className="font-semibold">{workLog.accountNo}</span>
            </h6>
            <h6 className="text-sm font-normal">
              Tariff: <span className="font-semibold">{workLog.tariff}</span>
            </h6>
            <h6 className="flex items-center gap-2">
              <FaLocationDot className="text-blue-900" />
              <span className="text-sm font-semibold">{workLog.location}</span>
            </h6>
          </div>
        </div>
      ))}
    </>
  );
};
