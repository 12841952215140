import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
import Header from "../../components/_app/Header";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";
import { addAsset } from "../../service/Asset";
import { FaArrowLeft } from "react-icons/fa";
import { RiUploadCloud2Line } from "react-icons/ri";
const translations = {
  en: {
    addnewasset: "Add New Assert",
    add: "Add",
    cancle: "Cancel",
  },
  ar: {
    addnewasset: "إضافة تأكيد جديد",
    add:"يضيف",
    cancle:"يلغي"
  },
};
const AddAsset = () => {
  const [AssertType, setAssertType] = useState("Honeywell_Mobile");
  const [AssertName, setAssertName] = useState("");
  const [AssertBarcode, setAssertBarcode] = useState("");
  const [Description, setDescription] = useState("");
  const [photoUrl, setPhotoUrl] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);
  const { theme, selectedOrgId,langMode } = useContext(AppContext);

  const navigate = useNavigate();
  const handleSelectChange = (event) => {
    setAssertType(event.target.value);
  };
  const handleAssertNameChange = (event) => {
    setAssertName(event.target.value);
  };
  const handleAssertBarcodeChange = (event) => {
    setAssertBarcode(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleFileUpload = async (file, fileType) => {
    try {
      const url = await uploadAdapter(file, fileType);
    } catch (error) {}
  };
  async function uploadAdapter(loader, fileType) {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_REGION,
    });

    const uploadParams = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: loader.name,
      Body: loader,
    };
    try {
      const uploadData = await s3.upload(uploadParams).promise();
      const url = `https://${process.env.REACT_APP_BUCKET_NAME}.s3.${process.env.REACT_APP_REGION}.amazonaws.com/${loader.name}`;
      setPhotoUrl(url);
      setDocumentUrl(url);
      return url;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  }

  const handleAddAssert = async () => {
    if (
      !AssertType ||
      !AssertName ||
      !AssertBarcode ||
      !Description ||
      !photoUrl ||
      !documentUrl
    ) {
      toast.error("Please fill all fields before adding asset");
      return;
    }
    const formData = {
      assertType: AssertType,
      assertName: AssertName,
      assertBarcode: AssertBarcode,
      assertImg: photoUrl,
      description: Description,
      assertDoc: documentUrl,
      organizationId: selectedOrgId,
    };
    try {
      await addAsset(formData);
      setAssertType("");
      setAssertName("");
      setDescription("");
      setDocumentUrl(null);
      setPhotoUrl(null);
      setAssertBarcode("");
      navigate("/asset");
    } catch (error) {
      console.error("Failed to add asset:", error);
    }
  };

  const handleCancel = () => {
    setAssertType("");
    setAssertName("");
    setDescription("");
    setDocumentUrl(null);
    setPhotoUrl(null);
    setAssertBarcode("");
  };
  const handleGoBack = () => {
    navigate("/asset");
  };
  return (
    <>
      <Header pageName={translations[langMode].addnewasset} />
      <div
        onClick={handleGoBack}
        className={`p-2 absolute top-30 rounded-full border cursor-pointer w-9 m-3 ml-5
       ${
         theme === "dark" ? "bg-gray-800 border-gray-600 hover:bg-gray-700" : ""
       } 
       ${
         theme === "high-contrast"
           ? "bg-yellow-200 border-black hover:bg-yellow-300"
           : ""
       } 
       ${
         theme !== "dark" && theme !== "high-contrast"
           ? "border-gray-400 hover:bg-gray-200"
           : ""
       }`}
      >
        <FaArrowLeft
          className={`${
            theme === "dark"
              ? "text-white"
              : theme === "high-contrast"
              ? "text-black"
              : "text-gray-700"
          }`}
        />
      </div>
      <div
        className={`px-5  pt-14 bg-white ${
          theme === "dark"
            ? "dark-mode  "
            : theme === "high-contrast"
            ? "high-contrast  "
            : ""
        }`}
      >
        <div className="w-1/2">
          {/* <h3 className="text-sm text-gray-500 font-bold">Add New Asset</h3> */}
          <div className="mt-3 grid grid-cols-1 gap-x-4 gap-y-5 sm:grid-cols-4">
            <div className="sm:col-span-2">
              <div className="relative">
                <select
                  id="vehical_dropdown"
                  value={AssertType}
                  onChange={handleSelectChange}
                  className={`block required: px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                >
                  <option value="Honeywell_Mobile">Honeywell_Mobile</option>
                  <option value="Electric_Meter">Electric_Meter</option>
                  <option value="Water_Meter">Water_Meter</option>
                </select>
                <label
                  htmlFor="vehical_dropdown"
                  className={`${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  } absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-6 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                >
                  Assert Type
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="relative">
                <input
                  type="text"
                  id="name"
                  value={AssertName}
                  onChange={handleAssertNameChange}
                  className={` ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  } block required: px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                />
                <label
                  className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4  ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                >
                  Assert Name
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="relative">
                <input
                  type="text"
                  id="AssertName"
                  disabled
                  className={` ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  } block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                />
                <label
                  className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                >
                  Assert ID
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="relative">
                <input
                  type="text"
                  id="AssertName"
                  value={AssertBarcode}
                  onChange={handleAssertBarcodeChange}
                  className={`${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  } block required: px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 peer`}
                />
                <label
                  className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-8 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-700 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                    theme === "dark"
                      ? "dark-mode  "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                >
                  Assert Barcode
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex items-center justify-center py-2 w-full">
                <label
                  for="dropzone-file"
                  className="flex flex-col items-center border border-blue-500 justify-center w-full h-54 bg-gray-100  rounded-lg cursor-pointer  dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div className="flex flex-row items-center justify-center gap-4 p-2 pt-5 pb-6">
                    <RiUploadCloud2Line className=" w-10 h-10" />
                    <div className="flex-flex-col items-center">
                      <p className="mb-2 text-sm text-gray-600 dark:text-gray-400">
                        <span className="font-semibold">
                          Drag and drop Photos
                        </span>
                      </p>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Max file size: 5MB
                      </p>
                      <p className="text-sm text-blue-700 font-semibold underline dark:text-gray-400">
                        Browse
                      </p>
                    </div>
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden required:"
                    onChange={(e) =>
                      handleFileUpload(e.target.files[0], "image")
                    }
                  />
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex items-center justify-center py-2 w-full">
                <label
                  for="dropzone-file"
                  className="flex flex-col items-center justify-center  w-full h-54  bg-gray-100 border border-blue-500  rounded-lg cursor-pointer  dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div className="flex flex-row items-center justify-center gap-4 p-2 pt-5 pb-6">
                    <RiUploadCloud2Line className=" w-10 h-10" />
                    <div className="flex-flex-col items-center">
                      <p className="mb-2 text-sm text-gray-600 dark:text-gray-400">
                        <span className="font-semibold">
                          Drag and drop Documents
                        </span>
                      </p>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Max file size: 5MB
                      </p>
                      <p className="text-sm text-blue-700 font-semibold underline dark:text-gray-400">
                        Browse
                      </p>
                    </div>
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden required:"
                    onChange={(e) =>
                      handleFileUpload(e.target.files[0], "document")
                    }
                  />
                </label>
              </div>
            </div>
            <div className="sm:col-span-2">
              <textarea
                id="message"
                rows="4"
                value={Description}
                onChange={handleDescriptionChange}
                className="block p-2.5 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Description"
              ></textarea>
            </div>
          </div>
        </div>
        <div className="">
          <div className="sm:col-span-4">
            <hr className="w-full border-gray-300" />
          </div>
          <div className="sm:col-span-4 pt-5 flex justify-end items-center">
            <button
              type="button"
              onClick={handleCancel}
              className="px-3 py-2 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
               {translations[langMode].cancle}
            </button>
            <button
              type="button"
              onClick={handleAddAssert}
              className="px-3 py-2 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              {translations[langMode].add}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAsset;
