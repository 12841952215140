import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { IoEye } from "react-icons/io5";
import ChartModal from "../user/static-charts/ChartModal";

const PieChart = ({
  tempData = { graphValueList: [] },
  chartName = "Default Chart Name",
}) => {
  const seriesData = tempData.graphValueList.map((item) => item.frequency || 0);
  const [showModal, setShowModal] = useState(false);
  const [chartView, setChartView] = useState("chart");

  // Extract labels from the response
  const labels = tempData.graphValueList.map(
    (item) => item.value || "No Label"
  );

  // Define colors for the charts
  const colors = ["#FF0000", "#FFA62F", "#0A6847", "#640D6B", "#673F69"];

  // ApexCharts options
  const options = {
    labels: labels,
    colors: colors, // Set custom colors
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
            },
          },
        },
      },
    },
    toolbar: {
      show: false, // Hide the toolbar to remove download options
    },
    dataLabels: {
      enabled: true, // Hide data labels
    },
    legend: {
      show: true, // Hide legend
    },
    tooltip: {
      enabled: true, // Hide tooltips
    },
  };

  const ChartView = () => (
    <div id="chart">
      <ReactApexChart
        options={{
          ...options,
          toolbar: {
            show: false, // Hide the toolbar to remove download options
          },
          dataLabels: {
            enabled: true, // Hide data labels
          },
          legend: {
            show: true, // Hide legend
            position: "bottom",
          },
          tooltip: {
            enabled: true, // Hide tooltips
          },
        }}
        series={seriesData}
        type="donut"
        width="390"
      />
    </div>
  );

  const GraphView = () => (
    <div id="graph">
      <ReactApexChart
        options={{
          ...options,
          chart: {
            ...options.chart,
            type: "bar",
            toolbar: {
              show: false, // Hide the toolbar to remove download options
            },
          },
          plotOptions: {
            bar: {
              distributed: true, // Ensures each bar gets a different color
            },
          },
          dataLabels: {
            enabled: true, // Hide data labels
          },
          legend: {
            show: true, // Hide legend
          },
          tooltip: {
            enabled: true, // Hide tooltips
          },
        }}
        series={[{ data: seriesData }]}
        type="bar"
        width="450"
      />
    </div>
  );

  const AreaChartView = () => (
    <div id="area-chart">
      <ReactApexChart
        options={{
          ...options,
          chart: {
            ...options.chart,
            type: "area",
            toolbar: {
              show: false,
            },
          },

          stroke: {
            curve: "smooth",
          },
          dataLabels: {
            enabled: true, // Hide data labels
          },
          legend: {
            show: true, // Hide legend
          },
          tooltip: {
            enabled: true, // Hide tooltips
          },
        }}
        series={[{ data: seriesData }]}
        type="area"
        width="450"
      />
    </div>
  );

  const RadarChartView = () => {
    const radarOptions = {
      chart: {
        type: "radar",
      },
      toolbar: {
        show: false, // Hide the toolbar to remove download options
      },
      xaxis: {
        categories: labels || ["Category 1", "Category 2", "Category 3"],
      },
      markers: {
        size: 4,
      },
      dataLabels: {
        enabled: true, // Hide data labels
      },
      legend: {
        show: true, // Hide legend
      },
      tooltip: {
        enabled: true, // Hide tooltips
      },
      colors: colors,
    };

    const radarSeries = [
      {
        name: chartName,
        data: seriesData,
      },
    ];

    return (
      <div id="radar">
        <ReactApexChart
          options={radarOptions}
          series={radarSeries}
          type="radar"
          width="450"
        />
      </div>
    );
  };

  return (
    <div className="mixed-chart">
      <div className="flex justify-between my-5">
        <h3 className="text-md font-semibold">{chartName}</h3>
        <button
          type="button"
          className="px-3 text-xs justify-end font-medium text-center inline-flex items-center"
          onClick={() => setShowModal(true)}
        >
          <IoEye className="w-5 h-5 mr-2" />
        </button>
      </div>
      {chartView === "chart" ? (
        <ChartView />
      ) : chartView === "area" ? (
        <AreaChartView />
      ) : chartView === "radar" ? (
        <RadarChartView />
      ) : (
        <GraphView />
      )}

      <ChartModal
        showModal={showModal}
        setShowModal={setShowModal}
        setChartView={setChartView}
      />
    </div>
  );
};

export default PieChart;
