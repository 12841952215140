import React, { useState } from "react";
import { MdDashboardCustomize } from "react-icons/md";
import Header from "../../components/_app/Header";
import AddLeaderBoard from "../../components/leaderboard/AddLeaderBoard";
import leaderImg from "../../assets/score 1.png"; 

const LeaderboardMain = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="flex justify-between items-center">
        <Header pageName={"Leader-board"} />
        {/* <button
          type="button"
          className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={() => setOpen(true)}
        >
          <MdDashboardCustomize className="w-5 h-5 mr-2" /> Add Leader-board
          Dashboard
        </button> */}
      </div>
      <div className="flex flex-col justify-center items-center bg-white h-full min-h-screen">
        <img src={leaderImg} alt="Leaderboard" className="max-w-full max-h-full mb-4" />
        <span className="text-gray-500 font-semibold">Coming soon</span>
      </div>
      <AddLeaderBoard open={open} setOpen={setOpen} />
    </>
  );
};

export default LeaderboardMain;
