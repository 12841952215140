import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import { IoEye } from "react-icons/io5";
import ChartModal from "../user/static-charts/ChartModal";

const AreaChart = ({ tempData, chartName }) => {
  const [showModal, setShowModal] = useState(false);
  const [chartView, setChartView] = useState("area");
  const colors = ["#EF5A6F", "#3A1078", "#399918", "#FFD433", "#EBD3F8"];
  const categories =
    tempData?.graphValueList?.map((item) => item.value || "No label") || [];
  const data = tempData?.graphValueList?.map((item) => item.frequency) || [];

  const options = {
    chart: {
      stacked: true,
    },
    toolbar: {
      show: false, // Hide the toolbar to remove download options
    },
    xaxis: {
      categories: categories.length > 0 ? categories : ["Default Label"],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
    },
    tooltip: {
      enabled: true,
    },
    colors: colors,
  };

  const series = [
    {
      name: "Frequency",
      data: data,
    },
  ];

  const ChartView = () => (
    <div id="chart" className="w-full">
      <ReactApexChart
        options={{
          ...options,
          toolbar: {
            show: false, // Hide the toolbar to remove download options
          },
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
            position: "bottom",
          },
          tooltip: {
            enabled: true,
          },
        }}
        series={series}
        type="donut"
        width="390"
      />
    </div>
  );

  const GraphView = () => (
    <div id="graph" className="w-full">
      <ReactApexChart
        options={{
          ...options,
          chart: {
            ...options.chart,
            type: "bar",
            toolbar: {
              show: false, // Hide the toolbar to remove download options
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
          },
          tooltip: {
            enabled: true,
          },
        }}
        series={series}
        type="bar"
        width="450"
      />
    </div>
  );

  const AreaChartView = () => (
    <div id="area-chart" className="w-full">
      <ReactApexChart
        options={{
          ...options,
          chart: {
            ...options.chart,
            type: "area",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
          },
          tooltip: {
            enabled: true,
          },
        }}
        series={series}
        type="area"
        width="450"
      />
    </div>
  );
  const RadarChartView = () => {
    const radarOptions = {
      chart: {
        type: "radar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: categories || ["Category 1", "Category 2", "Category 3"],
      },
      markers: {
        size: 4,
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: true,
      },
      tooltip: {
        enabled: true,
      },
      colors: colors,
    };

    return (
      <div id="radar" className="w-full">
        <ReactApexChart
          options={radarOptions}
          series={series}
          type="radar"
          width="450"
        />
      </div>
    );
  };
  return (
    <div className="stacked-column-chart">
      <div className="flex justify-between my-5">
        <h3 className="text-md font-semibold">{chartName}</h3>
        <button
          type="button"
          className="px-3 text-xs justify-end font-medium text-center inline-flex items-center"
          onClick={() => setShowModal(true)}
        >
          <IoEye className="w-5 h-5 mr-2" />
        </button>
      </div>
      {chartView === "chart" ? (
        <ChartView />
      ) : chartView === "area" ? (
        <AreaChartView />
      ) : chartView === "radar" ? (
        <RadarChartView />
      ) : (
        <GraphView />
      )}
      <ChartModal
        showModal={showModal}
        setShowModal={setShowModal}
        setChartView={setChartView}
      />
    </div>
  );
};

export default AreaChart;
