import React, { useContext, useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import Header from "../../components/_app/Header";
import AppContext from "../../context/AppContext";
import Cookies from "js-cookie";
import Modal from "../../components/modals/Modal";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { ColumnMap } from "./ColumnMap";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import * as XLSX from "xlsx";
import { fetchMetadataColumn } from "../../service/Task";
import { IoCloudUpload } from "react-icons/io5";
import { fetchExcelDetails } from "../../service/Task";

const translations = {
  en: {
    DragDetail: "Drag and drop CSV containing all the task details",
    sizeMsg: "Max file size: 15MB",
    import: " Import CSV ",
  },
  ar: {
    DragDetail: "قم بسحب وإسقاط ملف CSV الذي يحتوي على جميع تفاصيل المهمة",
    sizeMsg: "الحد الأقصى لحجم الملف: 15 ميجابايت",
    import: "استيراد ملف CSV",
  },
};
const ImportCsv = () => {
  const [file, setFile] = useState(null);
  const [modalValue, setModalValue] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [mapopen, setMapOpen] = useState(false);
  const [colHeaders, setcolHeaders] = useState([]);
  const [mandatoryFields, setmandatoryFields] = useState([]);
  const [optionalFields, setoptionalFields] = useState([]);
  const [open, setOpen] = useState(false);
  const context = useContext(AppContext);
  const { selectedOption, theme, matchedColumns, langMode } = context;
  const [totalValue, setTotalValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [excelDetails, setexcelDetails] = useState([])
  const formatFileSize = (size) => {
    if (size < 1024) {
      return size + " bytes";
    } else if (size >= 1024 && size < 1048576) {
      return (size / 1024).toFixed(1) + " KB";
    } else if (size >= 1048576) {
      return (size / 1048576).toFixed(1) + " MB";
    }
  };

  const handleFileChange = async (event) => {
    try {
      const ResponseData = await fetchMetadataColumn(selectedOption);
      const multiMetaData = ResponseData.data;
      if (multiMetaData.header.code === 600) {
        setmandatoryFields(multiMetaData.body.value.mandatoryColumns);
        setoptionalFields(multiMetaData.body.value.otherColumns);
        const newFile = event.target.files[0];
        if (newFile) {
          setFile(newFile);
          setMapOpen(true);
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const headers = [];
            const range = XLSX.utils.decode_range(worksheet["!ref"]);
            const firstRow = range.s.r; // Get the starting row number
            for (let col = range.s.c; col <= range.e.c; col++) {
              const cell =
                worksheet[XLSX.utils.encode_cell({ r: firstRow, c: col })];
              const header = cell ? cell.v : `UNKNOWN ${col}`;
              headers.push(header);
            }
            setcolHeaders(headers);
          };
          reader.readAsArrayBuffer(newFile);
          setFile(newFile);
          setFileSize(formatFileSize(newFile.size));
        }
      }
    } catch (error) {
      console.error("Error fetching organization data:", error);
      toast.error("Failed to fetch organization data");
    }
  };
  const handleUploadClick = () => {
    if (!selectedOption) {
      toast.warn("Please select an activity");
    } else {
      const fileInput = document.getElementById("dropzone-file");
      fileInput.value = null;
      fileInput.click();
    }
  };
  const navigate = useNavigate();
  const handleGoBack = () => {
    const navigationPath =
      Cookies.get("viewMode") === "map" ? "/maptask" : "/task-manage";
    navigate(navigationPath);
    Cookies.remove("viewMode");
  };
  const totalPages = Math.ceil(totalValue / pageSize) - 1;
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const totalItems = totalValue;

  const handlePageSizeChange = (e) => {
    const size = parseInt(e.target.value, 10);
    console.log(size);
    setPageSize(size);
    setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await fetchExcelDetails(selectedOption,currentPage,pageSize);
        if (responseData.header.code === 600) {
          toast.success("test");
          setexcelDetails(responseData.body.value)
          return;
        }
        else {
          toast.error('test')
        }


      } catch (error) {
        console.error("Error fetching task form:", error);
      }
    };

    fetchData();
  }, [selectedOption,currentPage,pageSize]);


  useEffect(() => {
    console.log(pageSize);
  }, [pageSize])


  return (
    <>
      <Header pageName={translations[langMode].import} />
      <div
        onClick={handleGoBack}
        className={`p-2 ml-9 absolute top-30 rounded-full border cursor-pointer w-9 m-3 
        ${theme === "dark"
            ? "bg-gray-800 border-gray-600 hover:bg-gray-700"
            : ""
          } 
        ${theme === "high-contrast"
            ? "bg-yellow-200 border-black hover:bg-yellow-300"
            : ""
          } 
        ${theme !== "dark" && theme !== "high-contrast"
            ? "border-gray-400 hover:bg-gray-200"
            : ""
          } `}
      >
        <FaArrowLeft
          className={`${theme === "dark"
            ? "text-white"
            : theme === "high-contrast"
              ? "text-black"
              : "text-gray-700"
            }`}
        />
      </div>
      <div
        className={`px-5 pt-14 bg-white ${theme === "dark"
          ? "dark-mode"
          : theme === "high-contrast"
            ? "high-contrast"
            : ""
          }`}
      >
        <div className="flex justify-between w-full">
          <div className="overflow-x-auto sm:rounded-lg rounded-md border shadow-xl">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-md font-normal text-gray-700 border-b border-gray-300 dark:text-gray-400">
                <tr className="font-semibold">
                  <th scope="col" className="px-6 py-3 uppercase">
                    Uploaded By
                  </th>
                  <th scope="col" className="px-6 py-3 uppercase">
                    Upload Date
                  </th>
                  <th scope="col" className="px-6 py-3 uppercase">
                    Download Sheet
                  </th>
                </tr>
              </thead>
              <tbody>
                {excelDetails && excelDetails.length > 0 ? (
                  excelDetails.map((detail) => (
                    <tr
                      key={detail.id}
                      className="odd:bg-white even:bg-gray-50 border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                    >
                      <td className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                        {detail.uploadedByName}
                      </td>
                      <td className="px-6 py-4">
                        {new Date(detail.uploadedAt).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4">
                        <a
                          href={detail.excelUrl}
                          className="font-medium text-white bg-yellow-400 px-4 py-2 rounded-md hover:bg-yellow-500 transition duration-200 ease-in-out"
                          download
                        >
                          Download
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3} className="px-6 py-4 text-center text-gray-500">
                      No data available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="px-5 py-5 flex justify-between items-center">
              <div>
                <div className="text-sm text-gray-400 flex items-center gap-4">
                  <label htmlFor="pageSize">Rows per page:</label>
                  <select
                    id="countries_disabled"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={pageSize}
                    onChange={handlePageSizeChange}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
              <div>
                <p className="text-sm text-gray-400 flex px-5 justify-end gap-4">
                  {`${startIndex}-${endIndex} of ${totalItems} items`}
                  <IoIosArrowBack
                    className="cursor-pointer"
                    onClick={goToPreviousPage}
                    disabled={currentPage === 1}
                  />
                  <IoIosArrowForward
                    className="cursor-pointer"
                    onClick={goToNextPage}
                    disabled={currentPage === totalPages}
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="py-5">
            <button
              className={`bg-blue-800 hover:bg-blue-600 flex text-white py-2 px-2 rounded-lg cursor-pointer ${theme === "dark"
                ? "dark-mode"
                : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
                }`}
              onClick={handleUploadClick}
            >
             <IoCloudUpload className="mr-2 mt-1"/> Upload Task Sheet
            </button>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              onChange={handleFileChange}
              accept=".xlsx"
              disabled={!selectedOption}
            />
          </div>
        </div>

        {file && (
          <>
            {uploadProgress !== -1 && (
              <div className="block max-w-sm p-4 mt-10 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <div className="flex items-center">
                  <img
                    className="w-10 h-10"
                    alt="logo"
                    src="https://e7.pngegg.com/pngimages/261/19/png-clipart-computer-file-computer-icons-file-format-scalable-graphics-comma-separated-values-export-icon-blue-angle.png"
                  />
                  <div className="p-2">
                    <p className="text-xs font-bold text-gray-700 dark:text-gray-400">
                      {file.name}
                    </p>
                    <div className="mt-1 flex items-center gap-2">
                      <span className="text-xs text-gray-400">{fileSize}</span>
                      <div className="w-60 bg-gray-200 rounded-full h-1.5  dark:bg-gray-700">
                        <div
                          className="bg-blue-600 h-1.5 rounded-full dark:bg-blue-500"
                          style={{ width: `${uploadProgress}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <Modal
        open={open}
        setOpen={setOpen}
        value={modalValue}
        setModalValue={setModalValue}
      />
      {file && (
        <ColumnMap
          newFile={file}
          mapopen={mapopen}
          setMapOpen={setMapOpen}
          colHeaders={colHeaders}
          optionalFields={optionalFields}
          mandatoryFields={mandatoryFields}
          setModalValue={setModalValue}
          open={open}
          setOpen={setOpen}
          selectedOption={selectedOption}
          setUploadProgress={setUploadProgress}
          uploadProgress={uploadProgress}
        />
      )}
    </>
  );
};

export default ImportCsv;
