import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ReportToolbar from "../../components/report/ReportToolbar";
import Header from "../../components/_app/Header";
import RequestReport from "../../components/report/RequestReport";
import AppContext from "../../context/AppContext";
import requestImage from "../../assets/Group 84.png";
import { fetchReportData } from "../../service/Report";
import { BiSolidReport } from "react-icons/bi";
import { IoDocument } from "react-icons/io5";
const useDebounce = (func, delay) => {
  const timeoutRef = useRef(null);
  const debouncedFunction = useCallback(
    (...args) => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    },
    [func, delay]
  );
  return debouncedFunction;
};
const translations={
  en:{
    reportbtn:"Request Report",
    report:"Reports"
  },
  ar:{
      reportbtn:"طلب تقرير",
        report:"التقارير"
  }
}
const ReportList = () => {
  const [reportData, setReportData] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5); // Number of rows per page
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const { theme ,selectedOrgName , langMode} = useContext(AppContext);
  const totalPages = Math.ceil(totalValue / pageSize) - 1;
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const totalItems = totalValue;
  const handlePageSizeChange = (e) => {
    const size = parseInt(e.target.value, 10); // Use radix 10 to correctly parse integers
    setPageSize(size);
    setCurrentPage(0); // Reset to the first page
    debouncedLoadReportData(); // Fetch data with the new page size
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const tableHeaders = [
    "Organizations",
    "User",
    "User Type",
    "Report Type",
    "From date",
    "To date",
    "Status",
  ];

  const loadReportData = async () => {
    const result = await fetchReportData(currentPage, pageSize, filters,selectedOrgName);
    setReportData(result.reportList);
    setTotalValue(result.totalCount);
  };

  useEffect(() => {
    debouncedLoadReportData();
  }, [filters, currentPage, pageSize,selectedOrgName]);

  const handleReportRequestSuccess = () => {
    loadReportData(); 
  };
  const debouncedLoadReportData = useDebounce(loadReportData, 300);

  const handleDownloadClick = (url) => {
    window.open(url, "_blank");
  };
  return (
    <>
      <Header pageName={translations[langMode].report} />
      <div
        className={` ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        } px-5 py-5 mt-5 bg-white`} >
        <div className="flex justify-end items-center">
          
          <button
            type="button"
            className="px-3 py-1 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={() => setOpen(true)}
          >
          <span className="bg-white rounded-full mr-2 p-1">
          <IoDocument className="h-4 w-4 text-gray-600"/>
          </span>
            {translations[langMode].reportbtn}
          </button>
        </div>
        <div className="relative overflow-x-auto sm:rounded-lg">
          <ReportToolbar onFiltersChange={setFilters} />
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead
              className={`text-sm text-gray-500  dark:text-gray-400 ${
                theme === "dark"
                  ? "dark-mode border-b"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}>
              <tr>
                {tableHeaders.map((header, index) => (
                  <th key={index} scope="col" className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {reportData.map((report, index) => (
                <tr
                  key={index}
                  className={`bg-white text-sm border-b  dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`} >
                  <td className="px-3 py-2">{report.organizationName}</td>
                  <td className="px-3 py-2">{report.userId}</td>
                  <td className={`px-3 py-2 `}>
                    <span
                      className={`${
                        report.userType === "office_user"
                          ? " px-3 py-1 text-center font-semibold text-purple-500 rounded-full bg-purple-400 bg-opacity-30"
                          : ""}
                        ${ report.userType === "admin"
                        ? " px-3 py-1 text-center font-semibold text-blue-500 rounded-full bg-blue-400 bg-opacity-30"
                        : ""
                    }`}
                    >
                      {report.userType}
                    </span>
                  </td>
                  <td className="px-3 py-2">{report.reportType}</td>
                  <td className="px-3 py-2">{report.fromDate}</td>
                  <td className="px-3 py-2">{report.toDate}</td>
                  <td className={`px-3 `}>
                    {report.status === 0 ? (
                      <span>Pending</span>
                    ) : (
                      <button
                        className="rounded-md bg-blue-800 text-white border font-semibold text-xs p-2  my-2 "
                        onClick={() => handleDownloadClick(report.reportLink)}
                      >
                        Download
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="px-5 py-5 flex justify-between items-center">
            <>
              <div className="text-sm text-gray-400 flex items-center gap-4">
                <label htmlFor="pageSize">Rows per page:</label>
                <select
                  id="pageSize" // Added id for the label to work correctly
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                  value={pageSize}
                  onChange={handlePageSizeChange} >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                </select>
              </div>
            </>
            <div>
              <p className="text-sm text-gray-400 flex items-center gap-4">
                {`${startIndex + 1}-${Math.min(
                  endIndex,
                  totalItems
                )} of ${totalItems} items`}
                <IoIosArrowBack
                  onClick={goToPreviousPage}
                  disabled={currentPage === 0}
                />{" "}
                <IoIosArrowForward
                  onClick={goToNextPage}
                  disabled={currentPage === totalPages}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <RequestReport
        open={open}
        setOpen={setOpen}
        handleReportRequestSuccess={handleReportRequestSuccess}
      />
    </>
  );
};
export default ReportList;
