import React, { useEffect, useState, useContext } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "react-grid-layout/css/styles.css";
import {
  FaPlus,
  FaMinus,
  FaArrowLeft,
  FaChevronUp,
  FaChevronDown,
} from "react-icons/fa";
import { GiCheckMark } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { MdCheck } from "react-icons/md";
import "react-resizable/css/styles.css";
import { DraggableElement } from "../../components/workflow/DraggableElement";
import DroppableArea from "../../components/workflow/DroppableArea";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import Header from "../../components/_app/Header";
import Modal from "./PreviewModal/Modal";
import DynamicPage from "./DynamicPage";
import { useLocation, useNavigate } from "react-router-dom";
import ValidationModal from "./ValidationModal";
import ValidationPreview from "./ValidationPreview";
import WorkflowVersions from "./WorkflowVersions";
import { FaRegClone } from "react-icons/fa";
import { cloneWorkflow } from "../../service/workflow";
import AppContext from "../../context/AppContext";
import { RxCross2 } from "react-icons/rx";
import ExitModal from "./ExitModal/ExitModal";
import { Translations } from "./constants";
import { Parameters } from "./constants";
import { Elements } from "./constants";
import Joyride from "react-joyride";

const DynamicWorkFlow = () => {
  const {
    selectedOrgId,
    theme,
    langMode,
    workflowmaker,
    runTour,
    handleJoyrideCallback,
  } = useContext(AppContext);
  const [isNewFieldDragged, setIsNewFieldDragged] = useState(false);
  const [elements, setElements] = useState(Elements);
  const [parameters, setParameters] = useState(Parameters);
  const [droppedItems, setDroppedItems] = useState([]);
  const [openTab, setOpenTab] = useState(1);
  const [mainTab, setMainTab] = useState(1);
  const [showInput, setShowInput] = useState(false);
  const [inputValues, setInputValues] = useState([]);
  const [nextId, setNextId] = useState(1);
  const [savedItems, setSavedItems] = useState([]);
  const [preview, setPreview] = useState({});
  const [dragItems, setDragItems] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [tempInputValue, setTempInputValue] = useState({});
  const [tempEditValues, setTempEditValues] = useState({});
  const [isEditShow, setIsEditShow] = useState("");
  const [radioButtons, setRadioButtons] = useState([]);
  const [workflowName, setworkflowName] = useState("");
  const [reportField, setreportField] = useState(false);
  const [reportInput, setreportInput] = useState("");
  const [pdfInput, setPdfInput] = useState("");
  const [showRadioOptions, setShowRadioOptions] = useState(true);
  const [selectedRadioOption, setSelectedRadioOption] = useState();
  const [page, setPage] = useState(1);
  const [fieldIdsToBeDeleted, setfieldIdsToBeDeleted] = useState([]);
  const [radioValueIdsToBeDeleted, setradioValueIdsToBeDeleted] = useState([]);
  const [validationValueToBeDeleted, setvalidationValueToBeDeleted] = useState(
    []
  );
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [collapse, setCollapse] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedRadioId, setSelectedRadioId] = useState(null);
  const location = useLocation();
  const { isClone } = location.state || {};
  const { isEdit } = location.state || {};
  const [timerValue, setTimerValue] = useState(
    Array.from({ length: 11 }, (_, i) => (i + 1) * 5)
  );
  const [selectedTimer, setSelectedTimer] = useState(30);
  const [workflowVersionHistories, setWorkflowVersionHistories] = useState([]);
  const [showVersions, setShowVersions] = useState(false);
  const [validationId, setValidationId] = useState(1);
  const [validationModal, setValidationModal] = useState(false);
  const [exitModal, setExitModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const {
    workflowId,
    workflowFields,
    existWorkflowName,
    existPdfTitleName,
    workflowTimer,
    existDragItems,
    existRadioButtons,
  } = location.state || {};

  useEffect(() => {
    if (isEdit) {
      setworkflowName(existWorkflowName);
      setTitle(existPdfTitleName);
      setSelectedTimer(workflowTimer);
      setPdfInput(preview.excelReportName);
      setreportInput(preview.reportName);
    }
  }, [
    isEdit,
    existWorkflowName,
    existPdfTitleName,
    preview.excelReportName,
    preview.reportName,
  ]);

  useEffect(() => {
    if (isClone) {
      setPdfInput(preview.excelReportName);
      setreportInput(preview.reportName);
    }
  }, [isClone, preview.excelReportName, preview.reportName]);

  const pdfMandatory = (event, previewKey) => {
    const newDroppedItems = [...droppedItems];
    const newDraggedItems = [...dragItems];
    const newItem = newDraggedItems.find(
      (item) => item.uniqueKey === previewKey
    );

    if (newItem) {
      const droppedItem = newDroppedItems.find(
        (item) => item.uniqueKey === previewKey
      );
      if (droppedItem) {
        droppedItem.isPdf = event.target.checked;
      } else {
        console.error("Item not found in droppedItems");
      }
    } else {
      console.error("Item not found in draggedItems");
    }
    const newDroppedItem = dragItems.find(
      (item) => item.uniqueKey === previewKey
    );
    newDroppedItem.isPdf = event.target.checked;
    setDroppedItems(newDroppedItems);
    setDragItems(newDraggedItems);
    setPreview(newItem);
    setShowInput(event.target.checked);
  };

  useEffect(() => {
    const initialCollapseState = Object.keys(groupedRadioButtons).reduce(
      (acc, uniqueKey) => {
        acc[uniqueKey] = true;
        return acc;
      },
      {}
    );
    setCollapsedGroups(initialCollapseState);
    setCollapse(false);
  }, []);

  const toggleCollapse = (uniqueKey) => {
    setCollapse((prevState) => ({
      ...prevState,
      [uniqueKey]: true,
    }));
    setCollapsedGroups((prevState) => {
      const newState = {};
      Object.keys(prevState).forEach((key) => {
        newState[key] = true;
      });
      newState[uniqueKey] = !prevState[uniqueKey];
      return newState;
    });
  };

  const workflowUpdate = async (isEdit = true) => {
    const token = Cookies.get("accessToken");
    const dataToSend = {
      workflowId: workflowId,
      timer: isEdit ? selectedTimer : workflowTimer,
      pdfTitle: isEdit ? title : existPdfTitleName,
      workflowName: isEdit ? workflowName : existWorkflowName,
      fieldsMetadata: droppedItems,
      fieldIdsToBeDeleted: fieldIdsToBeDeleted,
      radioValueIdsToBeDeleted: radioValueIdsToBeDeleted,
      validationValueToBeDeleted: validationsDelteId,
      validationValueToBeDeleted: validationValueToBeDeleted,
    };
    const url = `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/workflow/update`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dataToSend),
      });
      const jsonData = await response.json();
      if (jsonData.header.code === 600) {
        toast.success(jsonData.body.value);
        navigate("/workflow");
      } else {
        toast.error(jsonData.body.value);
      }
    } catch (err) {}
  };
  const handleVersionClick = async () => {
    try {
      setShowVersions((val) => !val);
      const token = Cookies.get("accessToken");
      if (workflowId) {
        const url = `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/workflow/get-workflow-history?workflowId=${workflowId}&page=0&size=10`;
        try {
          const response = await fetch(url, {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          const jsonData = await response.json();
          if (jsonData.header.code === 600) {
            if (jsonData.body.value.workflowHistories.length > 0) {
              setWorkflowVersionHistories(
                jsonData.body.value.workflowHistories
              );
            } else {
              toast.info("No versions available");
            }
          } else {
            toast.error("Failed to fetch versions");
          }
        } catch (err) {}
      } else {
        toast.info("Create Workflow");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (existDragItems && existDragItems.length > 0) {
      setDroppedItems(existDragItems);
      if (Array.isArray(workflowFields) && workflowFields.length > 0) {
        workflowFields.forEach((field) => {
          if (Array.isArray(field.validationValues)) {
            setValidationFormValues(field.validationValues);
          } else {
            console.error(
              `validationValues is not an array for field: ${field.fieldName}`
            );
          }
        });
      } else {
        console.error(
          "existDragItems is either not defined or not an array or is empty."
        );
      }
    }
    if (existDragItems && existDragItems.length > 0) {
      setDragItems(existDragItems);
    }
    if (existRadioButtons && existRadioButtons.length > 0) {
      setRadioButtons(existRadioButtons);
    }
  }, [workflowFields]);

  const handleTimer = (event) => {
    setSelectedTimer(parseInt(event.target.value)); // Convert the value to an integer
  };

  const handleChangee = (event) => {
    setworkflowName(event.target.value);
  };

  const titleHandle = (event) => {
    setTitle(event.target.value);
  };

  const handleReportInput = (event) => {
    setreportInput(event.target.value);
  };

  const handlePdfInput = (event) => {
    setPdfInput(event.target.value);
  };

  const workflowSubmit = async () => {
    const token = Cookies.get("accessToken");
    const dataToSend = {
      timer: selectedTimer,
      workflowName: workflowName,
      pdfTitle: title,
      fieldsMetadata: droppedItems,
      organizationId: selectedOrgId,
    };
    const url = `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/workflow/create-workflow`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dataToSend),
      });
      const jsonData = await response.json();
      if (jsonData.header.code === 600) {
        toast.success(jsonData.body.value);
        navigate("/workflow");
      } else {
        toast.error(jsonData.body.value);
      }
    } catch (err) {}
  };

  function updateNestedAllInputValues(items, targetUniqueKey, newInputData) {
    return items.map((item) => {
      if (item.uniqueKey === targetUniqueKey) {
        return {
          ...item,
          allInputValues: [...newInputData],
        };
      }
      if (item.radioInputValues && item.radioInputValues.length > 0) {
        const updatedRadioInputValues = updateNestedAllInputValues(
          item.radioInputValues,
          targetUniqueKey,
          newInputData
        );
        return { ...item, radioInputValues: updatedRadioInputValues };
      }
      return item;
    });
  }

  const updateAllInputValues = (updatedValues, previewkey) => {
    let newAllInputValues = [];
    updatedValues.forEach((input) => {
      if (input.uniqueKey === previewkey && input.value !== "") {
        const key = input.radioKey !== undefined ? input.radioKey : input.id;
        const newEntry = {
          inputKey: `input-${key}`,
          inputValue: input.value,
        };
        if (input.workflowFormFieldMetadataId !== undefined) {
          newEntry.id = input.id;
        }
        newAllInputValues.push(newEntry);
      }
    });

    const updatedDroppedItems = updateNestedAllInputValues(
      droppedItems,
      previewkey,
      newAllInputValues
    );
    setDroppedItems(updatedDroppedItems);
    setDragItems((prevItems) => {
      return prevItems.map((item) => {
        if (item.uniqueKey === previewkey) {
          return {
            ...item,
            allInputValues: newAllInputValues,
          };
        }
        return item;
      });
    });
  };

  // const handleAddInput = (uniqueKey, page) => {
  //   setInputValues([
  //     ...inputValues,
  //     { id: nextId, value: "", uniqueKey: uniqueKey },
  //   ]);
  //   setRadioButtons([
  //     ...radioButtons,
  //     {
  //       id: nextId,
  //       value: "",
  //       uniqueKey: uniqueKey,
  //       isShow: true,
  //       radioPage: page,
  //     },
  //   ]);
  //   setNextId(nextId + 1);
  // };
  const handleAddInput = (uniqueKey, page) => {
    const nextKey = `${new Date().getTime()}`;

    setInputValues([
      ...inputValues,
      { id: nextKey, value: "", uniqueKey: uniqueKey },
    ]);

    setRadioButtons([
      ...radioButtons,
      {
        id: nextKey,
        value: "",
        uniqueKey: uniqueKey,
        isShow: true,
        radioPage: page,
      },
    ]);
  };

  const addReportfield = (previewKey, reportName) => {
    if (!reportInput && !reportName) {
      toast.warn("Add report name first");
      return;
    }
    const updatedData = droppedItems.map((item) => {
      if (item.uniqueKey === previewKey) {
        return {
          ...item,
          reportName: reportInput,
          reportField: false,
        };
      }
      return item;
    });
    setDroppedItems(updatedData);
    setDragItems(updatedData);
    setreportField(false); // Uncheck the checkbox
  };

  const addPdffield = (previewKey, excelReportName) => {
    if (!pdfInput && !excelReportName) {
      toast.warn("Add report name first");
      return;
    }
    const updatedData = droppedItems.map((item) => {
      if (item.uniqueKey === previewKey) {
        return {
          ...item,
          excelReportName: pdfInput,
          showInput: false,
        };
      }
      return item;
    });
    setDroppedItems(updatedData);
    setDragItems(updatedData);
    setShowInput(false);
  };

  const onCloseValidation = () => setValidationModal(false);
  const [validationFormValues, setValidationFormValues] = useState([]);
  const [currentValidation, setCurrentValidation] = useState({
    validationId: validationId,
    validationCode: "",
    errorMessage: "",
    uniqueKey: "",
  });

  useEffect(() => {}, [validationFormValues]);

  const handleCreateValidation = (event) => {
    event.preventDefault();
    if (currentValidation.validationCode !== "") {
      setValidationFormValues((prev) => [...prev, currentValidation]);
      updateValidationField(
        validationFormValues.concat(currentValidation),
        currentValidation.uniqueKey
      );
    }
    setCurrentValidation({
      validationId: validationId,
      validationCode: "",
      errorMessage: "",
      uniqueKey: "",
    });
    setValidationModal(false);
  };
  const updateValidationField = (updatedValues, previewkey) => {
    let newValidationVals = updatedValues
      .filter(
        (input) => input.uniqueKey === previewkey && input.validationCode !== ""
      )
      .map((input) => ({
        validationId: `validation-${input.validationId}`,
        errorMessage: input.errorMessage,
        validationCode: input.validationCode,
      }));
    const updatedDroppedItems = updateNestedFiledValidations(
      droppedItems,
      previewkey,
      newValidationVals
    );
    setDroppedItems(updatedDroppedItems);
  };
  function updateNestedFiledValidations(items, targetUniqueKey, newInputData) {
    return items.map((item) => {
      if (item.uniqueKey === targetUniqueKey) {
        return {
          ...item,
          validationValues: [...newInputData],
        };
      } else if (item.radioInputValues && item.radioInputValues.length > 0) {
        const updatedRadioInputValues = updateNestedFiledValidations(
          item.radioInputValues,
          targetUniqueKey,
          newInputData
        );
        return {
          ...item,
          radioInputValues: updatedRadioInputValues,
        };
      }
      return item;
    });
  }

  const [validationsDelteId, setValidationsDeleteId] = useState([]);
  const handleValidationDelete = (id, previewKey, editId) => {
    setvalidationValueToBeDeleted((prevFieldIds) => [...prevFieldIds, editId]);
    setValidationFormValues((prevItems) => {
      const updatedValues = prevItems.filter(
        (item) => !(item.validationId === id && item.uniqueKey === previewKey)
      );
      return updatedValues;
    });
    setDroppedItems((prevItems) =>
      prevItems.map((item) => deleteValidationFromItem(item, id, previewKey))
    );
    setValidationsDeleteId((prev) => [...prev, editId]);
  };

  function deleteValidationFromItem(item, id, previewKey) {
    if (item.uniqueKey === previewKey) {
      if (item.validationValues) {
        item.validationValues = item.validationValues.filter(
          (validation) => validation.validationId !== `validation-${id}`
        );
      }
      if (item.radioInputValues && item.radioInputValues.length > 0) {
        item.radioInputValues = item.radioInputValues.map((nestedItem) =>
          deleteValidationFromItem(nestedItem, id, previewKey)
        );
      }
    } else if (item.radioInputValues && item.radioInputValues.length > 0) {
      item.radioInputValues = item.radioInputValues.map((nestedItem) =>
        deleteValidationFromItem(nestedItem, id, previewKey)
      );
    }
    return item;
  }

  const handleRadioOptions = (id, value, previewkey) => {
    let updatedRadioValues = [...radioButtons];
    const index = updatedRadioValues.findIndex(
      (input) => input.id === id && input.uniqueKey === previewkey
    );
    if (index !== -1) {
      updatedRadioValues[index] = { ...updatedRadioValues[index], value };
    } else {
      updatedRadioValues.push({ id, value, uniqueKey: previewkey });
    }
    setRadioButtons(updatedRadioValues);
    updateAllInputValues(updatedRadioValues, previewkey);
    setInputValues([{ id, value, uniqueKey: previewkey }]);
    removeSpecificInput(id, previewkey);
  };

  const removeSpecificInput = (idToRemove, uniqueKeyToRemove) => {
    const updatedRadioButtons = inputValues.filter(
      (input) =>
        !(input.id === idToRemove && input.uniqueKey === uniqueKeyToRemove)
    );
    setInputValues(updatedRadioButtons);
  };

  const handleRadioDeleteOptions = (id, radioKey, value, previewKey) => {
    setradioValueIdsToBeDeleted((prevFieldIds) => [...prevFieldIds, id]);
    setDroppedItems((prevItems) =>
      prevItems.map((item) => {
        if (item.uniqueKey === previewKey) {
          let canDelete =
            !item.radioInputValues ||
            (Array.isArray(item.radioInputValues) &&
              item.radioInputValues.length === 0);
          if (canDelete) {
            setRadioButtons((prevRadioButtons) =>
              prevRadioButtons.filter(
                (btn) =>
                  !(
                    btn.id === id &&
                    btn.radioKey === radioKey &&
                    btn.uniqueKey === previewKey
                  )
              )
            );
            setDragItems((prevDragItems) =>
              prevDragItems.map((dragItem) => {
                if (dragItem.uniqueKey === previewKey) {
                  let updatedAllInputValues = dragItem.allInputValues.filter(
                    (input) => input.inputKey !== `input-${radioKey}-${id}`
                  );
                  return { ...dragItem, allInputValues: updatedAllInputValues };
                }
                return dragItem;
              })
            );
            setTempInputValue((prevValues) => {
              const keyToDelete = `${previewKey}-${radioKey}-${id}`;
              const { [keyToDelete]: _, ...remainingValues } = prevValues;
              return remainingValues;
            });
            return {
              ...item,
              allInputValues: item.allInputValues.filter(
                (input) => input.inputKey !== `input-${radioKey}-${id}`
              ),
            };
          } else {
            toast.warning(
              "Warning: This option is already included in the workflow. Removal is restricted."
            );
          }
        }
        return item;
      })
    );
    setRadioButtons((prevRadioButtons) => {
      const updatedRadioButtons = prevRadioButtons.filter(
        (btn) =>
          !(
            btn.id === id &&
            btn.radioKey === radioKey &&
            btn.uniqueKey === previewKey
          )
      );
      updateAllInputValues(updatedRadioButtons, previewKey);
      return updatedRadioButtons;
    });
    removeSpecificInput(id, previewKey);
  };

  const handleInputChange = (event, preview) => {
    setIsChecked(false);
    const newDroppedItems = [...droppedItems];
    const newDraggedItems = [...dragItems];
    const newItem = newDraggedItems.find((item) => item.uniqueKey === preview);
    if (newItem) {
      newItem.fieldName = event.target.value;
    } else {
      console.error("Item not found in droppedItems");
    }
    const newDroppedItem = newDroppedItems.find(
      (item) => item.uniqueKey === preview
    );
    if (newDroppedItem) {
      newDroppedItem.fieldName = event.target.value;
    } else {
      console.error("Item not found in dragItems");
    }
    setDroppedItems(newDroppedItems);
    setDragItems(newDraggedItems);
    setPreview(newItem);
  };
  const reportMandatory = (event, preview) => {
    const newDroppedItems = [...droppedItems];
    const newDraggedItems = [...dragItems];
    const newItem = newDraggedItems.find((item) => item.uniqueKey === preview);
    if (newItem) {
      const droppedItem = newDroppedItems.find(
        (item) => item.uniqueKey === preview
      );
      if (droppedItem) {
        droppedItem.isReport = event.target.checked;
      } else {
        console.error("Item not found in droppedItems");
      }
    } else {
      console.error("Item not found in draggedItems");
    }
    const newDroppedItem = dragItems.find((item) => item.uniqueKey === preview);
    newDroppedItem.isReport = event.target.checked;
    setDroppedItems(newDroppedItems);
    setDragItems(newDraggedItems);
    setPreview(newItem);
    if (event.target.checked === true) {
      setreportField(true);
    } else {
      setreportField(false);
    }
  };
  const handleToggleChange = (event, preview) => {
    setIsChecked(false);
    const newDroppedItems = [...droppedItems];
    const newDraggedItems = [...dragItems];
    const newItem = newDraggedItems.find((item) => item.uniqueKey === preview);
    if (newItem) {
      let droppedItem = newDroppedItems.find(
        (item) => item.uniqueKey === preview
      );
      if (!droppedItem) {
        newDroppedItems.forEach((item) => {
          if (item.radioInputValues) {
            item.radioInputValues.forEach((radioInput) => {
              if (radioInput.uniqueKey === preview) {
                droppedItem = radioInput;
              }
            });
          }
        });
      }
      if (droppedItem) {
        droppedItem.isMandatory = event.target.checked;
        if (droppedItem.radioInputValues) {
          droppedItem.radioInputValues.forEach((radioInput) => {
            radioInput.isMandatory = event.target.checked;
          });
        }
      } else {
        console.error("Item not found in droppedItems");
      }
    } else {
      console.error("Item not found in draggedItems");
    }
    const newDroppedItem = dragItems.find((item) => item.uniqueKey === preview);
    newDroppedItem.isMandatory = event.target.checked;
    setDroppedItems(newDroppedItems);
    setDragItems(newDraggedItems);
    setPreview(newItem);
  };

  const handleRadioChange = (id, selectedValue, uniqueKey) => {
    setInputValues((prevInputValues) =>
      prevInputValues.map((input) =>
        input.id === id ? { ...input, selected: selectedValue } : input
      )
    );
  };

  function insertIntoRadioInputValues(
    items,
    targetUniqueKey,
    newItem,
    radioId
  ) {
    return items.reduce((acc, item) => {
      if (item.uniqueKey === targetUniqueKey) {
        const itemWithRadioId = { ...newItem, radioId: `input-${radioId}` };
        acc.push(item);
        acc.push(itemWithRadioId);
      } else {
        acc.push(item);
      }
      return acc;
    }, []);
  }

  function removeFromRadioInputValues(items, targetUniqueKey) {
    return items.reduce((acc, item) => {
      if (item.uniqueKey === targetUniqueKey) {
        return acc;
      } else if (item.radioInputValues) {
        const updatedRadioInputValues = removeFromRadioInputValues(
          item.radioInputValues,
          targetUniqueKey
        );
        if (updatedRadioInputValues.length > 0) {
          acc.push({ ...item, radioInputValues: updatedRadioInputValues });
        } else {
          const { radioInputValues, ...rest } = item;
          acc.push(rest);
        }
      } else {
        acc.push(item);
      }
      return acc;
    }, []);
  }

  function insertRadioVal(selectedItem, radioId, radioKey) {
    return droppedItems.map((val) =>
      val.uniqueKey === selectedItem.uniqueKey
        ? {
            ...val,
            radioId: radioKey ? `input-${radioKey}` : `input-${radioId}`,
          }
        : val
    );
  }

  function getFieldNameByInputKey(keyNumber, previewKey) {
    const inputKey = `input-${keyNumber}`;
    let foundFieldName = null;
    for (const item of droppedItems) {
      if (item.allInputValues) {
        for (const input of item.allInputValues) {
          if (input.inputKey === inputKey) {
            foundFieldName = item.fieldName;
            let updatedRadioValues = [...radioButtons];
            const index = updatedRadioValues.findIndex(
              (radioInput) =>
                radioInput.id === keyNumber &&
                radioInput.uniqueKey === previewKey
            );
            if (index !== -1) {
              updatedRadioValues[index] = {
                ...updatedRadioValues[index],
                fieldName: foundFieldName,
              };
            } else {
              updatedRadioValues.push({
                id: keyNumber,
                fieldName: foundFieldName,
                uniqueKey: previewKey,
              });
            }
            setRadioButtons(updatedRadioValues);
            break;
          }
        }
      }
      if (foundFieldName) break;
    }
    return foundFieldName;
  }

  const handleRadioBtnBelongs = (
    radioUniqueKey,
    radioId,
    value,
    preview,
    radioKey
  ) => {
    const fieldName = getFieldNameByInputKey(radioId, radioUniqueKey);
    const selectedItem = droppedItems.find(
      (dropItem) => dropItem.uniqueKey === preview.uniqueKey
    );
    const filteredDroppedItems = droppedItems.filter(
      (dropItem) => dropItem.uniqueKey !== preview.uniqueKey
    );
    if (!selectedItem) {
      const itemsWithoutSelected = removeFromRadioInputValues(
        droppedItems,
        preview.uniqueKey
      );
      const findItemRecursive = (items, uniqueKey) => {
        for (let item of items) {
          if (item.uniqueKey === uniqueKey) {
            return item;
          } else if (item.radioInputValues) {
            const found = findItemRecursive(item.radioInputValues, uniqueKey);
            if (found) return found;
          }
        }
        return null;
      };
      const newSelectedItem = findItemRecursive(
        droppedItems,
        preview.uniqueKey
      );
      const updatedDroppedItems = insertIntoRadioInputValues(
        itemsWithoutSelected,
        radioUniqueKey,
        newSelectedItem,
        radioId
      );
      const updateRadioVal = insertRadioVal(newSelectedItem, radioId, radioKey);
      setDroppedItems(updateRadioVal);
    } else {
      if (filteredDroppedItems.length === 0) {
      } else {
        const updateRadioVal = insertRadioVal(selectedItem, radioId, radioKey);
        setDroppedItems(updateRadioVal);
      }
    }
  };
  const handleRemoveInput = () => {
    setInputValues(inputValues.slice(0, -1));
  };
  const shouldRenderSelectOption = radioButtons.some(
    (input) => input.uniqueKey !== preview.uniqueKey && input.value !== ""
  );
  const shouldRenderSelectOptionEdit = radioButtons.some(
    (input) => input.uniqueKey === preview.uniqueKey && input.value !== ""
  );
  const handleRemoveElement = (uniqueKey, backendkey) => {
    const collectNestedKeys = (item) => {
      let keys = [];
      if (item.radioInputValues) {
        for (const nestedItem of item.radioInputValues) {
          keys.push(nestedItem.uniqueKey);
          keys = keys.concat(collectNestedKeys(nestedItem));
        }
      }
      return keys;
    };

    const removeItemRecursive = (items, uniqueKey, collectedKeys = []) => {
      return items.reduce((acc, item) => {
        if (item.uniqueKey === uniqueKey) {
          collectedKeys.push(...collectNestedKeys(item));
          return acc;
        }
        if (item.radioInputValues) {
          item.radioInputValues = removeItemRecursive(
            item.radioInputValues,
            uniqueKey,
            collectedKeys
          );
        }
        acc.push(item);
        return acc;
      }, []);
    };
    let collectedKeys = [];
    const updatedDroppedItems = removeItemRecursive(
      droppedItems,
      uniqueKey,
      collectedKeys
    );
    const updateDragItems = dragItems.filter(
      (item) =>
        !collectedKeys.includes(item.uniqueKey) &&
        !uniqueKey.includes(item.uniqueKey)
    );
    const updatedRadioButtons = radioButtons.filter(
      (item) =>
        !collectedKeys.includes(item.uniqueKey) &&
        !uniqueKey.includes(item.uniqueKey)
    );
    setRadioButtons(updatedRadioButtons);
    setDragItems(updateDragItems);
    setDroppedItems(updatedDroppedItems);
    setfieldIdsToBeDeleted((prevFieldIds) => [...prevFieldIds, backendkey]);
    const updatedPreview = updatedDroppedItems.find(
      (item) => item.uniqueKey === uniqueKey
    );
    setPreview(updatedPreview || {});
  };

  const selectedId = parseInt(
    selectedRadioOption ? selectedRadioOption.split("-")[1] : ""
  );

  const selectedRadioButton = radioButtons.find((button) => {
    const keyToCompare =
      button.radioKey !== undefined ? button.radioKey : button.id;
    return keyToCompare === selectedId;
  });

  const displaySelectedValue = selectedRadioButton
    ? selectedRadioButton.value
    : "";
  const question = selectedRadioButton ? selectedRadioButton.fieldName : "";
  const FieldQuestion = preview.fieldName;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [workflowPreviewData, setWorkflowPreviewData] = useState([]);
  const handlePreviewClick = () => {
    setIsModalOpen(true);
    setWorkflowPreviewData(droppedItems);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const navigate = useNavigate();
  const navigateToWorkflow = () => {
    navigate("/workflow");
  };

  const handleCloneElement = (cloneName, fieldName) => {
    const elementToClone = elements.find(
      (element) => element.cloneName === cloneName
    );
    const elementWithInputValues = dragItems.find(
      (element) =>
        element.uniqueKey === preview.uniqueKey && element.allInputValues
    );
    if (elementToClone) {
      const newUniqueKey = `${new Date().getTime()}`;
      const { icon, ...elementWithoutIcon } = elementToClone;
      const newElement = {
        ...elementWithoutIcon,
        uniqueKey: newUniqueKey,
        page,
        fieldName: fieldName,
      };
      if (elementWithInputValues) {
        let localNextId = nextId;
        const updatedInputValues = elementWithInputValues.allInputValues.map(
          (inputValue) => {
            const newInputKey = `input-${localNextId}`;
            const newRadioButton = {
              id: localNextId,
              value: inputValue.inputValue,
              uniqueKey: newUniqueKey,
              isShow: true,
              radioPage: page,
            };
            setRadioButtons((prevButtons) => [...prevButtons, newRadioButton]);
            const updatedInputValue = {
              ...inputValue,
              inputKey: newInputKey,
            };
            localNextId += 1;
            return updatedInputValue;
          }
        );
        newElement.allInputValues = updatedInputValues;
        setNextId(localNextId);
      }
      setDroppedItems((prevItems) => [...prevItems, newElement]);
      setDragItems((prevItems) => [...prevItems, newElement]);
    }
  };
  const groupedRadioButtons = radioButtons.reduce((acc, input) => {
    if (input.isShow === true && input.value !== "") {
      if (!acc[input.uniqueKey]) {
        acc[input.uniqueKey] = [];
      }
      acc[input.uniqueKey].push(input);
    }
    return acc;
  }, {});
  const getHeadline = (uniqueKey) => {
    const item = dragItems.find((item) => item.uniqueKey === uniqueKey);
    return item ? item.fieldName : "";
  };

  const workflowClone = async (isClone = true) => {
    const transformedDroppedItems = droppedItems.map((item) => ({
      fieldName: item.fieldName,
      value: "",
      fieldType: item.fieldType,
      isMandatory: item.isMandatory,
      excelReportName: item.excelReportName,
      reportName: item.reportName,
      isReport: item.isReport !== null ? item.isReport : false,
      cloneName: item.cloneName,
      validationValues: item.validationValues,
      uniqueKey: item.uniqueKey,
      page: item.page,
    }));

    try {
      const message = await cloneWorkflow(
        workflowName,
        selectedTimer,
        transformedDroppedItems,
        selectedOrgId,
        title
      );
      toast.success("Cloned Successfully");
      navigate("/workflow");
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleCloneClick = () => {
    if (!workflowName.trim()) {
      toast.error("Please enter a name for the cloned workflow.");
      return;
    }
    workflowClone(true);
  };

  const handleExitModal = () => {
    if (
      (!isEdit && !isClone && dragItems.length !== 0) ||
      droppedItems.length !== 0
    ) {
      console.log(dragItems, droppedItems);
      setExitModal(true);
    } else if (isEdit || isClone) {
      console.log(dragItems, droppedItems);
      setExitModal(true);
    } else {
      navigateToWorkflow();
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (
        !isEdit &&
        !isClone &&
        (dragItems.length > 0 || droppedItems.length > 0)
      ) {
        e.preventDefault();
        setExitModal(true);
        e.returnValue = "";
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isEdit, isClone, dragItems.length, droppedItems.length]);

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    const handlePopState = (e) => {
      if (
        !isEdit &&
        !isClone &&
        (dragItems.length > 0 || droppedItems.length > 0)
      ) {
        e.preventDefault();
        setExitModal(true);
        e.returnValue = "";
        e.returnValue = "";
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    console.log(droppedItems);
  }, [droppedItems]);

  return (
    <>
      <Joyride
        steps={workflowmaker}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
        callback={handleJoyrideCallback}
      />
      <Header
        pageName={Translations[langMode].workflow}
        onClick={handleExitModal}
      />
      <div
        className={`min-h-screen bg-gray-100 flex flex-col  ${
          theme === "dark"
            ? "dark-mode "
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        <div className="flex justify-start ">
          <div
            onClick={handleExitModal}
            // onClick={navigateToWorkflow}
            className={`ml-5 flex h-8 p-2 rounded-full border border-gray-400 hover:bg-gray-200 cursor-pointer   ${
              theme === "dark"
                ? "bg-gray-800 border-gray-600 hover:bg-gray-700"
                : ""
            } 
    ${
      theme === "high-contrast"
        ? "bg-yellow-200 border-black hover:bg-yellow-300"
        : ""
    } 
    ${
      theme !== "dark" && theme !== "high-contrast"
        ? "border-gray-400 hover:bg-gray-200"
        : ""
    }`}
          >
            <FaArrowLeft
              className={`${
                theme === "dark"
                  ? "text-white"
                  : theme === "high-contrast"
                  ? "text-black"
                  : "text-gray-700"
              }`}
            />
          </div>
          <div className="flex items-center ml-52 ">
            <div>
              {isClone ? (
                <div className="flex flex-col space-y-2 py-3">
                  <div className="flex items-center">
                    <label
                      htmlFor="cloneName"
                      className="text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {Translations[langMode].entername}
                    </label>
                    <span className="text-red-500 ml-1">*</span>
                  </div>
                  <input
                    id="cloneName"
                    type="text"
                    value={workflowName}
                    className={` ${
                      theme === "dark"
                        ? "dark-mode "
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[350px] p-2.5  dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    onChange={handleChangee}
                    placeholder="Enter workflow name"
                  />
                </div>
              ) : isEdit ? (
                <div className="flex flex-col space-y-2 py-2">
                  <div className="flex items-center">
                    <label
                      htmlFor="cloneName"
                      className="text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {Translations[langMode].entername}
                    </label>
                    <span className="text-red-500 ml-1">*</span>
                  </div>
                  <input
                    id="cloneName"
                    type="text"
                    value={workflowName}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[350px] p-2.5   dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                      theme === "dark"
                        ? "dark-mode "
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }`}
                    onChange={handleChangee}
                    placeholder="Enter workflow name"
                  />
                </div>
              ) : (
                <div className="flex flex-col space-y-2 py-2">
                  <div className="flex items-center">
                    <label
                      htmlFor="cloneName"
                      className="text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {Translations[langMode].entername}
                    </label>
                    <span className="text-red-500 ml-1">*</span>
                  </div>
                  <input
                    id="cloneName"
                    type="text"
                    value={workflowName}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[350px] p-2.5   dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                      theme === "dark"
                        ? "dark-mode "
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }`}
                    onChange={handleChangee}
                    placeholder="Enter workflow name"
                  />
                </div>
              )}
            </div>
            <div className="ml-5">
              {isEdit ? (
                <div className="flex flex-col space-y-2 py-3">
                  <div className="flex items-center">
                    <label
                      htmlFor="cloneName"
                      className="text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {Translations[langMode].title}
                    </label>
                    <span className="text-red-500 ml-1">*</span>
                  </div>
                  <input
                    id="cloneName"
                    type="text"
                    value={title}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[350px] p-2.5   dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                      theme === "dark"
                        ? "dark-mode "
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }`}
                    onChange={titleHandle}
                    placeholder="Enter PDF Title"
                  />
                </div>
              ) : (
                <div className="flex flex-col space-y-2 py-3">
                  <div className="flex items-center">
                    <label
                      htmlFor="cloneName"
                      className="text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {Translations[langMode].title}
                    </label>
                    <span className="text-red-500 ml-1">*</span>
                  </div>
                  <input
                    id="cloneName"
                    type="text"
                    value={title}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[350px] p-2.5   dark:focus:ring-blue-500 dark:focus:border-blue-500  
                      ${
                        theme === "dark"
                          ? "dark-mode "
                          : theme === "high-contrast"
                          ? "high-contrast"
                          : ""
                      }`}
                    onChange={titleHandle}
                    placeholder="Enter PDF title"
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            {isEdit ? (
              <div className="flex flex-col space-y-2 py-3">
                <div className="flex items-center">
                  <label
                    htmlFor="cloneName"
                    className="text-sm ml-5 font-medium text-gray-900 dark:text-white"
                  >
                    {Translations[langMode].selectTimer}
                  </label>
                </div>
                <select
                  id="numberDropdown"
                  className={`w-full ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block  p-2.5   dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                    theme === "dark"
                      ? "dark-mode "
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                  onChange={handleTimer}
                  value={selectedTimer}
                >
                  {timerValue.map((option) => (
                    <option
                      key={option}
                      value={option}
                      className={` ${
                        theme === "dark"
                          ? "dark-mode "
                          : theme === "high-contrast"
                          ? "high-contrast"
                          : ""
                      }`}
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div className="flex flex-col space-y-2 py-3">
                <div className="flex items-center">
                  <label
                    htmlFor="cloneName"
                    className="text-sm ml-5 font-medium text-gray-900 dark:text-white"
                  >
                    {Translations[langMode].selectTimer}
                  </label>
                </div>
                <select
                  id="numberDropdown"
                  className={`w-full ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block  p-2.5   dark:focus:ring-blue-500 dark:focus:border-blue-500 *: ${
                    theme === "dark"
                      ? "dark-mode "
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                  onChange={handleTimer}
                  value={selectedTimer}
                >
                  {timerValue.map((option) => (
                    <option
                      key={option}
                      value={option}
                      className={`${
                        theme === "dark"
                          ? "dark-mode "
                          : theme === "high-contrast"
                          ? "high-contrast"
                          : ""
                      }`}
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>
        <DndProvider backend={HTML5Backend}>
          <div className="flex-grow flex justify-between gap-4">
            <div
              className={`w-1/6 bg-white p-4 rounded-md border border-gray-300 shadow-2xl  ${
                theme === "dark"
                  ? "dark-mode "
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
            >
              {mainTab === 1 && (
                <React.Fragment className="">
                  <div className="mb-4 flex rounded-sm fields">
                    <button
                      onClick={() => setOpenTab(1)}
                      className={` py-2 px-2 rounded-sm text-gray-400 font-semibold transition-all duration-300 ${
                        openTab === 1
                          ? "border-b-4 border-blue-900 text-gray-900"
                          : ""
                      } ${
                        theme === "dark"
                          ? "dark-mode "
                          : theme === "high-contrast"
                          ? "high-contrast"
                          : ""
                      }`}
                    >
                      {Translations[langMode].element}
                    </button>
                    <button
                      onClick={() => setOpenTab(2)}
                      className={` py-2 px-2 rounded-sm text-gray-400  font-semibold  transition-all duration-300 ${
                        openTab === 2
                          ? "border-b-4 border-blue-900 text-gray-900"
                          : ""
                      } ${
                        theme === "dark"
                          ? "dark-mode "
                          : theme === "high-contrast"
                          ? "high-contrast"
                          : ""
                      }`}
                    >
                      {Translations[langMode].parameter}
                    </button>
                  </div>
                  <div
                    className=""
                    style={{ display: openTab === 1 ? "block" : "none" }}
                  >
                    {elements.map((element) => (
                      <DraggableElement
                        key={element.id}
                        id={element.id}
                        value={element.value}
                        fieldName={element.fieldName}
                        isNewFieldDragged={isNewFieldDragged}
                        setIsNewFieldDragged={setIsNewFieldDragged}
                        icon={element.icon}
                        fieldType={element.fieldType}
                        isMandatory={element.isMandatory}
                        isReport={element.isReport}
                        cloneName={element.cloneName}
                        validationValues={validationFormValues}
                        setSelectedRadioId={setSelectedRadioId}
                        setCollapse={setCollapse}
                      />
                    ))}
                  </div>
                  <div style={{ display: openTab === 2 ? "block" : "none" }}>
                    {parameters.map((parameter) => (
                      <DraggableElement
                        key={parameter.id}
                        id={parameter.id}
                        value={parameter.value}
                        fieldName={parameter.fieldName}
                        fieldType={parameter.fieldType}
                        isNewFieldDragged={isNewFieldDragged}
                        setIsNewFieldDragged={setIsNewFieldDragged}
                      />
                    ))}
                  </div>
                </React.Fragment>
              )}
            </div>
            <DroppableArea
              setPdfInput={setPdfInput}
              setDroppedItems={setDroppedItems}
              droppedItems={droppedItems}
              mainTab={mainTab}
              setMainTab={setMainTab}
              savedItems={savedItems}
              setPreview={setPreview}
              preview={preview}
              setDragItems={setDragItems}
              dragItems={dragItems}
              handleRemoveInput={handleRemoveInput}
              setreportInput={setreportInput}
              setreportField={setreportField}
              setShowInput={setShowInput}
              setShowRadioOptions={setShowRadioOptions}
              setSelectedRadioOption={setSelectedRadioOption}
              setRadioButtons={setRadioButtons}
              radioButtons={radioButtons}
              page={page}
              handleVersionClick={handleVersionClick}
              isNewFieldDragged={isNewFieldDragged}
              setIsNewFieldDragged={setIsNewFieldDragged}
              setSelectedRadioId={setSelectedRadioId}
              setCollapse={setCollapse}
            />
            <div
              className={`w-1/3 bg-white p-6 rounded-md border border-gray-300 shadow-2xl  ${
                theme === "dark"
                  ? "dark-mode "
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
            >
              <h6 className="text-md font-semibold">
                {Translations[langMode].preview}
              </h6>
              <div className="flex justify-center">
                <DynamicPage
                  page={page}
                  setPage={setPage}
                  dragItems={dragItems}
                />
              </div>
              {showVersions && (
                <WorkflowVersions
                  workflowHistories={workflowVersionHistories}
                />
              )}
              {preview.page === page && (
                <>
                  <div
                    className={`block max-w-sm p-8 mt-5 bg-slat-50 border border-gray-300
                  rounded-md shadow-lg hover:bg-gray-150  ${
                    theme === "dark"
                      ? "dark-mode "
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                  >
                    <div className="flex justify-end">
                      <div>
                        <FaRegClone
                          onClick={() => {
                            handleCloneElement(
                              preview.cloneName,
                              preview.fieldName
                            );
                          }}
                          style={{ cursor: "pointer" }}
                          className="mr-5"
                        />
                      </div>
                      <div>
                        <IoMdClose
                          onClick={() => {
                            handleRemoveElement(preview.uniqueKey, preview.id);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <div key={preview.uniqueKey} className="mb-6">
                      <label
                        htmlFor={`input-${preview.uniqueKey}`}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white max-w-full overflow-hidden text-ellipsis whitespace-nowrap"
                      >
                        {preview.fieldName}
                      </label>
                      <input
                        type="text"
                        id={`input-${preview.uniqueKey}`}
                        defaultValue={preview.fieldName || "Default Text"}
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md
                           focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                             dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                               theme === "dark"
                                 ? "dark-mode "
                                 : theme === "high-contrast"
                                 ? "high-contrast"
                                 : ""
                             }`}
                        onChange={(event) =>
                          handleInputChange(event, preview.uniqueKey)
                        }
                      />
                      <ValidationPreview
                        setCurrentValidation={setCurrentValidation}
                        setValidationModal={setValidationModal}
                        setValidationId={setValidationId}
                        previewKey={preview.uniqueKey}
                        validationId={validationId}
                        validationFormValues={validationFormValues}
                      />
                      {isEdit ? (
                        <>
                          <div className="flex items-center justify-between">
                            <div className="relative inline-block w-10 mr-2 align-middle select-none my-3">
                              <label className="font-semibold whitespace-nowrap">
                                {Translations[langMode].mandatory}
                              </label>
                              <input
                                fieldName={`toggle-${preview.uniqueKey}`}
                                checked={preview.isMandatory}
                                id={`green-checkbox toggle-${preview.uniqueKey}`}
                                type="checkbox"
                                onChange={(event) =>
                                  handleToggleChange(event, preview.uniqueKey)
                                }
                                class="w-4 ml-2 h-4 text-green-600 bg-gray-100 border-gray-500 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </div>
                            <div className="relative inline-block w-10 mr-2 align-middle select-none">
                              <label className="font-semibold whitespace-nowrap">
                                {Translations[langMode].excel}
                              </label>
                              <span className="text-red-500 ml-1">*</span>
                              <input
                                fieldName={`toggle-${preview.uniqueKey}`}
                                // checked={showInput}
                                checked={preview.excelReportName && showInput}
                                id={`green-checkbox toggle-${preview.uniqueKey}`}
                                type="checkbox"
                                onChange={(event) =>
                                  pdfMandatory(event, preview.uniqueKey)
                                }
                                class="w-4 ml-2 h-4 text-green-600 bg-gray-100 border-gray-500 rounded
                                 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </div>
                          </div>
                          {showInput && (
                            <div className="flex justify-start items-center py-3 gap-2 space-x-4">
                              <input
                                type="text"
                                placeholder="Enter Excel Name.."
                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm 
                                  rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[250px] 
                                  p-2.5 transition duration-300 ease-in-out transform hover:scale-105  
                                   dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                                     theme === "dark"
                                       ? "dark-mode "
                                       : theme === "high-contrast"
                                       ? "high-contrast"
                                       : ""
                                   }`}
                                value={pdfInput}
                                onChange={handlePdfInput}
                              />
                              <GiCheckMark
                                onClick={() => addPdffield(preview.uniqueKey)}
                                className="text-blue-500  cursor-pointer transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-700"
                                size={20}
                              />
                            </div>
                          )}
                          <div>
                            <div className="relative inline-block w-10 mr-2 align-middle select-none">
                              <label className="font-semibold whitespace-nowrap">
                                {Translations[langMode].pdf}
                              </label>
                              <input
                                fieldName={`toggle-${preview.uniqueKey}`}
                                checked={preview.isReport && reportField}
                                id={`green-checkbox toggle-${preview.uniqueKey}`}
                                type="checkbox"
                                onChange={(event) =>
                                  reportMandatory(event, preview.uniqueKey)
                                }
                                class="w-4 ml-2 h-4 text-green-600 bg-gray-100 border-gray-500 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </div>
                            {reportField && (
                              <div className="flex justify-start items-center py-3 gap-2 space-x-4">
                                <input
                                  type="text"
                                  placeholder="Enter PDF Name..."
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md
                                   focus:ring-blue-500 focus:border-blue-500 block w-[250px] p-2.5 transition 
                                   duration-300 ease-in-out transform hover:scale-105   dark:focus:ring-blue-500
                                    dark:focus:border-blue-500  ${
                                      theme === "dark"
                                        ? "dark-mode "
                                        : theme === "high-contrast"
                                        ? "high-contrast"
                                        : ""
                                    }`}
                                  value={reportInput}
                                  onChange={handleReportInput}
                                />
                                <GiCheckMark
                                  onClick={() =>
                                    addReportfield(preview.uniqueKey)
                                  }
                                  className="text-blue-500  cursor-pointer transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-700"
                                  size={20}
                                />
                              </div>
                            )}
                          </div>
                        </>
                      ) : isClone ? (
                        <>
                          <div className="flex items-center justify-between">
                            <div className="relative inline-block w-10 mr-2 align-middle select-none my-3">
                              <label className="font-semibold whitespace-nowrap">
                                {Translations[langMode].mandatory}
                              </label>
                              <input
                                fieldName={`toggle-${preview.uniqueKey}`}
                                checked={preview.isMandatory}
                                id={`green-checkbox toggle-${preview.uniqueKey}`}
                                type="checkbox"
                                onChange={(event) =>
                                  handleToggleChange(event, preview.uniqueKey)
                                }
                                class="w-4 ml-2 h-4 text-green-600 bg-gray-100 border-gray-500 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </div>
                            <div className="relative inline-block w-10 mr-2 align-middle select-none">
                              <label className="font-semibold whitespace-nowrap">
                                {Translations[langMode].excel}
                              </label>
                              <span className="text-red-500 ml-1">*</span>
                              <input
                                fieldName={`toggle-${preview.uniqueKey}`}
                                checked={showInput}
                                id={`green-checkbox toggle-${preview.uniqueKey}`}
                                type="checkbox"
                                onChange={(event) =>
                                  pdfMandatory(event, preview.uniqueKey)
                                }
                                class="w-4 ml-2 h-4 text-green-600 bg-gray-100 border-gray-500 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </div>
                          </div>
                          {showInput && (
                            <div className="flex justify-start items-center py-3 gap-2 space-x-4">
                              <input
                                type="text"
                                placeholder="Enter Excel Name.."
                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md
                                 focus:ring-blue-500 focus:border-blue-500 block w-[250px] 
                                 p-2.5 transition duration-300 ease-in-out transform hover:scale-105 
                                   dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                                     theme === "dark"
                                       ? "dark-mode "
                                       : theme === "high-contrast"
                                       ? "high-contrast"
                                       : ""
                                   }`}
                                value={pdfInput}
                                onChange={handlePdfInput}
                              />
                              <GiCheckMark
                                onClick={() => addPdffield(preview.uniqueKey)}
                                className="text-blue-500  cursor-pointer transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-700"
                                size={20}
                              />
                            </div>
                          )}
                          <div>
                            <div className="relative inline-block w-10 mr-2 align-middle select-none">
                              <label className="font-semibold whitespace-nowrap">
                                {Translations[langMode].pdf}
                              </label>
                              <input
                                fieldName={`toggle-${preview.uniqueKey}`}
                                checked={preview.isReport}
                                id={`green-checkbox toggle-${preview.uniqueKey}`}
                                type="checkbox"
                                onChange={(event) =>
                                  reportMandatory(event, preview.uniqueKey)
                                }
                                class="w-4 ml-2 h-4 text-green-600 bg-gray-100 border-gray-500 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </div>
                            {reportField && (
                              <div className="flex justify-start items-center py-3 gap-2 space-x-4">
                                <input
                                  type="text"
                                  placeholder="Enter PDF Name..."
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm 
                                  rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[250px] p-2.5 
                                  transition duration-300 ease-in-out transform hover:scale-105  
                                   dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                                     theme === "dark"
                                       ? "dark-mode "
                                       : theme === "high-contrast"
                                       ? "high-contrast"
                                       : ""
                                   }`}
                                  value={reportInput}
                                  onChange={handleReportInput}
                                />
                                <GiCheckMark
                                  onClick={() =>
                                    addReportfield(preview.uniqueKey)
                                  }
                                  className="text-blue-500  cursor-pointer transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-700"
                                  size={20}
                                />
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="flex items-center justify-between">
                            <div className="mandtory relative inline-block w-10 mr-2 align-middle select-none my-3">
                              <label className="font-semibold whitespace-nowrap">
                                {Translations[langMode].mandatory}
                              </label>
                              <input
                                fieldName={`toggle-${preview.uniqueKey}`}
                                checked={preview.isMandatory}
                                id={`green-checkbox toggle-${preview.uniqueKey}`}
                                type="checkbox"
                                onChange={(event) =>
                                  handleToggleChange(event, preview.uniqueKey)
                                }
                                class="w-4 ml-2 h-4 text-green-600 bg-gray-100 border-gray-500 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </div>
                            <div className="excel relative inline-block w-10 mr-2 align-middle select-none">
                              <label className="font-semibold whitespace-nowrap">
                                {" "}
                                {Translations[langMode].excel}
                              </label>
                              <span className="text-red-500 ml-1">*</span>
                              <input
                                fieldName={`toggle-${preview.uniqueKey}`}
                                // checked={
                                //   preview.isPdf || preview.excelReportName
                                // }
                                checked={showInput}
                                id={`green-checkbox toggle-${preview.uniqueKey}`}
                                type="checkbox"
                                onChange={(event) =>
                                  pdfMandatory(event, preview.uniqueKey)
                                }
                                class="w-4 ml-2 h-4 text-green-600 bg-gray-100 border-gray-500 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </div>
                          </div>
                          {showInput && (
                            <div className="flex justify-start items-center py-3 gap-2 space-x-4">
                              <input
                                type="text"
                                placeholder="Enter Excel Name..."
                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md
                                 focus:ring-blue-500 focus:border-blue-500 block w-[250px] p-2.5 transition 
                                 duration-300 ease-in-out transform hover:scale-105   dark:focus:ring-blue-500
                                  dark:focus:border-blue-500  ${
                                    theme === "dark"
                                      ? "dark-mode "
                                      : theme === "high-contrast"
                                      ? "high-contrast"
                                      : ""
                                  }`}
                                value={pdfInput || preview.excelReportName}
                                onChange={handlePdfInput}
                              />
                              <GiCheckMark
                                onClick={() =>
                                  addPdffield(
                                    preview.uniqueKey,
                                    preview.excelReportName
                                  )
                                }
                                className="text-blue-500  cursor-pointer transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-700"
                                size={20}
                              />
                            </div>
                          )}
                          <div>
                            <div className="pdf relative inline-block w-10 mr-2 align-middle select-none">
                              <label className="font-semibold text-sm whitespace-nowrap">
                                {Translations[langMode].pdf}
                              </label>
                              <input
                                fieldName={`toggle-${preview.uniqueKey}`}
                                checked={preview.isReport && reportField}
                                id={`green-checkbox toggle-${preview.uniqueKey}`}
                                type="checkbox"
                                onChange={(event) =>
                                  reportMandatory(event, preview.uniqueKey)
                                }
                                class="w-4 ml-2 h-4 text-green-600 bg-gray-100 border-gray-500 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </div>
                            {reportField && (
                              <div className="flex justify-start items-center py-3 gap-2 space-x-4">
                                <input
                                  type="text"
                                  placeholder="Enter PDF Name..."
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md
                                   focus:ring-blue-500 focus:border-blue-500 block w-[250px] p-2.5
                                    transition duration-300 ease-in-out transform hover:scale-105  
                                     dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                                       theme === "dark"
                                         ? "dark-mode "
                                         : theme === "high-contrast"
                                         ? "high-contrast"
                                         : ""
                                     }`}
                                  value={reportInput || preview.reportName}
                                  onChange={handleReportInput}
                                  disabled={!preview.isReport}
                                />
                                <GiCheckMark
                                  onClick={() =>
                                    addReportfield(
                                      preview.uniqueKey,
                                      preview.reportName
                                    )
                                  }
                                  className="text-blue-500  cursor-pointer transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-700"
                                  size={20}
                                />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {shouldRenderSelectOption && (
                        <div className="mt-5">
                          <p className="font-semibold text-md">
                            {" "}
                            {Translations[langMode].selectoption}
                          </p>
                          {Object.keys(groupedRadioButtons).map((uniqueKey) => (
                            <div
                              key={uniqueKey}
                              className={`mb-4 rounded-md p-3 mt-2 ${
                                shouldRenderSelectOptionEdit &&
                                uniqueKey === preview.uniqueKey
                                  ? ""
                                  : "border border-blue-500"
                              }`}
                            >
                              <h3
                                className="text-md font-semibold mb-2 flex items-center cursor-pointer justify-between"
                                onClick={() => toggleCollapse(uniqueKey)}
                              >
                                {uniqueKey !== preview.uniqueKey
                                  ? getHeadline(uniqueKey)
                                  : ""}

                                {!(
                                  shouldRenderSelectOptionEdit &&
                                  uniqueKey === preview.uniqueKey
                                ) && (
                                  <span className="ml-2">
                                    {collapse[uniqueKey] &&
                                    collapsedGroups[uniqueKey] ? (
                                      <FaChevronDown className="h-3" />
                                    ) : (
                                      <FaChevronUp className="h-3" />
                                    )}
                                  </span>
                                )}
                              </h3>
                              {collapse[uniqueKey] &&
                                !collapsedGroups[uniqueKey] && (
                                  <div>
                                    {groupedRadioButtons[uniqueKey].map(
                                      (input) => {
                                        const isChecked =
                                          selectedRadioId === input.id;
                                        return input.uniqueKey !==
                                          preview.uniqueKey ? (
                                          <div
                                            key={input.id}
                                            className="flex items-center space-x-2.5 mt-2.5"
                                          >
                                            <input
                                              type="text"
                                              value={input.value}
                                              className={`bg-gray-50 border border-gray-400 text-gray-900 text-sm
                                             rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                                               dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                                                 theme === "dark"
                                                   ? "dark-mode "
                                                   : theme === "high-contrast"
                                                   ? "high-contrast"
                                                   : ""
                                               }`}
                                              readOnly
                                            />
                                            <label className="relative">
                                              <input
                                                type="radio"
                                                checked={isChecked}
                                                name={`radio-${input.id}`}
                                                value={input.value}
                                                onChange={(e) => {
                                                  handleRadioBtnBelongs(
                                                    input.uniqueKey,
                                                    input.id,
                                                    e.target.value,
                                                    preview,
                                                    input.radioKey
                                                  );
                                                  setSelectedRadioId(input.id);
                                                }}
                                                className="appearance-none w-6 h-6 border border-gray-500 rounded-full checked:bg-blue-600"
                                              />
                                              {isChecked && (
                                                <div className="absolute inset-0 flex items-center justify-center">
                                                  <div
                                                    className={`w-3 h-3 bg-white rounded-full  ${
                                                      theme === "dark"
                                                        ? "dark-mode "
                                                        : theme ===
                                                          "high-contrast"
                                                        ? "high-contrast"
                                                        : ""
                                                    }`}
                                                  ></div>
                                                </div>
                                              )}
                                            </label>
                                          </div>
                                        ) : null;
                                      }
                                    )}
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      )}
                      {shouldRenderSelectOptionEdit && (
                        <div>
                          <div className="py-3">
                            <button
                              onClick={() => setIsEditShow((val) => !val)}
                              className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-md hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-900 dark:focus:ring-blue-800"
                            >
                              {Translations[langMode].editInputvalue}
                            </button>
                          </div>
                          {isEditShow && (
                            <>
                              {radioButtons.map((input) =>
                                input.uniqueKey === preview.uniqueKey &&
                                input.value !== "" ? (
                                  <div
                                    key={input.id}
                                    className="flex items-center space-x-2.5 mt-2.5"
                                  >
                                    <input
                                      type="text"
                                      value={
                                        tempEditValues[
                                          `${input.uniqueKey}-${input.id}`
                                        ] || input.value
                                      }
                                      onChange={(e) =>
                                        setTempEditValues((prevValues) => ({
                                          ...prevValues,
                                          [`${input.uniqueKey}-${input.id}`]:
                                            e.target.value,
                                        }))
                                      }
                                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm 
                                      rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                                        dark:focus:ring-blue-500 dark:focus:border-blue-500  ${
                                          theme === "dark"
                                            ? "dark-mode "
                                            : theme === "high-contrast"
                                            ? "high-contrast"
                                            : ""
                                        }`}
                                    />
                                    <MdCheck
                                      onClick={() => {
                                        const editValue =
                                          tempEditValues[
                                            `${input.uniqueKey}-${input.id}`
                                          ] || input.value;
                                        handleRadioOptions(
                                          input.id,
                                          editValue,
                                          preview.uniqueKey
                                        );
                                      }}
                                    />
                                    <RxCross2
                                      onClick={() => {
                                        const editValue =
                                          tempEditValues[
                                            `${input.uniqueKey}-${input.id}`
                                          ] || input.value;
                                        handleRadioDeleteOptions(
                                          input.id,
                                          input.radioKey,
                                          editValue,
                                          preview.uniqueKey
                                        );
                                      }}
                                    />
                                  </div>
                                ) : null
                              )}
                            </>
                          )}
                        </div>
                      )}
                      <div className="mt-3">
                        {isNewFieldDragged && displaySelectedValue && (
                          <p className="font-semibold text-md">
                            {Translations[langMode].selectedOption}:
                            <span className="text-md font-normal block">
                              {Translations[langMode].Que}: {question}
                            </span>
                            <span className="text-md font-normal block">
                              {Translations[langMode].option}:{" "}
                              {displaySelectedValue}
                            </span>
                          </p>
                        )}
                      </div>
                      <div>
                        <div>
                          {preview.fieldType === "select" ||
                          preview.fieldType === "radio" ||
                          preview.fieldType === "checkbox" ? (
                            <>
                              <div className="flex justify-between item-center py-2">
                                <label>
                                  {Translations[langMode].addremove}
                                </label>
                                <FaPlus
                                  onClick={() => {
                                    handleAddInput(
                                      preview.uniqueKey,
                                      preview.page
                                    );
                                  }}
                                />
                                <FaMinus onClick={handleRemoveInput} />
                              </div>
                              {inputValues.map((input) =>
                                input.uniqueKey === preview.uniqueKey ? (
                                  <div
                                    key={input.id}
                                    className="flex items-center space-x-2.5 mt-2.5"
                                  >
                                    <input
                                      type="text"
                                      value={
                                        tempInputValue[
                                          `${input.uniqueKey}-${input.id}`
                                        ] || input.value
                                      }
                                      onChange={(e) =>
                                        setTempInputValue((prevValues) => ({
                                          ...prevValues,
                                          [`${input.uniqueKey}-${input.id}`]:
                                            e.target.value,
                                        }))
                                      }
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    />
                                    <GiCheckMark
                                      onClick={() => {
                                        const editValue =
                                          tempInputValue[
                                            `${input.uniqueKey}-${input.id}`
                                          ] || input.value;

                                        if (!editValue.trim()) {
                                          toast.error(
                                            "Input field cannot be empty!"
                                          );
                                          return;
                                        }
                                        handleRadioOptions(
                                          input.id,
                                          editValue,
                                          preview.uniqueKey
                                        );
                                      }}
                                    />
                                  </div>
                                ) : null
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </DndProvider>
        {validationModal && (
          <ValidationModal
            onCloseValidation={onCloseValidation}
            handleCreateValidation={handleCreateValidation}
            setCurrentValidation={setCurrentValidation}
            currentValidation={currentValidation}
            validationFormValues={validationFormValues}
            previewKey={preview.uniqueKey}
            handleValidationDelete={handleValidationDelete}
          />
        )}
        <div className="w-100 p-6 flex gap-4">
          {isClone ? (
            <button
              type="button"
              onClick={handleCloneClick}
              className="px-5 py-3 text-sm font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-md hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-900 dark:focus:ring-blue-800"
            >
              {Translations[langMode].clone}
            </button>
          ) : workflowFields && workflowFields.length > 0 ? (
            <button
              type="button"
              className="px-5 py-3 text-sm font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-md hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-900 dark:focus:ring-blue-800"
              onClick={workflowUpdate}
            >
              {Translations[langMode].update}
            </button>
          ) : (
            <button
              type="button"
              className="px-5 py-3 text-sm font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-md hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-900 dark:focus:ring-blue-800"
              onClick={workflowSubmit}
            >
              {Translations[langMode].Submit}
            </button>
          )}
          <button
            type="button"
            className="px-5 py-3 text-sm font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-md hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-900 dark:focus:ring-blue-800"
            onClick={handlePreviewClick}
          >
            {Translations[langMode].preview}
          </button>
          {isModalOpen && workflowPreviewData && (
            <Modal
              onClose={handleCloseModal}
              previewData={workflowPreviewData}
              page={page}
            />
          )}
        </div>
      </div>

      {exitModal && (
        <ExitModal
          setExitModal={setExitModal}
          exitModal={exitModal}
          navigateToWorkflow={navigateToWorkflow}
        />
      )}
    </>
  );
};

export default DynamicWorkFlow;
