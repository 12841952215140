import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_BASE_BACKEND_API_URL;

export const fetchTasks = async ({
  workflowId,
  currentPage,
  pageSize,
  priority,
  active,
  areaCode,
  accountNo,
  selectedUserId,
  selectedSubactivity,
}) => {
  const token = Cookies.get("accessToken");
  let priorityParam = priority === "Priority" ? "Everything" : priority;
  let statusParam = active === "Status" ? "Everything" : active;

  // Build the params object dynamically
  const params = {
    workflowId,
    priority: priorityParam,
    status: statusParam,
    page: currentPage,
    size: pageSize,
    areaCode,
    accountNo,
    assigneeId: selectedUserId,
  };

  // Conditionally add subActivity only if selectedSubactivity is not empty or undefined
  if (selectedSubactivity) {
    params.subActivity = selectedSubactivity;
  }

  try {
    const response = await axios.get(`${baseURL}api/v1/task/list/get-tasks`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.body.value;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};


export const getTaskDetail = async (taskId) => {
  const token = Cookies.get("accessToken");

  try {
    const response = await axios.get(
      `${baseURL}api/v1/task/web/get-task?taskId=${taskId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.body.value;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const updateTaskPriority = async (taskId, newPriority) => {
  try {
    const token = Cookies.get("accessToken");
    const data = {
      taskId: taskId,
      priority: newPriority,
    };

    const response = await axios.post(
      `${baseURL}api/v1/task/update-task-priority`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating task priority:", error);
    throw error;
  }
};

export const deleteTasks = async (selectedtask) => {
  const token = Cookies.get("accessToken");
  const payload = { taskIdList: selectedtask };

  try {
    const response = await axios.post(
      `${baseURL}api/v1/task/multi-delete`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to put tasks on hold
export const putTasksOnHold = async (selectedTasks) => {
  const token = Cookies.get("accessToken");
  const selectedTaskIds = selectedTasks.map((task) => task.taskId);
  const payload = { taskIdList: selectedTaskIds };

  try {
    const response = await axios.post(
      `${baseURL}api/v1/task/tasks-on-hold`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to get field agents
export const getFieldAgents = async () => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.get(`${baseURL}api/v1/user/field-agents`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.body.value;
  } catch (error) {
    throw error;
  }
};

// Function to delete all records
export const deleteAllRecords = async (workflowId) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.post(
      `${baseURL}api/v1/task/delete-tasks`,
      { workflowId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function for smart assign
export const smartAssign = async (workflowId) => {
  const token = Cookies.get("accessToken");

  try {
    const response = await axios.post(
      `${baseURL}api/v1/assignment-rule/smart-assign`,
      null,  // No request body
      {
        params: { workflowId },  // Pass workflowId as query parameter
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const GetTaskHistory = async (accountNo, page, size) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.get(
      `${baseURL}api/v1/task/get-task-history?accountNo=${accountNo}&page=${page}&size=${size}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.body.value;
  } catch (error) {
    throw error;
  }
};

//task assign
export const fetchFieldAgents = async () => {
  try {
    const token = Cookies.get("accessToken");
    const response = await axios.get(
      `${baseURL}api/v1/user/field-agents`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.body.value;
  } catch (error) {
    console.error("An error occurred while fetching field agents:", error);
    throw error; // Propagate the error back to the caller
  }
};

//multi task assign
export const assignTasks = async (taskIdList, fieldAgentId, workflowId) => {
  try {
    const token = Cookies.get("accessToken");
    const response = await axios.post(
      `${baseURL}api/v1/task/multi-assign`,
      {
        taskIdList,
        fieldAgentId,
        workflowId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("An error occurred while assigning tasks:", error);
    throw error; // Propagate the error back to the caller
  }
};

export const fetchPdfData = async (
  selectedOption,
  AccountNo,
  currentPage,
  pageSize,
  fromDate,
  toDate
) => {
  const token = Cookies.get("accessToken");
  const formatDateTime = (date, isStartOfDay) => {
    const isoString = new Date(date).toISOString();
    return isStartOfDay
      ? isoString.replace("T00:00:00.000Z", "T00:00:00.000")
      : isoString.replace("T00:00:00.000Z", "T23:59:59.999");
  };

  const formattedFromDate = fromDate ? formatDateTime(fromDate, true) : "";
  const formattedToDate = toDate ? formatDateTime(toDate, false) : "";

  const response = await axios.get(
    `${baseURL}api/v1/task/task-pdf-list?workflowId=${selectedOption}&accountNo=${AccountNo}&page=${currentPage}&size=${pageSize}&fromDate=${formattedFromDate}&toDate=${formattedToDate}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data.body.value;
};

export const getTaskDetails = async (taskId) => {
  const token = Cookies.get("accessToken");
  return fetch(
    `${baseURL}api/v1/task/web/get-task?taskId=${taskId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json());
};

export const getFieldAgentList = () => {
  const token = Cookies.get("accessToken");
  return axios.get(
    `${baseURL}api/v1/user/field-agents`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getTasks = async (workflowId, taskStatus) => {
  
  if (!workflowId || !taskStatus) {
    console.warn("Both workflowId and taskStatus are required to fetch tasks");
    return null; // or return an empty array/object depending on your needs
  }

  const token = Cookies.get("accessToken");
  try {
    const response = await axios.get(
      `${baseURL}api/v1/task/map/get-tasks?workflowId=${workflowId}&taskStatus=${taskStatus}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Return the data from the response
  } catch (error) {
    console.error("Error fetching tasks:", error);
    return null; // or handle the error appropriately
  }
};


export const multiassignTasks = async (multiAgent, selectedfieldAgent, workflowId) => {
  // Check if selectedfieldAgent is valid and multiAgent is not empty
  if (
    selectedfieldAgent !== undefined &&
    selectedfieldAgent !== null &&
    selectedfieldAgent !== '' &&
    Array.isArray(multiAgent) &&
    multiAgent.length > 0
  ) {
    const temp = JSON.stringify({
      taskIdList: multiAgent,
      fieldAgentId: selectedfieldAgent,
      workflowId: workflowId,
    });
    const token = Cookies.get("accessToken");
    return fetch(`${baseURL}api/v1/task/multi-assign`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: temp,
    }).then((response) => response.json());
  } else {
    toast.error('Please select the field agents and assign tasks');
  }
};


export const deleteTask = async (taskId) => {
  const temp = JSON.stringify({ taskIdList: [taskId] });
  const token = Cookies.get("accessToken");
  return fetch(
    `${baseURL}api/v1/task/multi-delete`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: temp,
    }
  ).then((response) => response.json());
};

//taskdetail page

export const fetchTaskDetail = async (taskId) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.get(
      `${baseURL}api/v1/task/web/get-task-with-metadata`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          taskId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching task details");
  }
};

export const updateTaskDetails = async (updatedTask) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.post(
      `${baseURL}api/v1/task/update-task`,
      updatedTask,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error updating task details");
  }
};

//export data

export const fetchExportData = (selectedOption, startDate, endDate) => {
  const token = Cookies.get("accessToken");

  const formattedStartDate = moment(startDate).format("YYYY-MM-DDTHH:mm:ss");
  const formattedEndDate = moment(endDate).format("YYYY-MM-DDTHH:mm:ss");

  // Convert selectedOption array to a comma-separated string
  const workflowIds = selectedOption.join(',');

  return axios.get(
    `${baseURL}api/v1/task/generate-report`,
    {
      params: {
        workflowIds: workflowIds, // Pass the workflow IDs as a comma-separated string
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};


//create task page

export const fetchTaskForm = async (workflowId) => {
  if (!workflowId) {
    console.warn("workflowId is required to fetch Data");
    return {};
  }
  const token = Cookies.get("accessToken");

  try {
    const response = await axios.get(
      `${baseURL}api/v1/task-meta/get-task-form?workflowId=${workflowId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data for activity:", error);
    throw error;
  }
};

export const saveTask = async (workflowId, taskData, accountNo) => {
  const token = Cookies.get("accessToken");
  const requestData = {
    workflowId,
    priority: "Low_Priority",
    taskDetails: JSON.stringify(taskData), // Convert taskData to a JSON string
    accountNo,
  };

  try {
    const response = await axios.post(
      `${baseURL}api/v1/task/update-task`,
      requestData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error saving task:", error);
    throw error;
  }
};

export const fetchFilterExcel = async ({
  workflowIds,
  priority,
  active,
  areaCode,
  accountNo,
  organizationId,
}) => {
  const token = Cookies.get("accessToken");
  let priorityParam = priority === "Priority" ? "Everything" : priority;
  let statusParam = active === "Status" ? "Everything" : active;

  try {
    const response = await axios.get(
      `${baseURL}api/v1/task/list/generate-report`,
      {
        params: {
          workflowIds,
          priority: priorityParam,
          status: statusParam,
          areaCode,
          accountNo,
          organizationId
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const fetchFieldAgentMap = async (selectedOrgId) => {
  const token = Cookies.get("accessToken");
  const userType = Cookies.get("userType");

  const url =
    userType === "super_admin" && selectedOrgId
      ? `${baseURL}api/v1/user/field-agents?organizationId=${selectedOrgId}`
      : `${baseURL}api/v1/user/field-agents`;

  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const Subactivity = (workflowId) => {
  const token = Cookies.get("accessToken");
  return axios.get(
    `${baseURL}api/v1/workflow/sub-activity-list?workflowId=${workflowId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const fetchMetadataColumn = async (workflowId) => {
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.get(
      `${baseURL}api/v1/task-meta/excel-upload/get-field-names?workflowId=${workflowId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching organization data:", error);
    throw error;
  }
};

export const fetchExcelDetails = async (workflowId,currentPage,pageSize) => {
  if (!workflowId) {
    console.warn("workflowId is required to fetch Data");
    return {};
  }
  const token = Cookies.get("accessToken");

  try {
    const response = await axios.get(
      `${baseURL}api/v1/task-excel/get-all?workflowId=${workflowId}&page=${currentPage}&size=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data for activity:", error);
    throw error;
  }
};
