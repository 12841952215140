import React from 'react'
import { useRef, useState } from "react";
import otpImage from "../../assets/otpImage.png";
import ResetPasswordPage from "./ResetPasswordPage";
import { toast } from "react-toastify";
import { checkOtp } from "../../service/AuthService";

const OtpVerification = ({ emaill }) => {
  const [resetEmail, setResetEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const inputOne = useRef(null);
  const inputTwo = useRef(null);
  const inputThree = useRef(null);
  const inputFour = useRef(null);
  const inputChangeHandler = (event, nextInput) => {
    if (event.target.value.length === 1 && nextInput) {
      nextInput.current.focus();
    }
  };

  async function onSubmit(event) {
    try {
      event.preventDefault();
      setLoading(true);

      const otp_1 = inputOne.current.value;
      const otp_2 = inputTwo.current.value;
      const otp_3 = inputThree.current.value;
      const otp_4 = inputFour.current.value;
      const otp = `${otp_1}${otp_2}${otp_3}${otp_4}`;
      const responseData = await checkOtp(otp, emaill);
      if (responseData.header.code === 600) {
        toast.success("OTP correct");
        setLoading(false);
        setResetEmail(emaill);
      } else {
        toast.error(responseData.body.value);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to verify OTP");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      {!resetEmail && (
        <div className="bg-gray-50 flex justify-center items-center h-screen">
          <div className="lg:p-36 md:p-52 sm:20 p-8 w-full lg:w-1/2 text-center">
            <h1 className="text-md text-blue-800 mb-4">
              We have sent you{" "}
              <span className="font-bold">One Time Password</span> to your email
            </h1>
            <p className="text-md text-gray-500 font-semibold">
              Please Enter OTP
            </p>
            <div className="mt-10">
              <form onSubmit={onSubmit}>
                <div className="flex flex-col space-y-16">
                  <div className="flex flex-row items-center justify-between mx-auto w-full max-w-xs">
                    <div className="w-16 h-16">
                      <input
                        className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none border-b-2 border-gray-400 text-lg bg-white"
                        type="text"
                        name="inputOne"
                        id="inputOne"
                        maxLength={1}
                        ref={inputOne}
                        onChange={(e) => inputChangeHandler(e, inputTwo)}
                      />
                    </div>
                    <div className="w-16 h-16">
                      <input
                        className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none border-b-2 border-gray-400 text-lg bg-white"
                        type="text"
                        name="inputTwo"
                        id="inputTwo"
                        maxLength={1}
                        ref={inputTwo}
                        onChange={(e) => inputChangeHandler(e, inputThree)}
                      />
                    </div>
                    <div className="w-16 h-16">
                      <input
                        className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none border-b-2 border-gray-400 text-lg bg-white"
                        type="text"
                        name="inputThree"
                        id="inputThree"
                        maxLength={1}
                        ref={inputThree}
                        onChange={(e) => inputChangeHandler(e, inputFour)}
                      />
                    </div>
                    <div className="w-16 h-16">
                      <input
                        className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none border-b-2 border-gray-400 text-lg bg-white"
                        type="text"
                        name="inputFour"
                        id="inputFour"
                        maxLength={1}
                        ref={inputFour}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-center gap-6">
                    <button
                      className="rounded-lg  py-3 px-6 font-sans text-xs  uppercase text-blue-800 border border-blue-800  transition-all  focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      data-ripple-light="true"
                    >
                      Resend OTP
                    </button>
                    <button
                      className="rounded-lg bg-blue-800 py-3 px-6 font-sans text-xs  uppercase text-white  transition-all hover:shadow-lg  focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      data-ripple-light="true"
                    >
                      Verify OTP
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="w-1/2 h-screen hidden lg:block">
            <img
              src={otpImage}
              alt="Placeholder Image"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      )}
      {resetEmail && <ResetPasswordPage emaill={resetEmail} />}
    </div>
  );
};

export default OtpVerification;
