import React, { useContext } from "react";
import AppContext from "../context/AppContext";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children, privilegesRequired }) => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const hasRequiredPrivileges =
    user && privilegesRequired.every((privilege) => user[privilege] === true);
  if (hasRequiredPrivileges) {
    return children;
  } else {
    if (privilegesRequired.includes("canCreateDashboard")) {
      navigate("/all-users");
    }
    return (
      <>
        <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm flex justify-center items-center z-50">
          <div className="bg-white p-5 rounded-lg shadow-xl">
            <h2 className="text-sm font-semibold text-red-600">
              Access Denied !
            </h2>
            <p className="texts-sm">
              You don't have the necessary permissions to view this page.
            </p>
            <button
              className="mt-4 px-4 py-2 bg-blue-500 text-white text-nowrap hover:bg-blue-700 text-sm rounded-md w-[100px]"
              style={{ whiteSpace: "nowrap" }}
              onClick={() => window.history.back()}
            >
              Go Back →
            </button>
          </div>
        </div>
        <div className="filter blur-sm">{children}</div>
      </>
    );
  }
};

export default ProtectedRoute;
