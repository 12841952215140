import React, { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import AppContext from "../../context/AppContext";
import { RxCross2 } from "react-icons/rx";

export default function Modal({ downloadModal, setDownloadModal, downloadFiltration }) {
  const cancelButtonRef = useRef(null);
  const { theme } = useContext(AppContext);
  const [selectedOption, setSelectedOption] = useState("all"); // Default to 'all'

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleDownload = () => {
    downloadFiltration(selectedOption);
    setDownloadModal(false)
  };

  return (
    <Transition.Root show={downloadModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setDownloadModal(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity`} />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${
                  theme === "dark" ? "dark-mode" : theme === "high-contrast" ? "high-contrast" : ""
                }`}
              >
                <div className={`bg-white rounded-lg shadow ${theme === "dark" ? "dark-mode" : theme === "high-contrast" ? "high-contrast" : ""}`}>
                  <div className="flex items-center justify-between p-2 md:p-5 rounded-t dark:border-gray-600">
                    <h3 className="text-md font-semibold text-gray-900 dark:text-white">
                      Download Task Report
                    </h3>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setDownloadModal(false)}
                    >
                      <RxCross2 />
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="flex flex-col p-4">
                    <div className="flex items-center mb-2">
                      <input
                        type="radio"
                        id="selectAll"
                        name="activityOption"
                        value="all"
                        checked={selectedOption === "all"}
                        onChange={handleOptionChange}
                        className="mr-2"
                      />
                      <label htmlFor="selectAll" className="text-gray-700 dark:text-gray-200 text-md">
                        Select All Activity
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        id="selectParticular"
                        name="activityOption"
                        value="particular"
                        checked={selectedOption === "particular"}
                        onChange={handleOptionChange}
                        className="mr-2"
                      />
                      <label htmlFor="selectParticular" className="text-gray-700 dark:text-gray-200 text-md">
                        Selected Particular Activity
                      </label>
                    </div>
                  </div>
                  <div className="flex justify-end p-5 gap-5">
                    <button
                      className="rounded-md border-2 shadow-inner px-2 py-2"
                      onClick={() => setDownloadModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-yellow-400 hover:bg-yellow-500 rounded-md px-2 py-2 text-white"
                      onClick={handleDownload} // Correctly pass function reference
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
