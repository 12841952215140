import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import Header from "../../components/_app/Header";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import AppContext from "../../context/AppContext";
import Modal from "../../components/modals/Modal";
import { fetchPdfData } from "../../service/Task";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
const translation = {
  en: {
    pdf: "PDF",
  },
  ar: {},
};
function PdfGenerator() {
  const [activeTab, setActiveTab] = useState("table");
  const [pdfData, setPdfData] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [AccountNo, setAccountNo] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedPdfLink, setSelectedPdfLink] = useState("");
  const context = useContext(AppContext);
  const { selectedOption } = context;
  const userType = Cookies.get("userType");
  const totalPages = Math.ceil(totalValue / pageSize) - 1;
  const startIndex = currentPage * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalValue);
  const totalItems = totalValue;
  const { theme } = useContext(AppContext);

  useEffect(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const formatDate = (date) => date.toISOString().split("T")[0];
    setFromDate(formatDate(yesterday));
    setToDate(formatDate(today));
  }, []);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await fetchPdfData(
          selectedOption,
          AccountNo,
          currentPage,
          pageSize,
          fromDate,
          toDate
        );
        setPdfData(data.taskPdfList);
        setTotalValue(data?.count || 0);
      } catch (error) {}
    };
    getData();
  }, [selectedOption, AccountNo, currentPage, pageSize, fromDate, toDate]);

  const handlePageSizeChange = (e) => {
    const size = parseInt(e.target.value, 10);
    setPageSize(size);
    setCurrentPage(0);
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleAccountNoChange = (event) => {
    setAccountNo(event.target.value);
  };

  const handleExportClick = (pdfLink) => {
    setSelectedPdfLink(pdfLink);
    setOpen(true);
  };

  const handleFromDate = (event) => {
    setFromDate(event.target.value); // Corrected assignment here
  };

  const handleToDate = (event) => {
    setToDate(event.target.value);
  };
  const navigate = useNavigate();

  // const handleGoBack = () => {
  //   navigate("/worklog");
  // };
  const handleGoBack = () => {
    const navigationPath =
      Cookies.get("viewMode") === "worklog" ? "/worklog" : "/dashboard";
    navigate(navigationPath);
    Cookies.remove("viewMode");
  };

  return (
    <div>
      <Header pageName={"PDF"} />

      <div
        className={`bg-white  ${
          theme === "dark"
            ? "dark-mode "
            : theme === "high-contrast"
            ? "high-contrast "
            : ""
        }`}
      >
        <div className="flex p-2 gap-5">
          <div className="flex gap-2 px-5">
            <div
              onClick={handleGoBack}
              className={`p-2 py-2 mt-4 rounded-full border cursor-pointer w-9 h-9
    ${theme === "dark" ? "bg-gray-800 border-gray-600 hover:bg-gray-700" : ""} 
    ${
      theme === "high-contrast"
        ? "bg-yellow-200 border-black hover:bg-yellow-300"
        : ""
    } 
    ${
      theme !== "dark" && theme !== "high-contrast"
        ? "border-gray-400 hover:bg-gray-200"
        : ""
    }
  `}
            >
              <FaArrowLeft
                className={`${
                  theme === "dark"
                    ? "text-white"
                    : theme === "high-contrast"
                    ? "text-black"
                    : "text-gray-700"
                }`}
              />
            </div>
            <div className="flex flex-col">
              {" "}
              <label className="text-xs font-medium uppercase tracking-wider">
                From Date
              </label>
              <input
                type="date"
                value={fromDate}
                onChange={handleFromDate}
                className={`${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast "
                    : ""
                } rounded-md`}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-xs font-medium uppercase tracking-wider">
                To Date
              </label>
              <input
                type="date"
                value={toDate}
                onChange={handleToDate}
                className={`rounded-md ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast "
                    : ""
                }`}
              />
            </div>
          </div>
        </div>
        <div className="relative overflow-x-auto  sm:rounded-lg">
          <div className="bg-gray-white py-5">
            <div className="container1 mx-auto px-4 sm:px-6 lg:px-4">
              <table
                id="example"
                className={`w-full text-sm text-left rtl:text-right t ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                }`}
              >
                <thead
                  className={`text-sm text-gray-500  ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                >
                  <tr
                    className={`border-b  border-gray-300 bg-gray-100 ${
                      theme === "dark"
                        ? "dark-mode"
                        : theme === "high-contrast"
                        ? "high-contrast"
                        : ""
                    }`}
                  >
                    <th className="px-3 py-1">
                      <span className="flex text-left text-xs font-medium uppercase tracking-wider">
                        Account No
                      </span>
                      <span>
                        <input
                          className={`${
                            theme === "dark"
                              ? "dark-mode "
                              : theme === "high-contrast"
                              ? "high-contrast "
                              : ""
                          } w-24 my-2 rounded-md text-sm text-gray-700`}
                          type="text"
                          placeholder="Search.."
                          value={AccountNo}
                          onChange={handleAccountNoChange}
                        />
                      </span>
                    </th>
                    <th className="px-3 py-1 text-left text-xs font-medium uppercase tracking-wider">
                      Visit Date
                    </th>
                    <th className="px-3 py-1 text-left text-xs font-medium uppercase tracking-wider">
                      Visit Time
                    </th>
                    <th className="px-3 py-1">
                      <span className="flex gap-1 text-left text-xs font-medium uppercase tracking-wider">
                        <FaCloudDownloadAlt className="mt-1" /> Export Pdf
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pdfData.map((item) => (
                    <tr key={item.id} className="border-b ">
                      <td className=" p-4">{item.accountNo}</td>
                      <td className=" p-4">{item.visitDate}</td>
                      <td className=" p-4">{item.visitTime}</td>
                      <td className=" p-4">
                        {item.pdfLink ? (
                          <div onClick={() => handleExportClick(item.pdfLink)}>
                            <button className="py-2 px-5 rounded-md w-20 bg-yellow-400 hover:bg-yellow-500 text-white">
                              Export
                            </button>
                          </div>
                        ) : (
                          <span>No PDF available</span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="px-5 py-5 flex justify-between items-center">
          <div className="text-sm text-gray-400 flex items-center gap-4">
            <label htmlFor="pageSize">Rows per page:</label>
            <select
              id="pageSize"
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5 ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
          </div>
          <div>
            <p className="text-sm text-gray-400 flex items-center gap-4">
              {`${startIndex + 1}-${Math.min(
                endIndex,
                totalItems
              )} of ${totalItems} items`}
              <button
                onClick={goToPreviousPage}
                disabled={currentPage === 0}
                className={`p-2 rounded-lg ${
                  currentPage === 0
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-200"
                }`}
              >
                <IoIosArrowBack />
              </button>
              <button
                onClick={goToNextPage}
                disabled={currentPage === totalPages}
                className={`p-2 rounded-lg ${
                  currentPage === totalPages
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-200"
                }`}
              >
                <IoIosArrowForward />
              </button>
            </p>
          </div>
        </div>
      </div>
      {open && (
        <Modal pdfLink={selectedPdfLink} setOpen={setOpen} open={open} />
      )}
    </div>
  );
}

export default PdfGenerator;
