import React, { useContext, useEffect, useState, useRef } from "react";
import { FaPerson, FaUserGroup } from "react-icons/fa6";
import { FiMenu } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import MapView from "../../assets/map-sharp.png";
import MultiSelect from "../../assets/multi-select.png";
import createImg from "../../assets/Vector (6).png";
import importImg from "../../assets/arrow-undo-circle.png";
import assignImg1 from "../../assets/Group 2085662708.png";
import assignImg2 from "../../assets/Group 2085662707.png";
import {
  assignByArea,
  smartAssignRulesList,
} from "../../service/Configuration";
import { FaExpand } from "react-icons/fa";
import debounce from "lodash/debounce";
import { getFieldAgents, smartAssign } from "../../service/Task";
import { RxCross2 } from "react-icons/rx";
import Cookies from "js-cookie";
import Joyride from "react-joyride";
const translations ={
  en:{
    multiSelect:"Multi Select",
    smartAssign:"Smart Assign",
    fieldAgent:"Field Agent",
    createNewTask:" Create new task",
    importCSV:"Import CSV"
  },
  ar:{
    multiSelect:"تحديد متعدد",
    smartAssign:"التعيين الذكي",
    fieldAgent:"الوكيل الميداني",
    createNewTask:"إنشاء مهمة جديدة",
    importCSV:"استيراد ملف CSV"
  }
}
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const MapViewToolbar = ({
  selectedtask,
  setIsChecked,
  showFieldAgent,
  setShowFieldAgent,
  onMultiSelect,
  drawingEnabled
}) => {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const { maptoolbar,runTour,setIsDraggable, isDraggable, setFieldAgentList, workflowId , langMode } =
    context;
  const [showAssignByRules, setShowAssignByRules] = useState(false);
  const { theme } = useContext(AppContext);
  const [assignmentList, setAssignmentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const [showSmartAssignModal, setShowSmartAssignModal] = useState(false);
  const [fetchState, setFetchState] = useState({ page: 0, size: 2 });
  const initialLoadRef = useRef(true);
  const toggleDraggable = () => {
    setIsDraggable(!isDraggable);
  };

  useEffect(() => {}, [isDraggable]);
  useEffect(() => {
    const fetchFieldAgents = async () => {
      try {
        const agents = await getFieldAgents();
        setFieldAgentList(agents);
      } catch (error) {}
    };
    fetchFieldAgents();
  }, []);

  const toggleSmartAssignModal = async () => {
    try {
      const responseData = await smartAssign(workflowId);
      if (responseData.header.code == 600) {
        toast.success(responseData.body.value);
      } else {
        toast.error(responseData.body.value);
      }
    } catch (error) {}
  };
  useEffect(() => {
    const handleScroll = () => {
      if (isLoading || !hasMore) return;
      const scrollTop = window.innerHeight + document.documentElement.scrollTop;
      const offsetHeight = document.documentElement.offsetHeight;
      if (scrollTop >= offsetHeight - 200) {
        loadMore();
      }
    };
    const debounceScroll = debounce(handleScroll, 200);
    window.addEventListener("scroll", debounceScroll);
    return () => {
      window.removeEventListener("scroll", debounceScroll);
    };
  }, [isLoading, hasMore]);

  useEffect(() => {
    if (page > 0 && hasMore && !isLoading) {
      fetchAssignmentRules();
    }
  }, [page, hasMore, isLoading]);

  const loadMore = () => {
    if (!isLoading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const fetchAssignmentRules = async (reset = false) => {
    setIsLoading(true);
    try {
      if (
        initialLoadRef.current ||
        page !== fetchState.page ||
        size !== fetchState.size
      ) {
        setFetchState({ page, size });
        if (initialLoadRef.current) {
          initialLoadRef.current = false;
        }
        await smartAssignRulesList(
          reset,
          workflowId,
          page,
          size,
          setIsLoading,
          setAssignmentList,
          setHasMore,
          setError
        );
      }
    } catch (error) {
      console.error("Error fetching assignment rules:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAssignmentRules();
  }, [page, size]);

  useEffect(() => {
    if (workflowId) {
      setPage(0);
      setSize(4);
      setAssignmentList([]);
      fetchAssignmentRules(true);
    }
  }, [workflowId]);

  const handleAssignByArea = async (selectedIds) => {
    try {
      const response = await assignByArea(selectedIds);
      if (response.header.code === 600) {
        toast.success("Tasks successfully assigned by area.");
        setShowSmartAssignModal(false);
      } else {
        throw new Error(response.header.message);
      }
    } catch (error) {
      console.error("Error assigning tasks by area:", error);
      toast.error("Failed to assign tasks by area.");
    }
  };

  const openSmartAssignPopup = () => {
    if (workflowId) {
      setShowSmartAssignModal(true);
    } else {
      toast.warn("Please select activity");
    }
  };

  const handleMapView = () => {
    navigate("/maptask");
  };
  const handleListView = () => {
    navigate("/task-manage");
  };
  const getRulesList = () => {
    setShowAssignByRules(!showAssignByRules);
    fetchAssignmentRules();
  };
  const handleFieldAgentClick = () => {
    setShowFieldAgent((prevState) => !prevState);
  };
  const handleGoCsv = () => {
    Cookies.set("viewMode", "map");
    navigate("/task/import-csv");
  };
  return (
    <>
    <Joyride
        steps={maptoolbar}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
        
      />
      <div
        className={`flex justify-between items-center bg-white p-2 rounded-md ${
          theme === "dark"
            ? "dark-mode border"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        <div className="flex items-center gap-4">
          <div className=" flex rounded-md">
            <button
              onClick={handleMapView}
              className={`py-2 px-2 bg-blue-800   text-lg rounded-l-md font-semibold border border-gray-300 transition-all duration-300 ${
                theme === "dark"
                  ? "dark-mode  "
                  : theme === "high-contrast"
                  ? "high-contrast  "
                  : ""
              }`}
            >
              <img
                src={MapView}
                alt="mapview"
                className="filter brightness-0 invert"
              />
            </button>
            <button
              onClick={handleListView}
              className={`py-2 px-2 rounded-r-md  text-lg  font-semibold border border-gray-300 transition-all duration-300 ${
                theme === "dark"
                  ? "dark-mode  "
                  : theme === "high-contrast"
                  ? "high-contrast  "
                  : ""
              }`}
            >
              <FiMenu />
            </button>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={onMultiSelect}
              type="button"
              className={`${
               !drawingEnabled
                  ? "text-gray-900 multiselect bg-gray-50 hover:bg-blue-800 hover:text-white border-2 border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex gap-2 items-center  me-2"
                  : `text-white bg-blue-800 hover:bg-indigo-600 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex gap-2 items-center me-2 
                 ${
                   theme === "dark"
                     ? "dark-mode"
                     : theme === "high-contrast"
                     ? "high-contrast"
                     : ""
                 }`
              }`}
            >
              {isDraggable ? (
                <FaExpand />
              ) : (
                <img src={MultiSelect} alt="multi-select" />
              )}
           {translations[langMode].multiSelect}
            </button>
            <button
              onClick={openSmartAssignPopup}
              type="button"
              className={`text-gray-900 smartAssign bg-gray-50 hover:bg-gray-100 border-2 border-gray-200 focus:ring-4 
              focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-2 py-2.5 text-center 
              inline-flex gap-2 items-center dark:focus:ring-gray-600  dark:border-gray-700
               dark:text-white dark:hover:bg-gray-700 me-2  ${
                 theme === "dark"
                   ? "dark-mode "
                   : theme === "high-contrast"
                   ? "high-contrast  "
                   : ""
               }`}
            >
              <FaUserGroup />  {translations[langMode].smartAssign}
            </button>
            <button
              onClick={handleFieldAgentClick}
              type="button"
              className={`fieldagent focus:outline-none 
            focus:ring-gray-100 font-medium rounded-lg text-sm px-2 py-2.5 text-center 
            inline-flex gap-2 items-center dark:focus:ring-gray-600 dark:border-gray-700
            dark:text-white dark:hover:bg-gray-700 me-2 ${
              showFieldAgent
                ? "bg-blue-800 hover:bg-blue-800 text-gray-50"
                : "bg-gray-50 shadow-inner border hover:bg-blue-800 hover:text-gray-100 text-gray-800"
            } ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
            >
              <FaPerson />
              {translations[langMode].fieldAgent}
            </button>
          </div>
        </div>
        <div>
          <button
            type="button"
            className="createTasks px-2 py-2 text-xs mr-4 font-medium text-center inline-flex items-center text-white bg-gray-700 rounded-lg hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            onClick={() => navigate("/task/create-task")}
          >
            <img src={createImg} className="px-1" alt="create tmg" />
            {translations[langMode].createNewTask}
          </button>
          <button
            type="button"
            className="gocsvs px-2 py-2 text-xs mr-4 font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-gray-300 "
            onClick={handleGoCsv}
          >
            <img src={importImg} className="px-1 h-5" alt="import cvs" />
            {translations[langMode].importCSV}
          </button>
        </div>
      </div>
      {showSmartAssignModal && (
        <>
          <div
            className={`bg-gray-400 shadow fixed inset-0 z-50 flex items-center justify-center bg-opacity-50
                  ${
                    theme === "dark"
                      ? "dark-mode"
                      : theme === "high-contrast"
                      ? "high-contrast"
                      : ""
                  }`}
                  style={{zIndex:"9999"}}
          >
            <div className="bg-white w-[500px] rounded-lg">
              <div className="flex items-center justify-between p-2 md:p-5  rounded-t dark:border-gray-600">
                <div className="flex flex-col">
                  <h3 className="text-sm font-bold text-gray-900 dark:text-white ">
                    Smart Assign
                  </h3>
                  <h3 className="text-sm font-semibold text-gray-700 dark:text-white">
                    Assign What ?
                  </h3>
                </div>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="small-modal"
                  onClick={() => setShowSmartAssignModal(false)}
                >
                  <RxCross2 />
                </button>
              </div>
              <div className="p-4 grid grid-cols-1 gap-x-2 gap-y-5 sm:grid-cols-4">
                <div className="flex justify-between w-full gap-24 ml-16 items-center">
                  <div
                    className="flex flex-col items-center shadow-md p-5"
                    onClick={toggleSmartAssignModal}
                  >
                    <img src={assignImg1} alt="Smart Assign" />
                    <span className="text-sm whitespace-nowrap">
                      Smart Assign
                    </span>
                  </div>
                  <div
                    className="flex flex-col items-center  shadow-md p-5"
                    onClick={getRulesList}
                  >
                    <img src={assignImg2} alt="Assign by Rules" />
                    <span
                      className="text-sm whitespace-nowrap"
                      style={{ cursor: "pointer" }}
                    >
                      Assign by Rules
                    </span>
                  </div>
                </div>
                {showAssignByRules && (
                  <>
                    <div className="sm:col-span-4 overflow-y-auto h-40">
                      <ul className="pl-5 list-none">
                        {assignmentList.map((assignment, index) => (
                          <li
                            className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600"
                            key={index}
                          >
                            <div className="flex items-center ps-3">
                              <input
                                id={`rule-${index}`}
                                type="checkbox"
                                value={assignment.id}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-500 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                onChange={(e) => {
                                  const updatedList = assignmentList.map(
                                    (rule, idx) =>
                                      idx === index
                                        ? {
                                            ...rule,
                                            isSelected: e.target.checked,
                                          }
                                        : rule
                                  );
                                  setAssignmentList(updatedList);
                                }}
                              />
                              <label
                                htmlFor={`rule-${index}`}
                                className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                {assignment.ruleName}
                              </label>
                            </div>
                          </li>
                        ))}
                        {isLoading && <p>Loading...</p>}
                        {!hasMore && !isLoading && <p>No more rules to load</p>}
                      </ul>
                    </div>
                  </>
                )}
              </div>
              <div className="flex items-center float-right p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button
                  onClick={() => setShowSmartAssignModal(false)}
                  data-modal-hide="small-modal"
                  type="button"
                  className="py-1.5 px-3 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                >
                  Cancel
                </button>
                <button
                  data-modal-hide="small-modal"
                  type="button"
                  onClick={() =>
                    handleAssignByArea(
                      assignmentList
                        .filter((rule) => rule.isSelected)
                        .map((rule) => rule.id)
                    )
                  }
                  className="text-white bg-blue-800 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 ml-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Assigne
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default MapViewToolbar;
